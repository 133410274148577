import { isEditableByPath } from "@components/smart/FieldInfo";
import { ISmartFieldBlur, ISmartFieldChange } from "@components/smart/smartField/SmartField";
import { createPath } from "@odata/BindingContext";
import { getEntitySetByDocumentType } from "@odata/EntityTypes";
import {
    BusinessPartnerEntity,
    DocumentBusinessPartnerEntity,
    DocumentEntity,
    FiscalYearEntity,
    IInvoiceReceivedEntity,
    InvoiceReceivedEntity
} from "@odata/GeneratedEntityTypes";
import { isVatRegisteredCompany } from "@utils/CompanyUtils";

import { Status } from "../../enums";
import { SAVED_VATS_PATH } from "../admin/vatRules/VatRules.utils";
import { createAndSetSymbolVariable } from "../banks/bankAccounts/BankAccounts.utils";
import { isBusinessPartnerField } from "../businessPartner/BusinessPartner.utils";
import { DocumentFormViewForExtend } from "./DocumentFormView";
import { IDocumentFormViewProps } from "./DocumentInterfaces";

abstract class ReceivedDocumentBaseFormView extends DocumentFormViewForExtend {
    lastNumberTheirs = "";

    constructor(props: IDocumentFormViewProps) {
        super(props);

        this.onAfterLoad = this.onAfterLoad.bind(this);
        this.refreshFormAfterDecisiveDateChange = this.refreshFormAfterDecisiveDateChange.bind(this);
        this.confirmationBeforeSave = this.confirmationBeforeSave.bind(this);
    }

    get entity(): IInvoiceReceivedEntity {
        return this.props.storage.data.entity as IInvoiceReceivedEntity;
    }

    async onAfterLoad(hasPreloadedData?: boolean): Promise<void> {
        await super.onAfterLoad(hasPreloadedData);


        if (!this.props.storage.data.bindingContext.isNew() || this.isDraftView()) {
            this.validateNumberTheirs();
        }

        this.lastNumberTheirs = this.entity.NumberTheirs;

    }

    async refreshFormAfterDecisiveDateChange(path: string): Promise<boolean> {
        const hasChanged = await super.refreshFormAfterDecisiveDateChange(path);
        if (hasChanged) {
            this.validateNumberTheirs();
        }
        return hasChanged;
    }

    validateNumberTheirs = async (): Promise<void> => {
        const storage = this.props.storage;
        const numberTheirsBc = this.props.storage.data.bindingContext.navigate(InvoiceReceivedEntity.NumberTheirs);
        const error = await storage.validateField(numberTheirsBc);
        if (!error && this.entity.NumberTheirs && this.entity.BusinessPartner?.BusinessPartner?.Id) {
            storage.setAdditionalFieldData(numberTheirsBc, "isBusy", true);
            storage.refreshField(numberTheirsBc.getPath());

            let filter = `${InvoiceReceivedEntity.NumberTheirs} eq '${this.entity.NumberTheirs}'`;
            if (!storage.data.bindingContext.isNew()) {
                filter += ` AND ${InvoiceReceivedEntity.Id} ne ${this.entity.Id}`;
            }
            if (this.entity.FiscalYear?.Id) {
                filter += ` AND ${createPath(InvoiceReceivedEntity.FiscalYear, FiscalYearEntity.Id)} eq ${this.entity.FiscalYear.Id}`;
            }

            filter += ` AND ${createPath(InvoiceReceivedEntity.BusinessPartner, DocumentBusinessPartnerEntity.BusinessPartner, BusinessPartnerEntity.Id)} eq ${this.entity.BusinessPartner.BusinessPartner.Id}`;

            const res = await storage.oData.getEntitySetWrapper(getEntitySetByDocumentType(this.documentTypeCode))
                .query()
                .filter(filter)
                .count()
                .top(0)
                .fetchData();

            const count = res._metadata.count;
            const validationResult = count ? {
                status: Status.Warning,
                message: storage.t("InvoicesReceived:Validation.NumberTheirsDuplicityWarning", { count })
            } : {
                status: Status.Success,
                message: storage.t("InvoicesReceived:Validation.NumberTheirsDuplicityOk")
            };

            storage.setAdditionalFieldData(numberTheirsBc, "isBusy", false);
            storage.setAdditionalFieldData(numberTheirsBc, "validationResult", validationResult);
        } else {
            storage.setAdditionalFieldData(numberTheirsBc, "validationResult", null);
        }
        storage.refreshField(numberTheirsBc.getPath());
    };

    handlePaymentMethodChange_variableSymbol(e: ISmartFieldChange): void {
        if (e.bindingContext.getPath() === "PaymentMethod") {
            // we may switched to wire transfer payment method -> VariableSymbol becomes visible, fill it.
            const { storage } = this.props;
            createAndSetSymbolVariable(storage, this.entity.NumberTheirs, this.entity.NumberTheirs);
        }
    }

    handleDateVatDeductionChangeSpecial(e: ISmartFieldChange): void {
        if (e.bindingContext.getPath() === "DateVatDeduction") {
            // if not isVatRegisteredCompany for the changed DateVatDeduction date, set SAVED_VATS_PATH to null
            const dateVatDeduction = e.value as Date;

            if (isVatRegisteredCompany(this.context, dateVatDeduction) === false) {
                this.props.storage.setValue(e.bindingContext.getRootParent().navigate(SAVED_VATS_PATH), null);
            }
        }
    }

    handleSpecificNumberTheirsBlur = (e: ISmartFieldBlur): void => {
        if (e.bindingContext.getPath() !== "NumberTheirs") {
            return;
        }

        this.validateNumberTheirs();

        const { storage } = this.props;
        if (isEditableByPath(storage, DocumentEntity.SymbolVariable)) {
            createAndSetSymbolVariable(storage, this.lastNumberTheirs, this.entity.NumberTheirs);
        }

        this.lastNumberTheirs = this.entity.NumberTheirs;
    };

    handleSpecificBusinessPartnerChange = (e: ISmartFieldChange) => {
        const isBPMainField = isBusinessPartnerField(e.bindingContext.getNavigationPath(true));
        if (isBPMainField && e.triggerAdditionalTasks) {
            this.validateNumberTheirs();
        }
    };


    documentSpecificChangeHandlers(e: ISmartFieldChange): void {
        super.documentSpecificChangeHandlers(e);

        this.handlePaymentMethodChange_variableSymbol(e);
        this.handleDateVatDeductionChangeSpecial(e);
        this.handleSpecificBusinessPartnerChange(e);
    }

    documentSpecificBlurHandlers(e: ISmartFieldBlur): void {
        super.documentSpecificBlurHandlers(e);

        this.handleSpecificNumberTheirsBlur(e);
    }


    async confirmationBeforeSave(): Promise<boolean> {
        const { storage, confirmationDialog } = this.props;
        const numberTheirsBc = storage.data.bindingContext.navigate(InvoiceReceivedEntity.NumberTheirs);
        const validationResult = storage.getAdditionalFieldData(numberTheirsBc, "validationResult");

        if (validationResult?.status === Status.Warning) {
            return confirmationDialog.open({
                content: storage.t("Document:Form.NumberTheirsDuplicityConfirmation", {
                    BPName: this.entity.BusinessPartner.Name,
                    numberTheirs: this.entity.NumberTheirs
                })
            });
        }
        return super.confirmationBeforeSave();
    }
}

export default ReceivedDocumentBaseFormView;
