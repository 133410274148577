import { BusyIndicatorSize } from "@components/busyIndicator/BusyIndicator.utils";
import { isComplexFilterArr } from "@components/conditionalFilterDialog/ConditionalFilterDialog.utils";
import { getDrillDownFilters, getDrillDownVariant, getIntentNavParams } from "@components/drillDown/DrillDown.utils";
import { CloseIcon, PlusIcon } from "@components/icon";
import { formatDateToDateString } from "@components/inputs/date/utils";
import { ISelectItem, TSelectItemId } from "@components/inputs/select/Select.types";
import { isSelectBasedComponent } from "@components/inputs/select/SelectAPI";
import { getSimpleBoolSelectItems, IFieldInfoProperties } from "@components/smart/FieldInfo";
import { ISmartFieldChange } from "@components/smart/smartField/SmartField";
import {
    FilterBarGroup,
    IFieldData,
    IFilterDef,
    TFilterDef
} from "@components/smart/smartFilterBar/SmartFilterBar.types";
import {
    COLUMN_INDEX_REGEX,
    getCleanColumnId,
    IReportColumnDef,
    IReportHierarchy,
    IReportRowDef,
    IReportWarning
} from "@components/smart/smartTable";
import { fetchReportTableData } from "@components/smart/smartTable/SmartReportTable.utils";
import { iterateOverRows, TCustomRowAction } from "@components/smart/smartTable/SmartTable.utils";
import { isValueHelperField } from "@components/smart/smartValueHelper";
import { IRow, IRowValues, ISort, TColumn, TId } from "@components/table";
import { NoData } from "@components/table/NoData";
import { getLeavesColumns } from "@components/table/TableUtils";
import { IToolbarItem, TToolbarItem } from "@components/toolbar";
import { getCompanyVariantContext, Variant, VariantType } from "@components/variantSelector/VariantOdata";
import { setBoundValue } from "@odata/Data.utils";
import { getEntitySetByDocumentType } from "@odata/EntityTypes";
import { IFieldInfo } from "@odata/FieldInfo.utils";
import { IAccountEntity } from "@odata/GeneratedEntityTypes";
import { ActionTypeCode, DocumentTypeCode, VariantAccessTypeCode } from "@odata/GeneratedEnums";
import { logAction } from "@odata/OData.utils";
import { WithOData, withOData } from "@odata/withOData";
import { isDefined, isNotDefined, sortCompareFn } from "@utils/general";
import { logger } from "@utils/log";
import { compareString } from "@utils/string";
import { saveAs } from "file-saver";
import { cloneDeep, debounce, isEqual, uniq } from "lodash";
import React, { ReactElement } from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import Alert, { AlertAction } from "../../components/alert/Alert";
import { BreadCrumbProvider, THistoryBack } from "../../components/breadCrumb/index";
import BusyIndicator from "../../components/busyIndicator/BusyIndicator";
import { Button } from "../../components/button";
import Clickable from "../../components/clickable";
import ConfigurationList, {
    getItemCopyId,
    IConfigList,
    IGroupListColumnDef,
    IGroupListGroupDef,
    IGroupListItemDef,
    trimCopyId
} from "../../components/configurationList/ConfigurationList";
import Dialog from "../../components/dialog/Dialog";
import SmartDrillDownFilters from "../../components/drillDown/SmartDrillDownFilters";
import SmartReportTable from "../../components/smart/smartTable/SmartReportTable";
import TableSortingDialog from "../../components/table/TableSortingDialog";
import { DASH_CHARACTER, INPUT_DEBOUNCE_TIME, REST_API_URL } from "../../constants";
import { AppContext } from "../../contexts/appContext/AppContext.types";
import {
    ConfigListItemBoundType,
    FieldType,
    GroupStatus,
    LogicOperator,
    ReportTableRowType,
    Sort,
    Status,
    ValidatorType,
    ValueType
} from "../../enums";
import { TRecordAny, TRecordString, TRecordType, TValue } from "../../global.types";
import { ModelEvent } from "../../model/Model";
import { StorageModel } from "../../model/StorageModel";
import BindingContext, { getBindingContext, IEntity } from "../../odata/BindingContext";
import { ROUTE_CHARTS_OF_ACCOUNTS } from "../../routes";
import TestIds from "../../testIds";
import DateType, { getUtcDate } from "../../types/Date";
import customFetch from "../../utils/customFetch";
import ExcelExport, { ExportType } from "../../utils/ExcelExport";
import LocalSettings from "../../utils/LocalSettings";
import { SmartHeaderStyled } from "../../views/formView/FormView.styles";
import ConfirmationButtons from "../../views/table/ConfirmationButtons";
import TablePrintDialog from "../../views/table/TablePrintDialog";
import TableToolbar, { TableButtonsAction, TableButtonsActionType } from "../../views/table/TableToolbar";
import { TableWrapper } from "../../views/table/TableView.styles";
import { IChangedFilter } from "../../views/table/TableView.utils";
import View from "../../views/View";
import {
    findColumnInHierarchy,
    getColumnFilterId,
    getColumnLabel,
    getFieldTypeFromColumnType,
    getFilterNameWithoutEntitySetPrefix,
    getMatchingColumn,
    getNumberAggFuncItem,
    getNumberAggFuncItems,
    getReportRowId,
    getTimeAggFuncItems,
    getValidatorTypeFromColumnType,
    getValueTypeFromColumnType,
    IReportData,
    IReportTableDefinition,
    NumberAggFuncTypes,
    NumberAggregationFunction,
    prepareReportDataForExport,
    ReportColumnType,
    ReportConfigGroup,
    ReportFilterNodeColumnType,
    reportGroups,
    ReportNodeOperator,
    TAggregationFunction,
    TGetReportDefValFn,
    TimeAggregationFunction
} from "./Report.utils";
import { applyFilter, buildFilterTree, getReportLogActionDetail, TBuildReportNodeFilter } from "./ReportFilter.utils";
import { ReportId } from "./ReportIds";
import { IReportStorageDefaultCustomData, ReportStorage } from "./ReportStorage";
import { StyledSmartFilterBar, Warnings } from "./ReportView.styles";

const dimValues = [0];
const SUPPORTED_COLUMNS_PATH = "SupportedColumns";

export interface IReportFilterNode extends Omit<IReportColumnDef, "ColumnAlias" | "Type" | "Column"> {
    Operator?: LogicOperator | ReportNodeOperator;
    Value?: TValue;
    Left?: IReportFilterNode;
    Right?: IReportFilterNode;
    Type?: ReportFilterNodeColumnType;
    ColumnAlias?: string;
    Column?: IReportColumnDef;
    Node?: IReportFilterNode;
}

export interface IReportFilterChangeEvent {
    settings: IEntity;
    filterChange: ISmartFieldChange;
    isParameterChanged: boolean;
}

export interface IReportViewBaseProps<C extends IReportStorageDefaultCustomData = IReportStorageDefaultCustomData> {
    storage: ReportStorage<C>;
    rootStorage?: StorageModel;
    definition: IReportTableDefinition;

    onFilterChange?: (args: IReportFilterChangeEvent) => IEntity;
    onExpandAll?: () => void;
    onCollapseAll?: () => void;
    onRowSelect?: (bindingContext: BindingContext) => void;
    rowAction?: TCustomRowAction;
    onPrintReportButtonClick?: (buttonAction: TableButtonsAction) => void;
    getFilterNode?: (filter: IFieldData) => IReportFilterNode;
    expandable?: boolean;
    disableConfiguration?: boolean;
    disableSort?: boolean;
    customToolbarButtons?: (storage: ReportStorage<C>) => IToolbarItem[];
    showExportButton?: boolean;
    showPrintReportButtons?: boolean;
    disableToolbarButtons?: boolean;

    back?: THistoryBack;

    ref?: React.Ref<ReportView>;
}

export interface IReportViewProps<C extends IReportStorageDefaultCustomData = IReportStorageDefaultCustomData> extends IReportViewBaseProps<C>, WithTranslation, WithOData {

}

interface IUpdateTableSettings {
    isParameterChanged?: boolean;
    filterChange?: ISmartFieldChange;
    forceImmediate?: boolean;
}

export interface IReportViewState {
    loaded: boolean;
    hasNoData: boolean;
    preventReload: boolean;
    isTableReadyForPrint: boolean;
    showPdfExport: boolean;
    isCustomizationDialogOpen: boolean;
    isTableSortDialogOpen: boolean;
    dialogReportHierarchy: IReportHierarchy;
}

class ReportView<P extends IReportViewProps = IReportViewProps> extends React.Component<P, IReportViewState> {
    static contextType = AppContext;
    //sadly, breaks typescript type checking
    //context: React.ContextType<typeof AppContext>;

    static defaultProps = {
        showExportButton: true
    };

    tableRef = React.createRef<HTMLDivElement>();

    state: IReportViewState = {
        loaded: false,
        hasNoData: false,
        preventReload: false,
        isTableReadyForPrint: false,
        showPdfExport: false,
        isCustomizationDialogOpen: false,
        isTableSortDialogOpen: false,
        dialogReportHierarchy: null
    };

    componentDidMount() {
        this.init();
        this.props.storage.emitter.on(ModelEvent.VariantChanged, this.handleVariantChange);
    }

    componentWillUnmount() {
        this.props.storage.emitter.off(ModelEvent.VariantChanged, this.handleVariantChange);
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<IReportViewState>, snapshot?: any) {
        //
    }

    get isToolbarDisabled(): boolean {
        return !this.props.storage.tableAPI?.getState()?.loaded;
    }

    init = async (): Promise<void> => {
        const localSettings = LocalSettings.get(this.props.storage.id);
        // hierarchy has already been initialized in ReportStorage
        const reportHierarchy = this.props.storage.reportHierarchy;

        this.injectDrillDownFiltersAsColumns(reportHierarchy);
        this.props.storage.setCustomData({ ...localSettings.customData });

        // onBeforeLoad can initialize storage - has to be called before initTableSettings
        await Promise.all([
            this.props.definition.onBeforeLoad?.(this.props.storage),
            this.fetchSupportedColumns(this.props.definition)
        ]);

        const settings = this.initTableSettings(this.props.definition);

        // needs supported columns to be already loaded
        if (!this.props.definition.updateFiltersFromResponse) {
            this.updateFiltersFromReportHierarchy(reportHierarchy as IReportHierarchy);
        }
        this.setSettings(settings, true);

        if (!this.state.loaded) {
            this.setState({
                loaded: true
            });
        }

        if (this.state.preventReload) {
            this.setState({
                preventReload: false
            });
        }
    };

    handleVariantChange = async (): Promise<void> => {
        // TODO: move filter management to report storage
        // set withoutSetSettings true to prevent firing multiple requests in SmartReportTable,
        // settings are updated in updateTableSettings again
        this.setState({
            preventReload: true
        }, async () => {
            await this.init();
            this.updateTableSettings();
        });
    };

    /** Backend doesn't allow filtering on columns that are not part of reportHierarchy.
     * If drilldown filter is present for column that is not add by default, we have to inject it. */
    injectDrillDownFiltersAsColumns = (reportHierarchy: IReportHierarchy): void => {
        const drillDownFilters = getDrillDownFilters();

        if (!reportHierarchy || !drillDownFilters) {
            return;
        }

        for (const filter of Object.keys(drillDownFilters)) {
            if (this.isFilterParameter(filter)) {
                continue;
            }

            const cleanFilterName = BindingContext.cleanLocalContext(filter);

            if (!findColumnInHierarchy(reportHierarchy, cleanFilterName)) {
                reportHierarchy.Columns.push({
                    ColumnAlias: cleanFilterName
                });
            }
        }
    };

    // todo merge with updateTableSettings
    initTableSettings = (definition: IReportTableDefinition): TRecordAny => {
        const storage = this.props.storage;
        // insert drilldown filters to into the initial request
        // for filter change, it's handled by TableStorage.getChangedFilters
        // don't use filters from LocalSettings when drillDownFilters passed via history state
        const drillDownFilters = getDrillDownFilters();
        const drillDownVariant = getDrillDownVariant();
        const savedFilters = { ...(drillDownFilters ?? storage.getVariant().filters ?? {}) };

        if (drillDownVariant) {
            // remove local storage variant if exists -> drillDown variant should be visible in the selector as it is used
        }

        const andFilters: IFieldData[] = [];
        let settings = storage.settings;
        // initialize parameters with default value or from saved filters
        for (const filterGroup of definition.filterBarDef) {
            for (const filterId of filterGroup.defaultFilters) {
                const bindingContext = storage.data.bindingContext.navigate(filterId);
                const info = storage.getInfo(bindingContext);
                // default value is already in the storage + can be modified by particular report in before load hook
                let value = storage.getValue(bindingContext);

                // savedFilters have higher priority over default filters
                if (savedFilters[filterId] !== undefined) {
                    value = savedFilters[filterId];
                }

                value = this.getCorrectFilterValue(value, info);

                if (this.isFilterParameter(bindingContext) && isDefined(value) && !info?.fieldSettings?.frontendOnly) {
                    settings = setBoundValue({
                        bindingContext,
                        data: settings,
                        newValue: value,
                        dataBindingContext: storage.data.bindingContext
                    });

                    delete savedFilters[filterId];
                }

            }
        }

        // use the rest of saved filters to initialise filter query
        for (const [filterName, filterValue] of Object.entries(savedFilters)) {
            const bc = storage.data.bindingContext.navigate(filterName);
            const info = definition.filterBarDef[0]?.filterDefinition[filterName] ?? definition.filterBarDef[1]?.filterDefinition[filterName];
            const path = bc.getNavigationPath();

            if (!info?.fieldSettings?.frontendOnly && isDefined(filterValue) && filterValue !== "") {
                andFilters.push({
                    id: path,
                    bindingContext: bc,
                    value: (info?.filter?.transformFilterValue?.(filterValue as TValue, this.props.storage.getInfo(bc)) ?? filterValue) as TValue,
                    filterName: info?.filterName
                });
            }
        }

        if (andFilters.length > 0) {
            settings["Filter"] = this.buildFilterTree(andFilters);
        }

        if (drillDownVariant) {
            let variant: Variant = storage.data?.variants?.allVariants?.[drillDownVariant];

            if (!variant) {
                // variant that only has FE definition
                // => create fake "Variant" object and use it as currentVariant
                const variantDef = storage.data.definition.defaultReportVariants[drillDownVariant];

                const { accounting, vatStatus } = getCompanyVariantContext(this.context);

                if (variantDef) {
                    variant = new Variant({
                        id: drillDownVariant,
                        name: drillDownVariant,
                        viewId: this.props.storage.id,
                        variantType: VariantType.Table,
                        accessType: VariantAccessTypeCode.SystemVariant,
                        entityType: null,
                        vatStatusCode: vatStatus,
                        accountingCode: accounting,
                        table: {
                            columns: this.props.storage.convertReportHierarchyToVariantColumns(variantDef.ReportHierarchy),
                            filters: null,
                            visibleFilters: []
                        }
                    });
                }
            }

            if (!variant) {
                logger.error("drilldown variant is missing");
            }
            storage.data.variants.currentVariant = variant;
        }
        return settings;
    };

    fetchSupportedColumns = async (definition: IReportTableDefinition): Promise<void> => {
        const url = `${REST_API_URL}/${definition.path}/${SUPPORTED_COLUMNS_PATH}`;

        const response = await customFetch(url);
        const returnedValues = response.ok ? await response.json() : {};
        const supportedColumns = returnedValues.Columns ?? [];
        // filters that are not 1:1 with the report hierarchy settings
        // they represent virtual columns that only have one filter field on FE that filters across all of the at once
        const additionalFilters = returnedValues.AdditionalFilters ?? [];

        this.props.storage.supportedColumns = supportedColumns;
        this.props.storage.additionalFilters = additionalFilters;
    };

    updateFilters = (columns: IReportColumnDef[], aggFnIncluded?: boolean): void => {
        const customFilters: string[] = [];
        const drillDownFilters = getDrillDownFilters();
        const savedFilters = drillDownFilters ?? this.props.storage.getVariant()?.filters ?? {};

        for (const column of columns) {
            const supportedColumn = this.getColumnFilterDef(column.ColumnAlias);

            if (!supportedColumn) {
                logger.warn(`column '${column.ColumnAlias}' defined in report hierarchy is not present in supportedColumns`);
                continue;
            }

            if (isDefined(supportedColumn.IsFilterable) && !supportedColumn.IsFilterable) {
                continue;
            }

            const id = getColumnFilterId(supportedColumn.IsColumnAliasFilterPrefix ? supportedColumn : column, aggFnIncluded);

            // skip columns that already have their definition added
            // virtual columns can use the same filter definition multiple times
            if (!!customFilters.find(filterName => filterName.endsWith(`/${id}`))) {
                continue;
            }

            const bindingContext = this.props.storage.data.bindingContext.navigate(id);
            const path = bindingContext.getNavigationPath();
            const fullPath = bindingContext.toString();
            const label = getColumnLabel({
                ...column,
                Label: supportedColumn.Label
            });
            const hasNumberAggFn = !!column.AggregationFunction && !!getNumberAggFuncItem(column.AggregationFunction as NumberAggregationFunction);

            if (supportedColumn.VirtualColumn) {
                // virtual columns are not represented in filters
                continue;
            }

            let filterMetadata: IFieldInfo & IFilterDef = {
                id, label,
                bindingContext,
                type: hasNumberAggFn ? FieldType.NumberInput : getFieldTypeFromColumnType(supportedColumn),
                valueType: hasNumberAggFn ? ValueType.Number : getValueTypeFromColumnType(supportedColumn),
                // don't use value help for IsColumnAliasFilterPrefix
                // how would we group the columns into one value help (e.g. in BalanceSheet)?
                isValueHelp: !supportedColumn.IsColumnAliasFilterPrefix,
                validator: {
                    type: hasNumberAggFn
                        ? column.AggregationFunction === NumberAggregationFunction.Count ? ValidatorType.Integer : ValidatorType.Number
                        : getValidatorTypeFromColumnType(supportedColumn)
                },
                fieldSettings: {}
            };

            // filters for Labels don't use value helps,
            // instead use LabelSelect, just like in oData pages
            if (!hasNumberAggFn && supportedColumn.Type === ReportColumnType.Label) {
                filterMetadata.isValueHelp = false;
                filterMetadata.fieldSettings.idName = "Name";
                filterMetadata.fieldSettings.oneItemPerHierarchy = false;
                filterMetadata.type = FieldType.LabelSelect;
                filterMetadata.filter = {
                    select: `Name eq '${supportedColumn.Label}'`
                };
            } else if (supportedColumn.Type === ReportColumnType.Boolean) {
                // filters for Boolean don't use value helps as well
                filterMetadata.isValueHelp = false;
                filterMetadata.fieldSettings.items = getSimpleBoolSelectItems();
            }

            if (isDefined(savedFilters[path])) {
                this.props.storage.data.entity[id] = savedFilters[path];
                // propagate to parsedValue, otherwise value won't show properly in read only filter bar
                this.props.storage.setAdditionalFieldData(bindingContext, "parsedValue", savedFilters[path] as TValue);
            }

            // merge with custom filter definition if defined for the filter id
            // (available filters doesn't have to be shown, but can have some special definition for the dynamic columns)
            const filterDef = this.props.definition.filterBarDef?.[1]?.filterDefinition ?? {};
            const availableFilters = Object.keys(filterDef).map(id => ({ id, ...filterDef[id] }));
            let customFilter = availableFilters?.find((f: TFilterDef) => f.id.startsWith(id));

            if (customFilter) {
                customFilter = { ...customFilter, id: filterMetadata.id };

                filterMetadata = {
                    ...filterMetadata,
                    ...customFilter
                };
            }

            this.props.storage.data.fieldsInfo[fullPath] = filterMetadata;

            customFilters.push(fullPath);
        }

        this.props.storage.data.visibleFilters[FilterBarGroup.Filters] = customFilters;
        this.updateTableStorageValidationSchema();
    };

    // because some filters are fetched dynamically, we can't create correct validation schema for all of them at init
    updateTableStorageValidationSchema = (): void => {
        this.props.storage.createValidationSchema(Object.values(this.props.storage.data.fieldsInfo).map(info => info.id), false);
    };

    /** Basic reports take filters from "reportHierarchy" which has all the required data.
     *  Some reports (like General Ledger, BalanceSheet, IncomeStatement) doesn't use reportHierarchy because of their predefined structure.
     *  We can't predefine the filters manually because
     *      1. they can change based on report parameters (GeneralLedger which changes time agg function)
     *      2. there can be virtual columns which should be aggregated to one filter field on FE (BalanceSheet, IncomeStatement)
     *  In those cases, we have to build the filters AFTER the data are loaded from BE */
    updateFiltersFromReturnedColumns = (): void => {
        const columns = this.props.storage.tableAPI?.getState()?.data?.Columns;

        if (columns) {
            this.updateFilters(columns, true);
            // the filter bar can already be opened, we need to refresh whole report view
            this.forceUpdate();
        }
    };

    /** keep report hierarchy (default or changed in setting dialog) in sync with filters */
    updateFiltersFromReportHierarchy = (reportHierarchy: IReportHierarchy): void => {
        const columns: IReportColumnDef[] = [];
        const oldCustomFilters = [...this.props.storage.data.visibleFilters[FilterBarGroup.Filters]];

        for (const property of reportGroups) {
            columns.push(...(reportHierarchy[property] as IReportColumnDef[]));
        }

        this.updateFilters(columns);


        const currentCustomFilters = this.props.storage.data.visibleFilters[FilterBarGroup.Filters];
        for (const oldCustomFilter of oldCustomFilters) {
            if (!currentCustomFilters.includes(oldCustomFilter)) {
                this.props.storage.clearFilterByPath(oldCustomFilter);
            }
        }
    };

    isRowFilteredOutForColumn = (values: IRowValues, filters: IChangedFilter[]): boolean => {
        for (const filter of filters) {
            const cleanId = (filter.info.filterName as string) ?? BindingContext.cleanLocalContext(filter.info.id);

            if (!applyFilter(values[cleanId] as string | number, filter)) {
                return true;
            }
        }

        return false;
    };

    /** Sets value helper items to all unique items retrieved from table data */
    updateValueHelpers = (rows: IRow[]): void => {
        // unique keys in original order
        const uniqueKeys: Record<string, Set<TValue>> = {};
        // values for the keys stored in object, without order
        const values: Record<string, Record<string, TValue>> = {};
        const filterDef = this.props.definition.filterBarDef?.find(def => def.id === FilterBarGroup.Filters);
        const defaultFilters = filterDef?.defaultFilters ?? [];
        const filtersIds = [
            ...this.props.storage.data.visibleFilters[FilterBarGroup.Filters],
            ...defaultFilters.map((filter) => this.props.storage.data.bindingContext.navigate(filter).toString())
        ].filter(filterName => isValueHelperField(this.props.storage.data.fieldsInfo[filterName], this.props.storage));
        const drilldownFilters = getDrillDownFilters();

        const currentFilters = this.props.storage.getChangedFilters().groups[FilterBarGroup.Filters] ?? [];

        for (const filterId of filtersIds) {
            uniqueKeys[filterId] = new Set();
            values[filterId] = {};
        }

        iterateOverRows(rows, (row) => {
            const originalValues = row.customData.originalValues;
            const originalValuesFormatted = row.customData.originalValuesFormatted;
            const originalReportRow = row.customData.originalRow as IReportRowDef;

            for (const filterId of filtersIds) {
                if (drilldownFilters?.[filterId.split("/").slice(-1)[0]]) {
                    // drilldown filters are disabled => ignore
                    continue;
                }

                const labelId = BindingContext.cleanLocalContext(getFilterNameWithoutEntitySetPrefix(filterId));
                const cleanId = (this.props.storage.data.fieldsInfo[filterId]?.filterName as string) ?? labelId;
                const otherColumnsFilters = currentFilters.filter(changedFilter => BindingContext.cleanLocalContext(changedFilter.info.id) !== labelId);

                if (originalReportRow.Type === ReportTableRowType.Total || originalReportRow.Type === ReportTableRowType.GrandTotal) {
                    continue;
                }

                if (isDefined(originalValues?.[cleanId]) && isNotDefined(values[filterId][originalValues[cleanId].toString()]) && !this.isRowFilteredOutForColumn(originalValues, otherColumnsFilters)) {
                    const label = originalValuesFormatted[labelId];

                    uniqueKeys[filterId].add(originalValues[cleanId]);
                    values[filterId][originalValues[cleanId].toString()] = isNotDefined(label) || label === "" ? this.props.t("Common:General.Empty") : label;
                }
            }

            return row;
        });

        for (const filterId of filtersIds) {
            if (drilldownFilters?.[filterId.split("/").slice(-1)[0]]) {
                // drilldown filters are disabled => ignore
                continue;
            }

            const items: ISelectItem[] = [];

            for (const key of uniqueKeys[filterId].keys()) {
                items.push({
                    id: key as TSelectItemId,
                    label: values[filterId][key.toString()]?.toString()
                });
            }

            // apply same sorting rules as used in oData tables
            if (this.props.storage.data.fieldsInfo[filterId]?.valueType !== ValueType.Date) {
                items.sort((a, b) => compareString(a.label, b.label));
            } else {
                items.sort((a, b) => sortCompareFn(getUtcDate(a.id as string), getUtcDate(b.id as string), Sort.Desc));
            }

            this.props.storage.data.fieldsInfo[filterId] = {
                ...this.props.storage.data.fieldsInfo[filterId],
                fieldSettings: {
                    ...this.props.storage.data.fieldsInfo[filterId]?.fieldSettings,
                    items
                }
            };
        }

        this.props.storage.refresh();
    };

    buildFilterTree = (filters: IFieldData[]): IReportFilterNode => {
        const filtersWithDefs: TBuildReportNodeFilter[] = filters.map(filter => {
            const filterName = BindingContext.cleanLocalContext(filter.bindingContext.getPath());
            const filterDef = this.getColumnFilterDef(filterName);
            return {
                ...filter,
                columnDef: filterDef
            };
        });

        return buildFilterTree({
            filters: filtersWithDefs,
            allColumns: this.props.storage.tableAPI?.getState()?.data?.Columns,
            getCustomNode: this.props.getFilterNode
        });
    };

    isFilterParameter = (filter: BindingContext | string): boolean => {
        return !!this.props.definition.parameters?.find(param =>
            typeof filter === "string" ? param === filter : param === filter.getNavigationPath()
        );
    };

    getCorrectFilterValue = (value: any, info: IFieldInfoProperties) => {
        let newValue = value;

        if (info.filter?.transformFilterValue) {
            newValue = info.filter?.transformFilterValue(newValue, info as IFieldInfo);
        }

        if (isNotDefined(newValue) || isComplexFilterArr(newValue)) {
            return newValue;
        }

        if ((info.filterName || info?.type === FieldType.LabelSelect) && info.fieldSettings?.items) {
            // we want to retrieve value from Select items that is different from id
            // replaces values (that are id) with values defined by filterName
            // LabelSelects has to use "Name" instead of their default id

            if (Array.isArray(newValue)) {
                newValue = info.fieldSettings?.items.filter(item => (newValue as any[]).indexOf(item.id) >= 0).map(item => item.additionalData?.[info.filterName as string] ?? item.id);
            } else {
                const item = info.fieldSettings?.items.find(item => item.id === newValue);
                newValue = item?.additionalData?.[info.filterName as string] ?? item?.id;
            }
        }

        if (newValue instanceof Date) {
            newValue = formatDateToDateString(newValue as Date);
        } else if (newValue && typeof newValue === "object") {
            for (const key of Object.keys(newValue)) {
                if (newValue[key] instanceof Date) {
                    newValue[key] = formatDateToDateString(newValue[key] as Date);
                }
            }
        }

        return newValue;
    };

    handleFilterChange = (args: ISmartFieldChange) => {
        // if (args.type === FieldType.LabelSelect) {
        //     args.value = (args.value as string[]).map((val, index) => args.selectedItems[index].additionalData.Name);
        // }

        if (args) {
            this.props.storage.handleFilterChange(args);
        }

        if (isSelectBasedComponent(args.type) && !args.triggerAdditionalTasks) {
            this.props.storage.refreshFields();
            return null;
        }

        let isParameterChanged = false;

        if (args) { // can be called without args, just to refresh filter query value from already changed filter data
            isParameterChanged = this.isFilterParameter(args.bindingContext);
        }

        return this.updateTableSettings({
            isParameterChanged,
            filterChange: args
        });
    };

    canExpandCollapseRows = (): boolean => {
        const reportHierarchy: IReportHierarchy = this.props.storage.reportHierarchy;
        // if there is no option to configure the report (configuration is disabled), expand/collapse button visibility
        // is driven by this.props.expandable flag and they are always enabled. If there is settings button, we enable/disable
        // the button according to Aggregation settings (only when there is anything to collapse/expand)
        const canCollapse = !!((reportHierarchy?.Aggregate && reportHierarchy.Groups?.length && (reportHierarchy.Groups?.length > 1 || reportHierarchy.Columns.length)) || this.props.disableConfiguration);

        return canCollapse || !!this.props.definition?.canExpandCollapseRows?.(this.props.storage);
    };

    handleClearFilter = (): void => {
        const filtersChanged = this.props.storage.clearFilters();

        if (filtersChanged) {
            this.updateTableSettings({});
        }
    };

    setSettings = (settings: IEntity, preventUpdate?: boolean): void => {
        this.props.storage.settings = settings;

        if (!preventUpdate) {
            this.forceUpdate();
        }
    };

    updateSettings = (newSettings: IEntity, preventUpdate?: boolean): void => {
        const settings = { ...this.props.storage.settings, ...newSettings };
        this.setSettings(settings, preventUpdate);
    };

    debouncedSetSettings = debounce((settings: IEntity, callback: () => void): void => {
        callback();
        this.setSettings(settings);
    }, INPUT_DEBOUNCE_TIME);

    updateTableSettings = (args: IUpdateTableSettings = {}): IChangedFilter[] => {
        let settings = { ...this.props.storage.settings };
        let andFilters = [];
        const updatedFilters = [];
        const changedFilters = this.props.storage.getChangedFilters();

        for (const filter of changedFilters.changedFields) {
            const updatedFilter = { ...filter };

            updatedFilters.push(updatedFilter);
            updatedFilter.value = this.getCorrectFilterValue(updatedFilter.value, updatedFilter.info);

            if (this.isFilterParameter(updatedFilter.bindingContext)) {
                const value = updatedFilter.value; // updatedFilter.values ? updatedFilter.values : updatedFilter.value;

                settings = setBoundValue({
                    bindingContext: updatedFilter.bindingContext,
                    data: settings,
                    newValue: value,
                    dataBindingContext: this.props.storage.data.bindingContext
                });
            } else {
                if (!updatedFilter.info?.fieldSettings?.frontendOnly) {
                    andFilters.push(updatedFilter);
                }
            }
        }

        if (args.isParameterChanged) {
            // if parameter was changed, remove values from non-parameter filters
            // change is propagated to updatedFilters as well, because it has same filter references
            this.props.storage.clearFilters();
            // refresh value helpers, parameter change can change all the values as well
            this.props.storage.unfilteredRows = null;

            const newChangedFilters = this.props.storage.getChangedFilters();
            andFilters = andFilters.filter(andFilter => newChangedFilters.groups[FilterBarGroup.Filters]?.find(filter => filter.info.id === andFilter.info.id));

            const filterChangeValue = args.filterChange?.parsedValue ?? args.filterChange?.value;

            if (args.filterChange && (isNotDefined(filterChangeValue) || (Array.isArray(filterChangeValue) && filterChangeValue.length === 0))) {
                // getChangedFilters doesn't return empty values
                // but parameters needs to be send in the request, even when empty
                settings = setBoundValue({
                    bindingContext: args.filterChange.bindingContext,
                    data: settings,
                    newValue: filterChangeValue,
                    dataBindingContext: this.props.storage.data.bindingContext
                });
            }

            // in filter with updateFiltersFromResponse, user could've selected dynamic column for sorting,
            // this column can no longer be available after parameter change
            // => remove all dynamic columns from sorting
            if (this.props.definition.updateFiltersFromResponse && this.props.storage.tableAPI) {
                const newSort = this.props.storage.tableAPI.getSort().filter(sort => {
                    // if sort column is not in the supported columns,
                    // it means it is dynamically generated
                    // => remove from sort
                    return !!this.getSupportedColumn(sort.id.toString());
                });

                this.updateSort(newSort);
            }
        }

        const filterTree = this.buildFilterTree(andFilters.map(andFilter => {
            return {
                ...andFilter.info,
                ...andFilter
            };
        }));

        if (filterTree) {
            settings.Filter = filterTree;
        } else {
            delete settings.Filter;
        }

        settings = (args.filterChange && this.props.onFilterChange?.({
            filterChange: args.filterChange,
            isParameterChanged: args.isParameterChanged,
            settings
        })) ?? settings;

        if (!args.filterChange) {
            this.setSettings(settings);
        } else {
            // only change state after some debouncing time
            // prevents creating too many requests while user is writing inside the filter field
            this.debouncedSetSettings(settings, () => {
                // todo does this still make sense with unique row ids?
                // items should be expanded to maximum when filtered
                // if (!this.props.storage.settings?.Filter && settings.Filter) {
                //     this.expandAll();
                // }
            });
        }

        this.props.storage.refreshFields();
        // return filters with changed values into FilterGroup
        // only way how to pass down new filter values
        return updatedFilters;
    };

    get isExpandable(): boolean {
        const tableState = this.props.storage.tableAPI?.getState();

        return tableState?.allGroupStatus !== GroupStatus.Expanded;
    }

    expandAll = (): void => {
        this.props.onExpandAll?.();

        this.props.storage.tableAPI.toggleAllGroups(GroupStatus.Expanded);
    };

    collapseAll = (): void => {
        this.props.onCollapseAll?.();

        this.props.storage.tableAPI.toggleAllGroups(GroupStatus.Collapsed);
    };

    handleGroupToggle = (rows: IRow[]): void => {
        // refresh this.isExpandable
        this.forceUpdate();
        this.props.definition.onGroupToggle?.(this.props.storage);
    };

    handleSortSettingsClick = (): void => {
        this.setState({
            isTableSortDialogOpen: true
        });
    };

    handleSettingsClick = async (): Promise<void> => {
        this.setState({
            isCustomizationDialogOpen: true,
            dialogReportHierarchy: cloneDeep(this.props.storage.reportHierarchy)
        });
    };

    handleExportClick = async (type: TableButtonsAction): Promise<void> => {
        const tableState = this.props.storage.tableAPI.getState();
        let data: IReportData = tableState.data;
        const exportType = type === TableButtonsAction.XLSXExport ? ExportType.XLSX : ExportType.CSV;

        // in GeneralLedger, we don't want to export some columns
        if (this.props.definition.id === ReportId.GeneralLedger) {
            data = {
                ...data,
                Columns: data.Columns.filter((column) => {
                    return !["InitialDebit_SUM", "InitialCredit_SUM", "FinalDebit_SUM", "FinalCredit_SUM"].includes(column.ColumnAlias);
                })
            };
        }

        const {
            rows,
            columns
        } = prepareReportDataForExport(data, this.props.t, tableState.columns as TColumn[], this.props.definition);

        const file = await ExcelExport.export({
            columns: columns,
            tableName: this.props.definition.title,
            rows: rows,
            type: exportType
        });

        logAction({
            actionId: this.props.definition.path,
            actionType: exportType === ExportType.CSV ? ActionTypeCode.CsvExport : ActionTypeCode.ExcelExport,
            detail: this.logActionDetail
        });

        saveAs(file);
    };

    get logActionDetail(): string {
        return getReportLogActionDetail(this.props.storage);
    }

    handlePdfExportClick = (): void => {
        this.setState({
            showPdfExport: true,
            isTableReadyForPrint: false
        });
    };

    handleTableButtonClick = (key: TableButtonsActionType): void => {
        switch (key) {
            case TableButtonsAction.XLSXExport:
            case TableButtonsAction.CSVExport:
                this.handleExportClick(key);
                break;
            case TableButtonsAction.PdfExport:
                this.handlePdfExportClick();
                break;
            case TableButtonsAction.Sorting:
                this.handleSortSettingsClick();
                break;
            case TableButtonsAction.Settings:
                this.handleSettingsClick();
                break;
            case TableButtonsAction.ExpandCollapseAll:
                if (this.isExpandable) {
                    this.expandAll();
                } else {
                    this.collapseAll();
                }
                break;
            case TableButtonsAction.PdfExportReportFull:
            case TableButtonsAction.PdfExportReportShort:
                this.props.onPrintReportButtonClick?.(key);
                break;
            default:
                this.handleCustomAction(key);
        }
    };

    handleCustomAction = (action: string): void => {
        // prepared to be overridden
    };

    handleCloseCustomizationDialog = (): void => {
        this.setState({
            dialogReportHierarchy: null,
            isCustomizationDialogOpen: false
        });
    };

    handleConfirmCustomizationDialog = (): void => {
        const reportHierarchy: IReportHierarchy = this.state.dialogReportHierarchy;
        const isDrilldown = !!getDrillDownFilters();
        const hasGroupsChanged = this.props.storage.reportHierarchy.Aggregate !== reportHierarchy.Aggregate || !isEqual(this.props.storage.reportHierarchy.Groups, reportHierarchy.Groups);

        if (hasGroupsChanged) {
            // unique ids of the rows will change => remove stored, no longer valid openedRowsIds
            LocalSettings.remove(this.props.definition.id, "openedRowsIds");
        }

        this.updateFiltersFromReportHierarchy(reportHierarchy);
        this.updateTableSettings();

        if (!isDrilldown) {
            const variant = this.props.storage.getVariant();
            const currentVariantColumns = variant?.columns;
            const newVariantColumns = this.props.storage.convertReportHierarchyToVariantColumns(reportHierarchy);
            const isColumnsSame = isEqual(newVariantColumns, currentVariantColumns);

            if (!isColumnsSame) {
                // only store in variants if not in drilldown
                this.props.storage.setLocalStorageVariant({
                    columns: newVariantColumns,
                    filters: variant?.filters
                });
            }
        }

        this.props.storage.reportHierarchy = reportHierarchy;
        // we need to refresh rows when report hierarchy is changed
        this.props.storage.unfilteredRows = null;

        this.setState({
            isCustomizationDialogOpen: false,
            dialogReportHierarchy: null
        });

    };

    removeItemFromGroup = (group: IGroupListGroupDef, itemId: string) => {
        const index = group.itemIds.indexOf(itemId);

        if (index < 0) {
            return;
        }

        group.itemIds.splice(index, 1);
    };

    convertConfigListToReportHierarchy = (configList: IConfigList, aggregate: boolean) => {
        return {
            Groups: configList.groups[ReportConfigGroup.Groups]?.itemIds.map(this.getColumnOrGroupHierarchyColumn.bind(this, configList, aggregate)) ?? [],
            Columns: configList.groups[ReportConfigGroup.Columns]?.itemIds.map(this.getColumnOrGroupHierarchyColumn.bind(this, configList, aggregate)) ?? [],
            Aggregations: configList.groups[ReportConfigGroup.Aggregations]?.itemIds.map(this.getAggregationHierarchyColumn.bind(this, configList)) ?? []
        };
    };

    /** Converts the report hierarchy structure to configuration list definition */
    convertReportHierarchyToConfigListDef = (reportHierarchy: IReportHierarchy, supportedColumns: IReportColumnDef[]): IConfigList => {
        const drillDownFilters = getDrillDownFilters();

        const handleColumn = (column: IReportColumnDef, groupId: ReportConfigGroup) => {
            const columnAlias = column.ColumnAlias;
            let itemId = columnAlias;
            const itemOverride = typeof this.props.definition.configListItemsOverride === "function" ? this.props.definition.configListItemsOverride(items[itemId]) : this.props.definition.configListItemsOverride?.[itemId];
            const isOverrideValid = itemOverride && (!itemOverride.group || itemOverride.group === groupId);

            if (items[itemId].isCopyOnly && !(typeof itemOverride?.isCopyOnly === "boolean" && !itemOverride.isCopyOnly)) {
                if (!copies[itemId]) {
                    copies[itemId] = 0;
                }

                copies[itemId] += 1;

                const copyId = getItemCopyId(itemId, copies[itemId]);

                items[copyId] = {
                    ...items[itemId], id: copyId,
                    ...(isOverrideValid && itemOverride),
                    isCopyOnly: false
                };
                itemId = copyId;
            } else {
                this.removeItemFromGroup(groups[ReportConfigGroup.AvailableColumns], itemId);
                items[itemId] = {
                    ...items[itemId],
                    ...(isOverrideValid && itemOverride)
                };
            }

            const suffix = column.AggregationFunction ? `_${column.AggregationFunction}` : "";
            if (isDefined(drillDownFilters?.[BindingContext.localContext(`${columnAlias}${suffix}`)])) {
                // just like we inject the column into reportHierarchy in "injectDrillDownFiltersAsColumns"
                // we cannot let user remove it
                items[itemId].isRequired = true;
                items[itemId].boundTo = ConfigListItemBoundType.Group;
            }

            return itemId;
        };

        const handleTimeColumn = (column: IReportColumnDef, newItemId: string) => {
            const supportedColumn = this.getSupportedColumn(trimCopyId(column.ColumnAlias));

            if (supportedColumn.Type === ReportColumnType.Date) {
                items[newItemId].items = getTimeAggFuncItems();
                items[newItemId].selectedItemId = column.AggregationFunction;
            }
        };

        const handleAggregationColumn = (column: IReportColumnDef, newItemId: string) => {
            const supportedColumn = this.getSupportedColumn(trimCopyId(column.ColumnAlias));

            if (NumberAggFuncTypes.includes(supportedColumn.Type)) {
                items[newItemId].items = getNumberAggFuncItems();
            } else {
                items[newItemId].items = [getNumberAggFuncItem(NumberAggregationFunction.Count)];
            }

            items[newItemId].selectedItemId = column.AggregationFunction;
        };

        const hiddenFromAvailable: string[] = [];
        const items: TRecordType<IGroupListItemDef> = {};
        const groups: TRecordType<IGroupListGroupDef> = {};
        const columns: TRecordType<IGroupListColumnDef> = {
            visible: {
                id: "visible",
                label: this.props.t("Common:Form.VisibleColumns"),
                groupIds: null
            },
            available: {
                id: "available",
                label: this.props.t("Common:Form.AvailableColumns"),
                groupIds: [ReportConfigGroup.AvailableColumns]
            }
        };
        const copies: TRecordType<number> = {};

        supportedColumns.forEach((supportedColumn: IReportColumnDef) => {
            const id = supportedColumn.ColumnAlias;

            items[id] = {
                id,
                value: supportedColumn.Label,
                description: supportedColumn.Type === ReportColumnType.Label ? `(${this.props.t("Reporting:Config.Label")})` : (supportedColumn.EntityLabel ? `(${supportedColumn.EntityLabel})` : null),
                isCopyOnly: reportHierarchy.Aggregate
            };

            const itemOverride = typeof this.props.definition.configListItemsOverride === "function" ? this.props.definition.configListItemsOverride(items[id]) : this.props.definition.configListItemsOverride?.[id];
            const isOverrideValid = itemOverride && (!itemOverride.group || itemOverride.group === id);

            if (isOverrideValid) {
                items[id] = {
                    ...items[id],
                    ...itemOverride
                };
            }

            if (itemOverride?.hideFromAvailable) {
                hiddenFromAvailable.push(id);
            }
        });

        groups[ReportConfigGroup.AvailableColumns] = {
            id: ReportConfigGroup.AvailableColumns,
            itemIds: Object.keys(items).filter(key => !hiddenFromAvailable.includes(key)),
            isTransparent: true
        };

        if (reportHierarchy.Aggregate) {
            columns.visible.groupIds = [ReportConfigGroup.Groups, ReportConfigGroup.Columns, ReportConfigGroup.Aggregations];

            groups[ReportConfigGroup.Groups] = {
                id: ReportConfigGroup.Groups,
                label: this.props.t("Reporting:Config.FirstColumn"),
                isHierarchical: true,
                itemIds: reportHierarchy.Groups.map((column) => {
                    const itemId = handleColumn(column, ReportConfigGroup.Groups);

                    handleTimeColumn(column, itemId);

                    return itemId;
                })
            };
            groups[ReportConfigGroup.Columns] = {
                id: ReportConfigGroup.Columns,
                label: this.props.t("Reporting:Config.NextColumns"),
                itemIds: reportHierarchy.Columns.map((column) => {
                    const itemId = handleColumn(column, ReportConfigGroup.Columns);

                    handleTimeColumn(column, itemId);

                    return itemId;
                })
            };
            groups[ReportConfigGroup.Aggregations] = {
                id: ReportConfigGroup.Aggregations,
                label: this.props.t("Reporting:Config.Aggregations"),
                itemIds: reportHierarchy.Aggregations.map((column) => {
                    const itemId = handleColumn(column, ReportConfigGroup.Aggregations);

                    handleAggregationColumn(column, itemId);

                    return itemId;
                })
            };
        } else {
            columns.visible.groupIds = [ReportConfigGroup.Columns];

            groups[ReportConfigGroup.Columns] = {
                id: ReportConfigGroup.Columns,
                itemIds: uniq(reportHierarchy.Columns.map((column) => {
                    return handleColumn(column, ReportConfigGroup.Columns);
                })),
                isTransparent: true
            };
        }

        return {
            items,
            groups,
            columns
        };
    };

    getReportHierarchyColumn = (item: IGroupListItemDef, aggFuncGetter?: (item: IGroupListItemDef, column: IReportColumnDef) => TAggregationFunction) => {
        const trimmedId = trimCopyId(item.id);
        const supportedColumn = this.getSupportedColumn(trimmedId);
        const aggFunc = aggFuncGetter(item, supportedColumn);

        const hierarchyItem: IReportColumnDef = {
            ColumnAlias: trimmedId
        };

        if (aggFunc) {
            hierarchyItem.AggregationFunction = aggFunc;
        }

        return hierarchyItem;
    };

    getColumnOrGroupHierarchyColumn = (data: IConfigList, aggregate: boolean, itemId: string) => {
        return this.getReportHierarchyColumn(data.items[itemId], (item: IGroupListItemDef, column: IReportColumnDef) => {
            let aggFunc = null;

            if (aggregate && column.Type === ReportColumnType.Date) {
                aggFunc = getTimeAggFuncItems().find(funcItem => funcItem.id === item.selectedItemId) ? item.selectedItemId : TimeAggregationFunction.Month;
            }

            return aggFunc;
        });
    };

    getAggregationHierarchyColumn = (data: IConfigList, itemId: string) => {
        return this.getReportHierarchyColumn(data.items[itemId], (item: IGroupListItemDef, column: IReportColumnDef) => {
            if (NumberAggFuncTypes.includes(column.Type)) {
                return item.selectedItemId || NumberAggregationFunction.Sum;
            } else {
                return NumberAggregationFunction.Count;
            }
        });
    };

    handleConfigListChange = (newData: IConfigList, newReportHierarchy?: IReportHierarchy) => {
        const dialogReportHierarchy = newReportHierarchy ?? this.state.dialogReportHierarchy;
        const reportHierarchy = this.convertConfigListToReportHierarchy(newData, !!dialogReportHierarchy.Aggregate);

        this.setState({
            dialogReportHierarchy: {
                ...dialogReportHierarchy,
                ...reportHierarchy
            }
        });
    };

    createCustomizationDialogContent = () => {
        const currentReportHierarchy: IReportHierarchy = this.state.dialogReportHierarchy;
        const configListData = this.convertReportHierarchyToConfigListDef(currentReportHierarchy, this.props.storage.supportedColumns);

        return (
            <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
                <div>
                    <Button isTransparent
                            isDisabled={this.props.definition.disableAggregateButton}
                            onClick={this.handleAggButtonClick}
                            icon={currentReportHierarchy.Aggregate ? <CloseIcon/> :
                                <PlusIcon/>}
                            style={{
                                marginBottom: "25px"
                            }}
                            testid={TestIds.ConfigurationListAddAggregationButton}>
                        {currentReportHierarchy.Aggregate
                            ? this.props.t("Components:ConfigurationList.CancelColumns")
                            : this.props.t("Components:ConfigurationList.CreateColumns")}
                    </Button>
                </div>
                <div style={{ flex: "1 1 auto", overflow: "hidden" }}>
                    <ConfigurationList data={configListData} onDataChange={this.handleConfigListChange}/>
                </div>
            </div>
        );
    };

    getSupportedColumn = (columnAlias: string): IReportColumnDef => {
        return getMatchingColumn(this.props.storage.supportedColumns, columnAlias);
    };

    /** Try to either return supportedColumn or filter representation in AdditionalFilters
     * AdditionalFilters returned from "supportedColumns" endpoint can represent multiple virtual columns for filtering
     * In that case, they have IsColumnAliasFilterPrefix=true and represent every column with the same ColumnAlias prefix */
    getColumnFilterDef = (columnAlias: string): IReportColumnDef => {
        return this.getSupportedColumn(columnAlias) ?? getMatchingColumn(this.props.storage.additionalFilters, columnAlias);
    };

    handleAggButtonClick = () => {
        const oldReportHierarchy = this.state.dialogReportHierarchy;
        const shouldAggregate = !oldReportHierarchy.Aggregate;
        const newReportHierarchy: IReportHierarchy = {
            Aggregate: shouldAggregate,
            Groups: [], Columns: [], Aggregations: []
        };

        if (shouldAggregate) {
            // move all Currency type to Aggregations
            newReportHierarchy.Columns = oldReportHierarchy.Columns.filter((col: IReportColumnDef) => this.getSupportedColumn(col.ColumnAlias).Type !== ReportColumnType.Currency);
            newReportHierarchy.Aggregations = oldReportHierarchy.Columns.filter((col: IReportColumnDef) => this.getSupportedColumn(col.ColumnAlias).Type === ReportColumnType.Currency);
        } else {
            // move every item to Columns
            newReportHierarchy.Columns = [...oldReportHierarchy.Groups, ...oldReportHierarchy.Columns, ...oldReportHierarchy.Aggregations];
        }

        this.setState({
            dialogReportHierarchy: newReportHierarchy
        });

        const configListDef = this.convertReportHierarchyToConfigListDef(
            newReportHierarchy,
            this.props.storage.supportedColumns
        );

        this.handleConfigListChange(configListDef, newReportHierarchy);
    };

    handleTableLoad = async (): Promise<void> => {
        if (!this.props.storage.getCustomData().isTableReady) {
            this.props.storage.setCustomData({
                isTableReady: true
            });
        }
        const tableState = this.props.storage.tableAPI.getState();
        const hasNoData = !tableState.rowCount;

        if (this.props.definition.updateFiltersFromResponse) {
            this.updateFiltersFromReturnedColumns();
        }

        // create value helpers from the returned data
        // if filter is applied on the first request,
        // fire one more request without the filter
        if (!this.props.storage.unfilteredRows) {
            if (isNotDefined(this.props.storage.settings.Filter)) {
                this.props.storage.unfilteredRows = this.props.storage.tableAPI.getRowsArray();
            } else {
                // todo add some kind of optimization, mby only load after the first value helper is opened
                const response = await fetchReportTableData({
                    path: this.props.definition.path,
                    settings: {
                        ...this.props.storage.settings,
                        Filter: null
                    },
                    reportHierarchy: this.props.storage.reportHierarchy,
                    storage: this.props.storage,
                    sort: this.props.storage.tableAPI.getSort()
                });
                const fetchedData = await response.json();
                const preparedData = this.props.storage.tableAPI.prepareData(fetchedData);

                this.props.storage.unfilteredRows = preparedData.rowsArray;
            }
        }

        this.updateValueHelpers(this.props.storage.unfilteredRows);

        this.props.definition.onAfterTableLoad?.(this.props.storage);

        if (hasNoData !== this.state.hasNoData) {
            this.setState({
                hasNoData: hasNoData
            });
        }
    };

    handleTableReadyForPrint = () => {
        this.setState({
            isTableReadyForPrint: true
        });
    };

    handleSortChange = (sort: ISort[]): void => {
        if (!getDrillDownFilters()) {
            const variant = this.props.storage.getVariant();
            const variantColumns = variant?.columns;
            const updatedColumns = variantColumns.map((variantColumn, index) => {
                let updatedColumn = { ...variantColumn };
                const variantColumnId = variantColumn.aggregationFunction ? `${variantColumn.id}_${variantColumn.aggregationFunction}` : variantColumn.id;
                // we want columns with same id have the same sort => use getCleanColumnId
                const sortIndex = sort.findIndex(s => getCleanColumnId(s.id.toString()) === variantColumnId);
                const columnSort = sort?.[sortIndex];

                updatedColumn = {
                    ...updatedColumn,
                    ...this.props.storage.getVariantColumnSortProps(variantColumnId, columnSort, sortIndex)
                };

                return updatedColumn;
            });

            // some reports return virtual columns, that are not part of the reportHierarchy configuration
            // we still need to store those in variant to remember the applied sort
            // set group to null - column won't be used in reportHierarchy
            for (let i = 0; i < sort.length; i++) {
                const sortCol = sort[i];

                if (!updatedColumns.find(col => {
                    const colId = col.aggregationFunction ? `${col.id}_${col.aggregationFunction}` : col.id;

                    return getCleanColumnId(sortCol.id.toString()) === colId;
                })) {
                    updatedColumns.push({
                        id: sortCol.id.toString(),
                        sortOrder: i,
                        sortType: sortCol.sort,
                        group: null
                    });
                }
            }

            this.props.storage.setLocalStorageVariant({
                columns: updatedColumns,
                filters: variant?.filters
            });
        } else {
            // in drilldown, ignore variants, just apply the changed settings
            // this.props.storage.reportHierarchy = reportHierarchy;
        }
    };

    handleRowSelect = (id: TId, row: IReportRowDef) => {
        // TODO key will probably have other representation than just Document_Id
        const key = row.Value.Document_Id as number;

        if (!key) {
            return;
        }

        const entitySet = getEntitySetByDocumentType(row.Value.Document_DocumentTypeCode as DocumentTypeCode);

        this.props.onRowSelect?.(getBindingContext(entitySet, this.props.oData.getMetadata()).addKey(key));
    };

    getSelectedRow = () => {
        const bindingContext = this.props.storage.data.rowBindingContext;
        const key = bindingContext?.getKey();
        const rows = this.props.storage.tableAPI?.getState().data?.Rows;

        if (!bindingContext || !this.props.storage.tableAPI || !rows || rows.length === 0) {
            return null;
        }

        const selectedRow = this.getReportRowAndPath(this.props.storage.tableAPI.getState().data.Rows, (row) => {
            return row.Value.Document_Id === key;
        });

        if (!selectedRow) {
            return null;
        }

        return getReportRowId(selectedRow.path);
    };

    getReportRowAndPath = (rows: IReportRowDef[], checkFn: (row: IReportRowDef) => boolean, path: number[] = []): {
        row: IReportRowDef,
        path: number[]
    } => {
        for (let i = 0; i < rows.length; i++) {
            const row = rows[i];
            const currentPath = [...path, i];

            if (checkFn(row)) {
                return {
                    row, path: currentPath
                };
            }

            if (row.Rows) {
                const found = this.getReportRowAndPath(row.Rows, checkFn, currentPath);

                if (found) {
                    return found;
                }
            }
        }

        return null;
    };

    getDefinitionValue = (definitionProp: keyof IReportTableDefinition) => {
        return (typeof this.props.definition[definitionProp] === "function")
            ? (this.props.definition[definitionProp] as TGetReportDefValFn<any>)({
                settings: this.props.storage.settings,
                storage: this.props.storage
            })
            : this.props.definition[definitionProp];
    };

    getWarningsAlert = () => {
        const warnings: IReportWarning[] = this.props.storage.getCustomData().warnings;

        if (!warnings || warnings.length === 0) {
            return null;
        }

        const title: React.ReactNode = this.props.t("Document:Error.Warning");
        const subtitles: React.ReactNode[] = [];
        let hoverTitle = "";
        const accountsWarnings: TRecordString = {
            AccountsWithoutBSSection: "BalanceSheet",
            AccountsWithoutISSection: "IncomeStatement"
        };

        for (const warning of warnings) {
            let subtitle: React.ReactNode = "";

            if (Object.keys(accountsWarnings).includes(warning.WarningIdentifier)) {
                const translationGroup = accountsWarnings[warning.WarningIdentifier];
                const fiscalYears: TRecordAny = {};
                const accountsWithBalanceText = `${this.props.t(`Reporting:${translationGroup}.AccountsWithBalance`)} `;
                const accountsNotAssignedText = `${this.props.t(`Reporting:${translationGroup}.AccountsNotAssigned`)}:`;

                subtitle += accountsWithBalanceText;
                subtitle = (
                    <>
                        {subtitle}
                        <strong>
                            {accountsNotAssignedText}
                        </strong>
                        {"\n\n"}
                    </>
                );

                hoverTitle += accountsWithBalanceText;
                hoverTitle += accountsNotAssignedText;

                for (const account of warning.AdditionalInfo.Accounts) {
                    const fiscalYearId = account.FiscalYear.Id;

                    if (!fiscalYears[fiscalYearId]) {
                        fiscalYears[fiscalYearId] = account.FiscalYear;
                        fiscalYears[fiscalYearId].ChartOfAccountsId = account.ChartOfAccountsId;
                        fiscalYears[fiscalYearId].Accounts = [];
                    }

                    fiscalYears[fiscalYearId].Accounts.push(account.Account);
                }

                const fiscalYearsArray = Object.values(fiscalYears);

                for (let i = 0; i < fiscalYearsArray.length; i++) {
                    const fiscalYear = fiscalYearsArray[i];
                    const fiscalYearText = `${this.props.t("Common:Settings.FiscalYear")} ${fiscalYear.Number}`;

                    hoverTitle += ` ${fiscalYearText}`;
                    subtitle = (
                        <>
                            {subtitle}
                            <strong>{fiscalYearText}</strong>
                            {"\n"}
                        </>
                    );
                    subtitle = (
                        <>
                            {subtitle}
                            <>
                                {
                                    fiscalYear.Accounts.map((account: IAccountEntity, index: number) => {
                                        const params = getIntentNavParams({
                                            route: `${ROUTE_CHARTS_OF_ACCOUNTS}/${fiscalYear.ChartOfAccountsId}/Accounts/${account.Id}`,
                                            context: this.context,
                                            storage: this.props.storage
                                        });
                                        let linkText = `${account.Number} ${DASH_CHARACTER} ${account.Name}`;

                                        linkText += index < fiscalYear.Accounts.length - 1 ? ", " : ".";
                                        hoverTitle += linkText;

                                        return (
                                            <Clickable link={params} key={account.Id}>
                                                {linkText}
                                            </Clickable>
                                        );
                                    })
                                }
                            </>
                            {`\n${i !== fiscalYearsArray.length - 1 ? "\n" : ""}`}
                        </>
                    );
                }
            } else {
                const fiscalYearMissingText = `${this.props.t("Reporting:BalanceSheet.FiscalYearMissing")} `;
                const dateText = this.props.t(`Reporting:BalanceSheet.${warning.WarningIdentifier}`, { date: DateType.format(warning.AdditionalInfo.date) });

                subtitle = (
                    <>
                        {fiscalYearMissingText}
                        <b>{dateText}</b>
                    </>
                );

                hoverTitle += fiscalYearMissingText;
                hoverTitle += dateText;
            }

            if (subtitle) {
                subtitles.push(subtitle);
            }

            hoverTitle += " ";
        }

        return (
            <Warnings>
                <Alert key={warnings[0]?.WarningIdentifier}
                       status={Status.Warning}
                       title={title}
                       hoverTitle={hoverTitle}
                       isFullWidth
                       onClose={this.handleWarningClose}
                       action={AlertAction.Close}
                       subTitle={subtitles}/>
            </Warnings>
        );
    };

    handleWarningClose = (): void => {
        this.props.storage.setCustomData({ warnings: null });
        this.props.storage.refresh();
    };

    handlePrintDialogClose = (): void => {
        this.setState({
            showPdfExport: false
        });
    };

    renderPrintDialog = () => {
        if (!this.state.showPdfExport) {
            return null;
        }

        return (
            <TablePrintDialog element={this.state.isTableReadyForPrint && this.tableRef.current}
                              onClose={this.handlePrintDialogClose}
                              storage={this.props.storage}
                              logActionDetail={this.logActionDetail}/>
        );
    };

    disableToolbarButtons(): boolean {
        return this.props.disableToolbarButtons;
    }

    customToolbarContent = (): IToolbarItem[] => {
        return this.props.customToolbarButtons?.(this.props.storage);
    };

    getStaticToolbarItems = (): TToolbarItem[] => null;

    // can be overridden
    getRowAction = (): TCustomRowAction => {
        return this.props.rowAction;
    };

    handleActiveRowActionCountChange = (): void => {
        // refresh would reload secondary query filters, we just need to rerender confirmation buttons
        this.forceUpdate();
    };

    renderCustomizationDialog = (): ReactElement => {
        if (!this.state.isCustomizationDialogOpen) {
            return null;
        }

        return (
            <Dialog
                title={`${this.props.definition.title} ${DASH_CHARACTER} ${this.props.t("Common:Form.Customization")}`}
                width={"auto"}
                // we need fixed height for the ConfigurationList columns scrollbars to work
                height={"9999px"}
                onClose={this.handleCloseCustomizationDialog}
                onConfirm={this.handleConfirmCustomizationDialog}
                footer={<ConfirmationButtons onCancel={this.handleCloseCustomizationDialog}
                                             onConfirm={this.handleConfirmCustomizationDialog}
                                             useWrapper={false}/>}>
                {this.state.isCustomizationDialogOpen && this.createCustomizationDialogContent()}
            </Dialog>
        );
    };

    updateSort = (sort: ISort[]): void => {
        const isSortSame = isEqual(sort, this.props.storage.tableAPI.getSort());

        if (!isSortSame) {
            this.handleSortChange(sort);
            this.props.storage.updateCurrentTableColumns(sort);
        }
    };

    handleSortingDialogConfirm = (sort: ISort[]): void => {
        this.updateSort(sort);

        this.setState({
            isTableSortDialogOpen: false
        });
    };

    handleSortingDialogCancel = (): void => {
        this.setState({
            isTableSortDialogOpen: false
        });
    };

    renderSortingDialog = (): ReactElement => {
        if (!this.state.isTableSortDialogOpen) {
            return null;
        }

        return <TableSortingDialog
            columns={getLeavesColumns(this.props.storage.tableAPI.getState().columns as TColumn[])
                .filter(col => !COLUMN_INDEX_REGEX.test(col.id.toString()) && !col.disableSort)
                .map(col => ({
                    id: col.id,
                    label: col.label
                }))}
            sort={this.props.storage.getInitialSortOrder().filter(sort => !COLUMN_INDEX_REGEX.test(sort.id.toString()))}
            onConfirm={this.handleSortingDialogConfirm}
            onCancel={this.handleSortingDialogCancel}/>;
    };

    renderDefaultDialogs = (): ReactElement => {
        return (
            <>
                {this.renderPrintDialog()}
                {this.renderCustomizationDialog()}
                {this.renderSortingDialog()}
            </>
        );
    };

    renderFilters = (): ReactElement => (<>
        <SmartDrillDownFilters storage={this.props.storage}/>
        <StyledSmartFilterBar
            tableId={this.props.definition.id}
            storage={this.props.storage}
            onClearFilter={this.handleClearFilter}
            onFilterChange={this.handleFilterChange}>
        </StyledSmartFilterBar>
    </>);

    renderToolbar = (): ReactElement => {
        const isAnyFilterError = this.props.storage.isAnyError();
        const visibleButtons = [
            TableButtonsAction.Sorting
        ];
        const disabledButtons: TableButtonsAction[] = [];

        if (this.props.showExportButton) {
            visibleButtons.push(
                TableButtonsAction.XLSXExport,
                TableButtonsAction.CSVExport,
                TableButtonsAction.PdfExport
            );
        }

        if (this.props.showPrintReportButtons) {
            visibleButtons.push(TableButtonsAction.PdfExportReportFull, TableButtonsAction.PdfExportReportShort);
        }

        if (!this.props.disableConfiguration) {
            visibleButtons.push(TableButtonsAction.Settings);
        }
        if (this.props.expandable) {
            visibleButtons.push(TableButtonsAction.ExpandCollapseAll);

            if (!this.canExpandCollapseRows()) {
                disabledButtons.push(TableButtonsAction.ExpandCollapseAll);
            }
        }

        if (this.state.hasNoData || isAnyFilterError) {
            for (const action of [TableButtonsAction.XLSXExport, TableButtonsAction.CSVExport, TableButtonsAction.ExpandCollapseAll]) {
                if (!disabledButtons.includes(action)) {
                    disabledButtons.push(action);
                }
            }
        }

        if (this.disableToolbarButtons()) {
            for (const visibleButton of visibleButtons) {
                if (!disabledButtons.includes(visibleButton)) {
                    disabledButtons.push(visibleButton);
                }
            }
        }

        return (
            <TableToolbar
                isExpandable={this.isExpandable}
                isDisabled={this.isToolbarDisabled}
                staticItems={this.getStaticToolbarItems()}
                visibleButtons={visibleButtons}
                disabledButtons={disabledButtons}
                onClick={this.handleTableButtonClick}
            >
                {this.customToolbarContent()}
            </TableToolbar>
        );
    };

    renderTable = (): ReactElement => {
        const isAnyFilterError = this.props.storage.isAnyError();

        return (
            <TableWrapper>
                {isAnyFilterError && <NoData/>}
                {!isAnyFilterError &&
                    <SmartReportTable path={this.props.definition.path}
                                      tableId={this.props.definition.id}
                                      key={this.props.storage.data.uuid}
                                      storage={this.props.storage}
                                      passRef={this.tableRef}
                                      isForPrint={this.state.showPdfExport}
                                      settings={this.props.storage.settings}
                                      preventReload={this.state.preventReload}
                                      reportHierarchy={this.props.storage.reportHierarchy}
                                      onGroupToggle={this.handleGroupToggle}
                                      onAfterTableLoad={this.handleTableLoad}
                                      onReadyForPrint={this.handleTableReadyForPrint}
                                      onSortChange={this.handleSortChange}
                                      selectedRow={this.getSelectedRow()}
                                      showDrilldown={this.props.definition.showDrilldown}
                                      disableSort={this.props.disableSort}
                                      initialSortBy={this.props.storage.getInitialSortOrder()}
                                      currencyUnit={this.getDefinitionValue("currencyUnit")}
                                      rowFormatter={this.props.definition.rowFormatter}
                                      columnFormatter={this.props.definition.columnFormatter}
                                      rowsDataFactory={this.props.definition.rowsDataFactory}
                                      metaColumnFormatter={this.props.definition.metaColumnFormatter}
                                      columnOverrides={this.props.definition.columnOverrides}
                                      dimValues={dimValues}
                                      rowAction={this.getRowAction()}
                                      onActiveRowActionCountChange={this.handleActiveRowActionCountChange}
                    />
                }
            </TableWrapper>
        );
    };

    isLoading = (): boolean => !this.props.definition || !this.props.storage.loaded || !this.state.loaded;

    render() {
        if (this.isLoading()) {
            return <BusyIndicator size={BusyIndicatorSize.L} isDelayed/>;
        }

        return (
            <>
                <BreadCrumbProvider back={this.props.back ?? this.props.storage?.initialHistoryState?.back}
                                    customBreadCrumbs={this.props.storage?.initialHistoryState?.breadCrumbs}
                                    removeLast={!!this.props.storage.data.rowBindingContext}/>
                <View hotspotContextId={this.props.storage.id}>
                    <SmartHeaderStyled
                        title={this.props.definition.title}
                        hotspotId={"tableHeader"}
                        storage={this.props.storage}
                        shouldHideVariant={!!getDrillDownFilters() && !getDrillDownVariant()}
                    />
                    {this.renderFilters()}
                    {this.getWarningsAlert()}
                    {this.renderToolbar()}
                    {this.renderTable()}
                </View>
                {this.renderDefaultDialogs()}
            </>
        );
    }
}

export const commonReportTranslations = ["Reporting", "Components", "Common", "Document", "Enums"];
export { ReportView as ReportViewClean };
// todo fix ts interface
// @ts-ignore
export default withTranslation(commonReportTranslations, { withRef: true })(withOData(ReportView));