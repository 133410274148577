import { createBindingContext, createPath } from "@odata/BindingContext";
import {
    EntitySetName,
    ILabelEntity,
    ILabelHierarchyEntity,
    LabelEntity,
    LabelHierarchyEntity
} from "@odata/GeneratedEntityTypes";
import { SelectionCode } from "@odata/GeneratedEnums";
import { IPrepareQuerySettings, prepareQuery } from "@odata/OData.utils";
import { cloneDeep } from "lodash";
import React from "react";
import { DefaultTheme, withTheme } from "styled-components";

import { EMPTY_VALUE } from "../../../constants";
import { AppContext } from "../../../contexts/appContext/AppContext.types";
import { IconSize, Sort, TextAlign } from "../../../enums";
import memoizeOne from "../../../utils/memoizeOne";
import { LabelIcon } from "../../icon";
import { IMultiSelectionChangeArgs, MultiSelect } from "../../inputs/select/MultiSelect";
import { buildTreeFromAllItems } from "../../inputs/select/SelectAPI";
import { getInfoValue, ILabelSelectSettings } from "../FieldInfo";
import { ISmartMultiSelectProps } from "./SmartMultiSelect";
import { ISelectGroup, ISelectItem } from "@components/inputs/select/Select.types";

interface IProps extends ISmartMultiSelectProps, ILabelSelectSettings {
    theme: DefaultTheme;
    /** Adds EMPTY_VALUE item */
    addEmptyItem?: boolean;
}

class SmartLabelSelect extends React.Component<IProps> {
    static contextType = AppContext;
    //sadly, breaks typescript type checking
    //context: React.ContextType<typeof AppContext>;
    static defaultProps: Partial<IProps> = {
        oneItemPerHierarchy: true
    };

    _isMounted: boolean;
    _isLoading: boolean;
    _isFirstTime = true;
    _hiddenItems: ISelectItem[];

    componentDidMount() {
        this._isMounted = true;
        this.load();
    }

    componentDidUpdate() {
        this.load();
    }

    componentWillUnmount(): void {
        this._isMounted = false;
    }

    get items(): ISelectItem[] {
        return this.props.fieldInfo?.fieldSettings?.items || this.props.fieldInfo?.fieldSettings?.initialItems || [];
    }

    load = async () => {
        if (this._isLoading || this.props.storage?.loading) {
            return;
        }
        this._isLoading = true;
        await this.getHierarchies();
        this._isLoading = false;
    };

    // this way it is still loaded for every document, maybe set hierarchies on context?
    fetchLabelHierarchies = memoizeOne(async () => {
        const bc = createBindingContext(EntitySetName.LabelHierarchies, this.props.storage.oData.getMetadata()),
                settings: IPrepareQuerySettings = {};
        settings[""] = {
            sort: [{ id: "Name", sort: Sort.Asc }]
        };

        const query = prepareQuery({
            oData: this.props.storage.oData,
            bindingContext: bc,
            fieldDefs: [{ id: LabelHierarchyEntity.Id }, { id: LabelHierarchyEntity.Color }, { id: LabelHierarchyEntity.Name },
                { id: createPath(LabelHierarchyEntity.Labels, LabelEntity.Id) },
                { id: createPath(LabelHierarchyEntity.Labels, LabelEntity.Name) },
                { id: createPath(LabelHierarchyEntity.Labels, LabelEntity.IsActive) },
                {
                    id: createPath(LabelHierarchyEntity.Labels, LabelEntity.Parent),
                    additionalProperties: [{ id: LabelEntity.Name }]
                },
                {
                    id: createPath(LabelHierarchyEntity.Labels, LabelEntity.Children),
                    additionalProperties: [{ id: LabelEntity.Name }]
                }
            ],
            settings
        });

        const filter = getInfoValue(this.props.fieldInfo?.filter, "select", { context: this.context });
        if (filter) {
            query.filter(filter);
        }

        const hierarchies = await query.fetchData<ILabelHierarchyEntity[]>();
        return hierarchies.value;
    });

    getHierarchies = async () => {
        const hierarchies = await this.fetchLabelHierarchies();

        if (this._isMounted) {
            const keyName = this.props.fieldInfo?.fieldSettings?.idName ?? "Id";
            const groups: ISelectGroup[] = [];
            let items: ISelectItem[] = [];

            for (const hierarchy of hierarchies) {
                groups.push({
                    id: hierarchy.Id.toString(),
                    title: hierarchies.length > 1 ? hierarchy.Name : undefined,
                    hideDivider: true
                });
                for (const label of hierarchy.Labels) {
                    items.push({
                        label: label.Name,
                        // in reports, we filter by Name instead of Id
                        id: label[keyName as keyof ILabelEntity] as (string | number),
                        groupId: hierarchy.Id.toString(),
                        color: this.props.theme[hierarchy.Color as keyof DefaultTheme],
                        additionalData: {
                            Name: label.Name,
                            Parent: label.Parent,
                            Children: label.Children,
                            isActive: label.IsActive,
                            LabelHierarchy: { Id: hierarchy.Id }
                        }
                    });
                }
            }

            // new document has undefined value, which is obviously not iterable
            this._hiddenItems = [];
            if (this.props.value) {
                for (const value of this.props.value) {
                    const item = items.find(item => item.id === value);

                    if (item) {
                        this._hiddenItems.push(item);
                    }
                }
                this.disableRestOfLabelHierarchy(items, this._hiddenItems);
            }

            items = buildTreeFromAllItems(items.filter(item => item.additionalData.isActive), keyName);

            if (this.props.addEmptyItem) {
                items.unshift({
                    id: EMPTY_VALUE,
                    label: this.props.storage.t("Common:General.Empty")
                });
            }

            this.props.fieldInfo.fieldSettings.items = items;
            this.props.fieldInfo.fieldSettings.groups = groups;

            if (this._isFirstTime) {
                this._isFirstTime = false;
                // rerender read only item in the SmartFilterBar for the same bc as well
                this.props.storage.addActiveField(this.props.bindingContext);
                this.props.storage.refreshFields();
            }

            this.forceUpdate();
        }
    };

    disableRestOfLabelHierarchy(items: ISelectItem[], selectedItems: ISelectItem[]) {
        if (this.props.oneItemPerHierarchy) {
            for (const item of items) {
                item.isDisabled = !!selectedItems.find(selected => selected.id !== item.id && selected.groupId === item.groupId);
            }
        }
    }

    handleChange = (args: IMultiSelectionChangeArgs) => {
        if (args.triggerAdditionalTasks) {
            this.disableRestOfLabelHierarchy(this.props.fieldInfo.fieldSettings?.items, args.selectedItems);
        }
        this.props.onChange(args);
    };

    handleClick = (e: React.MouseEvent) => {
        const { storage, bindingContext } = this.props;
        return this.props.onClick?.(e, { storage, bindingContext });
    };

    handleIconActivate = (e: React.MouseEvent) => {
        const { storage, bindingContext } = this.props;
        return this.props.onIconActivate?.(e, { storage, bindingContext });
    };

    render() {
        const additionalItems = cloneDeep(this.props.additionalItems);
        if (this.props.bindingContext.getParent().getPath() === "LabelSelection") {
            const defItem = additionalItems?.find(i => i.id === SelectionCode.Default);
            if (defItem) {
                defItem.isSelected = this.props.storage.getValue(this.props.bindingContext.getParent())?.SelectionCode === SelectionCode.Default;
            }
        }

        return <MultiSelect
                {...this.props}
                onClick={this.handleClick}
                additionalItems={additionalItems}
                onIconActivate={this.handleIconActivate}
                inputIcon={<LabelIcon width={IconSize.M}/>}
                items={this.items}
                groups={this.props.fieldInfo.fieldSettings?.groups || []}
                hiddenItems={this._hiddenItems}
                onChange={this.handleChange}
                textAlign={TextAlign.Left}
                shouldCheckParents={false}
                shouldCheckChildren={!this.props.oneItemPerHierarchy}
                hideDisplayAll={true}
        />;
    }
}

export default withTheme(SmartLabelSelect);