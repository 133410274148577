import { IconButton } from "@components/button";
import { CheckboxContainer } from "@components/inputs/checkbox/Checkbox.styles";
import Field from "@components/inputs/field";
import styled from "styled-components/macro";

import Alert from "../../components/alert/Alert";
import { T_PLAIN_big } from "../../global.style";
import { DataBoxState } from "./SendMessageDialog";
import Clickable from "@components/clickable";

export const AlertStyled = styled(Alert)`
    margin-bottom: 27px;
`;

export const IconButtonStyled = styled(IconButton)`
    margin-left: 10px;
`;

export const StyledMessageContent = styled.div`
    white-space: pre-wrap;
    a {
        color: ${props => props.theme.C_TEXT_link};
    }
`;

export const MaxWidthField = styled(Field) `
    width: 100%;
`;

export const ContentEditableMessage = styled.div`
    ${T_PLAIN_big};
    width: 100%;
    min-height: 250px;
    background-color: ${props => props.theme.C_FIELD_fill};
    border: 1px solid ${props => props.theme.C_FIELD_line};
    padding: 5px 12px;
    white-space: pre-wrap;

    outline: 0px solid transparent;
    
    &:hover,
    &:focus {
        border-color: ${props => props.theme.C_ACT_hover_without_opacity};
    }
    
    a {
        color: ${props => props.theme.C_TEXT_link};
    }
`;

export const CheckboxWrapper = styled.div`
    ${T_PLAIN_big};
    display: flex;
    align-items: center;
    line-height: 14px;

    ${CheckboxContainer} {
        margin-right: 13px;
    }

    svg {
        margin-right: 6px;
    }
`;

export const ButtonWrapper = styled.div`
    display: inline-flex;
    align-items: center;
`;

export const IconWrapper = styled.div<{ state: string }>`
    display: inline-flex;
    align-items: center;
    margin-left: -10px;
    
    .SI_4L_stroke_fill_green {
        fill: ${props => props.state === DataBoxState.Empty ? "#fff" :  props.state === DataBoxState.Blocked ? props.theme.C_SEM_el_bad : props.theme.C_SEM_el_good};
    }
`;

export const DataBoxInfo = styled.table`
    ${T_PLAIN_big};
    margin: -8px 8px 11px;

    tr td:first-child {
        font-weight: 600;
    }
`;

export const ClickableMessage = styled(Clickable) `
    display: flex;
    align-items: center;
`;