import { IHeaderIcon } from "@components/header/Header";
import { getInfoValue, IFieldDef, TInfoValue } from "@components/smart/FieldInfo";
import { ISmartFieldBlur, ISmartFieldChange } from "@components/smart/smartField/SmartField";
import { IFormGroupDef } from "@components/smart/smartFormGroup/SmartFormGroup";
import { IGetSmartHeaderCustomInfo } from "@components/smart/smartHeader/SmartHeader.utils";
import { ISummaryItem } from "@components/smart/smartSummaryItem/SmartSummaryItem";
import { IPaneBookmark } from "@components/splitLayout";
import { IToolbarItem } from "@components/toolbar";
import { EntitySetName, IFileMetadataEntity } from "@odata/GeneratedEntityTypes";
import { CompanyPermissionCode, GeneralPermissionCode } from "@odata/GeneratedEnums";
import { IPrepareQuerySettings } from "@odata/OData.utils";
import { WithOData, withOData } from "@odata/withOData";
import { IFilesDefinition, TFieldsDefinition } from "@pages/PageUtils";
import { composeRefHandlers } from "@utils/general";
import { canUnlock } from "@utils/permissionUtils";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import SimpleBar from "simplebar-react";

import Alert from "../../components/alert/Alert";
import { BreadCrumbProvider } from "../../components/breadCrumb";
import BusyIndicator from "../../components/busyIndicator/BusyIndicator";
import { AppContext } from "../../contexts/appContext/AppContext.types";
import { WithPermissionContext, withPermissionContext } from "../../contexts/permissionContext/withPermissionContext";
import { PortalRootElementProvider } from "../../contexts/portalRootElement/PortalRootElementProvider";
import { FormMode, Status } from "../../enums";
import { TRecordAny } from "../../global.types";
import { Model, ModelEvent } from "../../model/Model";
import { StorageModel } from "../../model/StorageModel";
import BindingContext, { areBindingContextsDifferent, IEntity } from "../../odata/BindingContext";
import TestIds from "../../testIds";
import View from "../View";
import { focusFirstInputField } from "./Form.utils";
import FormCustomizationDialog from "./FormCustomizationDialog";
import { FormAlertPosition, FormStorage } from "./FormStorage";
import { IFormViewProps } from "./FormView";
import {
    FormAlertContainer,
    FormContent,
    FormFooter,
    SmartHeaderStyled,
    StyledSuccessWrapper
} from "./FormView.styles";
import { IPureFormProps, PureForm } from "./PureForm";

// D => DraftEntity interface
export interface IFormDef<E extends IEntity = IEntity, D extends IEntity = IEntity> {
    /** List of properties that should be fetched with the other data, but doesn't have their own field representation.
     * Similar to additionalProperties from field info, but related to the whole form, not particular field (different binding context path). */
    id: string;
    title?: string;
    summary?: ISummaryItem[];
    /** Adds custom action buttons into header.
     * onClick should be handled in extended FormView in overridden handleCustomHeaderAction method. */
    customHeaderActions?: (Omit<IHeaderIcon, "onClick" | "isDisabled"> & {
        isDisabled?: TInfoValue<boolean>;
        isVisible?: TInfoValue<boolean>
    })[];
    auditTrailSummary?: ISummaryItem[];
    groups?: IFormGroupDef[];
    formControl?: React.ComponentType<any>;
    fieldDefinition: TFieldsDefinition;
    additionalProperties?: IFieldDef[];
    files?: IFilesDefinition;
    isReadOnly?: TInfoValue<boolean>;
    /** Add delete action to the footer buttons. */
    isDeletable?: TInfoValue<boolean>;
    translationFiles: string[];
    lockProperty?: string;
    permissions?: (CompanyPermissionCode | GeneralPermissionCode)[];
    getItemBreadCrumbText?: (storage: Model) => string;
    secondaryBookmark?: TInfoValue<TSecondaryBookmarkDef>;
    showChanges?: boolean;
    getCustomAttachmentFileActions?: (file: IFileMetadataEntity, storage: FormStorage) => IToolbarItem[];
    // settings applied to prepareQuery, things like nested filter can be passed
    querySettings?: IPrepareQuerySettings;

    draftDef?: IFormDraftSettings<E, D>;
}

export interface IFormDraftSettings<E extends IEntity = IEntity, D extends IEntity = IEntity> {
    draftProperty: keyof E,
    draftEntitySet: EntitySetName,
    navigationToItem: string,
    draftAdditionalProps: IFieldDef[],
    navigationFromDraft: keyof D,
}

export type TSecondaryBookmarkDef = Omit<IPaneBookmark, "isActive"> & { content: React.ReactElement };

export const generateComparisonProps = (props: IFormViewProps<any>) => {
    const isComparison = props.storage?.formMode === FormMode.AuditTrail;
    return {
        style: props.style,
        passRef: props.passRef,
        renderScrollbar: !isComparison,
        hideBreadcrumbs: props.formProps?.hideBreadcrumbs || isComparison,
        hideHeader: isComparison
    };
};

type TPureFormProps = Pick<IPureFormProps, "onCancel" | "onConfirm" | "onTemporalChange" | "onChange" | "onBlur" | "onRemove" | "onLineItemChange" | "onLineItemAction" | "onFieldStateChange" | "onGroupAction" | "onGroupExpand" | "storage">

export interface IProps extends WithTranslation, WithOData, TPureFormProps, WithPermissionContext {
    title?: string;
    subtitle?: string;
    subtitleStatus?: Status;
    formName?: string;
    defaultData?: TRecordAny;
    refScroll?: React.Ref<HTMLElement>;
    refContent?: React.Ref<HTMLElement>;
    refHeader?: React.Ref<React.ReactElement>;
    style?: React.CSSProperties;
    headerIcons?: IHeaderIcon[];
    getCustomHeaderInfo?: (storage: FormStorage) => IGetSmartHeaderCustomInfo;
    shouldHideAuditTrail?: boolean;
    shouldHideVariant?: boolean;

    hideHeader?: boolean;
    hideBreadcrumbs?: boolean;
    /** Won't render BusyIndicator automatically based on storage.isBusy(),
     * instead, it is expected that it will be rendered elsewhere (e.g. in Dialog busy state) */
    hideBusyIndicator?: boolean;
    /**
     * Add root element provider to pass autocomplete menus to */
    addRootElementContext?: boolean;
    // for Form(View) usage in  Dialog, where we want to save space
    smallErrorAlert?: boolean;
    className?: string;
    withoutPadding?: boolean;         // top, left and right padding of form element
    withoutPaddingForAlert?: boolean; // bottom padding for success alert

    // light weighted form typically used in dialogs without some icons and buttons
    isSimple?: boolean;

    prependContent?: React.ReactElement;

    customFooterButtons?: React.ReactElement | ((storage: FormStorage) => React.ReactElement);

    /** Should be used for Forms in Dialogs, to get correct width automatically.
     * Scrollbar prevents dialog from having width based on the form width. */
    renderScrollbar?: boolean;
    passRef?: React.Ref<HTMLDivElement>;
}

class Form extends React.Component<IProps> {
    static contextType = AppContext;
    //sadly, breaks typescript type checking
    //context: React.ContextType<typeof AppContext>;
    _scrollBarInstanceRef = React.createRef<SimpleBar>();
    _refFooter = React.createRef<HTMLDivElement>();
    _refFormContent = React.createRef<HTMLDivElement>();
    lastBindingContext: BindingContext;

    public static defaultProps = {
        formName: "default",
        renderScrollbar: true
    };

    // resizeObserver: ResizeObserver;
    // footerWidth: number;

    constructor(props: IProps) {
        super(props);
        this.props.storage.emitter.on(ModelEvent.RecalculateScrollbar, this.recalculateScrollbar);
        // this.resizeObserver = new ResizeObserver(this.handleFooterResize);
    }

    componentDidMount() {
        // if (this._refFooter.current) {
        //     this.resizeObserver.observe(this._refFooter.current);
        //     this.setButtonsMaxWidth(this._refFooter.current);
        // }
        this.lastBindingContext = this.props.storage.data.bindingContext;

        if (this.props.storage.data.bindingContext.isNew()) {
            this.focusFirstField();
        }
    }

    componentWillUnmount() {
        this.props.storage.emitter.off(ModelEvent.RecalculateScrollbar, this.recalculateScrollbar);
        // this.resizeObserver.disconnect();
    }

    _focusOnLoad = false;

    componentDidUpdate(prevProps: Readonly<IProps>): void {
        if (areBindingContextsDifferent(this.lastBindingContext, this.props.storage.data.bindingContext)) {
            this.lastBindingContext = this.props.storage.data.bindingContext;

            this._focusOnLoad = this.props.storage.data.bindingContext.isNew();
        }
        // focus first field (after form loads), cause when switching from one form to new one, the old form fields
        // are still rendered and they might be disabled in case the entity is e.g. locked (then the focus didn't work)
        if (this._focusOnLoad && !this.props.storage.isBusy()) {
            this._focusOnLoad = false;
            this.focusFirstField();
        }
    }

    focusFirstField = () => {
        focusFirstInputField(this._refFormContent.current);
    };

    recalculateScrollbar = () => {
        // @ts-ignore
        this._scrollBarInstanceRef.current?.recalculate();
    };

    handleBlur = async (args: ISmartFieldBlur) => {
        await this.props.onBlur(args);
    };

    handleChange = (e: ISmartFieldChange) => {
        this.props.onChange(e);
    };

    // setButtonsMaxWidth = (footer: HTMLElement) => {
    //     const footerIsFullWidth = (footer.offsetWidth - 150) <= footer.querySelector<HTMLElement>("div").offsetWidth;
    //     const buttonTextWrappers = footer.querySelectorAll<HTMLElement>("button span");
    //     for (let i = 0; i < buttonTextWrappers.length; i++) {
    //         buttonTextWrappers[i].style.maxWidth = footerIsFullWidth ? "fit-content" : "inherit";
    //     }
    // };
    //
    // handleFooterResize = (entries: readonly ResizeObserverEntry[]) => {
    //     const footer = (entries[0].target as HTMLElement);
    //     // check if footer buttons are on fullwidth
    //     // we want to change buttons just on width change, resize sensor is called also when buttons are shrank and therefore footer height is changed
    //     // TODO: move footer to separate component
    //     if (footer.offsetWidth !== this.footerWidth) {
    //         this.setButtonsMaxWidth(footer);
    //     }
    //     this.footerWidth = footer.offsetWidth;
    // };

    handleAlertFadeEnd = (): void => {
        delete this.props.storage.data.alert;
        this.props.storage.refresh();
    };

    handleRemove = (bc: BindingContext) => {
        this.props.onRemove?.(bc);
    };

    handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        // we want to handle form submit through our own props
        e.preventDefault();
    }

    renderContent = () => {
        const formDef = this.props.storage.data.definition;
        const isFormReadOnly = getInfoValue(formDef, "isReadOnly", {
            storage: this.props.storage,
            data: this.props.storage.data.entity
        });
        const alert = this.props.storage.data.alert;

        const formElement: React.ElementType = isFormReadOnly ? "div" : "form";

        const formContentArgs = isFormReadOnly ? {} : {
            // novalidate prevents some browsers default behavior
            // e.g. https://stackoverflow.com/questions/5392882/why-is-chrome-showing-a-please-fill-out-this-field-tooltip-on-empty-fields
            noValidate: true
        };

        let content = (<>
            {this.props.prependContent}
            <FormContent data-testid={TestIds.FormContent}
                         as={formElement}
                         onSubmit={this.handleFormSubmit}
                         withoutPadding={this.props.withoutPaddingForAlert}
                         {...formContentArgs}
            >
                {!this.props.hideHeader &&
                        <SmartHeaderStyled
                                hideBreadcrumbs={this.props.hideBreadcrumbs}
                                ref={this.props.refHeader}
                                title={this.props.title}
                                subtitle={this.props.subtitle}
                                subtitleStatus={this.props.subtitleStatus}
                                hotspotId={"formHeader"}
                                storage={this.props.storage}
                                icons={this.props.headerIcons}
                                getCustomHeaderInfo={this.props.getCustomHeaderInfo as ((storage: StorageModel) => IGetSmartHeaderCustomInfo)}
                                shouldHideVariant={this.props.shouldHideVariant}
                                onChange={this.handleChange}/>
                }
                {alert && (alert.position === FormAlertPosition.Top || [Status.Error, Status.Warning].includes(alert.status)) &&
                        <FormAlertContainer detailData={alert.detailData}
                                            status={alert.status}
                                            title={alert.title}
                                            subTitle={alert.subTitle}
                                            onClose={alert.onClose}
                                            action={alert.action}
                                            useFade={alert.status === Status.Success}
                                            onFadeEnd={alert.onFadeEnd ?? this.handleAlertFadeEnd}
                                            isFullWidth
                                            isOneLiner={alert.isOneLiner || !!this.props.smallErrorAlert}/>
                }
                <PureForm storage={this.props.storage}
                          isSimple={this.props.isSimple}
                          onGroupExpand={this.props.onGroupExpand}
                          onBlur={this.handleBlur}
                          onCancel={this.props.onCancel}
                          onConfirm={this.props.onConfirm}
                          onChange={this.handleChange}
                          onTemporalChange={this.props.onTemporalChange}
                          onRemove={this.handleRemove}
                          onLineItemChange={this.props.onLineItemChange}
                          onLineItemAction={this.props.onLineItemAction}
                          onFieldStateChange={this.props.onFieldStateChange}
                          onGroupAction={this.props.onGroupAction}
                          passRef={composeRefHandlers(this.props.refContent, this._refFormContent)}
                />
                {alert && alert.status === Status.Success && alert.position !== FormAlertPosition.Top &&
                        <StyledSuccessWrapper
                                key={this.props.storage.data.uuid}>
                            <Alert
                                    detailData={alert.detailData}
                                    status={alert.status}
                                    title={alert.title}
                                    subTitle={alert.subTitle}
                                    isFullWidth={alert.isFullWidth !== false}
                                    isOneLiner={alert.isOneLiner !== false}
                                    useFade
                                    onFadeEnd={alert.onFadeEnd ?? this.handleAlertFadeEnd}
                            />
                        </StyledSuccessWrapper>
                }
            </FormContent>
            {this.props.children &&
                    <FormFooter
                            hasTopMargin={!isFormReadOnly}
                            ref={this._refFooter}
                            data-testid={TestIds.FormFooter}>
                        {this.props.children}
                    </FormFooter>
            }
        </>);

        if (this.props.addRootElementContext) {
            content = (<PortalRootElementProvider>{content}</PortalRootElementProvider>);
        }

        return (
                <View hotspotContextId={this.props.storage.id}
                      renderScrollbar={this.props.renderScrollbar}
                      scrollProps={{
                          ref: this._scrollBarInstanceRef,
                          scrollableNodeProps: {
                              ref: this.props.refScroll
                          }
                      }}
                      withoutPadding={this.props.withoutPadding}
                      passRef={this.props.passRef}
                      testid={TestIds.FormView}
                      style={this.props.style}
                      className={this.props.className}>
                    {content}
                </View>
        );
    };

    render() {
        if (!this.props.storage.data.bindingContext) {
            return null;
        }
        return (
                <>
                    {!this.props.hideBreadcrumbs &&
                            <BreadCrumbProvider
                                    locked={this.props.storage.data.locked}
                                    canBeUnlocked={canUnlock(this.props.storage, this.props.permissionContext.generalPermissions, this.props.storage.data.entity)}
                                    back={this.props.storage?.initialHistoryState?.back}
                                    customBreadCrumbs={this.props.storage?.initialHistoryState?.breadCrumbs}/>
                    }
                    {this.props.storage.getCustomData().isCustomizationDialogOpen &&
                            <FormCustomizationDialog storage={this.props.storage} title={this.props.title}/>}
                    {this.props.storage.isBusy() && !this.props.hideBusyIndicator &&
                            <BusyIndicator isDelayed={!!this.props.storage.getCustomData().busyDelayed}/>}
                    {/*always render content, even with BusyIndicator, to prevent its complete remounting*/}
                    {this.renderContent()}
                </>
        );
    }
}

export default withPermissionContext(withOData(withTranslation(["Common", "Error"])(Form)));