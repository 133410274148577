import { IAlertProps, IBaseAlertProps } from "@components/alert/Alert";
import { IGetTileDataArgs } from "@components/dashboard";
import { WithConfirmationDialogContext } from "@components/dialog/ConfirmationDialogProvider";
import { IFileStripItem } from "@components/fileStrip/FileStrip.utils";
import { formatDateToDateString, getWorkDateFromLocalStorage } from "@components/inputs/date/utils";
import { ISelectItem } from "@components/inputs/select/Select.types";
import { TSmartODataTableStorage } from "@components/smart/smartTable/SmartODataTableBase";
import { getRow } from "@components/smart/smartTable/SmartTable.utils";
import { IRow, TId } from "@components/table";
import { IRowProps } from "@components/table/Rows";
import { IToolbarItem } from "@components/toolbar";
import { isODataError } from "@odata/Data.types";
import {
    CompanySettingEntity,
    EntitySetName,
    EntityTypeName,
    FileMetadataEntity,
    ICompanySettingEntity,
    IInboxCommunicationEntity,
    IInboxFileEntity,
    InboxCommunicationEntity,
    InboxFileEntity,
    UserCompanyRoleEntity,
    UserEntity
} from "@odata/GeneratedEntityTypes";
import {
    ApprovalStatusTypeCode,
    CommunicationTypeCode,
    InboxEntityTypeCode,
    InboxFileSourceCode
} from "@odata/GeneratedEnums";
import { isBatchResultOk, OData } from "@odata/OData";
import { transformToODataString } from "@odata/OData.utils";
import { parseResponse } from "@odata/ODataParser";
import { hasEverBeenVatRegisteredCompany, isVatRegisteredCompany } from "@utils/CompanyUtils";
import { logger } from "@utils/log";
import { initials } from "@utils/string";
import i18next from "i18next";
import { cloneDeep } from "lodash";
import React from "react";

import { IProps as WriteLineProps } from "../../components/inputs/writeLine";
import { ATTACH_INBOX_FILE_URL, FILES_API_URL } from "../../constants";
import { IAppContext } from "../../contexts/appContext/AppContext.types";
import {
    BasicInputSizes,
    IconSize,
    Status,
    SubscriptionModule,
    TextAlign,
    ToolbarItemType,
    ValueType
} from "../../enums";
import { TRecordAny, TRecordType } from "../../global.types";
import { Model } from "../../model/Model";
import { ITableStorageDefaultCustomData } from "../../model/TableStorage";
import BindingContext, { createBindingContext, IEntity } from "../../odata/BindingContext";
import { Theme } from "../../theme";
import { getUtcDate } from "../../types/Date";
import customFetch, { getDefaultPostParams } from "../../utils/customFetch";
import FileStorage from "../../utils/FileStorage";
import memoizeOne from "../../utils/memoizeOne";
import { getAlertFromError } from "../../views/formView/Form.utils";
import { ISplitPageTableDef, ITableDef } from "../../views/table/TableView.utils";
import { currentUserIsCustomer } from "../admin/users/Users.utils";
import { ISplitPagePaneData } from "../Page";
import { IDefinition } from "../PageUtils";
import { InboxApprovalStatusTooltip } from "./Inbox.styles";
import { IUploadedFile } from "./InboxSendToAccountantSplitPage";

export const getInboxFilesBindingContext = memoizeOne((oData: OData) => {
    return createBindingContext(EntitySetName.InboxFiles, oData.getMetadata());
});

export enum InboxFilter {
    Unsorted = "Unsorted",
    Other = "Other", // InboxEntityTypeCode.Other
    Entity = "Entity",
    ForApprove = "ForApprove"
}

export enum InboxAction {
    AskForApproval = "AskForApproval",
    CancelApproval = "CancelApproval"
}

export const INBOX_ACTIONS_GROUP_ID = "InboxActionsGroup";

export interface IInboxTableCustomData extends ITableStorageDefaultCustomData {
    type?: InboxFilter;
    entityType?: InboxEntityTypeCode;
}

export interface IAttachFileResult {
    alert: IBaseAlertProps;
    shouldRefreshTable: boolean;
}

export const SEARCH_ID = "Search";

export function getSearchBoxToolbarItem(value?: string, props?: TRecordAny): IToolbarItem {
    const itemProps: WriteLineProps = {
        textAlign: TextAlign.Left,
        placeholder: i18next.t("Inbox:TableView.SearchPlaceholder"),
        maxWidth: BasicInputSizes.L,
        minWidth: BasicInputSizes.S,
        value,
        ...props
    };
    return {
        id: SEARCH_ID,
        itemType: ToolbarItemType.WriteLine,
        itemProps
    };
}

export function getPaneIcon(hasFiles: boolean): string {
    return hasFiles ? "DocumentFilled" : "Document";
}

export function getInboxFilterByType(type: InboxFilter, entityType?: InboxEntityTypeCode): string {
    switch (type) {
        case InboxFilter.Unsorted:
            return `${InboxFileEntity.EntityType} eq null AND ${InboxFileEntity.IsSorted} eq false`;
        case InboxFilter.Other:
            return `(${InboxFileEntity.EntityTypeCode} eq '${InboxEntityTypeCode.Other}' OR ${InboxFileEntity.EntityType} eq null) AND ${InboxFileEntity.IsSorted} eq true`;
        case InboxFilter.Entity:
            return `${InboxFileEntity.EntityTypeCode} eq '${entityType}'`;
        case InboxFilter.ForApprove:
            return `${InboxFileEntity.ApprovalStatusTypeCode} eq '${ApprovalStatusTypeCode.Pending}'`;
    }
}

export function getInboxTableDef(definition: IDefinition, type: InboxFilter, entityType?: InboxEntityTypeCode): ITableDef {
    const def = cloneDeep(definition.table);
    def.filter = getInboxFilterByType(type, entityType);
    def.id = `${def.id}#${type}${entityType ? "#" + entityType : ""}`;

    if (type === InboxFilter.ForApprove) {
        def.title = i18next.t("Inbox:ForApproval");
    } else if (type === InboxFilter.Unsorted) {
        def.title = i18next.t("Inbox:Title");
    }
    return def;
}

export const getSupportedInboxEntities = memoizeOne(async (context: IAppContext, oData: OData): Promise<InboxEntityTypeCode[]> => {
    const supported = [
        InboxEntityTypeCode.InvoicesReceived, InboxEntityTypeCode.InvoicesIssued,
        // DEV-16661 it's currently not possible to create draft for BankStatement EntityTypeName.BankStatement,
        InboxEntityTypeCode.CashReceiptsReceived, InboxEntityTypeCode.CashReceiptsIssued,
        InboxEntityTypeCode.ProformaInvoicesReceived, InboxEntityTypeCode.TaxDocumentsReceived,
        InboxEntityTypeCode.ProformaInvoicesIssued, InboxEntityTypeCode.TaxDocumentsIssued,
        InboxEntityTypeCode.OtherLiabilities, InboxEntityTypeCode.OtherReceivables,
        InboxEntityTypeCode.Other,
    ];
    if (!isVatRegisteredCompany(context)) {
        let taxRelatedFolders = [InboxEntityTypeCode.TaxDocumentsIssued, InboxEntityTypeCode.TaxDocumentsReceived];
        if (hasEverBeenVatRegisteredCompany(context)) {
            // if company was once VAT registered, we need to check
            // if there are some data in TAX folder and if so, show these folders.
            const counts = await getFolderItemCounts(oData, null, taxRelatedFolders);
            taxRelatedFolders = taxRelatedFolders.filter(entityType => counts[entityType] > 0);
        }
        return supported.filter(entityType => !taxRelatedFolders.includes(entityType));
    }
    return supported;
}, (context: IAppContext) => [context.getCompanyId()]);

export const getDefaultVisibleItems = memoizeOne(async (context: IAppContext, oData: OData): Promise<InboxEntityTypeCode[]> => {
    const supported = await getSupportedInboxEntities(context, oData);
    const defaultVisibleItems = [
        InboxEntityTypeCode.InvoicesReceived, InboxEntityTypeCode.InvoicesIssued,
        InboxEntityTypeCode.ProformaInvoicesReceived, InboxEntityTypeCode.ProformaInvoicesIssued,
        InboxEntityTypeCode.OtherLiabilities, InboxEntityTypeCode.OtherReceivables,
        InboxEntityTypeCode.TaxDocumentsIssued, InboxEntityTypeCode.TaxDocumentsReceived,
        InboxEntityTypeCode.Other
    ];

    return defaultVisibleItems.filter(item => supported.includes(item));
});

export const getIconName = (type: InboxEntityTypeCode): string => {
    const _iconName = (shortcut: string) => `Folder${shortcut}`;
    switch (type) {
        case InboxEntityTypeCode.InvoicesIssued:
            return _iconName("FV");
        case InboxEntityTypeCode.InvoicesReceived:
            return _iconName("FP");
        case InboxEntityTypeCode.OtherReceivables:
            return _iconName("PD");
        case InboxEntityTypeCode.CashReceiptsReceived:
            return _iconName("PPD");
            // case InboxEntityTypeCode.BankStatements:
            //     return _iconName("VBU");
        case InboxEntityTypeCode.CashReceiptsIssued:
            return _iconName("VPD");
        case InboxEntityTypeCode.OtherLiabilities:
            return _iconName("ZD");
        case InboxEntityTypeCode.ProformaInvoicesReceived:
            return _iconName("ZFP");
        case InboxEntityTypeCode.ProformaInvoicesIssued:
            return _iconName("ZFV");
        case InboxEntityTypeCode.TaxDocumentsReceived:
            return _iconName("PDDOPP");
        case InboxEntityTypeCode.TaxDocumentsIssued:
            return _iconName("VDDOPP");
        case InboxEntityTypeCode.Other:
            return _iconName("Dots");
        default:
            return null;
    }
};

export function getInboxFolderFileStripeItem(entityType: InboxEntityTypeCode, withIcon = true, count?: number): IFileStripItem {
    const label = i18next.t(`Inbox:Folder.${entityType}`);
    const shortcut = initials(label);
    const iconName = withIcon && getIconName(entityType);
    return {
        id: entityType,
        shortcut,
        iconName,
        label,
        count
    };
}

export const getEntityTypeFileStripItems = memoizeOne((supportedEntities: InboxEntityTypeCode[], withIcon = true, fileStripItemsCounts?: TRecordType<number>): IFileStripItem[] => {
    return supportedEntities
            .map((entityTypeName) => getInboxFolderFileStripeItem(entityTypeName, withIcon, fileStripItemsCounts?.[entityTypeName]));
}, (supportedEntities: InboxEntityTypeCode[], withIcon = true, fileStripItemsCounts?: TRecordType<number>) => [...supportedEntities, withIcon, fileStripItemsCounts]);

export function getFilterAndEntityTypeFromSelectItem(item: ISelectItem): {
    type: InboxFilter,
    entityType?: InboxEntityTypeCode
} {
    let type: InboxFilter,
            entityType: InboxEntityTypeCode;
    switch (item.id) {
        case InboxFilter.Other:
        case InboxFilter.Unsorted:
            type = item.id as InboxFilter;
            entityType = item.id === InboxFilter.Other ? InboxEntityTypeCode.Other : null;
            break;
        default:
            type = InboxFilter.Entity;
            entityType = item.id as InboxEntityTypeCode;
            break;
    }

    return { type, entityType };
}

export function getInboxFile(rowProps: IRowProps | IRow): IInboxFileEntity {
    return rowProps?.customData?.entity as IInboxFileEntity;
}

export function getApprovalStatus(inboxFile: IInboxFileEntity): ApprovalStatusTypeCode {
    return inboxFile?.ApprovalStatusTypeCode as ApprovalStatusTypeCode;
}

export function getStatusFromApprovalStatus(status: ApprovalStatusTypeCode): Status {
    switch (status) {
        case ApprovalStatusTypeCode.Pending:
            return Status.Warning;
        case ApprovalStatusTypeCode.Rejected:
            return Status.Error;
        default:
            // no status for approved and when approval not needed
            // or in case data are not yet loaded
            return null;
    }
}

export function getStatusTooltipFromApprovalStatus(status: ApprovalStatusTypeCode): React.ReactNode {
    const key = status === ApprovalStatusTypeCode.Rejected ? "Denied" : "WaitingForApproval";

    return (
            <InboxApprovalStatusTooltip
                    _status={status}>{i18next.t(`Inbox:TableView.${key}`)}</InboxApprovalStatusTooltip>
    );
}

interface IAttachInboxFileData {
    InboxFileId: number;
    EntityType: string;
    EntityId?: number;
}

export async function attachInboxFile(fileIds: TId[], EntityType: InboxEntityTypeCode | EntityTypeName, EntityId?: number): Promise<boolean | IEntity> {
    if (fileIds.length === 0) {
        return true;
    }

    const workDate = getWorkDateFromLocalStorage();
    const data: IAttachInboxFileData[] = fileIds
            .map((id) => ({
                InboxFileId: parseInt(id.toString()),
                EntityType,
                EntityId,
                WorkDate: workDate ? formatDateToDateString(workDate) : null
            }));

    const response = await customFetch(ATTACH_INBOX_FILE_URL, {
        ...getDefaultPostParams(),
        body: JSON.stringify(data)
    });

    const body = await parseResponse(response);
    if (!response.ok) {
        throw body;
    }
    return body;
}

export async function loadInboxFileDetail(bc: BindingContext, oData: OData): Promise<IInboxFileEntity> {

    const query = oData.getEntitySetWrapper(EntitySetName.InboxFiles).query(bc.getKey())
            .select(InboxFileEntity.Id, InboxFileEntity.Body, InboxFileEntity.ApprovalStatusTypeCode, InboxFileEntity.InboxFileSourceCode, InboxFileEntity.EntityTypeCode, InboxFileEntity.Sender)
            .expand(InboxFileEntity.Communications, (query) =>
                    query.select(InboxCommunicationEntity.Id, InboxCommunicationEntity.Body, InboxCommunicationEntity.CommunicationTypeCode, InboxCommunicationEntity.DateCreated)
                            .expand(InboxCommunicationEntity.CreatedBy, (q2) => q2.select(UserEntity.Id, UserEntity.Name)
                                    .expand(UserEntity.CompanyRoles, (q3) => q3
                                            .expand(UserCompanyRoleEntity.Company).expand(UserCompanyRoleEntity.CompanyRole)))
            )
            .expand(InboxFileEntity.FileMetadata);

    const response = await query.fetchData<IInboxFileEntity>();

    return response.value;
}

export async function loadInboxFileMetadata(oData: OData, inboxFileIds: TId[]): Promise<IInboxFileEntity[]> {
    const query = oData.getEntitySetWrapper(EntitySetName.InboxFiles)
            .query()
            .filter(`${InboxFileEntity.Id} in (${transformToODataString(inboxFileIds, ValueType.Number)})`)
            .select(InboxFileEntity.Id)
            .expand(InboxFileEntity.FileMetadata, (subQuery) =>
                    subQuery
                            .expand(FileMetadataEntity.CreatedBy)
                            .expand(FileMetadataEntity.LastModifiedBy));

    const response = await query.fetchData<IInboxFileEntity[]>();

    return response.value;
}

export async function createInboxFileMessage(oData: OData, inboxBc: BindingContext, message: string, status: ApprovalStatusTypeCode): Promise<boolean> {
    const entitySet = inboxBc.getEntitySet();
    const queryable = oData.fromPath(entitySet.getName());
    const newCommunication: IInboxCommunicationEntity = message && {
        Body: message,
        CommunicationTypeCode: CommunicationTypeCode.User
    };
    try {
        const postData: TRecordAny = {
            [InboxFileEntity.ApprovalStatusTypeCode]: status
        };
        if (newCommunication) {
            postData[`${InboxFileEntity.Communications}@odata.delta`] = [newCommunication];
        }
        await queryable.update(inboxBc.getKey(), postData);
    } catch (e) {
        return false;
    }
    return true;
}

export async function renameInboxAttachment(oData: OData, inboxBc: BindingContext, inboxFile: IInboxFileEntity, newName: string): Promise<void> {
    const bc = inboxBc.navigate(InboxFileEntity.FileMetadata);
    const metadataEntitySet = bc.getEntitySet();
    const queryable = oData.fromPath(metadataEntitySet.getName());

    await queryable.update(inboxFile.FileMetadata.Id, { Name: newName });
}

export async function deleteInboxFile(bc: BindingContext, oData: OData): Promise<boolean> {
    const result = await oData.getEntitySetWrapper(EntitySetName.InboxFiles).delete(bc.getKey());
    return !!result;
}

export async function handleRenameInboxFile(storage: TSmartODataTableStorage, bc: BindingContext, newName: string): Promise<boolean> {
    const tableState = storage.tableAPI.getState();
    const row = getRow(tableState.rows, bc);
    const inboxFile = row.customData.entity as IInboxFileEntity;
    try {
        await renameInboxAttachment(storage.oData, bc, inboxFile, newName);
        await storage.tableAPI.reloadRow(bc);
    } catch (error) {
        // todo: handle error
        return false;
    }
    return true;
}

export async function handleDeleteFile(storage: TSmartODataTableStorage, dialog: WithConfirmationDialogContext, id: BindingContext): Promise<boolean> {
    const isConfirmed = await dialog.open({
        content: storage.t("Common:Confirmations.ConfirmDelete")
    });

    if (isConfirmed) {
        if (await deleteInboxFile(id, storage.oData)) {
            // we don't wait for table reload, action is already done
            // TableAPI also might not be initialized if we delete from InboxFileDetail
            //  -> Table is reloaded after viewer is closed
            storage.tableAPI?.reloadTable();
            return true;
        }
    }
    return false;
}

export function getDragImage(fileNames: string[], theme: Theme, fallback?: string): HTMLCanvasElement {
    const gap = 5;
    const iconSize = IconSize.asNumber("M");
    const maxWidth = 500;
    const textY = iconSize / 2 + 3;
    const textX = iconSize + 5;
    const fontSize = 14;

    const baseIconSize = 42.5;
    const icon = new Path2D(`M33.8,15.2l-9-9.9h-15c-0.6,0-1.1,0.5-1.1,1.1v29.7c0,0.6,0.5,1.1,1.1,1.1h22.8c0.6,0,1.1-0.5,1.1-1.1 v-1.9V15.2l-9,0V5.3`);
    const m = document.createElementNS("http://www.w3.org/2000/svg", "svg").createSVGMatrix();

    const canvas = document.createElement("canvas");
    canvas.width = maxWidth;
    const rows = fileNames.length; // Math.min(fileNames.length, 10);
    const extra = fileNames.length - rows;
    canvas.height = 2 * (extra ? rows + 1 : rows) * (iconSize + gap);
    const ctx = canvas.getContext("2d");
    ctx.font = `${fontSize}px Lato`;
    ctx.textBaseline = "middle";
    ctx.strokeStyle = theme.C_ACT_main;

    const _drawIcon = (x: number, y: number) => {
        const p = new Path2D();
        const translation = m.translate(x, y + 2).scale(iconSize / baseIconSize);
        p.addPath(icon, translation);
        ctx.fillStyle = theme.C_BG_floorplan;
        ctx.fill(p);
        ctx.stroke(p);
    };

    const _drawTextWithBG = (txt: string, x: number, y: number) => {
        // background
        const width = ctx.measureText(txt).width;
        const height = fontSize + gap;
        ctx.beginPath();
        ctx.fillStyle = theme.C_TEXT_bg_DnD;
        ctx.roundRect(x, y - (height / 2), width + 2 * gap, height, 3);
        ctx.fill();
        ctx.closePath();
        // text
        ctx.fillStyle = theme.C_ACT_light_line;
        ctx.fillText(txt, x + gap, y, maxWidth - x);
    };

    const rowHeight = iconSize + gap;
    for (let idx = 0; idx < rows; idx++) {
        const name = fileNames[idx];
        const y = idx * rowHeight;
        _drawIcon(0, y);
        _drawTextWithBG(name ?? fallback, textX, y + textY);
    }
    if (extra) {
        _drawTextWithBG(`+${extra} ${i18next.t("Inbox:TableView.More")}`, textX, rows * rowHeight + textY);
    }

    return canvas;
}

export function isRowWithFile(rowProps: IRowProps): boolean {
    return !!(rowProps?.customData?.entity as IInboxFileEntity)?.FileMetadata?.Id;
}

export async function handleAttachFiles(successKey: string, callback: (() => Promise<boolean | IEntity>)): Promise<IAlertProps> {
    let resolution = null;
    const error = {
        status: Status.Error,
        title: i18next.t("Common:General.Error"),
        subTitle: i18next.t("Inbox:TableView.CompleteErrorOther")
    };

    try {
        resolution = await callback();
    } catch (e) {
        if (isODataError(e)) {
            return getAlertFromError(e);
        }
        return error;
    }
    return !!resolution ? {
        status: Status.Success,
        title: i18next.t("Common:Validation.SuccessTitle"),
        subTitle: i18next.t(successKey)
    } : error;
}

export async function getInboxForApprovalCount(args: IGetTileDataArgs): Promise<number> {
    const { oData, signal } = args;

    const query = oData.getEntitySetWrapper(EntitySetName.InboxFiles)
            .query()
            .filter(getInboxFilterByType(InboxFilter.ForApprove))
            .count();

    const data = await query.fetchData(null, { signal });

    return data?._metadata?.count ?? 0;
}

export async function changeInboxFileApprovalStatus(oData: OData, keys: string[], status: ApprovalStatusTypeCode): Promise<boolean> {
    const batch = oData.batch();
    batch.beginAtomicityGroup("names");
    for (const key of keys) {
        batch.getEntitySetWrapper(EntitySetName.InboxFiles).update(key, {
            [InboxFileEntity.ApprovalStatusTypeCode]: status
        });
    }
    const result = await batch.execute();
    const hasError = result.find((res) => !isBatchResultOk(res));
    return !hasError;
}

export async function approveInboxFiles(oData: OData, keys: string[]): Promise<boolean> {
    return changeInboxFileApprovalStatus(oData, keys, ApprovalStatusTypeCode.Approved);
}

async function createInboxFile(context: IAppContext, oData: OData, file: File, name: string, source?: InboxFileSourceCode): Promise<void> {
    const url = `${FILES_API_URL}/uploadinboxfile`;
    const metadata = await FileStorage.upload({ file, name, url });
    await oData.getEntitySetWrapper(EntitySetName.InboxFiles).create({
        [InboxFileEntity.FileMetadata]: { "@odata.id": `${EntitySetName.FilesMetadata}(${metadata.Id})` },
        [InboxFileEntity.InboxFileSourceCode]: source ?? InboxFileSourceCode.CustomerPortal,
        [InboxFileEntity.DateReceived]: formatDateToDateString(getUtcDate()),
        [InboxFileEntity.Company]: { "@odata.id": `${EntitySetName.Companies}(${context.getCompanyId()})` }
    });
}

export function createInboxFilesFromNamedUploads(context: IAppContext, oData: OData, files: IUploadedFile[], reportProgress?: (cnt: number) => void): Promise<void> {
    return createInboxFilesFromUploads(context, oData, files.map(item => item.file), files.map(item => item.name), reportProgress);
}

export async function createInboxFilesFromUploads(context: IAppContext, oData: OData, files: File[], names?: string[], reportProgress?: (cnt: number) => void): Promise<void> {
    const isCustomer = currentUserIsCustomer(context);
    const source = isCustomer ? InboxFileSourceCode.CustomerPortal : InboxFileSourceCode.UploadedByAccountant;
    let finishedCount = 0;
    const promises = files.map((f: File, idx: number) => {
        return createInboxFile(context, oData, f, (names?.[idx] ?? f.name), source)
                .then(() => reportProgress(++finishedCount));
    });
    await Promise.all(promises);
}

export function getPaneData(definition: IDefinition, type: InboxFilter, entityType?: InboxEntityTypeCode): ISplitPagePaneData<ISplitPageTableDef> {
    return {
        def: getInboxTableDef(definition, type, entityType),
        entitySet: definition.entitySet as EntitySetName,
        customData: {
            type, entityType
        }
    };
}

export function isRossumModuleActivated(storage: Model): boolean {
    const subscription = storage.context.getData().subscription;

    return subscription?.ModuleItems.some(item => item.ModuleCode === SubscriptionModule.Rossum && item.DateEnd === null);
}

export function isSupportedFolderForDataMining(folder: string): boolean {
    return folder && ![InboxEntityTypeCode.CashReceiptsReceived, InboxEntityTypeCode.CashReceiptsIssued].includes(folder as InboxEntityTypeCode);
}

export const getInboxFileContextMenuItems = (isReadOnly = false, status: ApprovalStatusTypeCode, canApproveInbox: boolean): ISelectItem[] => {
    const isPending = status === ApprovalStatusTypeCode.Pending;
    const showAction = isPending || [ApprovalStatusTypeCode.Canceled, ApprovalStatusTypeCode.NotNeeded].includes(status);
    return [
        ...(!isReadOnly && showAction ? [
            {
                id: InboxAction.AskForApproval,
                label: i18next.t("Inbox:TableView.AskForApproval"),
                isDisabled: isPending || !canApproveInbox,
                // iconName: "Edit"
            },
            {
                id: InboxAction.CancelApproval,
                label: i18next.t("Inbox:TableView.CancelApproval"),
                isDisabled: !isPending,
                // iconName: "Edit"
            }
        ] : []),
    ];
};

type TInboxFileItemCounts = TRecordType<number>;

export async function getFolderItemCounts(oData: OData, byStatuses?: ApprovalStatusTypeCode[], byEntities?: InboxEntityTypeCode[]): Promise<TInboxFileItemCounts> {
    const filters = [];

    if (byEntities?.length) {
        const entityFilter = `${InboxFileEntity.EntityTypeCode} in (${transformToODataString(byEntities, ValueType.String)})`;
        filters.push(entityFilter);
    }
    if (byStatuses?.length) {
        const statusFilter = `(${InboxFileEntity.ApprovalStatusType} eq null OR ${InboxFileEntity.ApprovalStatusTypeCode} in (${transformToODataString(byStatuses, ValueType.String)}))`;
        filters.push(statusFilter);
    }
    const query = oData.getEntitySetWrapper(EntitySetName.InboxFiles).query()
            .filter(filters.join(" and "))
            .groupBy(InboxFileEntity.IsSorted, InboxFileEntity.EntityTypeCode)
            .aggregate("$count as @odata.count");

    const fileStripItemsCounts: TInboxFileItemCounts = {};

    try {
        const res = await query.fetchData<IInboxFileEntity[]>();

        if (res) {
            for (const item of res.value) {
                const type = (item.EntityTypeCode as InboxEntityTypeCode) ?? (item.IsSorted ? InboxFilter.Other : InboxFilter.Unsorted);
                fileStripItemsCounts[type] = item._metadata[""].count;
            }
        }

    } catch (e) {
        logger.error("Fail to fetch item counts", e);
    }

    return fileStripItemsCounts;
}

export const hasInboxApprovers = async (oData: OData): Promise<boolean> => {
    const query = oData.getEntitySetWrapper(EntitySetName.CompanySettings).query()
            .expand(CompanySettingEntity.InboxApprovers, (q) => q.select(UserEntity.Id))
            .top(1);

    const result = await query.fetchData<ICompanySettingEntity[]>();
    const companySettings = result.value?.[0];

    return companySettings?.InboxApprovers?.length > 0;
};
