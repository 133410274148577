import {
    Direction,
    IContentBefore,
    ISelectItem,
    ISelectPropsBase,
    ISharedData,
    SelectAdditionalItems,
    SelectGroups,
    TOnItemsFetchedCallback
} from "@components/inputs/select/Select.types";
import { _isNoRecord, _showTabularHeader, NO_RECORD_FOUND } from "@components/inputs/select/Select.utils";
import {
    doesElementContainsElement,
    focusNextElement,
    handleRefHandlers,
    isDefined,
    isNotDefined,
    isObjectEmpty,
    isOverflowing
} from "@utils/general";
import { fixWhiteSpaceChar, startsWithAccentsInsensitive } from "@utils/string";
import autosize from "autosize";
import React, { FocusEvent } from "react";
import ReactDOM from "react-dom";
import { WithTranslation, withTranslation } from "react-i18next";
import { Manager, Popper, Reference, ReferenceChildrenProps } from "react-popper";

import { EMPTY_VALUE } from "../../../constants";
import { getPortalRootElement } from "../../../contexts/portalRootElement/PortalRootElement.utils";
import {
    WithPortalRootElement,
    withPortalRootElement
} from "../../../contexts/portalRootElement/withPortalRootElement";
import { TRecordAny } from "../../../global.types";
import { KeyName } from "../../../keyName";
import TestIds from "../../../testIds";
import Token from "../../token";
import { getSharedInputProps, IInputOnBlurEvent, IInputOnChangeEvent } from "../input";
import { IFormatterFns } from "../input/WithFormatter";
import {
    DescriptionSeparator,
    InputDescriptionText,
    InputTabularAllText,
    InputWrapper,
    ReadOnlyTabularData,
    SelectItemLink,
    StyledSelect,
    StyledSelectInput
} from "./Select.styles";
import {
    filterItems,
    getAllItems,
    getFirstMatchingItemIndex,
    getHighlightedIndex,
    handleNavigationKey,
    IGetFirstMatchingIndexReturnType,
    isDirectionKey
} from "./SelectAPI";
import { Menu } from "./SelectMenu";

const ALLOW_CREATE_ID = "__NEW_ITEM__";

/** Props used for multi selector*/
export interface IProps extends ISelectPropsBase {
    onItemsFetchRequested?: (value: string, highlightCallback?: TOnItemsFetchedCallback) => void;

    isSelected?: (item: ISelectItem) => boolean;
    hasValue?: boolean; // just for multiselect
    minCharsToOpenMenu?: number;
}

export interface IState {
    isOpen?: boolean;
    currentValue?: string;
}

const DEFAULT_WIDTH = 180;

class BasicSelect extends React.PureComponent<IProps & WithTranslation & WithPortalRootElement & IFormatterFns<any>, IState> {
    public static defaultProps = {
        useAutoSelection: false,
        useTypingForward: true
    };

    protected _refInput = React.createRef<HTMLInputElement>();
    protected _btnRef = React.createRef<HTMLButtonElement>();
    protected _refContent = React.createRef<HTMLInputElement>();
    protected _refOpener = React.createRef<HTMLInputElement>();
    protected _refMenuWrapper = React.createRef<HTMLDivElement>();
    protected _refMenu = React.createRef<React.ReactElement>();

    protected _skipAutoComplete = false;

    protected sharedData: ISharedData = {
        isLoading: false,
        highlightedIndex: null,
        searchTerm: ""
    };

    private _wasChanged = false;
    private _latestCurrentValueIsFromFocusHandler = false;
    private _lastItem: ISelectItem = null
    // flag for firing triggerAdditionalTasks on blur
    // typical case is, when user change item not by click (or enter) but by arrow selection and escape (or by typing half of item and autocomplete)
    private _shouldFireValueOnBlur = false;

    _refTabularData = React.createRef<HTMLDivElement>();

    constructor(props: IProps & WithTranslation & WithPortalRootElement) {
        super(props);
        this.state = {
            currentValue: "",
            isOpen: false
        };
    }

    _useAutoSelection = (): boolean => {
        return this.props.useAutoSelection && !this.props.showSearchBoxInMenu;
    };

    _isMulti = (): boolean => {
        return !!this.props.isMultiSelect;
    };

    _isTabular = (): boolean => {
        return (this.props.columns || []).length > 1 || !!this.props.customTabularData;
    };

    _isAsync = (): boolean => {
        return !!this.props.onItemsFetchRequested;
    };

    _cutEdges = (width: number): number => {
        if (this.props.isSharpLeft) {
            width += 14;
        }

        if (this.props.isSharpRight) {
            width += 14;
        }

        return width;
    };

    _getDefaultMenuWidth = (): number => {
        let width: number;
        if (this.props.openerRef) {
            //@ts-ignore
            width = this.props.openerRef.current.offsetWidth;
        } else if (this._refInput.current) {
            width = this._refInput.current.offsetWidth;
        }

        return width || DEFAULT_WIDTH;
    };

    _getComputedMenuWidth = (): number => {
        return this.props.width ? this._cutEdges((parseInt(this.props.width) || DEFAULT_WIDTH) - 28 /*edges*/) : this._getDefaultMenuWidth();
    };

    componentDidUpdate(prevProps: IProps, prevState: IState): void {
        if (this._refInput.current) {
            // Highlighted index is defined and is not -1 (item not found) -> there is smthg. to autocomplete
            const hasAutocompleteItem = isDefined(this.sharedData.highlightedIndex) && this.sharedData.highlightedIndex >= 0;

            const { item } = this.getItemByCurrentValue(this.getAllItems(), this.sharedData.searchTerm);
            const realCurrentValue = this.getCurrentValue();
            const currentValue = item?.label ?? realCurrentValue;
            // Skip situations, when user deletes e.g. first letter and the rest of the string matches with highlighted item, but we don't want to set cursor to the end of the input value
            const startsWithButNotSame = currentValue !== this.sharedData.searchTerm && startsWithAccentsInsensitive(currentValue, this.sharedData.searchTerm);
            if (!this.props.inputIsReadOnly && this.sharedData.searchTerm && hasAutocompleteItem && startsWithButNotSame) {
                this._refInput.current.setSelectionRange(this.sharedData.searchTerm.length, currentValue.length);
            }

            if (prevProps.value !== this.props.value) {
                this.setState({ currentValue: this.getCurrentValue() });
                if (this._refInput.current.nodeName === "TEXTAREA") {
                    autosize.update(this._refInput.current);
                }
            } else if (this.hasFocus && (this._latestCurrentValueIsFromFocusHandler || (!!this.getCurrentItem() && !this._lastItem)) && currentValue !== prevState.currentValue) {
                // state.currentValue is set in handleFocus
                // but at that time, items doesn't have to be loaded yet
                // => update state.currentValue if items are loaded and new currentValue is different
                this.setState({ currentValue });
            }

            // in some cases, items are not loaded yet => _lastItem is null,
            // but the select already has focus, and we need to update the current value once the item is available
            this._lastItem = this.getCurrentItem();

            if (this._latestCurrentValueIsFromFocusHandler && prevState.currentValue !== this.state.currentValue) {
                this._latestCurrentValueIsFromFocusHandler = false;
            }

            if (this.state.isOpen && !prevProps.isDisabled && this.props.isDisabled) {
                this.setIsOpen(false);
            }
        }
    }

    selectItem = (item: ISelectItem, triggerAdditionalTasks = true): void => {
        if (item && !item.isDisabled) {
            // user selects to create a new value, just don't try to match with existing item on blur. onChange
            // handler was already called via "fireLiveChange", we don't need to call it again
            const allowCreateItem = item.additionalData?.isAllowCreateItem;
            this._shouldFireValueOnBlur = !triggerAdditionalTasks;

            const value = (allowCreateItem ? item.additionalData.value : item.id);

            this.props.onChange({
                value,
                additionalData: item.additionalData,
                sharedData: this.sharedData,
                triggerAdditionalTasks: triggerAdditionalTasks
            });

            if (triggerAdditionalTasks && !this.props.inputIsReadOnly && !this._isMulti()) {
                this.sharedData.searchTerm = "";
                if (allowCreateItem) {
                    this.setState({ currentValue: value.toString() });
                }
            } else if (this._isMulti()) {
                const currentValue = item.additionalData?.isNoRecord ? this.getNoRecordText() : (item.additionalData?.closeOnSelection ? item.label : this.sharedData.searchTerm);
                this.setState({ currentValue });

                if (item.additionalData?.closeOnSelection || item.additionalData?.isNoRecord) {
                    this.setIsOpen(false);
                }
            }
        }

        this._wasChanged = true;
    };

    setIsOpen = (isOpen: boolean): void => {
        this._setState({
            isOpen
        }, () => {
            this.props.onIsOpenChange?.(isOpen);
        });
    };

    public open = (): void => {
        if (!this.state.isOpen) {
            this.openDialogByEnterOrClick();
        }
    };

    public isOpen = (): boolean => {
        return this.state.isOpen;
    };

    openDialogByEnterOrClick = (): void => {
        if (!this.state.isOpen) {
            this.props.onOpen?.();

            // this erase previous search
            if (this.props.showSearchBoxInMenu) {
                this.sharedData.searchTerm = "";
            }

            this.sharedData.scrollDirection = Direction.Center;
            this.sharedData.highlightedIndex = null;

            this.setIsOpen(true);
        }
    };

    fireLiveChange = (currentValue: string): void => {
        this._shouldFireValueOnBlur = true;

        this.props.onChange({
            value: this.props.allowCreate ? currentValue : (this.props.value || null),
            triggerAdditionalTasks: false
        });
    };

    _setState = (newState: TRecordAny, callback?: () => void): void => {
        let hasChanged = false;
        for (const [key, value] of Object.entries(newState)) {
            if (value !== this.state[key as keyof IState]) {
                hasChanged = true;
            }
        }

        if (hasChanged) {
            this.setState({
                ...newState
            }, callback);
        }
    };

    handleChange = (e: IInputOnChangeEvent): void => {
        // first letter of select string cannot be space
        const value = e.value === " " ? "" : (e.value as string);
        this._wasChanged = true;

        this.sharedData.searchTerm = value;
        this._shouldFireValueOnBlur = true;

        delete this.sharedData.highlightedIndex;

        this.setState({ currentValue: value });

        if (this._isAsync()) {
            this.sharedData.isLoading = true;

            this.props.onItemsFetchRequested(value, (items: ISelectItem[]) => this.handleItemsFetched(items, value));
            this.setIsOpen(!!e.value);

            this.fireLiveChange(value);
            return;
        }

        if (!this.props.minCharsToOpenMenu || value.length >= this.props.minCharsToOpenMenu) {
            this.openDialogByEnterOrClick();
        }

        // if we are typing FORWARD (not delete or backspace which is handled by flag _skipAutoComplete) we cant try to preselect first possible item -- if there is any
        // if not just fire live change to update input
        if (this.props.useTypingForward && !this._skipAutoComplete) {
            const allItems = this.getAllItems();
            if (this.changeHighlight(allItems, value) !== false) {
                return;
            }
        }

        this.fireLiveChange(value);
    };

    getItemByCurrentValue = (items: ISelectItem[], value: string): { item: ISelectItem, idx: number } => {
        const fitIndex = getFirstMatchingItemIndex(items, value, this.props.searchType);
        const item = isDefined(fitIndex) ? items[fitIndex?.startsWith] : null;
        return { item, idx: fitIndex?.startsWith };
    };

    changeHighlight = (items: ISelectItem[], value: string): boolean | number => {
        const { item, idx } = this.getItemByCurrentValue(items, value);
        if (item) {
            this.forceUpdate(); // TODO: suspicious forceupdate

            const useAutoSelection = this._useAutoSelection();
            const possibleCurrentValue = fixWhiteSpaceChar(item.label);
            const valueHasNotChanged = this.props.value && this.props.value === item.id;
            const currentValueHasNotChanged = this.getCurrentValue() === possibleCurrentValue;
            this.setState({ currentValue: possibleCurrentValue });

            if ((useAutoSelection && valueHasNotChanged) ||
                (!useAutoSelection && currentValueHasNotChanged && !this.props.allowCreate)) {
                // in this case we are typing same value as in selected string
                // we don't have to do anything as the value and label is still the same
                // we need to change the selection only which is in componentDidUpdate
                return true;
            }

            this.props.onChange({
                value: useAutoSelection ? item.id : (this.props.allowCreate ? value : this.props.value),
                additionalData: useAutoSelection ? item.additionalData : {},
                triggerAdditionalTasks: false
            });

            return idx;
        }
        return false;
    };

    handleItemsFetched = (items: ISelectItem[], value: string): void => {
        if (!this._skipAutoComplete && this.sharedData.searchTerm === value) {
            const index = this.changeHighlight(items, value);
            if (Number.isInteger(index)) {
                let highlighted = index as number;
                // fetched items doesn't contain "allowCreate" item, so we need to update index in case something was found
                if (!!this.sharedData.renderedItems.find(item => item.additionalData?.isAllowCreateItem)) {
                    highlighted += 1;
                }
                this.sharedData.highlightedIndex = highlighted;
                this.forceUpdate();
            }
        }
    };

    getAllItems = (): ISelectItem[] => {
        return getAllItems({
            items: this.props.items,
            additionalItems: this.props.additionalItems,
            noRecordText: this.getNoRecordText()
        });
    };

    addNoRecordItem = (items: ISelectItem[]): void => {
        if (!this.props.isLoading && !this.sharedData.isLoading && !this.sharedData.dontDisplayNoDataFound && !this.props.dontDisplayNoDataFound) {
            items.push({
                isNotHighlightable: true,
                label: `(${!!this.state.currentValue && (this.props.items.length > 0 || this._isAsync()) ? this.props.t("Validation.NoRecord") : this.props.t("Validation.NoItems")})`,
                id: NO_RECORD_FOUND
            });
        }
    };

    isSearchable = (): boolean => {
        return !(this.props.inputIsReadOnly && !this.props.showSearchBoxInMenu);
    };

    handleSelectionChange = (item: ISelectItem, triggerAdditionalTasks: boolean): void => {
        if (!item || _isNoRecord(item) || item?.isDisabled) {
            return;
        }

        if (this.props.closeOnSelection && triggerAdditionalTasks) {
            this.setIsOpen(false);
        }

        // this will clear selection without losing focus which is the case of removeAllRanges
        // the high number move focus to the end of the input
        const length = (item.label?.length) || 100;
        this._refInput.current?.setSelectionRange(length, length);

        this.selectItem(item, triggerAdditionalTasks);
        this._refInput.current?.focus();
    };

    handleBlur = (e: IInputOnBlurEvent): void => {
        const origEvent: any = e.origEvent || e;
        const target: HTMLElement = origEvent.relatedTarget;
        const contains = doesElementContainsElement(this._refMenuWrapper.current, target);
        const isBlurringToSearch = target?.getAttribute("data-testid") === TestIds.SelectSearchInput;

        if (!contains && !isBlurringToSearch) {
            if (!this.props.preventHideOnBlur) {
                this.setIsOpen(false);
            }

            let highlightedItem;
            if (this.state.isOpen && this.sharedData.searchTerm && !this._isMulti()) {
                if (isDefined(this.sharedData.highlightedIndex)) {
                    highlightedItem = this.sharedData.renderedItems[this.sharedData.highlightedIndex];
                } else if (!this.props.allowCreate && (this._useAutoSelection() || this.props.useTypingForward)) {
                    const { item } = this.getItemByCurrentValue(this.getAllItems(), this.sharedData.searchTerm);
                    highlightedItem = item;
                }
            }
            if (highlightedItem) {
                // Confirmation of current highlighted value
                this.selectItem(highlightedItem);
            } else if (this._shouldFireValueOnBlur) {
                // revert to last value or clear currentValue, so there is "no record", etc...
                this._fireValueOnBlur();
            }

            e.wasChanged = this._wasChanged;
            this._wasChanged = false;
            this.props.onBlur?.(e);
        }

        const item = this.getCurrentItem();
        // draws additional columns with gray color to input after blur is fired (for read only items it is displayed only without focus)
        // also, when item contains link, we need to make sure it renders it on blur
        if ((!this.props.inputIsReadOnly && this._isTabular()) || (!e.wasChanged && item?.link)) {
            this.forceUpdate();
        }
    };

    handleInputClick = (e: React.MouseEvent): void => {
        this.props.onClick?.(e);

        if (this.props.openOnClick && !e?.defaultPrevented) {
            this.openDialogByEnterOrClick();
            e.preventDefault();
        }
    };

    handleIconClick = (e: React.MouseEvent): void => {
        // prevent trigger input click
        e.stopPropagation();

        this.props.onIconActivate?.(e);
        if (e.nativeEvent.defaultPrevented) {
            return;
        }

        e.preventDefault();

        // we prevented propagation so we need to focus input manually
        this._refInput.current?.focus();

        if (this.state.isOpen) {
            this._fireValueOnBlur();
            this.setIsOpen(false);
        } else {
            this.openDialogByEnterOrClick();
        }
    };

    handleInputRef = (ref: HTMLInputElement): void => {
        handleRefHandlers(ref, this._refInput, this.props.inputRef);
    };

    handleHighlightChange = (item: ISelectItem): void => {
        if (this._useAutoSelection()) {
            this.selectItem(item, false);
        } else {
            const { isAllowCreateItem, value } = item.additionalData ?? {};
            const currentValue = isAllowCreateItem ? value.toString() : item.label;
            this.fireLiveChange(currentValue);
            this.setState({ currentValue });
        }
    };

    _opener = (): HTMLButtonElement => {
        return (this.props.buttonRef as React.RefObject<HTMLButtonElement>)?.current || this._btnRef?.current;
    };

    /**
     * Special handling of keydown on search input in menu
     * @param e
     */
    handleInputKeyDown = (e: React.KeyboardEvent): void => {
        // input in select menu is rendered directly in body, but we want to focus next element after it's opener
        const button = this._opener();

        if (e.key === KeyName.Tab) {
            focusNextElement(button);
            e.preventDefault();
            return;
        }

        if (e.key === KeyName.Escape) {
            if (this.props.isIconSelect) {
                button.focus();
            }
        }

        if (e.key === KeyName.Space) {
            return;
        }

        this.handleKeyDown(e);
    };

    _fireValueOnBlur(): void {
        const keepCurrentValue = this.props.allowCreate;
        const searchBy = keepCurrentValue ? this.sharedData.searchTerm : undefined;
        const item = !this._isMulti() && this.getCurrentItem(true, searchBy);
        if (item) {
            // Revert select to the last selected value
            this.selectItem(item, false);
            this.sharedData.searchTerm = "";
        } else {
            // If no item is selected (e.g. select was empty and there is no "noRecordText"), just clear currentValue
            // or if allowCreate with autoSelection === false is set, we may need to fire currentValue on blur
            const value = keepCurrentValue ? this.getCurrentValue() : "";
            this.fireLiveChange(value);
            this.sharedData.searchTerm = value;
            this.sharedData.highlightedIndex = null;
        }
    }

    _handleKeyDown(e: React.KeyboardEvent): void {
        // menu is opened -> forward the event to the menu component
        // @ts-ignore
        (this._refMenu.current as Menu)?._handleKeyDown(e);

        let shouldOpen = false;

        if (isDirectionKey(e.key)
            && !e.altKey // ignore combinations with alt, so that we can handle them somewhere else (e.g. fast entry), without triggering this action
        ) {
            this.props.onOpen?.();
            // this prevents scrolling page or div scrollers
            e.nativeEvent.preventDefault();

            // If menu is not open, direction keys change the value
            if (!this.state.isOpen && !this._isMulti()) {
                const items = this.getAllItems();
                const { value } = this.props;
                const highlightedIndex = this.sharedData.highlightedIndex ?? getHighlightedIndex(null, items, value, this.getCurrentValue());

                const result = handleNavigationKey(items, highlightedIndex, e.key as KeyName);
                if (result) {
                    this.sharedData.highlightedIndex = result.highlightedIndex;
                    this.sharedData.scrollDirection = result.scrollDirection;
                    // Directly change the value
                    this.handleSelectionChange(items[result.highlightedIndex], true);
                }
            }
        }

        this._skipAutoComplete = e.key === KeyName.Backspace || e.key === KeyName.Delete;

        switch (e.key) {
            case KeyName.Escape:
                if (this.state.isOpen) {
                    this._fireValueOnBlur();
                    // stop propagation so that for example we don't close dialog when closing opened select
                    e.stopPropagation();
                    this.setIsOpen(false);
                }

                this.sharedData.searchTerm = "";
                this.setState({ currentValue: this.getCurrentValue() });
                // Escape will by default clear input value -> we don't want that
                e.preventDefault();
                return;

            case KeyName.Enter:
                // enter on iconSelect already triggers open action by click event from button
                if (!this.props.isIconSelect) {
                    shouldOpen = true;
                    // stop propagation, so we don't confirm dialog when opening select
                    e.stopPropagation();
                }
                break;

            case KeyName.Space:
                if (this.props.inputIsReadOnly) {
                    this.setIsOpen(!this.state.isOpen);
                    e.nativeEvent.preventDefault();
                }
                break;
        }

        if (shouldOpen) {
            this.openDialogByEnterOrClick();
        }

        // In case it's last character of autocompleted string, trigger handleChange manually as it's
        // not called automatically by the input (the text is already here, so no change is triggered from input)
        const nextValue = `${this.sharedData.searchTerm}${e.key}`;
        const inputValue = this._refInput.current?.value;
        if (nextValue === inputValue) {
            this.handleChange({
                value: nextValue
            });
            // + remove selection of the last character as we are preventing default behavior (nothing changes actually)
            this._refInput.current.setSelectionRange(nextValue.length, nextValue.length);
            // prevent default behavior, so the key stroke is not duplicated
            e.preventDefault();
        }
    }

    handleKeyDown = (e: React.KeyboardEvent): void => {
        this.props.onKeyDown?.(e);

        if (!e.defaultPrevented) {
            this._handleKeyDown(e);
        }
    };

    handleInputBlur = (e: FocusEvent<HTMLInputElement>): void => {
        this.handleBlur({
            wasChanged: false,
            origEvent: e
        });
    };

    renderMenuWithPortal = (autocompleteRoot: HTMLElement): React.ReactPortal => {
        return ReactDOM.createPortal(this.renderMenuContent(), autocompleteRoot);
    };

    renderMenuWithoutPortal = (): React.ReactElement => {
        return this.renderMenuContent();
    };

    renderMenu = (): React.ReactNode => {
        const autocompleteRoot = getPortalRootElement(this.props.portalRootElementRef);
        return autocompleteRoot ? this.renderMenuWithPortal(autocompleteRoot) : this.renderMenuWithoutPortal();
    };

    handleSelectInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        this.handleChange({
            value: e.target.value,
            origEvent: e
        });

        this.forceUpdate();
    };

    renderMenuContent = (): React.ReactElement => {
        const value = this.sharedData.searchTerm ?? this.state.currentValue;
        const { groups } = this.props;

        // filter items based on typing or other conditions
        this.sharedData.renderedItems = filterItems({
            items: this.getAllItems(),
            skipFilter: this._isAsync() || !this.isSearchable(),
            searchType: this.props.searchType,
            value
        });

        // if there is no filterable items (that DOES NOT MEAN NO ITEM) .. f.e. additionalItems or action items
        // are 'not filterable' => set no record found flag
        const haveAnyFilterableItem = (this.sharedData.renderedItems || []).find(item => !item.isNotFilterable);
        if (!haveAnyFilterableItem && !this.props.allowCreate) {
            this.addNoRecordItem(this.sharedData.renderedItems);
        }

        if (this.props.allowCreate && value && (this._useAutoSelection() || this.props.useTypingForward)) {
            this.sharedData.renderedItems.unshift({
                label: `${value} (${this.props.t("Common:General.New")})`,
                id: ALLOW_CREATE_ID,
                isSearchable: false,
                additionalData: {
                    isAllowCreateItem: true,
                    value
                },
                groupId: SelectGroups.Default
            });
        }

        // the logic behind is this: typing RESET highlighting index so after user type stuff
        // this index is calculated and set to the first found item right here
        // the benefit is this is the only place who sets it after user types some stuff
        let firstItemIndex: IGetFirstMatchingIndexReturnType;
        if (value) {
            firstItemIndex = getFirstMatchingItemIndex(this.sharedData.renderedItems, value, this.props.searchType);
        } else if (this.props.noRecordText) {
            // if searchValue is empty, preselect noRecord item, so user can easily confirm it
            const noRecordIdx = this.sharedData.renderedItems.findIndex(item => item.additionalData?.isNoRecord);
            firstItemIndex = {
                anyWordFit: noRecordIdx,
                startsWith: noRecordIdx
            };
        }

        if (this.sharedData.searchTerm && isNotDefined(this.sharedData.highlightedIndex) && (this._useAutoSelection() || this.props.useTypingForward)) {
            if (this.props.allowCreate && (isNotDefined(firstItemIndex?.anyWordFit) || this._skipAutoComplete)) {
                delete this.sharedData.highlightedIndex;
                // todo: DEV-23821 try not to preselect "New" item, when there is no match. User has to select it explicitly
                // Put back if previous solution would be better from UX point of view
                // this.sharedData.highlightedIndex = this.sharedData.renderedItems.findIndex(item => item.additionalData?.isAllowCreateItem);
            } else {
                this.sharedData.highlightedIndex = firstItemIndex?.anyWordFit;
            }
        }

        const moveRight = !this.props.isSharpLeft && !this.props.fieldComponent;
        const left = this.props.menuOffset?.left ? this.props.menuOffset.left : (moveRight ? 15 : 0);
        const top = this.props.menuOffset?.top ? this.props.menuOffset.top : (this.props.displayArrow ? 15 : 0);
        return (
            <Popper
                modifiers={[
                    {
                        name: "preventOverflow",
                        options: {
                            rootBoundary: "viewport",
                            padding: 19
                        }
                    }, {
                        name: "offset",
                        options: {
                            offset: [left, top]
                        }
                    }
                ]}
                placement={this.props.placement || "bottom-start"}>
                {(popperProps) => (
                    <>
                        <Menu
                            shouldSearchWithKeyboard={this.props.inputIsReadOnly && !this.props.showSearchBoxInMenu}
                            ref={this._refMenu}
                            refOpener={this._refOpener}
                            refMenu={this._refMenuWrapper}
                            refContent={this._refContent}
                            currentValue={this.state.currentValue}
                            isTabular={this._isTabular()}
                            groups={groups}
                            defaultMenuWidth={this._getComputedMenuWidth()}
                            items={this.sharedData.renderedItems}
                            value={this.props.value}
                            isSelected={this.props.isSelected}
                            columns={this.props.columns}
                            isMulti={this._isMulti()}
                            itemContent={this.props.itemContent}
                            onSelectionChange={this.handleSelectionChange}
                            displayArrow={this.props.displayArrow}
                            inputValue={this.sharedData.searchTerm}
                            headerText={this.props.headerText}
                            searchType={this.props.searchType}
                            showTabularHeader={_showTabularHeader(this.props)}
                            onInputChange={this.handleSelectInputChange}
                            onInputKeyDown={this.handleInputKeyDown}
                            onInputBlur={this.handleInputBlur}
                            onHighlightChange={this.handleHighlightChange}
                            sharedData={this.sharedData}
                            showSearchBoxInMenu={this.props.showSearchBoxInMenu}
                            renderDefaultGroupWithoutCheckboxes={this.props.renderDefaultGroupWithoutCheckboxes}
                            popperProps={popperProps}/>
                    </>
                )}
            </Popper>
        );
    };


    handleFocus = (e: React.FocusEvent<HTMLInputElement>): void => {
        const item = this.getCurrentItem();

        this.setState({ currentValue: this.getCurrentValue() }, () => {
            this._latestCurrentValueIsFromFocusHandler = true;
        });

        if (this._shouldRenderAdditionalColumnsInInput() || item?.link) {
            // re-render the select, so tabular data are not rendered when element gains focus
            this.forceUpdate();
        }
        this.props.onFocus?.(e);
    };

    getNoRecordText = (): string => {
        return this.props.noRecordText;
    };

    getCurrentValue = (): string => {
        if (this.props.formatter) {
            const value = this.props.formatter(this.props.value);

            if (!value && this.props.noRecordText) {
                return this.getNoRecordText();
            }

            if (isDefined(value)) {
                return value;
            }
        }

        if (this.props.allowCreate) {
            // for autocomplete values, we bind the select to actually string values,
            // e.g. BP name, item description, so we should show currentValue according to the real props.value
            // (the autocomplete result). It's not navigation, so it doesn't make sense to search for matching items
            return this.props.value as string;
        }

        const item = this.getCurrentItem(true);

        if (item) {
            return item.label;
        }

        if (this.props.value && !this.props.displayName) {
            return this.props.value.toString();
        }

        if (this.props.noRecordText && (!this._isMulti || !this.props.hasValue)) {
            return this.getNoRecordText();
        }

        return "";
    };

    getCurrentItem = (includeAllItems = false, searchBy = this.props.value): ISelectItem => {
        return ((includeAllItems ? this.getAllItems() : this.props.items) || [])
            .concat(this.props.additionalItems).concat(this.props.initialItems).find(item => item && item.id?.toString() === searchBy?.toString());
    };

    createReadOnlyTabularText = (): string => {
        if (this.props.isReadOnly && isObjectEmpty(this.props.auditTrailData) && this.props.shouldDisplayAdditionalColumns && this.props.value && this._isTabular()) {
            const item = this.getCurrentItem();
            const label = item?.label || this.getCurrentValue();
            // force cases when label is not on the first position
            const tabularData = (this.fillTabularDataFromPreloadedData(item) || []).filter(name => name !== label);

            return " | " + tabularData.join(" | ");
        }

        return null;
    };

    createReadOnlyText = (currentValue?: string): string => {
        if (this._isMulti()) {
            return this.getAllItems().filter(item => item.id !== SelectAdditionalItems.SelectAll && this.props.isSelected(item)).map(item => item.label).join(", ");
        }

        return currentValue;
    };

    fillTabularDataFromPreloadedData = (item: ISelectItem): string[] => {
        let tabularData = item?.tabularData;
        const additionalData = item?.additionalData ?? this.props.additionalData;
        // this is the scenario where items are not loaded yet but we cant get additional info data by loadData prop
        // which is loaded (used additionalProperties) with the initial entity load
        if (!item?.tabularData && additionalData) {
            tabularData = [];
            for (const col of this.props.columns || []) {
                if (col.id !== this.props.displayName) {
                    tabularData.push(additionalData[col.id] as string);
                }
            }
        }

        return tabularData;
    };

    _shouldRenderAdditionalColumnsInInput = (): boolean => {
        return !!((this.props.shouldDisplayAdditionalColumns || this.props.customTabularData) && this.props.value);
    };

    get hasFocus(): boolean {
        return document.activeElement === this._refInput.current;
    }

    onLinkClick = (e: React.SyntheticEvent): void => {
        // prevent select from calling preventDefault
        e.stopPropagation();
    };

    createContentBefore = (): IContentBefore => {
        if (this.props.isReadOnly && isObjectEmpty(this.props.auditTrailData)) {
            return null;
        }

        if (!this._isMulti()) {
            const item = this.getCurrentItem();
            if (item?.color) {
                return {
                    title: item.label,
                    component: (
                        <Token
                            title={item.label}
                            color={item.color}/>
                    ),
                    zeroInputWidth: true
                };
            }

            if (item?.link && !this.hasFocus) {
                return {
                    title: item.label,
                    link: <SelectItemLink link={item.link}
                                          onClick={this.onLinkClick}>
                        {this.getCurrentValue()}
                    </SelectItemLink>,
                    zeroInputWidth: false
                };
            }
        }

        if (this._shouldRenderAdditionalColumnsInInput()) {
            if ((this.props.inputIsReadOnly || !this.hasFocus) && this._isTabular()) {
                const item = this.getCurrentItem();
                const label = item?.label || this.getCurrentValue();
                const tabularData = this.props.customTabularData ?? this.fillTabularDataFromPreloadedData(item);

                if (tabularData?.length > 0) {
                    const tabularText = (tabularData || []).filter(text => text && text !== label).join(DescriptionSeparator);
                    const title = `${label}${DescriptionSeparator}${tabularText}`;

                    const component = (
                        <>
                            {/*itemContent should be rendered both standalone and together with additional columns description*/}
                            {this.props.passItemContentToInput && item?.itemContent ? item.itemContent : null}
                            <InputTabularAllText
                                ref={this._refTabularData}
                                auditTrailType={this.props.auditTrailData?.type}
                                hasError={!!this.props.error}
                                data-testid={TestIds.SelectInputLabel}>
                                <span>{label}</span>
                                <InputDescriptionText>
                                    {DescriptionSeparator}{tabularText}
                                </InputDescriptionText>
                            </InputTabularAllText>
                        </>
                    );
                    return {
                        title, component, zeroInputWidth: true
                    };
                }
            }
        }

        if (!this._isMulti()) {
            const item = this.getCurrentItem();

            if (this.props.passItemContentToInput && item?.itemContent) {
                return {
                    title: item.label,
                    component: item.itemContent,
                    zeroInputWidth: false
                };
            }
        }

        return null;
    };

    getCursor = (): string => {
        return this.props.inputIsReadOnly && !this.props.isReadOnly && !this.props.isDisabled ? "pointer" : "";
    };

    render() {
        const singleSelectLabeledContentBefore = this.createContentBefore();
        const { title, component, link, zeroInputWidth } = singleSelectLabeledContentBefore ?? {};

        const inputProps: React.AllHTMLAttributes<HTMLInputElement> = {
            ...this.props.inputProps,
            width: !!zeroInputWidth ? "0px" : this.props.inputProps?.width,
            readOnly: isDefined(this.props.inputIsReadOnly) ? this.props.inputIsReadOnly : false
        };

        const sharedInputProps = getSharedInputProps(this.props);
        //todo: error handling not finished yet, decide what to throw and when
        // if (sharedInputProps.error && sharedInputProps.error.detailType === ValidationErrorDetailType.NoRecord) {
        //     sharedInputProps.error = null;
        // }
        const { error } = sharedInputProps;

        const customOverflowTooltip = !singleSelectLabeledContentBefore ? {} : {
            tooltip: () => isOverflowing(this._refTabularData?.current, 1) || error ? (error?.message ?? title) : ""
        };

        const currentValue = this.getCurrentValue();
        const isItalic = (currentValue === this.getNoRecordText() && !this.props.value) || this.props.value === EMPTY_VALUE;

        const readOnlyTabularData = this.createReadOnlyTabularText();

        let select = (
            <Manager>
                <Reference>
                    {({ ref }: ReferenceChildrenProps) => {
                        // ref cannot be called during rendering, otherwise setState is triggered leading to errors in console
                        setTimeout(() => {
                            //@ts-ignore
                            this.props.openerRef && ref(this.props.openerRef.current);
                        });

                        const handleBtnRef = (element: HTMLButtonElement) => {
                            handleRefHandlers(element, ref, this._btnRef);
                        };

                        return (
                            <>
                                {this.props.fieldComponent &&
                                    this.props.fieldComponent({
                                        isOpen: this.state.isOpen,
                                        isDisabled: this.props.isDisabled,
                                        onClick: this.handleInputClick,
                                        onBlur: this.handleBlur,
                                        onKeyDown: this.handleKeyDown,
                                        onWheel: this.props.onWheel,
                                        onIconClick: this.props.isIconWithoutAction ? null : this.handleIconClick,
                                        openerRef: this.props.openerRef ? null : handleBtnRef
                                    })
                                }
                                {!this.props.fieldComponent &&
                                    <InputWrapper
                                        onWheel={this.props.onWheel}
                                        _isItalic={isItalic && this.props.isReadOnly}
                                        ref={ref}>
                                        <StyledSelectInput
                                            {...sharedInputProps}
                                            {...customOverflowTooltip}
                                            _isItalic={isItalic}
                                            passRef={this.handleInputRef}
                                            isReadOnly={this.props.isReadOnly}
                                            onFocus={this.handleFocus}
                                            selectOnFocus={!this.props.inputIsReadOnly && this.props.openOnClick}
                                            isMultiLine={false}
                                            onKeyDown={this.handleKeyDown}
                                            contentBefore={this.props.contentBefore || component}
                                            link={link}
                                            onChange={this.handleChange}
                                            onBlur={this.handleBlur}
                                            onIconClick={this.props.isIconWithoutAction ? null : this.handleIconClick}
                                            onClick={this.handleInputClick}
                                            cursor={this.getCursor()}
                                            inputProps={inputProps}
                                            icon={this.props.inputIcon}
                                            value={this.props.isReadOnly ? this.createReadOnlyText(currentValue) : this.hasFocus ? this.state.currentValue ?? "" : currentValue ?? ""}
                                            isActive={this.state.isOpen}
                                            auditTrailData={this.props.auditTrailData}
                                        />
                                        {readOnlyTabularData &&
                                            <ReadOnlyTabularData
                                                isLight={this.props.isLight}>{readOnlyTabularData}</ReadOnlyTabularData>
                                        }
                                    </InputWrapper>
                                }
                            </>
                        );
                    }}
                </Reference>
                {this.state.isOpen &&
                    this.renderMenu()
                }
            </Manager>
        );

        if (!this.props.fieldComponent) {
            select = (
                <StyledSelect
                    className={this.props.className} style={this.props.style}
                    _height={this.props.height}
                    _cursor={this.getCursor()}
                    ref={this.props.passRef}
                    data-testid={TestIds.Select}>
                    {select}
                </StyledSelect>
            );
        }

        return select;
    }
}

const BasicSelectWithTranslation = withTranslation(["Common"], { withRef: true })(withPortalRootElement(BasicSelect));
export { BasicSelectWithTranslation as BasicSelect, BasicSelect as BasicSelectClean };
