import { IEntity } from "@odata/BindingContext";
import { IFieldInfo } from "@odata/FieldInfo.utils";
import { CommonReportProps } from "@pages/reports/CommonDefs";
import { IncomeStatementDelta } from "@pages/reports/incomeStatement/IncomeStatement";
import {
    findColumnInHierarchy,
    formatDateByTimeAggFn,
    getColumnFullAlias,
    getColumnLabel,
    getCurrencyColumn,
    getReportRowId,
    getTimeAggFuncItem,
    IReportData,
    IReportErrorData,
    IReportSettings,
    labelChildrenSuffix,
    NumberAggFuncTypes,
    NumberAggregationFunction,
    ReportColumnType,
    TAggregationFunction,
    TimeAggregationFunction,
    TReportColumnOverrides
} from "@pages/reports/Report.utils";
import { ReportStorage } from "@pages/reports/ReportStorage";
import { formatPercent, ifPositive, isDefined } from "@utils/general";
import { getOneFetch } from "@utils/oneFetch";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import { FETCH_ABORT_ERROR } from "../../../constants";
import { AppContext } from "../../../contexts/appContext/AppContext.types";
import {
    ActionState,
    CurrencyUnit,
    GroupStatus,
    IconSize,
    ReportTableRowType,
    RowAction,
    RowType,
    Sort,
    Status,
    TextAlign,
    ToggleState
} from "../../../enums";
import { TRecordAny, TSetState, TValue } from "../../../global.types";
import BooleanType from "../../../types/Boolean";
import CurrencyType from "../../../types/Currency";
import { getUtcDate } from "../../../types/Date";
import NumberType from "../../../types/Number";
import LocalSettings from "../../../utils/LocalSettings";
import memoize from "../../../utils/memoize";
import { GrowthNegativeIcon, GrowthPositiveIcon, GrowthZeroIcon } from "../../icon";
import {
    ICellValueObject,
    IColumn,
    IMetaColumn,
    IRow,
    IRowAction,
    IRowValues,
    ISort,
    TCellValue,
    TColumn,
    TId
} from "../../table";
import { IRowProps } from "../../table/Rows";
import { CellMicroChart, CellMicroChartText } from "../../table/Rows.styles";
import { NEW_ROW_ID } from "../../table/Table.types";
import { tableTokenFormatter } from "../../token";
import { IFieldInfoProperties } from "../FieldInfo";
import { ISmartODataTableState } from "./SmartODataTableBase";
import { DimmedValue } from "./SmartReportTable.styles";
import {
    addIndexToDuplicateColumnId,
    COLUMN_INDEX_REGEX,
    fetchReportTableData,
    getCleanColumnId,
    getColumnOverride,
    labelParentFormatter,
    propagateParentValues
} from "./SmartReportTable.utils";
import { ISmartTableCommonProps } from "./SmartTable";
import {
    getGroupToggleState,
    getRow,
    getRowsArrayFromRows,
    getToggleState,
    iterateOverRows,
    saveGroupRowsState,
    TCustomRowAction,
    updateRow,
    updateRows
} from "./SmartTable.utils";
import { IGroupToggleEvent, SmartTableBase } from "./SmartTableBase";
import SmartTableManager from "./SmartTableManager";
import SmartTableStateBase from "./SmartTableStateBase";

const oneFetch = getOneFetch();

interface IProps extends ISmartTableCommonProps, WithTranslation {
    settings: IReportSettings;
    // sometimes (e.g. on variant change),
    // we want to prevent table from reloading too early, before the settings are correctly set,
    // otherwise, there might be multiple reloads after each other
    preventReload?: boolean;
    path: string;
    onRowSelect?: (id: TId, row: IReportRowDef) => void;
    selectedRow?: TId;
    onGroupToggle?: (updatedRows: ISmartReportTableRow[]) => void;
    onSortChange?: (sort: ISort[]) => void;
    /** Let report know that the smart table has rerendered in look for printing,
     * otherwise the reference to table could point outside of current DOM tree */
    onReadyForPrint?: () => void;
    initialSortBy?: ISort[];
    reportHierarchy?: IReportHierarchy;
    rowAction?: TCustomRowAction;
    onActiveRowActionCountChange?: (activeRowActionCount: number) => void;
    currencyUnit?: CurrencyUnit;
    metaColumnFormatter?: TMetaColumnFormatter;
    rowFormatter?: TReportRowFormatter;
    columnFormatter?: TReportColumnFormatter;
    columnOverrides?: TReportColumnOverrides;
    /** Can be used to alter the data returned from backend. Called before rows are created. */
    rowsDataFactory?: (rowsData: IReportRowDef[], args: IReportRowFormatterArgs) => IReportRowDef[];
    showDrilldown?: boolean;
    dimValues?: number[];
    /** If storage given, table will expose its state and API inside it. Otherwise local variable is used. */
    storage: ReportStorage;
    ref?: React.RefObject<any>;
}

export type TMetaColumnFormatter = (args: IMetaColumnFormatterArgs) => Omit<IMetaColumn, "columns">;
export type TReportColumnFormatter = (args: IReportColumnFormatterArgs) => ISmartReportColumn;
export type TReportRowFormatter = (row: IReportRowDef, args: IReportRowFormatterArgs) => Partial<ISmartReportTableRow>;

export interface IReportRowFormatterArgs {
    storage: ReportStorage;
    row?: ISmartReportTableRow;
    columns: ISmartReportColumn[];
}

export interface IMetaColumnFormatterArgs {
    index: number;
    settings: IReportSettings;
    metaColumn: IReportColumnGroupDef;
    // each meta column has only one column in reports
    column: ISmartReportColumn;
    columnIndex: number;
    allColumns: ISmartReportColumn[];
}

export interface IReportColumnFormatterArgs {
    index: number;
    isInMetaColumn: boolean;
    /** Index of the parent meta column, if column is present inside of some */
    metaColumnIndex?: number;
    columnDef?: IReportColumnDef;
    column: ISmartReportColumn;
    allColumns: ISmartReportColumn[];
    settings: IReportSettings;
}

export interface ISmartReportTableState extends ISmartODataTableState {
    data?: IReportData;
    sort?: ISort[];
    noDataText?: string;
    // GroupStatus.Expanded when all groups expanded, GroupStatus.Collapsed when all groups closed
    // GroupStatus.Unknown otherwise
    allGroupStatus?: GroupStatus;
}

export interface IAffectedReportRow {
    id: TId;
    originalValues: TRecordAny;
}

interface IPreparedData {
    rows: Record<string, ISmartReportTableRow>;
    rowsOrder: string[];
    rowsArray: ISmartReportTableRow[];
    columns: IFieldInfo[];
    rowCount: number;
}

export interface ISmartReportTableAPI {
    getState: () => ISmartReportTableState;
    setState: TSetState<IProps, ISmartReportTableState>;
    getRowsArray: () => IRow[];
    getAffectedRows: () => Promise<IAffectedReportRow[]>;
    confirmAction: () => Promise<boolean>;
    cancelAction: () => void;
    reloadTable: () => Promise<void>;
    forceUpdate: () => void;
    toggleAllGroups: (status: GroupStatus) => void;
    getAllGroupStatus: () => GroupStatus;
    getSort: () => ISort[];
    prepareData: (data: IReportData) => IPreparedData;
}

export interface ISmartReportTableRow extends IRow {
    closedValues?: IRowValues;
    openedValues?: IRowValues;
}

export interface IReportRowDef {
    Type: ReportTableRowType;
    Value: Record<string, TValue>;
    Rows: IReportRowDef[];
}

export interface IReportHierarchy {
    Aggregate: boolean;
    Groups: IReportColumnDef[];
    Columns: IReportColumnDef[];
    Aggregations: IReportColumnDef[];
}

export interface IReportVariantDef {
    ReportHierarchy: IReportHierarchy;
    Sort?: ISort[];
}

export interface IReportColumnDef {
    ColumnAlias: string;
    Table?: string;
    Column?: string;
    Label?: string;
    EntityLabel?: string;
    Type?: ReportColumnType;
    AggregationFunction?: TAggregationFunction;
    AdditionalInfo?: TRecordAny;
    DependentColumnsAliases?: string[];
    VirtualColumn?: boolean;
    IsColumnAliasFilterPrefix?: boolean;
    IsFilterable?: boolean;
}

export interface IReportColumnGroupDef {
    Label: string;
    AdditionalInfo: TRecordAny;
    ColumnsInGroup: string[];
}

export interface IReportSortDef {
    Column?: IReportColumnDef;
    ColumnAlias?: string;
    Order: Sort;
}

export interface ISmartReportColumn extends IColumn {
    type: ReportColumnType;
    currency?: string;
}

export interface IReportWarning {
    WarningIdentifier: string;
    AdditionalInfo?: TRecordAny;
}

type TDefaultReportTableData = Pick<ISmartODataTableState, "columns" | "rows" | "rowsOrder" | "rowCount" | "activeRows" | "toggleState">;
const getDefaultTableData = (): TDefaultReportTableData => ({
    columns: [],
    rows: {},
    rowsOrder: [],
    rowCount: null,
    activeRows: new Set<string>(),
    toggleState: ToggleState.AllUnchecked
});

class SmartReportTable extends SmartTableStateBase<IProps, ISmartReportTableState> {
    static contextType = AppContext;
    //sadly, breaks typescript type checking
    //context: React.ContextType<typeof AppContext>;
    static defaultProps = {
        currencyUnit: CurrencyUnit.Units
    };

    state: ISmartReportTableState = {
        // raw data from backend
        data: { Columns: [], ColumnGroups: [], Rows: [] },
        // processed columns and rows
        ...getDefaultTableData(),
        loaded: false,
        sort: null,
        noDataText: null,
        allGroupStatus: GroupStatus.Collapsed
    };

    componentWillUnmount() {
        super.componentWillUnmount();
        oneFetch.abort();
        SmartTableManager.unregisterTable(this.props.tableId);
    }

    componentDidMount = () => {
        this._isMounted = true;

        const tableAPI = {
            getState: this.getTableState,
            setState: this.setState.bind(this),
            getRowsArray: this.getRowsArray.bind(this),
            getAffectedRows: this.getAffectedRows,
            confirmAction: () => {
                this.resetTableSelection();
                return Promise.resolve(true);
            },
            cancelAction: this.resetTableSelection,
            reloadTable: this.loadData,
            forceUpdate: this.forceUpdate.bind(this),
            toggleAllGroups: this.toggleAllGroups,
            getAllGroupStatus: () => {
                return this.state.allGroupStatus;
            },
            getSort: this.getSort,
            prepareData: this.prepareData
        };

        SmartTableManager.registerTable(this.props.tableId, tableAPI);

        if (this.props.settings) {
            this.loadData();
        }
    };

    initAfterUpdate = (): void => {
        // reset action row selection on filter change
        this.handleToggleChange(ToggleState.AllUnchecked);
        this.loadData();
    };

    componentDidUpdate(prevProps: IProps, prevState: ISmartReportTableState) {
        if (!this.props.preventReload && (prevProps.settings !== this.props.settings
            || prevProps.reportHierarchy !== this.props.reportHierarchy
            || prevProps.preventReload !== this.props.preventReload)) {
            // clear table sort (can be changed by new variant) to retrieve it via initialSortBy
            // when in drilldown, current sort is only stored here on table => don't remove it
            if (!this.props.storage.data.predefinedFilters) {
                this.setState({
                    sort: null
                    // call after state is changed - initAfterUpdate works with state.sort, it needs to already be empty
                }, this.initAfterUpdate);
            } else {
                this.initAfterUpdate();
            }

        }

        if (!prevProps.isForPrint && this.props.isForPrint) {
            this.props?.onReadyForPrint();
        }
    }

    get isHierarchical(): boolean {
        return this.props.reportHierarchy?.Aggregate && this.props.reportHierarchy?.Groups.length > 0;
    }

    get rowActionType(): RowAction {
        return this.props.rowAction?.actionType;
    }

    /** By default, table handles row action state (e.g. selected rows).
     * If props.rowAction.getActionState is defined, state will no longer be handled by table.*/
    get shouldHandleRowActionState(): boolean {
        return !this.props.rowAction?.getActionState;
    }

    loadData = async () => {
        this.props.storage.setCustomData({
            warnings: null
        });
        this.setState({
            loaded: false
        });

        try {
            const response = await fetchReportTableData({
                path: this.props.path,
                settings: this.props.settings,
                reportHierarchy: this.props.reportHierarchy,
                storage: this.props.storage,
                sort: this.getSort()
                    // don't send multiple sorts for same column
                    .filter(sort => !COLUMN_INDEX_REGEX.test(sort.id.toString()))
            }, oneFetch.fetch);

            if (!response.ok) {
                const data: IReportData = {
                    Columns: this.getTableState().data.Columns,
                    ColumnGroups: this.getTableState().data.ColumnGroups,
                    Rows: []
                };
                // todo: show error to the user as there might be some invalid configuration in columns, etc...
                //       currently it looks like there are no data
                this.setState({
                    loaded: true,
                    ...getDefaultTableData(),
                    data
                }, () => {
                    this.props.onAfterTableLoad?.();
                });
                this.init(data);
                return;
            }

            const results = await response.json();

            if (results.Warnings) {
                this.props.storage.setCustomData({
                    warnings: results.Warnings
                });
                this.props.storage.refresh();
            }

            if (results.Message) {
                this.processErrorResponse(results as IReportErrorData);
            } else {
                this.processOkResponse(results as IReportData);
            }
        } catch (err) {
            if (err.name !== FETCH_ABORT_ERROR) {
                throw (err);
            }
        }
    };

    getRowsArray(rows: Record<string, IRow> = this.state.rows, rowsOrder = this.state.rowsOrder): IRow[] {
        return getRowsArrayFromRows(rows, rowsOrder);
    }

    processOkResponse = (data: IReportData) => {
        this.setState({
            data,
            noDataText: null
        });
        this.init(data);
        this.props.onAfterTableLoad?.();
        this.props.rowAction?.onTableReloaded?.();
    };

    processErrorResponse = (data: IReportErrorData) => {
        this.setState({
            ...getDefaultTableData(),
            noDataText: data.Message,
            loaded: true
        }, () => {
            this.props.onAfterTableLoad?.();
        });
    };

    getFirstColumnGroup = () => {
        const state = this.getTableState();

        return this.props.reportHierarchy?.Groups?.length > 0
            ? this.props.reportHierarchy.Groups.map(column => getColumnFullAlias(column))
            : [...(state.data.Columns[0] ? [state.data.Columns[0].ColumnAlias] : [])];
    };

    getFirstColumnId = () => {
        return this.getFirstColumnGroup()[0];
    };

    getFirstColumnLabel = () => {
        let groupedLabel;

        for (const level of this.getFirstColumnGroup()) {
            const column = this.getTableState().data.Columns.find((col) => {
                return col.ColumnAlias === level;
            });

            if (!column) {
                continue;
            }

            const label = getColumnLabel(column);

            groupedLabel = groupedLabel ? `${groupedLabel} > ${label}` : label;
        }

        return groupedLabel;
    };

    init = (data: IReportData) => {
        // allGroupStatus is used in this.prepareData to set "open" value of rows
        // => set to unknown so that the last state is not carried over for all the rows
        this.setState({
            allGroupStatus: GroupStatus.Unknown
        }, () => {
            const { columns, rows, rowsOrder, rowsArray, rowCount } = this.prepareData(data);
            const allGroupStatus = this.getGroupToggleState(rowsArray, {
                ignoreCount: true,
                preventSaving: true
            });

            this.setState({
                columns,
                rows,
                rowsOrder,
                rowCount,
                allGroupStatus,
                loaded: true
            });
        });
    };

    prepareData = (data: IReportData): IPreparedData => {
        data.Columns = data.Columns ?? [];
        data.Rows = data.Rows ?? [];
        data.ColumnGroups = data.ColumnGroups ?? [];

        const allColumns: ISmartReportColumn[] = [];
        // first column can be grouped from multiple different columns
        // we need the column to have one unique id and all its values has to be stored and that same id
        const firstColumnLabel = this.getFirstColumnLabel();
        const firstColumnId = this.getFirstColumnId();
        const firstColumnMetadata = data.Columns.find(col => col.ColumnAlias === firstColumnId);
        const columnsWithoutFirstGroup = data.Columns.slice(this.getFirstColumnGroup().length);

        for (let i = 0; i < columnsWithoutFirstGroup.length; i++) {
            const colMetadata = columnsWithoutFirstGroup[i];
            // only append index to column name if its present multiple times
            let index = null;

            if (colMetadata.ColumnAlias === firstColumnId) {
                index = 1;
            }

            for (const col of allColumns) {
                if (getCleanColumnId(col.id) === colMetadata.ColumnAlias) {
                    index += 1;
                }
            }

            const newCol = this.convertColumn(colMetadata, index);

            allColumns.push(newCol);
        }

        const rowCount = data.Rows.length;
        const rowsData = this.props.rowsDataFactory ? this.props.rowsDataFactory(data.Rows, {
            storage: this.props.storage,
            columns: allColumns
        }) : data.Rows;

        const { rows, rowsOrder, rowsArray } = this.prepareRows({
            rows: rowsData,
            columns: allColumns
        });

        if (firstColumnId) {
            // rows are preared without the first column
            // but we need it in allColumns for the Table
            allColumns.unshift({
                id: firstColumnId,
                type: firstColumnMetadata?.Type,
                label: firstColumnLabel
            });
        }

        const columnGroups = [...data.ColumnGroups];
        const metaColumns: TColumn[] = [];
        // columns from first column group are merged into one => there can be more columns in data.Columns than in allColumns
        const allColumnsLengthDiff = data.Columns.length - allColumns.length;
        let counterMetaCols = 0;
        let counterCols = 0;
        let i = 0;

        while (i < allColumns.length) {
            const col = allColumns[i];
            const colDef = data.Columns[i === 0 ? 0 : i + allColumnsLengthDiff];
            const metaColumnIndex = columnGroups.findIndex((metaCol) => {
                return metaCol.ColumnsInGroup.includes(getCleanColumnId(col.id));
            });
            const metaColumn: IReportColumnGroupDef = columnGroups[metaColumnIndex];

            if (metaColumn) {
                const innerColumnsCount = metaColumn.ColumnsInGroup.length;
                const innerColumns = [];
                const lastInnerColumnIndex = i + innerColumnsCount;

                for (let j = i; j < lastInnerColumnIndex; j++) {
                    const innerColumn = allColumns[j];

                    innerColumns.push(this.getFormattedColumn({
                            index: counterCols,
                            isInMetaColumn: !!metaColumn,
                            metaColumnIndex: !!metaColumn ? counterMetaCols : null,
                            columnDef: colDef,
                            column: innerColumn,
                            allColumns,
                            settings: this.props.settings
                        }
                    ));
                    counterCols += 1;
                }

                const metaColumnDef = {
                    label: metaColumn?.Label || col.label,
                    columns: innerColumns,
                    ...this.props.metaColumnFormatter?.({
                        index: counterMetaCols,
                        metaColumn: metaColumn,
                        columnIndex: counterCols - 1,
                        column: innerColumns[innerColumns.length - 1],
                        allColumns,
                        settings: this.props.settings
                    })
                };

                counterMetaCols += 1;
                metaColumns.push(metaColumnDef);
                i += innerColumnsCount;
            } else {
                metaColumns.push(
                    this.getFormattedColumn({
                        index: counterCols,
                        isInMetaColumn: !!metaColumn,
                        metaColumnIndex: !!metaColumn ? counterMetaCols : null,
                        columnDef: colDef,
                        column: col,
                        allColumns,
                        settings: this.props.settings
                    })
                );
                i += 1;
                counterCols += 1;
            }
        }

        return {
            columns: metaColumns as IFieldInfo[],
            rows,
            rowsOrder,
            rowsArray,
            rowCount
        };
    };

    getColumnOverride = memoize((columnId: string): IFieldInfoProperties => {
        return getColumnOverride(this.props.columnOverrides, columnId);
    });

    getFormattedColumn = (args: IReportColumnFormatterArgs): ISmartReportColumn => {
        const column = this.props.columnFormatter
            ? this.props.columnFormatter({
                index: args.index,
                isInMetaColumn: args.isInMetaColumn,
                metaColumnIndex: args.metaColumnIndex,
                columnDef: args.columnDef,
                column: args.column,
                allColumns: args.allColumns,
                settings: this.props.settings
            })
            : args.column;

        const columnOverride = this.getColumnOverride(args.column.id);

        if (columnOverride) {
            if (columnOverride.textAlign) {
                column.textAlign = columnOverride.textAlign;
            }
            if (columnOverride.tooltip) {
                column.info = columnOverride.tooltip as string;
            }
            if (columnOverride.fieldSettings?.disableSort) {
                column.disableSort = columnOverride.fieldSettings?.disableSort;
            }
            if (columnOverride.width) {
                column.width = parseInt(columnOverride.width as string);
            }
        }

        return column;
    };

    rowShouldBeOpen = (row: ISmartReportTableRow) => {
        return row.type === RowType.Group && (this.state.allGroupStatus === GroupStatus.Expanded || LocalSettings.get(this.props.tableId).openedRowsIds?.[row.id.toString()]);
    };

    formatValue = (value: any, column: ISmartReportColumn, values: IEntity, addTextForEmptyValues?: boolean) => {
        const { storage } = this.props;
        const valType = column.type as ReportColumnType;
        let formattedValue: TCellValue = value;
        const cleanColumnId = getCleanColumnId(column.id);
        const aggFunc = findColumnInHierarchy(this.props.reportHierarchy, cleanColumnId)?.AggregationFunction;
        const columnOverride = this.getColumnOverride(cleanColumnId);
        const shouldDimValue = !addTextForEmptyValues && this.props.dimValues?.includes(value ?? 0);

        if (columnOverride && columnOverride.formatter) {
            formattedValue = columnOverride.formatter(value, {
                entity: values,
                storage,
                context: this.context
            }) as TCellValue;
        } else if (valType === ReportColumnType.Label) {
            const origColumn = this.getOriginalColumn(getCleanColumnId(column.id));
            const childrenPropName = `${origColumn.ColumnAlias}${labelChildrenSuffix}`;
            const filteringPropName = origColumn.ColumnAlias;

            if (values[childrenPropName]) {
                formattedValue = labelParentFormatter(value);
                // most reports return "LabelWithPath_Name_X: null" when the label value is empty,
                // which works with hasOwnProperty check,
                // unfortunately, IncomeStatement doesn't return anything,
                // which is why I commented out the check.
                // HOPEFULLY it is no longer needed,
                // uncomment if causes problems => would need BE to return the null values in IncomeStatement
            } else if (/*values.hasOwnProperty(filteringPropName) && */!values[filteringPropName]) {
                if (addTextForEmptyValues) {
                    formattedValue = `(${this.props.t("Reporting:Common.NoLabel")} ${column.label})`;
                }
            } else {
                formattedValue = tableTokenFormatter(value, origColumn.AdditionalInfo?.LabelColor);
            }

        } else if (!value && addTextForEmptyValues) {
            formattedValue = `(${this.props.t("Reporting:Common.NoValue")})`;
        } else if (valType === ReportColumnType.Boolean) {
            formattedValue = isDefined(value) ? BooleanType.format(value) : "";
        } else if ([ReportColumnType.Date, ReportColumnType.DateTimeOffset].includes(valType)) {
            // todo: common handling of default/empty values
            const timeAggFn: TimeAggregationFunction = aggFunc as TimeAggregationFunction
                || getTimeAggFuncItem(column.id.split("_").slice(-1)[0] as TimeAggregationFunction)?.id
                || TimeAggregationFunction.Day;

            formattedValue = value && formatDateByTimeAggFn(getUtcDate(value), timeAggFn);
        } else if ([ReportColumnType.Currency, ReportColumnType.Delta].includes(valType) && aggFunc !== NumberAggregationFunction.Count) {
            formattedValue = CurrencyType.format(value, {
                currency: values[column.currency] || "CZK", // todo currency code is missing in totals and groups
                scale: this.props.currencyUnit
            });
        } else if (NumberAggFuncTypes.includes(valType)) {
            formattedValue = NumberType.format(value);
        }

        if (valType === ReportColumnType.Delta) {
            const status = ifPositive<Status>(value, Status.Success, Status.Error);
            const Icon = ifPositive(value, GrowthPositiveIcon, GrowthNegativeIcon, GrowthZeroIcon);
            if (storage.settings[CommonReportProps.showDelta] === IncomeStatementDelta.InPercent) {
                formattedValue = formatPercent(value);
            }

            formattedValue = {
                tooltip: (formattedValue as ICellValueObject)?.tooltip ?? formattedValue, value: (
                    <CellMicroChart>
                        <Icon width={IconSize.XS} height={IconSize.XS}
                              status={status}/>
                        <CellMicroChartText status={status}>
                            {(formattedValue as ICellValueObject)?.value ?? formattedValue}
                        </CellMicroChartText>
                    </CellMicroChart>
                )
            };
        } else if (shouldDimValue) {
            formattedValue = {
                tooltip: (formattedValue as ICellValueObject)?.tooltip ?? formattedValue,
                value: (
                    <DimmedValue>
                        {(formattedValue as ICellValueObject)?.value ?? formattedValue}
                    </DimmedValue>
                )
            };
        }

        return formattedValue;
    };

    prepareRows = ({ rows, columns, path = [], uniqueId = "", lastParentGroupFirstColumnValue, parent, parentRow }
                       : {
        rows: IReportRowDef[],
        columns: ISmartReportColumn[],
        path?: number[],
        uniqueId?: string,
        lastParentGroupFirstColumnValue?: string,
        parent?: IReportRowDef,
        parentRow?: ISmartReportTableRow
    }) => {
        let newRows: Record<string, ISmartReportTableRow> = {};
        const newRowsOrder: string[] = [];
        const newRowsArray: ISmartReportTableRow[] = [];
        const firstColumnGroup = this.getFirstColumnGroup();
        const level = path.length;
        // Columns argument is always without the first column. First column is retrieved here based on current level.
        // take first if column doesn't exist because
        // GeneralLedger is kinda fake and have hierarchical first column even though in reportHierarchy.Groups settings, there is only one column
        let currentLevelFirstColumnId = firstColumnGroup[level] ?? firstColumnGroup[0];
        const firstColumnId = firstColumnGroup[0];
        let lastGroupFirstColumnValue: string;
        const isHierarchical = this.isHierarchical;

        for (let index = 0; index < rows.length; index++) {
            const row = rows[index];
            const newPath = [...path, index];
            let newUniqueId = uniqueId;
            const type = this.getRowType(row.Type);
            let newRow: ISmartReportTableRow = {
                id: getReportRowId(newPath),
                type: type,
                drilldown: this.props.showDrilldown && type === RowType.Value,
                values: {},
                customData: {
                    originalValues: row.Value,
                    originalRow: row,
                    originalValuesFormatted: {},
                    parent: parentRow
                }
            };
            const metaValueKeys: string[] = this.getTableState().data.MetaValueKeys ?? [];
            const valuesCount = Object.keys(row.Value).filter(key => !metaValueKeys.includes(key)).length;

            // add first column value
            if (this.shouldFirstColumnShowValue(newRow)) {
                // usually, the structure and nesting of the rows corresponds to the levels of firstColumnGroup (Groups parameter)
                // but LABELS can have deeper structure and each of the label columns can be repeated more than once
                // EITHER there will be just one value => we can use it as first label
                // OR in special case with MergedGroup, we use last reportHierarchy.Group as first label
                const shouldUseLastGroupColumn = () => isHierarchical && row.Type === ReportTableRowType.MergedGroup && !row.Rows?.length;
                if (row.Value.GroupColumnAlias) {
                    // cool value add by BE so that we don't have to use all this weird ifs
                    currentLevelFirstColumnId = row.Value.GroupColumnAlias as string;
                } else if (shouldUseLastGroupColumn()) {
                    const groupColumn = this.props.reportHierarchy.Groups[this.props.reportHierarchy.Groups.length - 1];
                    currentLevelFirstColumnId = getColumnFullAlias(groupColumn);
                } else if (valuesCount === 1) {
                    currentLevelFirstColumnId = Object.keys(row.Value)[0];
                } else if (!row.Value[currentLevelFirstColumnId] && isHierarchical) {
                    currentLevelFirstColumnId = Object.values(this.props.reportHierarchy.Groups).find(col => row.Value.hasOwnProperty(col.ColumnAlias))?.ColumnAlias;
                }

                const column = this.getConvertedColumn(currentLevelFirstColumnId);

                if (column) {
                    const value = row.Value[currentLevelFirstColumnId];
                    const formattedValue = this.formatValue(value, column, row.Value, type !== RowType.Value) as TCellValue;

                    newRow.values[firstColumnId] = formattedValue;

                    if (row.Type === ReportTableRowType.Group || row.Type === ReportTableRowType.GroupValue) {
                        lastGroupFirstColumnValue = (formattedValue as ICellValueObject)?.tooltip as string ?? formattedValue as string;
                    }

                    if (isHierarchical) {
                        // for hierarchical reports (Aggregate true, Groups.length > 0)
                        // try to build unique row id, that can be used to keep closed/expanded groups
                        // when filter/sort is changed
                        // this breaks on Groups config change
                        newUniqueId += `${value}|${type}-`;
                        newRow.id = newUniqueId;
                    }
                }
            }

            propagateParentValues(firstColumnGroup, row, parent);

            // add rest of the columns values
            for (const column of columns) {
                const cleanColumnId = getCleanColumnId(column.id);
                const formattedValue: TCellValue = this.formatValue(row.Value[cleanColumnId], column, row.Value);

                // used to build value helper with  formatted values
                // otherwise it would be hard to determine the correct values,
                // because multiple columns are aggregated into one column when hierarchy is used
                newRow.customData.originalValuesFormatted[column.id] = (formattedValue as ICellValueObject)?.tooltip ?? formattedValue;

                if (firstColumnGroup.includes(cleanColumnId) &&
                    (row.Type === ReportTableRowType.Group || row.Type === ReportTableRowType.GroupValue
                        || row.Type === ReportTableRowType.Total || row.Type === ReportTableRowType.GrandTotal)) {
                    continue;
                }

                newRow.values[column.id] = formattedValue;
            }

            for (const columnId of firstColumnGroup) {
                const column = this.getConvertedColumn(columnId);
                const formattedValue = this.formatValue(row.Value[columnId], column, row.Value) as TCellValue;

                newRow.customData.originalValuesFormatted[columnId] = (formattedValue as ICellValueObject)?.tooltip ?? formattedValue;
            }

            const totalLabel = `${this.props.t("Reporting:Common.Total")}:`;
            const isLastInGroup = !rows[index + 1];

            if (row.Type === ReportTableRowType.GrandTotal) {
                // add Celkem label to the first column of Total row
                newRow.isBold = true;
                newRow.values[firstColumnId] = isLastInGroup && !lastParentGroupFirstColumnValue ? totalLabel
                    : `${this.props.t("Reporting:Common.Total")} ${lastParentGroupFirstColumnValue} ${this.props.t("Reporting:Common.All")}:`;
            }

            if (row.Type === ReportTableRowType.Total) {
                newRow.values[firstColumnId] = lastParentGroupFirstColumnValue
                    ? `${this.props.t("Reporting:Common.Total")} ${lastParentGroupFirstColumnValue}${isLastInGroup ? "" : ` ${this.props.t("Reporting:Common.Separately")}`}:`
                    : totalLabel;
            }

            if (row.Rows && row.Rows.length > 0) {
                let totalRow = row.Rows.find(r => r.Type === ReportTableRowType.GrandTotal);

                if (!totalRow) {
                    totalRow = row.Rows.find(r => r.Type === ReportTableRowType.Total);
                }

                if (newRow.type === RowType.Group && this.rowShouldBeOpen(newRow)) {
                    newRow.open = true;
                }

                if (totalRow) {
                    // prepareRow has not yet been called for the totalRow
                    // => meaning values from propagateParentValues are not in its values yet
                    // ==> extend the values here as well
                    const extendedTotalRow = {
                        ...totalRow,
                        Value: {
                            ...totalRow.Value
                        }
                    };

                    propagateParentValues(firstColumnGroup, extendedTotalRow, row);

                    newRow.closedValues = { [firstColumnId]: newRow.values[firstColumnGroup[0]] };
                    newRow.openedValues = newRow.values;

                    for (const column of columns) {
                        const cleanColumnId = getCleanColumnId(column.id);

                        if (firstColumnGroup.includes(cleanColumnId)) {
                            continue;
                        }

                        newRow.closedValues[column.id] = isDefined(extendedTotalRow.Value[cleanColumnId]) ? this.formatValue(extendedTotalRow.Value[cleanColumnId], column, extendedTotalRow.Value) : newRow.values[column.id];
                    }

                    if (!newRow.open) {
                        newRow.values = newRow.closedValues;
                    }
                }

                newRow.rowCount = row.Rows.length || 0;

                const childRows = this.prepareRows({
                    rows: row.Rows,
                    columns,
                    path: newPath,
                    uniqueId: newUniqueId,
                    lastParentGroupFirstColumnValue: lastGroupFirstColumnValue,
                    parent: row,
                    parentRow: newRow
                });

                newRow.rows = childRows.rowsOrder.map(rowId => childRows.rows[rowId]);
                newRows = {
                    ...newRows,
                    ...childRows.rows
                };
            }

            if (this.props.rowFormatter) {
                newRow = {
                    ...newRow,
                    ...this.props.rowFormatter(row, {
                        row: newRow,
                        storage: this.props.storage,
                        columns: columns
                    })
                };
            }

            const id = newRow.id.toString();

            newRows[id] = newRow;
            newRowsArray.push(newRow);
            newRowsOrder.push(id);
        }

        return {
            rows: newRows,
            rowsOrder: newRowsOrder,
            rowsArray: newRowsArray
        };
    };

    shouldFirstColumnShowValue = (row: ISmartReportTableRow) => {
        return (this.props.reportHierarchy && (!this.props.reportHierarchy.Aggregate || !this.props.reportHierarchy.Groups.length))
            || row.type !== RowType.Value;
    };

    getConvertedColumn = (columnId: string) => {
        const originalColumn = this.getOriginalColumn(columnId);

        return this.convertColumn(originalColumn);
    };

    getOriginalColumn = (columnId: string) => {
        return this.getTableState().data.Columns.find(col => col.ColumnAlias === columnId);
    };

    // backends can return same column (with same ColumnAlias) multiple times
    // Table expects all columns to have unique id - we need to append index
    convertColumn = (column: IReportColumnDef, index?: number): ISmartReportColumn => {
        let reportColumn;

        if (column) {
            const textAlign = this.getColumnTextAlign(column);

            reportColumn = {
                id: addIndexToDuplicateColumnId(column.ColumnAlias, index),
                label: getColumnLabel(column),
                type: column.Type,
                currency: getCurrencyColumn(column),
                isHighlighted: column.Type === ReportColumnType.Delta,
                stretchContent: column.Type === ReportColumnType.Delta,
                textAlign
            };
        }

        return reportColumn;
    };

    getColumnTextAlign = (column: IReportColumnDef) => {
        const leftAlignTypes: ReportColumnType[] = [ReportColumnType.String, ReportColumnType.Label, ReportColumnType.Date];
        return leftAlignTypes.indexOf(column.Type) >= 0 ? TextAlign.Left : TextAlign.Right;
    };

    getSort = () => {
        return this.getTableState().sort as ISort[]
            || this.props.initialSortBy
                ?.map(sort => {
                    // initialSortBy is defined without the unique column index ids
                    // because we want to show the same sort on the duplicated (same) columns
                    return {
                        ...sort,
                        id: this.state.columns.find(col => getCleanColumnId(col.id)?.toLowerCase() === sort.id.toString().toLowerCase())?.id ?? sort.id
                    };
                })
            || [];
    };

    getRowType = (type: ReportTableRowType): RowType => {
        switch (type) {
            case ReportTableRowType.Group:
            case ReportTableRowType.GroupValue:
                return RowType.Group;
            case ReportTableRowType.Value:
                return RowType.Value;
            case ReportTableRowType.Total:
            case ReportTableRowType.GrandTotal:
                return RowType.Aggregation;
            case ReportTableRowType.MergedGroup:
                return RowType.Merged;
            default:
                return RowType.Value;
        }
    };

    getRowById = (rowId: string): IReportRowDef => {
        let rows = this.getTableState().data.Rows;
        let row: IReportRowDef;

        if (this.isHierarchical) {
            // todo how to get row for the new way of id building
        } else {
            const path = rowId.split(":");

            for (const index of path) {
                row = rows[parseInt(index)];
                rows = row.Rows;
            }
        }

        return row;
    };

    handleRowSelect = (id: TId, props: IRowProps) => {
        const row = this.getRowById(id.toString());

        // not used at the moment
        // this.props.onRowSelect?.(id, row);
    };

    handleSortChange = (sort: ISort[]) => {
        // apply same sort on all other columns with same id
        const sortWithDuplicates: ISort[] = [...sort];

        for (const s of sort) {
            for (const column of this.state.columns) {
                if (column.id !== s.id && getCleanColumnId(s.id.toString()) === getCleanColumnId(column.id)) {
                    sortWithDuplicates.push({
                        ...s,
                        id: column.id
                    });
                }
            }
        }

        this.setState({
            sort: sortWithDuplicates
        }, () => {
            this.props.onSortChange?.(sort);
            this.loadData();
        });
    };

    getRowValues = (row: ISmartReportTableRow, isOpen: boolean): IRowValues => {
        if (row.openedValues && row.closedValues) {
            return isOpen ? row.openedValues : row.closedValues;
        }

        return row.values;
    };

    saveGroupRowsState = (rows: IRow[]): void => {
        if (this.isHierarchical && this.state.loaded && rows?.length) {
            saveGroupRowsState(rows, this.props.tableId, this.props.storage);
        }
    };

    getGroupToggleState = (rows: IRow[], { ignoreCount = false, preventSaving = false } = {}): GroupStatus => {
        const rowCount = this.getTableState().rowCount;
        const status = getGroupToggleState(rows, { ignoreCount, rowCount });

        if (!preventSaving) {
            this.saveGroupRowsState(rows);
        }

        return status;
    };

    handleGroupToggle = ({ id: toggledGroupId }: IGroupToggleEvent) => {
        const newRows = updateRow(this.state.rows, toggledGroupId, (group: ISmartReportTableRow) => {
            const open = !group.open;
            const values = this.getRowValues(group, open);

            return {
                ...group,
                open,
                values
            };
        });

        const updatedRowsArray = this.getRowsArray(newRows);
        const allGroupStatus = this.getGroupToggleState(updatedRowsArray);

        this.setState({
            rows: newRows,
            allGroupStatus
        }, () => {
            this.props.onGroupToggle?.(updatedRowsArray);
        });
    };

    toggleAllGroups = (status: GroupStatus) => {
        if (status === GroupStatus.Unknown) {
            return;
        }

        let updatedRows: Record<string, IRow>;

        this.setState((state) => {
            updatedRows = updateRows({
                rows: state.rows,
                rowsOrder: state.rowsOrder,
                updateFn: (row: ISmartReportTableRow) => {
                    const isOpen = row.rows?.length > 0 && status === GroupStatus.Expanded;

                    return {
                        ...row,
                        open: isOpen,
                        values: this.getRowValues(row, isOpen)
                    };
                }
            });

            this.saveGroupRowsState(this.getRowsArray(updatedRows));

            return {
                rows: updatedRows,
                allGroupStatus: status
            };
        }, () => {
            this.props.onGroupToggle?.(this.getRowsArray(updatedRows));
        });
    };

    isRowWithoutAction = (rowId: TId, action: RowAction, row?: IRow) => {
        // todo: identify all default conditions for report table
        return false;
    };

    getRowActionState = (rowId: TId, rowComponentProps: IRowProps): ActionState => {
        const row = getRow(this.getTableState().rows, rowId);

        if (rowId === NEW_ROW_ID || this.isRowWithoutAction(rowId, this.props.rowAction?.actionType, row)) {
            return ActionState.None;
        }

        if (this.props.rowAction.getActionState) {
            return this.props.rowAction.getActionState(rowId, rowComponentProps) ?? ActionState.None;
        }

        return this.state.activeRows.has(rowId.toString()) ? ActionState.Active : ActionState.Inactive;
    };

    handleRowActionClick = (rowId: TId, rowComponentProps: IRowProps, shouldOpen?: boolean): void => {
        const updateValue = !this.state.activeRows.has(rowId.toString());
        let updatedRow: IRow = null;
        const activeRows = new Set(this.state.activeRows);

        const updatefn = (row: IRow, value: boolean) => {
            const newRow: IRow = {
                ...row,
                open: shouldOpen && row.rows ? true : row.open
            };

            if (!this.isRowWithoutAction(row.id, this.props.rowAction.actionType)) {
                activeRows[value ? "add" : "delete"](row.id.toString());
            }

            return newRow;
        };

        let childrenRows: IRow[];
        let updatedRows = updateRow(this.getTableState().rows, rowId, (row) => {
            const newRow = updatefn(row, updateValue);

            updatedRow = newRow;

            // store to propagate downwards
            if (newRow.rows) {
                childrenRows = newRow.rows;
            }

            return newRow;
        });

        if (childrenRows?.length) {
            // propagate downwards
            updatedRows = updateRows({
                rows: updatedRows,
                rowsOrder: childrenRows.map(r => r.id.toString()),
                updateFn: (row) => {
                    return updatefn(row, updateValue);
                }
            });
        }

        // propagate upwards
        // for lock and also delete action, when you unselect row, also its parents must be unselected, otherwise it would be still removed/locked
        if (!updateValue) {
            let parent = updatedRow.customData?.parent;

            while (parent) {
                updatedRows = updateRow(updatedRows, parent.id, (row) => {
                    parent = row.customData?.parent;

                    return updatefn(row, false);
                });
            }
        }

        this.setState({
            rows: updatedRows,
            toggleState: this.getToggleState(this.getRowsArray(updatedRows), activeRows),
            activeRows
        }, () => {
            this.props.onActiveRowActionCountChange?.(activeRows.size);
        });
    };

    getToggleState = (rows: IRow[], activeRows: Set<string>): ToggleState => {
        return getToggleState(rows, (row: IRow) => activeRows.has(row.id.toString()));
    };

    getRowAction = (): IRowAction => {
        if (!this.rowActionType) {
            return null;
        }

        const { isSingleSelect, showIconsOnHover, onClick, render, toggleState } = this.props.rowAction ?? {};

        return {
            isSingleSelect,
            showIconsOnHover: showIconsOnHover ?? (isSingleSelect && this.state.activeRows.size > 0),
            actionType: this.rowActionType,
            getActionState: this.getRowActionState,
            onClick: (onClick as (rowId: TId) => void) ?? this.handleRowActionClick,
            toggleState: toggleState ?? this.state.toggleState,
            onToggleChange: this.handleToggleChange,
            render
        };
    };

    getAffectedRows = async (): Promise<IAffectedReportRow[]> => {
        const affectedRows: IAffectedReportRow[] = [];
        const { rows, activeRows } = this.state;

        iterateOverRows(this.getRowsArray(rows), (row: IRow) => {
            if (activeRows.has(row.id.toString())) {
                affectedRows.push({
                    id: row.id,
                    originalValues: row.customData.originalValues
                });
            }
            return row;
        });

        return affectedRows;
    };

    resetTableSelection = (): void => {
        this.setState({
            toggleState: ToggleState.AllUnchecked,
            activeRows: new Set<string>()
        });
    };

    toggleAll = (rowAction: RowAction, checked: boolean): Promise<void> => {
        return new Promise((resolve) => {
            const activeRows = new Set<string>();

            const updatedRows = updateRows({
                rows: this.state.rows,
                rowsOrder: this.state.rowsOrder,
                updateFn: (row) => {
                    if (checked) {
                        if (!this.isRowWithoutAction(row.id, rowAction)) {
                            activeRows.add(row.id.toString());
                        } else if (!this.isRowWithoutAction(row.customData?.parent?.id, rowAction)) {
                            activeRows.add(row.customData.parent.id.toString());
                        }
                    }
                    return row;
                }
            });

            this.setState({
                rows: updatedRows,
                // this.state.rowCount stores just highest level of rows, but we have to use it for smartTable, because
                // we load just first 100 rows, on the other hand for hierarchy we have to go through all rows and levels
                // but in hierarchy table we do load all rows at once, so it's working just fine
                activeRows,
                toggleState: checked ? ToggleState.AllChecked : ToggleState.AllUnchecked
            }, resolve);
        });
    };

    handleToggleChange = async (toggleState: ToggleState): Promise<void> => {
        if (this.props.rowAction?.onToggleChange) {
            this.props.rowAction.onToggleChange(toggleState);
        } else {
            await this.toggleAll(this.props.rowAction?.actionType, toggleState === ToggleState.AllChecked);
            this.props.onActiveRowActionCountChange?.(this.state.activeRows.size);
        }
    };

    render = () => {
        const state = this.getTableState();

        const rowAction = this.getRowAction();

        return (
            <SmartTableBase {...this.props as Omit<IProps, "ref">}
                            rows={this.getRowsArray(state.rows)}
                            rowCount={state.rowCount}
                            tableId={this.props.tableId}
                            columns={state.columns}
                            rowAction={rowAction}
                            disableSort={!!rowAction}
                            loaded={state.loaded}
                            sort={this.getSort()}
                            noDataText={this.state.noDataText}
                            onRowSelect={this.handleRowSelect}
                            onSortChange={this.handleSortChange}
                            hierarchy={"report"}
                            onGroupToggle={this.handleGroupToggle}/>
        );
    };
}

export default withTranslation(["Reporting", "Common"], { withRef: true })(SmartReportTable);
