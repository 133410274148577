const TestIds = Object.freeze({
    Accordion: "Accordion",
    AccordionPanel: "AccordionPanel",
    AccordionPanelContent: "AccordionPanelContent",
    AccordionPanelIconsWrapper: "AccordionPanelIconsWrapper",
    AccountingBalance: "AccountingBalance",
    ActionIconWrapper: "ActionIconWrapper",
    ActionItemWrapper: "ActionItemWrapper",
    AfterTokensText: "AfterTokensText",
    AgendaSpan: "AgendaSpan",
    Alert: "Alert",
    AlertAction: "AlertAction",
    AlertContent: "AlertContent",
    AlertSubtitle: "AlertSubtitle",
    AlertTitle: "AlertTitle",
    AmountOverview: "AmountOverview",
    AppliedSubTitle: "AppliedSubTitle",
    Attachment: "Attachment",
    AttachmentName: "AttachmentName",
    AuditTrail: "AuditTrail",
    AuditTrailLayer: "AuditTrailLayer",
    AuditTrailLineOrder: "AuditTrailLineOrder",
    AutoCompleteGroup: "AutoCompleteGroup",
    AutoCompleteHeader: "AutoCompleteHeader",
    Badge: "Badge",
    BankAccountFilterTile: "BankAccountFilterTile",
    BlockReadingMessages: "BlockReadingMessages",
    BoldText: "BoldText",
    BottomTextLabel: "BottomTextLabel",
    BottomTextLink: "BottomTextLink",
    Breadcrumb: "Breadcrumb",
    BreadcrumbItem: "BreadcrumbItem",
    BusyIndicator: "BusyIndicator",
    BusyWrapper: "BusyWrapper",
    Button: "Button",
    ButtonGroup: "ButtonGroup",
    CalculatedCoefficient: "CalculatedCoefficient",
    CalculatedTax: "CalculatedTax",
    CashboxFilterTile: "CashboxFilterTile",
    Chat: "Chat",
    CheckList: "CheckList",
    CheckListItem: "CheckListItem",
    Checkbox: "Checkbox",
    CheckboxContainer: "CheckboxContainer",
    CheckboxGroup: "CheckboxGroup",
    CheckboxGroupWrapper: "CheckboxGroupWrapper",
    CheckboxGroupColumn: "CheckboxGroupColumn",
    CheckboxLabel: "CheckboxLabel",
    CheckboxWrapper: "CheckboxWrapper",
    ClickableText: "ClickableText",
    CollapsibleContent: "CollapsibleContent",
    CollapsibleSection: "CollapsibleSection",
    CollapsibleSectionBody: "CollapsibleSectionBody",
    Color: "Color",
    ColorPicker: "ColorPicker",
    ColorPickerMenu: "ColorPickerMenu",
    ColumnSortIconWrapper: "ColumnSortIconWrapper",
    ContentAfterRow: "ContentAfterRow",
    CompanyAvatar: "CompanyAvatar",
    CompanyDashboard: "CompanyDashboard",
    CompanyOpener: "CompanyOpener",
    CompanySelector: "CompanySelector",
    CompanyTile: "CompanyTile",
    CompanyTileBody: "CompanyTileBody",
    CompanyTileContent: "CompanyTileContent",
    CompanyTileFooter: "CompanyTileFooter",
    CompanyTileHeader: "CompanyTileHeader",
    ComparisonBorder: "ComparisonBorder",
    ConditionalFilterDialog: "ConditionalFilterDialog",
    ConfigurationColumn: "ConfigurationColumn",
    ConfigurationGroup: "ConfigurationGroup",
    ConfigurationItem: "ConfigurationItem",
    ConfigurationItemAggregationSelector: "ConfigurationItemAggregationSelector",
    ConfigurationItemValue: "ConfigurationItemValue",
    ConfigurationList: "ConfigurationList",
    ConfigurationListAddAggregationButton: "ConfigurationListAddAggregationButton",
    ConfirmationHeader: "ConfirmationHeader",
    Content: "Content",
    ContentColumn: "ContentColumn",
    ContentEditableMessage: "ContentEditableMessage",
    ContextMenu: "ContextMenu",
    CustomerCompanySelector: "CustomerCompanySelector",
    Dashboard: "Dashboard",
    DashboardItem: "DashboardItem",
    DashboardTile: "DashboardTile",
    DashboardTileHeading: "DashboardTileHeading",
    DashedResizeLine: "DashedResizeLine",
    DataBoxInfo: "DataBoxInfo",
    DataBoxPermissionRow: "DataBoxPermissionRow",
    DatePickerBase: "DatePickerBase",
    DatePickerCalendar: "DatePickerCalendar",
    DatePickerMonthLabel: "DatePickerMonthLabel",
    DatePickerNextMonth: "DatePickerNextMonth",
    DatePickerPopup: "DatePickerPopup",
    DatePickerPopupBackButton: "DatePickerPopupBackButton",
    DatePickerPrevMonth: "DatePickerPrevMonth",
    DatePickerWeeks: "DatePickerWeeks",
    DatePickerYearLabel: "DatePickerYearLabel",
    DateRangePicker: "DateRangePicker",
    DayCurrent: "DayCurrent",
    DayCurrentMonth: "DayCurrentMonth",
    DayCurrentSelected: "DayCurrentSelected",
    DayOutsideMonth: "DayOutsideMonth",
    DaySelected: "DaySelected",
    Description: "Description",
    DeviceBody: "DeviceBody",
    Dialog: "Dialog",
    DialogBody: "DialogBody",
    DialogFooter: "DialogFooter",
    DialogHeader: "DialogHeader",
    DialogTitle: "DialogTitle",
    DialogSubTitle: "DialogSubTitle",
    Document: "Document",
    DomainName: "DomainName",
    DraftTimeStamp: "DraftTimeStamp",
    DrilldownWrapper: "DrilldownWrapper",
    DropArea: "DropArea",
    DropdownIcon: "DropdownIcon",
    EditableText: "EditableText",
    EditableTextReadLabel: "EditableTextReadLabel",
    EditableWindowCloseIcon: "EditableWindowCloseIcon",
    EditVariantsGrid: "EditVariantsGrid",
    ElSubDetailIncludedDocuments: "ElSubDetailIncludedDocuments",
    ElSubDetailLinks: "ElSubDetailLinks",
    ElSubPeriodCell: "ElSubPeriodCell",
    ErrorText: "ErrorText",
    ErrorStepper: "ErrorStepper",
    ErrorWrapper: "ErrorWrapper",
    ExpandButton: "ExpandButton",
    FastEntryItem: "FastEntryItem",
    FastEntryList: "FastEntryList",
    FastEntryListHeader: "FastEntryListHeader",
    FastEntryListLastField: "FastEntryListLastField",
    FastEntryListVirtualization: "FastEntryListVirtualization",
    Field: "Field",
    FieldContent: "FieldContent",
    FieldGroup: "FieldGroup",
    FieldGroupToggle: "FieldGroupToggle",
    FieldLabel: "FieldLabel",
    FieldsWrapper: "FieldsWrapper",
    FileIconWrapper: "FileIconWrapper",
    FileStrip: "FileStrip",
    FileStripItem: "FileStripItem",
    FileText: "FileText",
    FileUploader: "FileUploader",
    FileViewer: "FileViewer",
    FilterBar: "FilterBar",
    FilterBarToggle: "FilterBarToggle",
    FilterReadOnlyGroup: "FilterReadOnlyGroup",
    ChangeValueDialog: "ChangeValueDialog",
    FloatingText: "FloatingText",
    Footer: "Footer",
    FormContent: "FormContent",
    FormFooter: "FormFooter",
    FormGroup: "FormGroup",
    FormGroupAction: "FormGroupAction",
    FormGroupActions: "FormGroupActions",
    FormGroupAuditTrailLayer: "FormGroupAuditTrailLayer",
    FormGroupBody: "FormGroupBody",
    FormGroupHeader: "FormGroupHeader",
    FormGroupLine: "FormGroupLine",
    FormView: "FormView",
    GainRow: "GainRow",
    GainRowLabel: "GainRowLabel",
    GainRowValue: "GainRowValue",
    GroupedFields: "GroupedFields",
    Header: "Header",
    HeaderInfoText: "HeaderInfoText",
    HeaderSubtitle: "HeaderSubtitle",
    HeaderTitle: "HeaderTitle",
    HiddenFileInput: "HiddenFileInput",
    HiddenItems: "HiddenItems",
    Hotspots: "Hotspots",
    Icon: "Icon",
    IconBar: "IconBar",
    IconBarItem: "IconBarItem",
    ImageWrapper: "ImageWrapper",
    ImportSettings: "ImportSettings",
    ImportSettingsColumn: "ImportSettingsColumn",
    ImportSettingsColumnHeader: "ImportSettingsColumnHeader",
    ImportSettingsDragItem: "ImportSettingsDragItem",
    ImportSettingsDropItemLabel: "ImportSettingsDropItemLabel",
    ImportSettingsDropItemDroppable: "ImportSettingsDropItemDroppable",
    ImportSettingsDropItemSelector: "ImportSettingsDropItemSelector",
    ImportSettingsGrid: "ImportSettingsGrid",
    ImportSettingsValuePreview: "ImportSettingsValuePreview",
    InboxPage: "InboxPage",
    InitialAccountBalancesGrid: "InitialAccountBalancesGrid",
    InitialAccountBalancesGridCell: "InitialAccountBalancesGridCell",
    InputWrapper: "InputWrapper",
    Input: "Input",
    InputStatus: "InputStatus",
    InventoryGrid: "InventoryGrid",
    ItemsSummaryTable: "ItemsSummaryTable",
    LabelDescription: "LabelDescription",
    LabeledWriteLine: "LabeledWriteLine",
    Line: "Line",
    ListItemRow: "ListItemRow",
    ListItemRowLabel: "ListItemRowLabel",
    ListItemRowValue: "ListItemRowValue",
    LoginPage: "LoginPage",
    LoginInputField: "LoginInputField",
    LostPass: "LostPass",
    Menu: "Menu",
    MenuItem: "MenuItem",
    Message: "Message",
    MessageContent: "MessageContent",
    MessageStreamWrapper: "MessageStreamWrapper",
    MonthsGrid: "MonthsGrid",
    MoreItems: "MoreItems",
    Name: "Name",
    NewMessage: "NewMessage",
    NoData: "NoData",
    Notification: "Notification",
    NotificationAlert: "NotificationAlert",
    NotificationDescription: "NotificationDescription",
    NotificationInfo: "NotificationInfo",
    Notifications: "Notifications",
    NotificationsCount: "NotificationsCount",
    NotificationSettings: "NotificationSettings",
    NotificationSettingsRow: "NotificationSettingsRow",
    NoPreview: "NoPreview",
    ObjectList: "ObjectList",
    ObjectListItem: "ObjectListItem",
    OrganizationTile: "OrganizationTile",
    Page: "Page",
    PageControlFirstPage: "PageControlFirstPage",
    PageControlLastPage: "PageControlLastPage",
    PageControlNextPage: "PageControlNextPage",
    PageControlPreviousPage: "PageControlPreviousPage",
    PageControls: "PageControls",
    PageCount: "PageCount",
    Paginator: "Paginator",
    PairingRow: "PairingRow",
    PairingValueTitle: "PairingValueTitle",
    PairingValue: "PairingValue",
    PaymentOrderGrid: "PaymentOrderGrid",
    PdfWrapper: "PdfWrapper",
    PencilDialog: "PencilDialog",
    PermissionComponent: "PermissionComponent",
    PermissionsGrid: "PermissionsGrid",
    PlaceholderLine: "PlaceholderLine",
    PreviewCanvas: "PreviewCanvas",
    PrimaryContent: "PrimaryContent",
    PrimarySubTitle: "PrimarySubTitle",
    ProductTypeItem: "ProductTypeItem",
    BackgroundJobs: "BackgroundJobs",
    BackgroundJobsItem: "BackgroundJobsItem",
    BackgroundJobsItemsList: "BackgroundJobsItemsList",
    BackgroundJobsStatus: "BackgroundJobsStatus",
    ProgressBar: "ProgressBar",
    ProgressBarDescription: "ProgressBarDescription",
    ProgressValue: "ProgressValue",
    RadioButton: "RadioButton",
    RadioButtonGroup: "RadioButtonGroup",
    RadioButtonLabel: "RadioButtonLabel",
    ReadOnlyList: "ReadOnlyList",
    ReadOnlyListItem: "ReadOnlyListItem",
    ReadOnlyListItemLabel: "ReadOnlyListLabel",
    ReadOnlyListItemValue: "ReadOnlyListValue",
    RequiredMark: "RequiredMark",
    ScrollBar: "ScrollBar",
    SegmentedButton: "SegmentedButton",
    Select: "Select",
    SelectInputLabel: "SelectInputLabel",
    SelectMenu: "SelectMenu",
    SelectMenuColumn: "SelectMenuColumn",
    SelectMenuGroup: "SelectMenuGroup",
    SelectMenuGroupTitle: "SelectMenuGroupTitle",
    SelectMenuHeader: "SelectMenuHeader",
    SelectMenuItem: "SelectMenuItem",
    SelectSearchInput: "SelectSearchInput",
    Separator: "Separator",
    SettingLabel: "SettingLabel",
    SettingRow: "SettingRow",
    SettingsList: "SettingsList",
    SettingsListItem: "SettingListItem",
    Shellbar: "Shellbar",
    ShellbarContent: "ShellbarContent",
    ShellbarContentGroup: "ShellbarContentGroup",
    ShellbarContentGroupItem: "ShellbarContentGroupItem",
    ShellbarContentGroupTitle: "ShellbarContentGroupTitle",
    ShellIconsGroup: "ShellIconsGroup",
    SimpleGridTable: "SimpleGridTable",
    SimpleItem: "SimpleItem",
    SimpleTable: "SimpleTable",
    Slider: "Slider",
    SliderMark: "SliderMark",
    SliderMarkLabel: "SliderMarkLabel",
    SliderThumb: "SliderThumb",
    SliderThumbLabel: "SliderThumbLabel",
    SortableList: "SortableList",
    SortableListItem: "SortableListItem",
    SpiderAnim: "SpiderAnim",
    SplitDialogTitle: "SplitDialogTitle",
    SplitDialogValue: "SplitDialogValue",
    Splitter: "Splitter",
    State: "State",
    StaticItems: "StaticItems",
    Stepper: "Stepper",
    Subtitle: "Subtitle",
    Summary: "Summary",
    SummaryItem: "SummaryItem",
    SummaryItemLabel: "SummaryItemLabel",
    SummaryItemValue: "SummaryItemValue",
    SvgImage: "SvgImage",
    Switch: "Switch",
    SwitchBody: "SwitchBody",
    SwitchCircle: "SwitchCircle",
    SwitchPageInfo: "SwitchPageInfo",
    SwitchPageLink: "SwitchPageLink",
    Tab: "Tab",
    TabContent: "TabContent",
    TabCount: "TabCount",
    Table: "Table",
    TableAggregationRow: "TableAggregationRow",
    TableCell: "TableCell",
    TableCellContent: "TableCellContent",
    TableCellAfterContent: "TableCellAfterContent",
    TableCellHoverContent: "TableCellHoverContent",
    TableGroupRow: "TableGroupRow",
    TableHeader: "TableHeader",
    TableHeaderCell: "TableHeaderCell",
    TableHeaderCellContent: "TableHeaderCellContent",
    TableHeaderMetaColumn: "TableHeaderMetaColumn",
    TableHeaderMetaColumnContent: "TableHeaderMetaColumnContent",
    TableHeaderMetaColumnLabel: "TableHeaderMetaColumnLabel",
    TableMasterAction: "TableMasterAction",
    TableMergedRow: "TableMergedRow",
    TableRow: "TableRow",
    TableRowAction: "TableRowAction",
    TableSortingItem: "TableSortingItem",
    Tabs: "Tabs",
    TabsMenu: "TabsMenu",
    Tasks: "Tasks",
    TaskRow: "TaskRow",
    TaskRowCaret: "TaskRowCaret",
    TaskRowContent: "TaskRowContent",
    TaskRowStatusIcon: "TaskRowStatusIcon",
    TenantSelector: "TenantSelector",
    TenantTitle: "TenantTitle",
    Text: "Text",
    TicketMessageInput: "TicketMessageInput",
    TicketMessageStream: "TicketMessageStream",
    Title: "Title",
    TitleColumn: "TitleColumn",
    Token: "Token",
    TokenInput: "TokenInput",
    Tokenizer: "Tokenizer",
    Toolbar: "Toolbar",
    Tooltip: "Tooltip",
    TruncatedValue: "TruncatedValue",
    UnderTableText: "UnderTableText",
    UserName: "UserName",
    ValueHelper: "ValueHelper",
    ValueHelperAddButton: "ValueHelperAddButton",
    ValueHelperRow: "ValueHelperRow",
    ValuePicker: "ValuePicker",
    ValuePickerNextValue: "ValuePickerNextValue",
    ValuePickerNextValueButton: "ValuePickerNextValueButton",
    ValuePickerPrevValue: "ValuePickerPrevValue",
    ValuePickerPrevValueButton: "ValuePickerPrevValueButton",
    ValuePickerValue: "ValuePickerValue",
    ValueWrapper: "ValueWrapper",
    VariantLabel: "VariantLabel",
    VariantSelector: "VariantSelector",
    View: "View",
    WelcomeScreen: "WelcomeScreen",
    Wizard: "Wizard",
    WriteLine: "WriteLine",
    WriteLineLabel: "WriteLineLabel",
    YearsGrid: "YearsGrid",
    YearSwitcher: "YearSwitcher"
});

export default TestIds;
