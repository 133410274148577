import React from "react";
import { Content, ContentWrapper, IconWrapper, StaticItems, StyledToolbar } from "./Toolbar.styles";
import { getIcon, MoreIcon } from "../icon";
import { ToolbarItemType } from "../../enums";
import IconSelect from "../inputs/select/IconSelect";
import { IconButton } from "../button";
import { TRecordAny, TValue } from "../../global.types";
import TestIds from "../../testIds";
import CustomResizeObserver from "../customResizeObserver/CustomResizeObserver";
import { createToolbarItem, isToolbarItemDefinition } from "./Toolbar.utils";
import { ISegmentedButton } from "../button/SegmentedButton";
import { withDomManipulator, WithDomManipulator } from "../../contexts/domManipulator/withDomManipulator";
import { THistoryLocation } from "../drillDown/DrillDown.utils";
import { ISelectionChangeArgs, ISelectItem } from "@components/inputs/select/Select.types";

export interface IToolbarItem {
    id: string;

    isBusy?: boolean;
    order?: number;
    label?: string;
    iconName?: string;
    itemType: ToolbarItemType;
    isDisabled?: boolean;
    isLight?: boolean;
    isActive?: boolean;
    itemProps?: TRecordAny;

    // for ToolbarItemType.Custom
    render?: () => React.ReactElement;
    // how many elements will the custom item render into dom next to each other
    // it is needed for the updateSticky method, so that it can pair rendered element with correct definition
    // by default 1
    elementsCount?: number;
}

// todo remove every use of React.ReactElement and always use ToolbarItemType.Custom instead
// to prevent possible errors
// because one component can render multiple elements into the toolbar
// => updateSticky won't be able to pair rendered elements onto their children/definition counterpart
// ==> we either have to always render one element for one custom item, or custom def with elementsCount has to be used
/** Custom items can be pass as rendered element.
 * Pass each button as standalone element so that Toolbar can properly render and use collapsing for each of them.*/
export type TToolbarItem = IToolbarItem | React.ReactElement;

interface IProps {
    /**  Non collapsible items located to the left */
    staticItems?: TToolbarItem[];
    /** Collapsible items located to the right. Will be moved to selection menu if space is not big enough */
    children: TToolbarItem[];
    /** children exceeding 'maxVisibleItems' will be automatically added to collapsible section, even if there would be enough space for them */
    maxVisibleItems?: number;
    onItemChange?: (id: string, value?: TValue) => void;
    className?: string;
    style?: React.CSSProperties;
    /** Used to temporarily prevent update() calls in performance sensitive scenarios (e.g. scrolling).
     * Otherwise there can be some visible lags.  */
    disableResponsiveness?: boolean;
}

type IPropsExtended = IProps & WithDomManipulator;

interface IState {
    items?: ISelectItem[];
}

export interface IToolbarButtonProps {
    item: IToolbarItem;
    onClick?: (item: IToolbarItem) => void;
    /** Use instead of onClick  */
    link?: THistoryLocation;
}

const DEFAULT_ITEM_MARGIN = 12;
export const MENU_ICON_SIZE = 38;
// toolbar items that cannot be collapsed
const STATIC_TYPES = [
    ToolbarItemType.WriteLine, ToolbarItemType.NumericWriteLine,
    ToolbarItemType.ConfirmationButtons, ToolbarItemType.Custom
];

export class ToolbarButton extends React.PureComponent<IToolbarButtonProps> {
    handleClick = (): void => {
        this.props.onClick(this.props.item);
    };

    render() {
        const props = this.props.item.itemProps || {};
        const Icon = getIcon(this.props.item.iconName);
        const isDecorative = this.props.item.itemType === ToolbarItemType.SmallIcon;

        return (
                <IconButton title={this.props.item.label}
                            isActive={this.props.item.isActive}
                            isDisabled={this.props.item.isDisabled}
                            isDecorative={isDecorative}
                            isLight={this.props.item.isLight}
                            isTransparent={!isDecorative}
                            onClick={this.handleClick}
                            isBusy={this.props.item.isBusy}
                            hotspotId={this.props.item.id}
                            link={this.props.link}
                            {...props}>
                    <Icon/>
                </IconButton>
        );
    }
}

class Toolbar extends React.Component<IPropsExtended, IState> {
    _refStyledToolbar = React.createRef<HTMLDivElement>();
    _refContent = React.createRef<HTMLDivElement>();
    _refMenuIcon = React.createRef<HTMLDivElement>();
    _refStaticContent = React.createRef<HTMLDivElement>();

    state: IState = {
        items: undefined
    };

    componentDidMount(): void {
        this.update();
    }

    componentDidUpdate(prevProps: IPropsExtended, prevState: IState) {
        if (this.props.children?.length !== prevProps.children.length
                || this.props.staticItems?.filter(i => i !== null)?.length !== prevProps.staticItems?.filter(i => i !== null)?.length
                || this.props.maxVisibleItems !== prevProps.maxVisibleItems) {
            this.update();
        } else if (this.props.children !== prevProps.children) {
            // we don't need to do all the responsive computation from "update" function
            // but props of the children like "isDisabled" could be changed => update items stored in state
            if (this.state.items) {
                this.updateItemsProps();
            }
        }
    }

    get children() {
        // can be removed when only IToolbarItem are used,
        // but now, some components pass "false" in the child array
        return this.props.children.filter(c => c);
    }

    createElement = (item: IToolbarItem): React.ReactNode => {
        return createToolbarItem(item, this.props.onItemChange);
    };

    enableStickyItems = () => {
        return this.children.some(item => this.isStickyItem(item));
    };

    update = () => {
        if (this.props.disableResponsiveness) {
            return;
        }

        if (!this._refContent.current) {
            return;
        }

        if (this.enableStickyItems()) {
            this.updateSticky();
        } else {
            this.updateNotSticky();
        }

    };

    addItemToList = (items: ISelectItem[], item: IToolbarItem) => {
        if (!item) {
            return;
        }
        // each part of segmented button is moved to selection separately
        if (item.itemType === ToolbarItemType.SegmentedButton) {
            const segItems = item.itemProps.def as ISegmentedButton[];

            for (let i = segItems.length - 1; i >= 0; i--) {
                const segItem = segItems[i];

                items.unshift({
                    id: segItem.id,
                    label: segItem.title ?? segItem.label,
                    iconName: segItem.iconName,
                    isDisabled: item.isDisabled,
                    isSelected: item.itemProps.selectedButtonId === segItem.id,
                    groupId: item.id,
                    additionalData: {
                        itemId: item.id
                    }
                });
            }
            // each part of icon select is moved to selection separately
        } else if (item.itemType === ToolbarItemType.IconSelect) {
            const selItems = item.itemProps.items as ISelectItem[];

            for (let i = selItems.length - 1; i >= 0; i--) {
                const selItem = selItems[i];

                items.unshift({
                    id: selItem.id,
                    label: selItem.title ?? selItem.label,
                    iconName: selItem.iconName,
                    isDisabled: item.isDisabled,
                    isSelected: item.itemProps.selectProps?.value === selItem.id,
                    additionalData: {
                        itemId: item.id
                    }
                });
            }
        } else {
            items.unshift({
                id: item.id,
                label: item.label,
                iconName: item.iconName,
                isDisabled: item.isDisabled,
                isSelected: item.isActive
            });
        }
    };

    updateNotSticky = () => {
        this.props.domManipulatorOrchestrator.registerCallback(
                () => {
                    const items: ISelectItem[] = [];
                    const childNodes = this._refContent.current.childNodes;
                    const childCount = childNodes.length;
                    const baseBottom = (childNodes[0] as HTMLDivElement)?.offsetTop + (childNodes[0] as HTMLDivElement)?.clientHeight;
                    for (let i = childCount - 1; i >= 0; i--) {
                        const node = childNodes[i] as HTMLDivElement;
                        if (node.style.display === "none" || node.offsetTop >= baseBottom || (this.props.maxVisibleItems && i >= this.maxVisibleItems)) {
                            this.addItemToList(items, this.children[i] as IToolbarItem);
                        }
                    }
                    return items;
                }, (items: ISelectItem[]) => {
                    if (items.length > 0) {
                        this._refMenuIcon.current.style.display = "block";
                        this.setState({
                            items
                        });
                    } else {
                        this._refMenuIcon.current.style.display = "none";
                    }

                    if (this._refContent.current) {
                        // restore justifyContent, could've been changed in updateSticky call
                        // which would cause the Toolbar to be centered instead of aligned to the right
                        this._refContent.current.style.justifyContent = "flex-end";
                    }

                },
                [this._refContent, this._refMenuIcon]
        );
    };

    get maxVisibleItems(): number {
        const childCount = this.children.length;
        let maxVisibleItems = this.props.maxVisibleItems;

        if (maxVisibleItems && childCount > maxVisibleItems) {
            // three dot "more" menu, replaces the last visible item
            maxVisibleItems -= 1;
        }

        return maxVisibleItems;
    }

    isStickyItem = (item: TToolbarItem) => {
        return !isToolbarItemDefinition(item) || item.isActive || STATIC_TYPES.includes(item.itemType);
    };

    getRenderedElementsCount = (item: IToolbarItem): number => {
        switch (true) {
            case !isToolbarItemDefinition(item):
                return 1;
            case item.itemType === ToolbarItemType.ConfirmationButtons:
                return 2;
            case item.itemType === ToolbarItemType.Custom:
                return item.elementsCount ?? 1;
            default:
                return 1;
        }
    };

    // used for cases when we want some of the items (like WriteLine) to "stick"
    // meaning they will never get hidden under the three dot menu
    updateSticky = () => {
        let shouldDisplayMenu = false;
        const items: ISelectItem[] = [];

        const childNodes = this._refContent.current.childNodes;
        const stickyItemsWidth = (this._refStaticContent?.current as HTMLElement)?.offsetWidth ?? 0;
        let widthAvailable = (this._refStyledToolbar.current as HTMLElement).offsetWidth - stickyItemsWidth;

        this._refContent.current.style.justifyContent = "flex-start";
        for (const node of childNodes) {
            const el = node as HTMLElement;
            el.style.display = "flex";
        }

        let nodePos = childNodes.length - 1;

        for (let i = this.children.length - 1; i >= 0; i--) {
            const item = this.children[i];
            const node = childNodes[nodePos] as HTMLDivElement;
            const left = node.offsetLeft;
            const renderedElementsCount = this.getRenderedElementsCount(item as any);

            nodePos -= renderedElementsCount;

            // skip always present types and hide next item instead
            if (this.isStickyItem(item)) {
                widthAvailable -= node.offsetWidth + DEFAULT_ITEM_MARGIN;

                continue;
            }

            if (left + node.offsetWidth > widthAvailable || (this.props.maxVisibleItems && i >= this.maxVisibleItems)) {
                this.addItemToList(items, item as IToolbarItem);

                if (!shouldDisplayMenu) {
                    // susbtract width of the _refMenuIcon and its margin
                    const marginLeft = window.getComputedStyle(this._refMenuIcon.current).marginLeft;
                    widthAvailable -= MENU_ICON_SIZE + (marginLeft ? parseInt(marginLeft) : 0);
                    shouldDisplayMenu = true;
                }
                node.style.display = "none";
            }
        }

        this._refContent.current.style.justifyContent = "flex-end";

        this.setState({
            items
        });

        this._refMenuIcon.current.style.display = shouldDisplayMenu ? "block" : "none";
    };

    updateItemsProps = () => {
        const items: ISelectItem[] = [];
        const applicableChildren = [...this.children];

        for (let i = this.state.items.length - 1; i >= 0; i--) {
            const item = this.state.items[i];
            const origChildIndex = applicableChildren.findIndex(child => (child as IToolbarItem).id && ((child as IToolbarItem).id === item.additionalData?.itemId || (child as IToolbarItem).id === item.id));
            const origChild = applicableChildren[origChildIndex];

            if (origChild) {
                applicableChildren.splice(origChildIndex, 1);

                this.addItemToList(items, origChild as IToolbarItem);
            }
        }

        this.setState({
            items
        });
    };

    handleMenuChange = (e: ISelectionChangeArgs) => {
        const id = e.additionalData?.itemId ?? e.value;
        const value = e.additionalData?.itemId && e.value;

        this.props.onItemChange?.(id, value);
    };

    handleResize = () => {
        this.update();
    };

    getItem = (item: TToolbarItem): React.ReactNode => {
        if (!isToolbarItemDefinition(item)) {
            return item;
        }

        return this.createElement(item);
    };

    renderStaticItems = () => {
        if (!this.props.staticItems?.filter(i => i !== null).length) {
            return null;
        }

        return (
                <StaticItems ref={this._refStaticContent} data-testid={TestIds.StaticItems}>
                    {this.props.staticItems.map((item: TToolbarItem) => {
                        return this.getItem(item);
                    })}
                </StaticItems>
        );
    };

    renderToolbar = (content: React.ReactElement) => {
        return (
                <StyledToolbar ref={this._refStyledToolbar} data-testid={TestIds.Toolbar}
                               className={this.props.className} style={this.props.style}>
                    <CustomResizeObserver onResize={this.handleResize}/>
                    {this.props.staticItems && this.props.staticItems.length > 0 &&
                            this.renderStaticItems()
                    }
                    <ContentWrapper isSticky={this.enableStickyItems()}>
                        {content}
                        <IconWrapper ref={this._refMenuIcon}>
                            <IconSelect hotspotId={"toolbarMoreButton"}
                                        items={this.state.items}
                                        icon={<MoreIcon/>}
                                        value={undefined}
                                        onChange={this.handleMenuChange}
                            />
                        </IconWrapper>
                    </ContentWrapper>
                </StyledToolbar>
        );
    };

    renderNotSticky() {
        const maxVisibleItems = this.maxVisibleItems;
        const getDisplay = (index: number) => (index >= maxVisibleItems) ? "none" : null;

        const content = (
                <Content ref={this._refContent}
                         style={{
                             display: "flex",
                             flexDirection: "row",
                             flexWrap: "wrap",
                             overflow: "hidden",
                             justifyContent: "flex-end"
                         }}>
                    {(this.children || []).map((item: TToolbarItem, index: number) => {
                        if (isToolbarItemDefinition(item)) {
                            item = {
                                ...item,
                                itemProps: {
                                    ...item.itemProps,
                                    style: {
                                        ...item.itemProps?.style,
                                        display: getDisplay(index)
                                    }
                                }
                            };
                        }

                        return this.getItem(item);
                    })}
                </Content>
        );

        return this.renderToolbar(content);
    }

    renderSticky() {
        const content = (
                <Content ref={this._refContent}>
                    {(this.children || []).map((item: TToolbarItem) => this.getItem(item))}
                </Content>
        );

        return this.renderToolbar(content);
    }

    render() {
        if (this.enableStickyItems()) {
            return this.renderSticky();
        } else {
            return this.renderNotSticky();
        }
    }

}

const ToolbarWithDomManipulator = withDomManipulator(Toolbar);

export { ToolbarWithDomManipulator as Toolbar };