import { AppContext } from "../../contexts/appContext/AppContext.types";
import { FormViewForExtend } from "../../views/formView/FormView";
import { setBreadCrumbs } from "../../views/formView/Form.utils";
import { AccAssCreditAccountPath, AccAssDebitAccountPath } from "./AccountAssignmentDef";
import { isMatchingAccount } from "../documents/Document.utils";
import BindingContext from "../../odata/BindingContext";
import { ISmartFieldChange } from "@components/smart/smartField/SmartField";
import { EntitySetName, IAccountAssignmentEntity, IDocumentTypeEntity } from "@odata/GeneratedEntityTypes";
import { withPermissionContext } from "../../contexts/permissionContext/withPermissionContext";
import { IFormStorageSaveResult } from "../../views/formView/FormStorage";
import { getDocumentTypeLabel } from "./AccountAssignment.utils";
import { ISelectItem } from "@components/inputs/select/Select.types";

class AccountAssignmentFormView extends FormViewForExtend<IAccountAssignmentEntity> {
    static contextType = AppContext;
    //sadly, breaks typescript type checking
    //context: React.ContextType<typeof AppContext>;
    static defaultProps = {
        title: "AccountAssignment:FormTitle"
    };

    componentDidMount(): void {
        super.componentDidMount();
        this.loadDocumentTypes();
    }

    loadDocumentTypes = async (): Promise<void> => {
        if (!this.context.getData().custom?.documentTypes) {
            const response = await this.props.storage.oData
                .getEntitySetWrapper(EntitySetName.DocumentTypes)
                .query()
                .fetchData<IDocumentTypeEntity[]>();

            const docTypes = response.value ?? [];
            const items: ISelectItem[] = docTypes.map((documentType: IDocumentTypeEntity) => ({
                id: documentType.Code,
                label: getDocumentTypeLabel(documentType)
            }));

            this.context.setCustomData({
                documentTypes: items
            });

            this.props.storage.refresh();
        }
    };

    onAfterLoad = async () => {
        setBreadCrumbs(this.props.storage);
        this.storeDocumentTypes();
        !this.props.storage.data.bindingContext.isNew() && this.setAccountValues();
        return super.onAfterLoad();
    };

    setAccountValues = (): void => {
        const storage = this.props.storage;

        // local context for credit/debit account has to have some id so validation and select works properly, but it
        // should not be linked to existing accounts (eg. you have account with id 4, you change his name, but you still
        // want to display old name here until you chose again from select items)
        storage.setValueByPath(AccAssCreditAccountPath, BindingContext.NEW_ENTITY_ID_PROP);
        storage.setValueByPath(AccAssDebitAccountPath, BindingContext.NEW_ENTITY_ID_PROP);
        storage.refreshFields();
    };

    storeDocumentTypes = (): void => {
        const items = this.context.getData().custom?.documentTypes;
        if (this.props.storage.data.bindingContext && items) {
            const bc = this.props.storage.data.bindingContext.navigate("DocumentTypes");
            const info = this.props.storage.getInfo(bc);

            info.fieldSettings.items = items;
        }
    };

    getMatchingAccountId = (accounts: ISelectItem[], accountName: string, accountNumber: string) => {
        return accounts.find((accountItem) => isMatchingAccount(accountItem.additionalData, {
            Name: accountName,
            Number: accountNumber
        }))?.id;
    };

    save = async (): Promise<IFormStorageSaveResult> => {
        this.props.onBeforeSave();

        const isNew = this.props.storage.data.bindingContext.isNew();

        const result = await this.props.storage.save({
            successSubtitle: this.props.storage.t("AccountAssignment:Validation.Saved")
        });

        if (!result) {
            this.props.onSaveFail();
            this.forceUpdate(this.scrollPageUp);
            return result;
        }

        setBreadCrumbs(this.props.storage);
        this.onAfterSave(isNew, false);
        this.forceUpdate(isNew ? this.scrollPageDown : undefined);

        return result;
    };

    handleChange = (e: ISmartFieldChange): void => {
        super.handleChange(e);
        this.handleAccountChange(e);
    };

    handleAccountChange = (e: ISmartFieldChange): void => {
        const path = e.bindingContext.getPath();

        if (![AccAssCreditAccountPath, AccAssDebitAccountPath].includes(path)) {
            return;
        }

        if (e.triggerAdditionalTasks) {
            const cleanPath = BindingContext.cleanLocalContext(path);
            const { Name, Number } = e.additionalData ?? {};

            this.props.storage.setValueByPath(`${cleanPath}Name`, Name);
            this.props.storage.setValueByPath(`${cleanPath}Number`, Number);
        }
    };
}

export default withPermissionContext(AccountAssignmentFormView);