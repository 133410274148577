import React from "react";
import TextArea from "../textArea";
import { EditableTextSizes, Status } from "../../../enums";

import { withTranslation, WithTranslation } from "react-i18next";
import {
    EditableTextConfirmButtons,
    ReadLabel,
    ReadLabelText,
    StyledEditableText,
    TextAreaWrapper,
    Wrapper
} from "./EditableText.styles";
import { IInputOnChangeEvent, ISharedInputProps } from "../input";
import { KeyName } from "../../../keyName";
import TestIds from "../../../testIds";
import { IAuditTrailData } from "../../../model/Model";
import { renderReadOnlyText } from "../utils";
import { composeRefHandlers, doesElementContainsElement, isDefined } from "@utils/general";
import { RequiredMark } from "../field/Label.styles";
import { WithErrorAndTooltipProps } from "../formError/WithErrorAndTooltip";
import { InputStatusStraight } from "../input/Input.styles";
import KeyboardShortcutsManager, { KeyboardShortcut } from "../../../utils/keyboardShortcutsManager/KeyboardShortcutsManager";

export interface IEditableTextChangeEvent {
    value: string;
}

export interface IEditableTextProps extends ISharedInputProps, WithErrorAndTooltipProps {
    /** Value of the text area*/
    value: string;
    placeholder: string;
    isInEdit?: boolean;
    isDisabled?: boolean;
    isMultiLine?: boolean;
    isConfirmable?: boolean;
    showRequiredMark?: boolean;
    maxRows?: number;
    style?: React.CSSProperties;
    onConfirm?: () => void;
    onCancel?: () => void;
    onChange?: (args: IEditableTextChangeEvent) => void;
    onIsEditChanged?: (isInEdit: boolean) => void;
    auditTrailData?: IAuditTrailData;
    passRef?: React.Ref<HTMLTextAreaElement | HTMLDivElement>;
    outerRef?: React.RefObject<HTMLDivElement>;
}

interface IProps extends IEditableTextProps, WithTranslation {
    className?: string;
}

interface IEditableTextState {
    isInEdit: boolean;
}

const MAX_ROWS_SMALL = 12;
const MAX_ROWS_MEDIUM = 16;
const MAX_ROWS_LARGE = 20;

class EditableText extends React.Component<IProps, IEditableTextState> {
    textAreaRef = React.createRef<HTMLTextAreaElement>();
    buttonsWrapper = React.createRef<HTMLDivElement>();

    state: IEditableTextState = {
        isInEdit: false
    };

    get width() {
        return this.props.width || EditableTextSizes.M;
    }

    get maxRowCount() {
        if (this.props.maxRows) {
            return this.props.maxRows;
        }

        if (!this.props.isMultiLine) {
            return 1;
        }

        const width = this.width;

        if (width === EditableTextSizes.S) {
            return MAX_ROWS_SMALL;
        }

        if (width === EditableTextSizes.M) {
            return MAX_ROWS_MEDIUM;
        }

        if (width === EditableTextSizes.L) {
            return MAX_ROWS_LARGE;
        }

        return MAX_ROWS_SMALL;
    }

    get isInEdit() {
        // with error prop it's always rendered in edit mode as we are not able to display the error otherwise
        return !!this.props.error || this.props.isInEdit || this.state.isInEdit;
    }

    autoConfirmOnBlur(e: React.FocusEvent): void {
        const target = e.relatedTarget as HTMLDivElement;
        const contains = doesElementContainsElement(this.textAreaRef.current, target) || doesElementContainsElement(this.buttonsWrapper.current, target);
        if (!contains) {
            this.handleConfirm();
        }
    }

    switchToNonEditableState() {
        if (this.state.isInEdit) {
            this.setState({
                isInEdit: false
            }, () => {
                this.props.onIsEditChanged?.(false);
            });
        }
    }

    handleChange = (e: IInputOnChangeEvent): void => {
        this.props.onChange({
            value: e.value as string
        });
    };

    handleBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
        // ?. can make null -> undefined and mess up comparison
        const trimmedValue = isDefined(this.props.value) ? this.props.value?.trim() : this.props.value;
        if (this.props.value !== trimmedValue) {
            // todo: general solution to all inputs and textareas?
            this.props.onChange({
                value: trimmedValue
            });
        }
        if (!this.props.isConfirmable) {
            this.switchToNonEditableState();
        } else {
            this.autoConfirmOnBlur(e);
        }
    };

    handleButtonsBlur = (e: React.FocusEvent) => {
        if (this.props.isConfirmable) {
            this.autoConfirmOnBlur(e);
        }
    };

    handleClick = () => {
        if (!this.state.isInEdit) {
            this.setState({
                isInEdit: true
            }, () => {
                this.props.onIsEditChanged?.(true);
                this.textAreaRef.current.focus();
            });
        }
    };

    handleConfirm = () => {
        this.props.onConfirm?.();
        this.switchToNonEditableState();
    };

    handleCancel = () => {
        this.props.onCancel?.();
        this.switchToNonEditableState();
    };

    handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        switch (true) {
            case event.key === KeyName.Enter:
                if (event.target === event.currentTarget) {
                    this.handleClick();
                    event.preventDefault();
                }
                break;
            case event.key === KeyName.Escape:
                this.handleCancel();
                event.preventDefault();
                break;
            case KeyboardShortcutsManager.isEventShortcut(event, KeyboardShortcut.ALT_S):
                if (this.props.isConfirmable) {
                    this.handleConfirm();
                }
                break;
        }
    };

    renderEditableText() {
        const value = this.props.value === null ? "" : this.props.value;
        return (
            <StyledEditableText
                ref={this.props.outerRef}
                className={this.props.className}
                tabIndex={this.props.isDisabled || this.props.auditTrailData ? null : 0}
                auditTrailType={this.props.auditTrailData?.type}
                onKeyDown={this.handleKeyDown}
                isInEdit={this.isInEdit}
                isDisabled={this.props.isDisabled}
                data-testid={TestIds.EditableText}>
                <Wrapper
                    _hoverable
                    _width={this.width}
                    onClick={this.handleClick}
                    isInEdit={this.isInEdit}
                    style={this.props.style}>
                    {this.props.showRequiredMark && !this.isInEdit &&
                        <RequiredMark style={{ top: "5px", left: "10px" }}/>
                    }
                    {!this.isInEdit &&
                        <ReadLabel
                            ref={this.props.passRef as React.Ref<HTMLDivElement>}
                            _hoverable
                            title={value}>
                            {/*TODO maybe use <Text> component instead of ReadLabelText*/}
                            <ReadLabelText
                                auditTrailType={this.props.auditTrailData?.type}
                                maxRows={this.maxRowCount}
                                _hasValue={!!value}
                                data-testid={TestIds.EditableTextReadLabel}>
                                {value || this.props.placeholder}
                            </ReadLabelText>
                        </ReadLabel>
                    }
                    {this.isInEdit &&
                        <TextAreaWrapper>
                            <TextArea
                                maxRows={this.maxRowCount}
                                passRef={composeRefHandlers(this.textAreaRef, this.props.passRef)}
                                name={this.props.name}
                                value={value}
                                onChange={this.handleChange}
                                onBlur={this.handleBlur}
                                error={this.props.error}
                                tooltip={this.props.tooltip}/>
                        </TextAreaWrapper>
                    }
                    {!this.props.isReadOnly && this.props.showChange && <InputStatusStraight status={Status.Warning}/>}
                </Wrapper>
                {this.props.isConfirmable && this.isInEdit &&
                    <EditableTextConfirmButtons passRef={this.buttonsWrapper}
                                                onCancel={this.handleCancel}
                                                onConfirm={this.handleConfirm}
                                                onBlur={this.handleButtonsBlur}/>
                }
            </StyledEditableText>
        );
    }

    render(): React.ReactElement {
        return this.props.isReadOnly && !this.props.auditTrailData?.type ? renderReadOnlyText(this) : this.renderEditableText();
    }
}

export default withTranslation(["Components", "Common"])(EditableText);
