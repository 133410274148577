import { ConditionType, createFilterRow } from "@components/conditionalFilterDialog/ConditionalFilterDialog.utils";
import { getTableDrillDownLink } from "@components/drillDown/DrillDown.utils";
import { formatDateToDateString } from "@components/inputs/date/utils";
import { ISelectItem } from "@components/inputs/select/Select.types";
import { IFieldInfoProperties } from "@components/smart/FieldInfo";
import { CurrencyDef } from "@components/smart/GeneralFieldDefinition";
import {
    FilterBarGroup,
    getDefaultFilterGroupDef,
    IFilterGroupDef
} from "@components/smart/smartFilterBar/SmartFilterBar.types";
import { IReportHierarchy } from "@components/smart/smartTable";
import { ICellValueObject, ISort } from "@components/table";
import { EntityTypeName } from "@odata/GeneratedEntityTypes";
import { ClearedStatusCode, DocumentTypeCode } from "@odata/GeneratedEnums";
import { getEnumSelectItems } from "@odata/GeneratedEnums.utils";
import { IFormatOptions } from "@odata/OData.utils";
import { DocumentJournalProps } from "@pages/reports/documentJournal/DocumentJournalDef";
import { getCompanyCurrency } from "@utils/CompanyUtils";
import i18next from "i18next";
import React from "react";

import { BasicInputSizes, ConfigListItemBoundType, FieldType, Sort } from "../../../enums";
import { TValue } from "../../../global.types";
import { ModelEvent } from "../../../model/Model";
import BindingContext from "../../../odata/BindingContext";
import { ROUTE_DOCUMENT_JOURNAL } from "../../../routes";
import { formatCurrency } from "../../../types/Currency";
import { DATE_MAX, DATE_MIN, getUtcDate, getUtcDayjs } from "../../../types/Date";
import memoizeOne from "../../../utils/memoizeOne";
import Page, { IProps as IPageProps } from "../../Page";
import { DocumentStatusLocalPath, getStatusFilterId } from "../CommonDefs";
import {
    agingIntervalHandleFilterChange,
    agingIntervalOnAfterLoadCallback,
    getAgingIntervalFilterDef,
    getAgingIntervalUnitFilterDef
} from "../customFilterComponents/AgingInterval";
import {
    getLabelDrilldownFilters,
    IReportTableDefinition,
    NumberAggregationFunction,
    ReportConfigGroup,
    TGetReportConfigListItemOverride
} from "../Report.utils";
import { getWholeDateRangeFilter } from "../ReportFilter.utils";
import { ReportId } from "../ReportIds";
import { ReportSplitPage } from "../ReportSplitPage";
import { ReportStorage } from "../ReportStorage";
import { IReportFilterChangeEvent, IReportViewBaseProps } from "../ReportView";

export const AgingProps = {
    calculationDecisiveDate: BindingContext.localContext("CalculationDecisiveDate"),
    agingIntervals: BindingContext.localContext("AgingIntervals"),
    agingIntervalUnit: BindingContext.localContext("AgingIntervalUnit"),
    currency: BindingContext.localContext("Currency"),
    calculateToDay: BindingContext.localContext("CalculateToDate"),
    documentTypes: BindingContext.localContext("DocumentTypes")
};

export const AgingAPProps = {
    reportType: BindingContext.localContext("AgingAPReportType")
};

export const AgingARProps = {
    reportType: BindingContext.localContext("AgingARReportType")
};

export enum AgingType {
    AP = "AP",
    AR = "AR"
}

export enum AgingReportType {
    AfterDueDate = "AfterDueDate",
    BeforeDueDate = "BeforeDueDate"
}

export enum AgingCalculationDecisiveDate {
    DateDue = "DateDue",
    DateAccountingTrans = "DateAccountingTrans"
}

const agingTableBc = BindingContext.localContext("AgingTable");

export const AgingReceivedTypes = [DocumentTypeCode.InvoiceReceived, DocumentTypeCode.OtherLiability, DocumentTypeCode.CorrectiveInvoiceReceived];
export const AgingIssuedTypes = [DocumentTypeCode.InvoiceIssued, DocumentTypeCode.OtherReceivable, DocumentTypeCode.CorrectiveInvoiceIssued];

export interface IGetAgingDefinition {
    path: string;
    title: string;
    tableId: ReportId;
    reportTypeParam: string;
    prefix: string;
}

export const getDefinition = (getDefArgs: IGetAgingDefinition): IReportTableDefinition => {
    const initialSortBy: ISort[] = [{
        id: "BusinessPartner_Name",
        sort: Sort.Asc
    }];
    const translatedTitle = i18next.t(getDefArgs.title);
    const showDrilldown = true;
    const disableAggregateButton = true;
    const parameters: string[] = [
        AgingProps.calculationDecisiveDate,
        getDefArgs.reportTypeParam,
        AgingProps.agingIntervals,
        AgingProps.agingIntervalUnit,
        AgingProps.currency,
        AgingProps.calculateToDay,
        AgingProps.documentTypes
    ];

    const configListItemsOverride: TGetReportConfigListItemOverride = {
        BusinessPartner_Name: {
            isRequired: true,
            isDisabled: true,
            isCopyOnly: false
        },
        Document_TransactionAmountDue: {
            isRequired: true,
            isDisabled: true,
            isCopyOnly: false,
            areItemsReadOnly: true,
            boundTo: ConfigListItemBoundType.Column,
            group: ReportConfigGroup.Aggregations
        }
    };

    const defaultReportHierarchy: IReportHierarchy = {
        "Aggregate": true,
        "Groups": [
            {
                "ColumnAlias": "BusinessPartner_Name"
            }
        ],
        "Columns": [],
        "Aggregations": [
            {
                "ColumnAlias": "Document_TransactionAmountDue",
                "AggregationFunction": NumberAggregationFunction.Sum
            }
        ]
    };

    const columnOverrides = (columnId: string): IFieldInfoProperties => {
        if (!columnId.startsWith("Document_TransactionAmountDue")) {
            return null;
        }

        return {
            formatter: (val: TValue, args: IFormatOptions): ICellValueObject => {
                if (!val) {
                    return null;
                }

                const settings = (args.storage as ReportStorage).settings;
                const documentTypes = settings[AgingProps.documentTypes];
                const originalColumn = (args.storage as ReportStorage).tableAPI.getState().data.Columns.find(col => col.ColumnAlias === columnId);

                const wholeDateRangeFilter = getWholeDateRangeFilter();
                const { customQueryString } = wholeDateRangeFilter;

                const notClearedFilterStatus = createFilterRow({
                    type: ConditionType.Excluded,
                    value: [getStatusFilterId(EntityTypeName.ClearedStatus, ClearedStatusCode.Cleared)]
                });

                const filters = {
                    [BindingContext.localContext("BusinessPartner_Name")]: args.entity.BusinessPartner_Name,
                    // todo: define special filter just for ClearedStatusCode in DocumentJournal, which filter just by the one column
                    //  (this filter should add DocumentStatus column and force it as mandatory, so it can't be removed and ensure that ClearedStatusCode column is available
                    //  check also visibility of the filter values above the filterbar.
                    [DocumentStatusLocalPath]: [notClearedFilterStatus],
                    [BindingContext.localContext("DocumentType_Name")]: documentTypes,
                    [BindingContext.localContext("Document_TransactionCurrencyCode")]: args.entity.Document_TransactionCurrencyCode,
                    ...getLabelDrilldownFilters(args.entity),
                    ...wholeDateRangeFilter.filters
                };

                const decisiveDate = settings[AgingProps.calculationDecisiveDate];
                const dateParam = decisiveDate === AgingCalculationDecisiveDate.DateDue ? "Document_DateDue" : "Document_DateAccountingTransaction";

                // reports on BE use DateTime instead of DateTimeOffset or DateOnly
                // => we receive string in format like 0001-01-01T00:00:00 (with time and without Z at the end)
                // ==> it sometimes fail when we parse it into our date objects
                // ===> remove the time part, we only need the date part
                // todo refactor BE to use either DateTimeOffset or even better, DateOnly
                const fnParseDateOnly = (date: string): string => {
                    return date.slice(0, 10);
                };

                const toDate = settings[AgingProps.calculateToDay];

                if (toDate) {
                    filters[DocumentJournalProps.dateClearingStatusTo] = formatDateToDateString(toDate);
                }

                if (originalColumn.AdditionalInfo) {
                    // BE sends interval for the computed TransactionAmountDue columns
                    if (originalColumn.AdditionalInfo.IntervalStartDate === originalColumn.AdditionalInfo.IntervalEndDate) {
                        filters[BindingContext.localContext(dateParam)] = fnParseDateOnly(originalColumn.AdditionalInfo.IntervalStartDate);
                    } else {
                        filters[BindingContext.localContext(dateParam)] = {
                            from: fnParseDateOnly(originalColumn.AdditionalInfo.IntervalStartDate),
                            to: fnParseDateOnly(originalColumn.AdditionalInfo.IntervalEndDate)
                        };
                    }
                } else { // TransactionAmountDue sum
                    const reportType = args.storage.id === ReportId.AgingAP ? settings[AgingAPProps.reportType] : settings[AgingARProps.reportType];
                    const isAfterDueDate = reportType.endsWith(AgingReportType.AfterDueDate);

                    // for the after due date, MIN - YESTERDAY interval
                    const interval = isAfterDueDate ? {
                        from: formatDateToDateString(DATE_MIN),
                        to: formatDateToDateString(getUtcDayjs(toDate).startOf("day").subtract(1, "day"))
                    } : { // for before due date, TODAY - MAX
                        from: formatDateToDateString(getUtcDayjs(toDate).startOf("day")),
                        to: formatDateToDateString(DATE_MAX)
                    };

                    filters[BindingContext.localContext(dateParam)] = interval;
                }

                const formattedVal = formatCurrency(val as number, args.entity[originalColumn.DependentColumnsAliases[0]] ?? getCompanyCurrency(args.context));

                return getTableDrillDownLink(formattedVal, {
                    route: ROUTE_DOCUMENT_JOURNAL,
                    context: args.storage.context,
                    storage: args.storage,
                    filters,
                    customQueryString
                });
            }
        };
    };

    const filterBarDef: IFilterGroupDef[] = [
        {
            ...getDefaultFilterGroupDef(FilterBarGroup.Parameters),
            defaultFilters: [
                AgingProps.calculateToDay,
                AgingProps.calculationDecisiveDate,
                getDefArgs.reportTypeParam,
                AgingProps.agingIntervals,
                AgingProps.agingIntervalUnit,
                AgingProps.currency,
                AgingProps.documentTypes
            ],
            filterDefinition: {
                [AgingProps.calculationDecisiveDate]: {
                    type: FieldType.ComboBox,
                    defaultValue: "DateDue",
                    width: BasicInputSizes.L,
                    label: i18next.t("Reporting:Aging.DecisiveDate"),
                    fieldSettings: {
                        items: [
                            {
                                label: i18next.t("Reporting:Aging.FromDateDue"),
                                id: "DateDue"
                            },
                            {
                                label: i18next.t("Reporting:Aging.FromDateAccTrans"),
                                id: "DateAccountingTrans"
                            }
                        ]
                    }
                },
                [getDefArgs.reportTypeParam]: {
                    type: FieldType.ComboBox,
                    defaultValue: `${getDefArgs.prefix}${AgingReportType.AfterDueDate}`,
                    width: BasicInputSizes.L,
                    label: i18next.t("Reporting:Aging.ReportType"),
                    fieldSettings: {
                        items: [
                            {
                                label: i18next.t(`Reporting:Aging.${getDefArgs.prefix}${AgingReportType.AfterDueDate}`),
                                id: `${getDefArgs.prefix}${AgingReportType.AfterDueDate}`
                            },
                            {
                                label: i18next.t(`Reporting:Aging.${getDefArgs.prefix}${AgingReportType.BeforeDueDate}`),
                                id: `${getDefArgs.prefix}${AgingReportType.BeforeDueDate}`
                            }
                        ]
                    }
                },
                [AgingProps.agingIntervals]: getAgingIntervalFilterDef(getDefArgs),
                [AgingProps.agingIntervalUnit]: getAgingIntervalUnitFilterDef(),
                [AgingProps.currency]: {
                    ...CurrencyDef,
                    label: i18next.t("Reporting:Aging.Currency")
                },
                [AgingProps.calculateToDay]: {
                    type: FieldType.Date,
                    label: i18next.t("Reporting:Aging.CalculateToDay"),
                    defaultValue: () => getUtcDate()
                },
                [AgingProps.documentTypes]: {
                    type: FieldType.MultiSelect,
                    defaultValue: () => {
                        if (getDefArgs.tableId === ReportId.AgingAP) {
                            return AgingReceivedTypes;
                        } else {
                            return AgingIssuedTypes;
                        }
                    },
                    width: BasicInputSizes.L,
                    label: i18next.t("Reporting:Aging.DocumentType"),
                    fieldSettings: {
                        itemsFactory: async (): Promise<ISelectItem[]> => {
                            const items = getEnumSelectItems(EntityTypeName.DocumentType);

                            if (getDefArgs.tableId === ReportId.AgingAP) {
                                return items.filter(item => AgingReceivedTypes.includes(item.id.toString() as DocumentTypeCode));
                            } else {
                                return items.filter(item => AgingIssuedTypes.includes(item.id.toString() as DocumentTypeCode));
                            }
                        }
                    }
                }
            }
        },
        {
            ...getDefaultFilterGroupDef(FilterBarGroup.Filters),
            allowCustomFilters: false,
            defaultFilters: [],
            filterDefinition: {}
        }
    ];

    return {
        title: translatedTitle,
        path: getDefArgs.path,
        id: getDefArgs.tableId,
        showDrilldown,
        initialSortBy,
        disableAggregateButton,
        columnOverrides,
        filterBarDef,
        parameters,
        configListItemsOverride,
        defaultReportHierarchy
    };
};

class AgingBase extends Page<IPageProps> {
    storage: ReportStorage;

    constructor(props: IPageProps, context: any) {
        super(props, context);

        this.onAfterLoad = this.onAfterLoad.bind(this);
    }

    async onAfterLoad(storage: ReportStorage) {
        this.storage = storage;

        this.storage.emitter.on(ModelEvent.VariantChanged, this.handleVariantChange);
        agingIntervalOnAfterLoadCallback(storage);
    }

    componentWillUnmount() {
        this.storage.emitter.off(ModelEvent.VariantChanged, this.handleVariantChange);
    }


    handleVariantChange = (): void => {
        agingIntervalOnAfterLoadCallback(this.storage);
    };


    handleFilterChange = (args: IReportFilterChangeEvent) => {
        agingIntervalHandleFilterChange(args, this.storage);

        return args.settings;
    };

    getReportViewProps = memoizeOne((): Partial<IReportViewBaseProps> => {
        return {
            onFilterChange: this.handleFilterChange,
            expandable: true
        };
    });

    render = () => {
        if (!this.isReady()) {
            return null;
        }

        return (
            <ReportSplitPage
                {...this.getMandatoryProps()}
                onTableStorageLoad={this.onAfterLoad}
                reportViewProps={this.getReportViewProps()}
            />
        );
    };
}

export default AgingBase;
