import { DialogContext } from "@components/dialog";
import { ISmartFieldBlur, ISmartFieldChange } from "@components/smart/smartField/SmartField";
import { getNestedValue } from "@odata/Data.utils";
import {
    CompanyEntity,
    CompanyLegalAddressEntity,
    CompanyVatStatusEntity,
    EntityTypeName,
    ICompanyCommunicationAddressEntity,
    ICompanyVatStatusEntity,
    TaxStatementSignatoryPersonEntity
} from "@odata/GeneratedEntityTypes";
import {
    AccountingCode,
    GeneralPermissionCode,
    SignatoryPersonCode,
    TaxStatementSubmitterCode,
    TypeOfBusinessCode,
    VatStatementFrequencyCode,
    VatStatusCode
} from "@odata/GeneratedEnums";
import { getEnumDisplayValue } from "@odata/GeneratedEnums.utils";
import { getCurrentRangeIndex, TTemporal, updateTemporalRangesAfterCurrentTempPropChange } from "@odata/TemporalUtils";
import { hasTaxStatementSignatoryPersonPath } from "@pages/companies/TaxStatementSignatoryPerson.utils";
import { getNextSystemCompanyLogoId } from "@utils/CompanyUtils";
import { composeRefHandlers, isObjectEmpty } from "@utils/general";
import { logger } from "@utils/log";
import { getOneFetch, isAbortException } from "@utils/oneFetch";
import { removeWhiteSpace } from "@utils/string";
import { cloneDeep } from "lodash";
import React from "react";

import BusyIndicator from "../../components/busyIndicator";
import { FILES_API_URL, FU_BY_POSTAL_CODE_URL } from "../../constants";
import { withPermissionContext } from "../../contexts/permissionContext/withPermissionContext";
import { FormMode } from "../../enums";
import BindingContext, { createPath } from "../../odata/BindingContext";
import { DATE_MAX, DATE_MIN } from "../../types/Date";
import FileStorage from "../../utils/FileStorage";
import Form from "../../views/formView";
import { generateComparisonProps } from "../../views/formView/Form";
import { IFormStorageSaveResult } from "../../views/formView/FormStorage";
import { FormViewForExtend, IFormViewProps } from "../../views/formView/FormView";
import { companyIsSecondaryAddressPath } from "./Company.shared.utils";
import {
    currentVatStatus,
    currentVatStatusFrequency,
    getVatDeductionCoefficientCurrentYear,
    ICompanyExtendedEntity,
    ICompanyFormCustomData
} from "./Company.utils";
import { partnerAresFields, VatReducedDeductionCoefficient } from "./CompanyDef";
import { CompanyDialog } from "./CompanyDialog";

export interface ICompanyFormViewProps extends IFormViewProps<ICompanyExtendedEntity, ICompanyFormCustomData> {
    scrollRef?: React.RefObject<HTMLDivElement>;
    inDialog?: boolean;
}

const oneFetch = getOneFetch();

const POSTAL_CODE_LENGTH = 5;

class CompanyFormView extends FormViewForExtend<ICompanyExtendedEntity, ICompanyFormViewProps> {
    static contextType = DialogContext;

    _communicationAddressBackup: ICompanyCommunicationAddressEntity;
    _isMounted: boolean;

    constructor(props: ICompanyFormViewProps) {
        super(props);
        this.props.storage.setCustomData({ hasPermission: props.permissionContext.generalPermissions.has(GeneralPermissionCode.CompanyManagement) });
    }

    async componentDidMount() {
        super.componentDidMount();
        this._isMounted = true;

        this.props.storage.setFormAlert(null);
        if (this.isReady()) {
            // trigger onAfterLoad manually as the storage is already loaded before we subscribed to onAfterLoad event
            // ! beware, this can be called multiple times, if use switch between avatar selection and company form !
            //      keep that in mind in onAfterLoad handlers
            this.onAfterLoad();
        }
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        this._isMounted = false;
    }

    get postalCodePath(): string {
        return createPath(CompanyEntity.LegalAddress, CompanyLegalAddressEntity.PostalCode);
    }

    setBusy(busy: boolean): void {
        this.context?.setBusy ? this.context.setBusy(busy) : this.props.storage.setBusy(busy);
    }

    onAfterLoad(): Promise<void> {
        this.addLogoToNewCompany();
        this.initiateCompanyStamp();
        this.handleInitialIsSecondaryAddress(this.props);
        this.setCurrentVatStatus();
        this.setCurrentDeductionCoefficient();

        this.props.storage.setValueByPath(BindingContext.localContext("Edition"), "czech");
        this.props.storage.setValueByPath(hasTaxStatementSignatoryPersonPath, !this.props.storage.data.bindingContext.isNew() && !isObjectEmpty(this.entity.TaxStatementSignatoryPerson));

        this.setBusy(false);
        this.props.storage.refresh();
        this.fireFormLoadedEvent();

        return Promise.resolve();
    }

    setCurrentVatStatus = (): void => {
        const { storage } = this.props;

        if ((this.entity.VatStatuses ?? []).length === 0) {
            const vatStatus: ICompanyVatStatusEntity = BindingContext.createNewEntity(1, {
                DateValidFrom: DATE_MIN,
                DateValidTo: DATE_MAX,
                VatStatusCode: VatStatusCode.VATRegistered,
                VatStatus: {
                    Code: VatStatusCode.VATRegistered
                },
                VatStatementFrequencyCode: VatStatementFrequencyCode.Monthly,
                VatStatementFrequency: {
                    Code: VatStatementFrequencyCode.Monthly
                }
            });
            storage.setValueByPath(CompanyEntity.VatStatuses, [vatStatus]);
        }

        this.updateCurrentVatStatusFields();
    };

    setCurrentDeductionCoefficient = (): void => {
        const currentYear = getVatDeductionCoefficientCurrentYear();
        const sortedCoefficients = this.entity.VatReducedCoefficients?.sort((a, b) => b.Year - a.Year) ?? [];
        const currentCoeff = sortedCoefficients.find(coeff =>
                coeff.Year === currentYear
        ) ?? sortedCoefficients?.[0];
        this.props.storage.setValueByPath(VatReducedDeductionCoefficient, currentCoeff?.VatReducedCoefficient);
    };

    addLogoToNewCompany = (): void => {
        const { storage } = this.props;
        const company = this.entity;

        if (company.Logo) {
            return;
        }

        const nextCompanyLogoId = getNextSystemCompanyLogoId(storage.context.getData().companies);

        company.Logo = { Id: nextCompanyLogoId };
    };

    initiateCompanyStamp = (): void => {
        const { storage } = this.props;

        // add stamp url for the ImageUploader if the company already has a stamp uploaded
        // otherwise just clear possible remaining stamp data
        let stampImage: string = null;

        if (this.entity.Stamp?.Id) {
            stampImage = `${FILES_API_URL}/${this.entity.Stamp.Id}`;
        }

        storage.setCustomData({
            stampImage
        });
    };

    handleInitialIsSecondaryAddress = (props: ICompanyFormViewProps): void => {
        // set Companies/IsSecondaryAddress switch based on CommunicationAddress value
        const isSecondaryAddress = !(props.storage.data.bindingContext.isNew() || !this.entity.CommunicationAddress?.City);

        this._communicationAddressBackup = this.entity.CommunicationAddress;
        props.storage.clearAndSetValue(props.storage.data.bindingContext.navigate(companyIsSecondaryAddressPath), isSecondaryAddress);
        if (!isSecondaryAddress) {
            this.entity.CommunicationAddress = {};
        }
    };

    get scrollRef(): React.RefObject<HTMLElement> {
        // inside dialog, we need to use its scroller, not the form one
        return this.props.scrollRef ?? this._refScroll;
    }

    scrollPageDown = (): void => {
        if (this.scrollRef?.current) {
            this.scrollRef.current.scrollTop = this.scrollRef.current.scrollHeight;
        }
    };

    scrollPageUp = (): void => {
        if (this.scrollRef?.current) {
            this.scrollRef.current.scrollTop = 0;
        }
    };

    save = async (): Promise<IFormStorageSaveResult> => {
        this.setBusy(true);
        const { storage } = this.props;

        const { stampImage } = storage.getCustomData();
        if (stampImage instanceof File) {
            this.entity.Stamp = await FileStorage.upload({ file: stampImage });
        } else if (!stampImage) {
            this.entity.Stamp = {};
        }

        const saveData: ICompanyExtendedEntity = cloneDeep(this.entity);
        if (storage.getValueByPath(hasTaxStatementSignatoryPersonPath)) {
            saveData.TaxStatementSignatoryPerson = storage.clearHiddenFields(this.entity.TaxStatementSignatoryPerson, storage.data.bindingContext.navigate(CompanyEntity.TaxStatementSignatoryPerson));
        } else {
            saveData.TaxStatementSignatoryPerson = null;
        }

        if (!saveData.Name) {
            saveData.Name = saveData.LegalName;
        }

        const currentYear = getVatDeductionCoefficientCurrentYear();
        if (saveData.UseVatReducedDeduction) {
            const lastCoeffValue = storage.getValueByPath(VatReducedDeductionCoefficient);
            const lastCoeffEntity = saveData.VatReducedCoefficients?.find(item => item.Year === currentYear);
            if (lastCoeffEntity) {
                lastCoeffEntity.VatReducedCoefficient = lastCoeffValue;
            } else {
                if (!saveData.VatReducedCoefficients) {
                    saveData.VatReducedCoefficients = [];
                }
                saveData.VatReducedCoefficients.push({
                    Year: currentYear,
                    VatReducedCoefficient: lastCoeffValue
                });
            }
        } else {
            saveData.VatReducedCoefficients = saveData.VatReducedCoefficients?.filter(item => item.Year !== currentYear);
        }

        if (!saveData[companyIsSecondaryAddressPath]) {
            // remove partially filled communication address if it is hidden in form
            saveData.CommunicationAddress = null;
        }

        if (saveData.TypeOfBusinessCode === TypeOfBusinessCode.LegalPerson) {
            // LegalPerson doesn't have BirthNumber
            saveData.BirthNumber = null;
        }

        // update VatStatuses
        for (let i = 0; i < saveData.VatStatuses.length; i++) {
            const vatStatus = saveData.VatStatuses[i];

            if (vatStatus.VatStatusCode === VatStatusCode.NotVATRegistered) {
                // in case we change it back from VatPayer, we need to set null to delete the code from DB
                vatStatus.VatStatementFrequencyCode = null;
                vatStatus.VatStatementFrequency = null;
            } else if (vatStatus.VatStatusCode === VatStatusCode.IdentifiedPerson) {
                vatStatus.VatStatementFrequencyCode = VatStatementFrequencyCode.Monthly;
                vatStatus.VatStatementFrequency = {
                    Code: VatStatementFrequencyCode.Monthly
                };
            }
        }

        const isNew = this.props.storage.data.bindingContext.isNew();

        const result = await this.props.storage.save({
            data: saveData,
            successSubtitle: this.props.storage.t("Companies:Validation.Saved"),
            skipLoad: this.props.inDialog,
            // new company is created in a dialog, which should be without error alert
            fieldValidationWithoutErrorAlert: isNew
        });

        if (!result) {
            this.setBusy(false);
            this.forceUpdate(this.scrollPageUp);
            return result;
        }

        this.onAfterSave(isNew, false);

        return result;
    };

    handlePostalCodeChange = async (e: ISmartFieldChange): Promise<void> => {
        try {
            const path = e.bindingContext.getEntityPath(true);
            if ((([CompanyEntity.LegalName, CompanyEntity.LegalNumber] as string[]).includes(path) && e.triggerAdditionalTasks) || path === this.postalCodePath) {
                const storage = this.props.storage;
                const postalCode = path === this.postalCodePath ? removeWhiteSpace(e.value as string) : e.additionalData?.BillingAddress?.PostalCode;
                let FA = null;
                // valid czech postal code has always 5 digits, so we don't have to fetch data otherwise
                if (postalCode?.length === POSTAL_CODE_LENGTH) {
                    const response = await oneFetch.fetch(`${FU_BY_POSTAL_CODE_URL}${postalCode}`);
                    if (!this._isMounted) {
                        return;
                    }
                    if (!response.ok) {
                        // ux wants to clear when you write down bad postal code, or postal code without assigned financial administration
                        logger.error("couldn't fetch financial administration data from postal code");
                    } else {
                        FA = await response.json();
                    }
                }
                storage.setValueByPath(CompanyEntity.FinancialAdministration, FA);
                storage.refreshGroupByKey("Settings");
            }
        } catch (e) {
            if (isAbortException(e)) {
                return;
            } else {
                throw e;
            }
        }
    };

    handleTaxStatementSignatoryPersonChange = (e: ISmartFieldChange): void => {
        const storage = this.props.storage;
        const signatoryPath = createPath(CompanyEntity.TaxStatementSignatoryPerson, TaxStatementSignatoryPersonEntity.SignatoryPerson);
        const path = e.bindingContext.getPath();
        if (path === TaxStatementSignatoryPersonEntity.TaxStatementSubmitter) {
            if (e.value === TaxStatementSubmitterCode.TaxAdvisor) {
                const personTypeCode = storage.getValueByPath(createPath(CompanyEntity.TaxStatementSignatoryPerson, TaxStatementSignatoryPersonEntity.PersonTypeCode));
                storage.setValueByPath(signatoryPath, personTypeCode === TypeOfBusinessCode.LegalPerson ? SignatoryPersonCode._4cLegalEntityProvidingTaxConsultancy : SignatoryPersonCode._4bIndividual_TaxAdvisorOrAttorney);
            }
            if (e.value === TaxStatementSubmitterCode.Accountant) {
                storage.setValueByPath(signatoryPath, SignatoryPersonCode._4aGeneralAttorneyIndividualAndLegalPerson);
            }
            if (e.value === TaxStatementSubmitterCode.Customer) {
                storage.setValueByPath(createPath(CompanyEntity.TaxStatementSignatoryPerson, TaxStatementSignatoryPersonEntity.PersonTypeCode), null);
                storage.setValueByPath(createPath(CompanyEntity.TaxStatementSignatoryPerson, TaxStatementSignatoryPersonEntity.PersonType), null);
                storage.setValueByPath(createPath(CompanyEntity.TaxStatementSignatoryPerson, TaxStatementSignatoryPersonEntity.SignatoryPersonCode), null);
            }
        }

        if ((path === TaxStatementSignatoryPersonEntity.LegalPersonName || e.bindingContext.getPath() === TaxStatementSignatoryPersonEntity.LegalNumber) && e.triggerAdditionalTasks) {
            storage.setValueByPath(createPath(CompanyEntity.TaxStatementSignatoryPerson, TaxStatementSignatoryPersonEntity.LegalPersonName), e.additionalData?.Name);
            storage.setValueByPath(createPath(CompanyEntity.TaxStatementSignatoryPerson, TaxStatementSignatoryPersonEntity.LegalNumber), e.additionalData?.LegalNumber);
        }

        if (e.bindingContext.getNavigationPath(true) === createPath(CompanyEntity.TaxStatementSignatoryPerson, TaxStatementSignatoryPersonEntity.PersonType)) {
            const submitterCode = storage.getValueByPath(createPath(CompanyEntity.TaxStatementSignatoryPerson, TaxStatementSignatoryPersonEntity.TaxStatementSubmitter))?.Code;
            if (submitterCode === TaxStatementSubmitterCode.TaxAdvisor) {
                storage.setValueByPath(signatoryPath, e.value === TypeOfBusinessCode.NaturalPerson ? SignatoryPersonCode._4bIndividual_TaxAdvisorOrAttorney : SignatoryPersonCode._4cLegalEntityProvidingTaxConsultancy);
            }
        }

        if (path === hasTaxStatementSignatoryPersonPath) {
            if (e.value) {
                storage.setValueByPath(createPath(CompanyEntity.TaxStatementSignatoryPerson, TaxStatementSignatoryPersonEntity.TaxStatementSubmitter), TaxStatementSubmitterCode.Accountant);
                storage.setValueByPath(createPath(CompanyEntity.TaxStatementSignatoryPerson, TaxStatementSignatoryPersonEntity.PersonType), TypeOfBusinessCode.NaturalPerson);
                storage.setValueByPath(createPath(CompanyEntity.TaxStatementSignatoryPerson, TaxStatementSignatoryPersonEntity.SignatoryPerson), SignatoryPersonCode._4aGeneralAttorneyIndividualAndLegalPerson);
            } else {
                storage.setValueByPath(CompanyEntity.TaxStatementSignatoryPerson, null);
            }
            storage.refresh();
        }
    };

    handleTypeOfBusinessChange = (e: ISmartFieldChange): void => {
        if (e.bindingContext.getNavigationPath() !== CompanyEntity.TypeOfBusiness) {
            return;
        }

        if (e.value === TypeOfBusinessCode.LegalPerson) {
            // LegalPerson can only have AccountingForBusiness => set it
            this.props.storage.setValueByPath(CompanyEntity.Accounting, AccountingCode.AccountingForBusiness);
        }
    };

    handleChange = async (e: ISmartFieldChange): Promise<void> => {
        this.handlePostalCodeChange(e);

        this.props.storage.handleChange(e);
        this.handleTaxStatementSignatoryPersonChange(e);
        this.handlePartnerChange(e);
        this.handleIsSecondaryAddressChange(e);
        this.handleTypeOfBusinessChange(e);
        this.handleCurrentVatStatusChange(e);
        this.props.storage.refreshFields(e.triggerAdditionalTasks);
    };

    handleBlur = async (e: ISmartFieldBlur): Promise<void> => {
        const { storage } = this.props;

        if (e.bindingContext.getEntityPath(true) === this.postalCodePath &&
                storage.getValue(e.bindingContext)?.length !== POSTAL_CODE_LENGTH) {
            storage.setValueByPath(CompanyEntity.FinancialAdministration, null);
        }

        await storage.handleBlur(e);
        storage.refreshFields();
    };

    handlePartnerChange = (e: ISmartFieldChange): void => {
        const { storage } = this.props;

        const path = e.bindingContext.getPath(true);
        const isLegalNameChange = path === CompanyEntity.LegalName;

        if ((isLegalNameChange || path === CompanyEntity.LegalNumber) && e.triggerAdditionalTasks) {
            for (const partnerField of Object.values(partnerAresFields)) {
                if (e.additionalData.isAllowCreateItem && (path === CompanyEntity.LegalNumber || partnerField.to === CompanyEntity.LegalName)) {
                    // currently changed field -> it was handled by storage->handleChange automatically,
                    // do not need to process additional data
                    // furthermore, it doesn't work well with isAllowCreateItem as it clears the data
                    // (additionalData are empty, format is different - according to fieldName)
                    continue;
                }

                const fieldBc = storage.data.bindingContext.navigate(partnerField.to);
                let value = getNestedValue(partnerField.from, e.additionalData) ?? (e.additionalData?.isAllowCreateItem ? null : undefined);

                if (typeof value === "string") {
                    value = value.trim();
                }

                if (partnerField.to === CompanyEntity.CzNace) {
                    const info = storage.getInfo(fieldBc);
                    if (!info.fieldSettings.items.find(item => item.id === value)) {
                        // CZNace codes are preloaded, if not present, then we should not set the value as it's invalid
                        value = null;
                    }
                }

                if (value !== undefined) {
                    storage.clearAndSetValue(fieldBc, value, true);
                }
            }

            if (storage.data.bindingContext.isNew() && e.additionalData.VatStatus) {
                const vatStatusBc = storage.data.bindingContext.navigate(CompanyEntity.VatStatuses).addKey(this.entity.VatStatuses[0]).navigate(CompanyVatStatusEntity.VatStatus);
                const vatStatus = e.additionalData.VatStatus.Code;

                this.updateVatStatus(vatStatusBc, vatStatus);

                storage.refresh(true);
            }
        }
    };

    handleIsSecondaryAddressChange = (e: ISmartFieldChange): void => {
        if (e.bindingContext.getFullPath() === companyIsSecondaryAddressPath) {
            if (!e.value) {
                this._communicationAddressBackup = this.entity.CommunicationAddress;
                this.entity.CommunicationAddress = {};
            } else {
                this.entity.CommunicationAddress = this._communicationAddressBackup;
            }
        }
    };

    updateVatStatus(fieldBc: BindingContext, value: VatStatusCode): void {
        const { storage } = this.props;

        const additionalData = {
            Code: value,
            Name: getEnumDisplayValue(EntityTypeName.VatStatus, value)
        };
        const defaultFreq = value === VatStatusCode.NotVATRegistered ? null : VatStatementFrequencyCode.Monthly;
        const freqAdditionalData = defaultFreq ? {
            Code: defaultFreq,
            Name: getEnumDisplayValue(EntityTypeName.VatStatementFrequency, defaultFreq)
        } : {};

        if (value !== VatStatusCode.VATRegistered) {
            storage.setValueByPath(CompanyEntity.UseVatReducedDeduction, false);
            storage.setValueByPath(VatReducedDeductionCoefficient, null);
        }

        if (storage.data.bindingContext.isNew()) {
            // for new entity, we update the default row -> there is no temporal dialog
            const currentRange = this.entity.VatStatuses[0];
            currentRange.VatStatus = additionalData;
            currentRange.VatStatusCode = value;
            currentRange.VatStatementFrequencyCode = defaultFreq;
            currentRange.VatStatementFrequency = freqAdditionalData;
        } else {
            // update the current range
            updateTemporalRangesAfterCurrentTempPropChange({
                value,
                bindingContext: fieldBc,
                additionalData
            }, storage, CompanyEntity.VatStatuses);

            // also set default frequency to the temporal data
            updateTemporalRangesAfterCurrentTempPropChange({
                value: defaultFreq,
                bindingContext: fieldBc.getParent().navigate(CompanyVatStatusEntity.VatStatementFrequency),
                additionalData: freqAdditionalData
            }, storage, CompanyEntity.VatStatuses);
        }

        this.updateCurrentVatStatusFields();
    }

    updateCurrentVatStatusFields(): void {
        const { storage } = this.props;
        const current = this.entity.VatStatuses[getCurrentRangeIndex(this.entity.VatStatuses as TTemporal[])];

        storage.setValueByPath(currentVatStatus, current.VatStatusCode);
        storage.setValueByPath(currentVatStatusFrequency, current.VatStatementFrequencyCode);
    }

    handleCurrentVatStatusChange = (e: ISmartFieldChange): void => {
        const { storage } = this.props;
        const path = e.bindingContext.getPath();

        if (![currentVatStatus, currentVatStatusFrequency].includes(path)) {
            return;
        }

        const parentBc = e.bindingContext.getParent().navigate(CompanyEntity.VatStatuses);
        if (path === currentVatStatus) {
            const fieldBc = parentBc.navigate(CompanyVatStatusEntity.VatStatus);
            this.updateVatStatus(fieldBc, e.value as VatStatusCode);
        } else {
            if (storage.data.bindingContext.isNew()) {
                // for new entity, we update the default row -> there is no temporal dialog
                const currentRange = this.entity.VatStatuses[0];
                currentRange.VatStatementFrequencyCode = e.value as VatStatementFrequencyCode;
                currentRange.VatStatementFrequency = {
                    ...e.additionalData
                };
            } else {
                const fieldBc = parentBc.navigate(CompanyVatStatusEntity.VatStatementFrequency);
                updateTemporalRangesAfterCurrentTempPropChange({
                    ...e,
                    bindingContext: fieldBc
                }, storage, CompanyEntity.VatStatuses);
            }
        }

        storage.refresh();
    };

    handleAvatarSelectionClose = (): void => {
        const { storage } = this.props;
        storage.setCustomData({ isAvatarSelectionOpen: false });
        storage.refresh();
    };

    handleFormCancel = () => {
        this.props.storage.reload();
    };

    handleTemporalPropertyDialogClose = (current?: TTemporal<ICompanyVatStatusEntity>): void => {
        if (current) {
            this.props.storage.setValueByPath(currentVatStatus, current.VatStatusCode);
            this.props.storage.setValueByPath(currentVatStatusFrequency, current.VatStatementFrequencyCode);
        }
    };

    handleSmartTemporalPropertyDialogChange(args: ISmartFieldChange): void {
        super.handleSmartTemporalPropertyDialogChange(args);

        if (args.bindingContext.getPath() === CompanyVatStatusEntity.VatStatus) {
            const vatStatementFrequencyBc = args.bindingContext.getParent().navigate(CompanyVatStatusEntity.VatStatementFrequency);

            if (args.value === VatStatusCode.VATRegistered) {
                this.props.storage.setValue(vatStatementFrequencyBc, VatStatementFrequencyCode.Monthly);
            }
        }
    }

    renderForm(): React.ReactElement {
        const { storage } = this.props;
        const { inDialog } = this.props;
        const isComparison = storage?.formMode === FormMode.AuditTrail;

        return (
                <Form style={this.props.style}
                      storage={storage}
                      hideHeader={inDialog}
                      hideBreadcrumbs={inDialog}
                      headerIcons={this.getHeaderIcons()}
                      title={this.getFormTitle()}
                      subtitle={this.getFormSubtitle()}
                      subtitleStatus={this.getFormSubtitleStatus()}

                      refScroll={this._refScroll}
                      refHeader={this._refHeader}
                      refContent={this._refFormContent}

                      {...(isComparison ? generateComparisonProps(this.props) : {})}
                      passRef={composeRefHandlers(this._refView, this.props.passRef)}
                      onGroupExpand={this.handleGroupExpand}
                      onBlur={this.handleBlur}
                      onChange={this.handleChange}
                      onFieldStateChange={this.handleFieldStateChange}
                      onTemporalChange={this.handleTemporalChange}
                      onCancel={this.handleCancel}
                      onRemove={this.handleRemove}
                      onLineItemChange={this.handleLineItemsChange}
                      onLineItemAction={this.handleLineItemsAction}
                      onGroupAction={this.handleGroupAction}
                      onConfirm={this.handleConfirm}
                      {...this.props.formProps}>
                    {!isComparison && !inDialog && this.renderButtonsByContext()}
                    {this.renderTemporalPropertyDialog(this.handleTemporalPropertyDialogClose)}
                </Form>
        );
    }

    render() {
        const { storage } = this.props;

        if (!this.isReady()) {
            return <BusyIndicator isDelayed/>;
        }

        const { inDialog } = this.props;
        const { isAvatarSelectionOpen } = storage?.getCustomData() ?? {};

        return (
                <>
                    {super.render()}
                    {isAvatarSelectionOpen && !inDialog && (
                            <CompanyDialog storage={storage}
                                           onClose={this.handleAvatarSelectionClose}/>
                    )}
                </>
        );
    }
}

export default withPermissionContext(CompanyFormView);
export { CompanyFormView as CompanyFormViewClean };