import { FormStorage, IFormStorageDefaultCustomData } from "../../../views/formView/FormStorage";
import {
    AssetItemEntity,
    EntitySetName,
    IAssetEntity,
    IAssetValueEntity,
    IEntityBase,
    IPaymentDocumentItemEntity,
    ITaxDepreciationCoefficientEntity,
    TaxDepreciationCategoryEntity,
    TaxDepreciationCoefficientEntity,
    UnorganizedAssetEntity
} from "@odata/GeneratedEntityTypes";
import memoizeOne from "../../../utils/memoizeOne";
import DateType, { getUtcDayjs } from "../../../types/Date";
import { ASSET_API_URL, ASSET_TO_EXPENSE_API_URL, EMPTY_VALUE } from "../../../constants";
import i18next from "i18next";
import {
    AssetDisposalTypeCode,
    AssetItemTypeCode,
    AssetTypeCode,
    CbaAssetDisposalTypeCode,
    DepreciationTypeCode
} from "@odata/GeneratedEnums";
import BindingContext, { IEntity, TEntityKey } from "../../../odata/BindingContext";
import { Sort, ValueType } from "../../../enums";
import { IDefinition } from "../../PageUtils";
import { IFormGroupDef } from "@components/smart/smartFormGroup/SmartFormGroup";
import { TValue } from "../../../global.types";
import { formatValue, IFormatOptions, transformToODataString } from "@odata/OData.utils";
import { TCellValue } from "@components/table";
import { getTableIntentLink } from "@components/drillDown/DrillDown.utils";
import { ROUTE_FIXED_ASSET } from "../../../routes";
import { IFilterQuery } from "../../../model/TableStorage";
import { getNestedValue } from "@odata/Data.utils";
import { formatDateToDateString } from "@components/inputs/date/utils";
import { fetchDataAndParseError } from "../Asset.utils";
import { Model } from "../../../model/Model";
import { getCompanyCountryCode } from "../../companies/Company.utils";
import { FixedAssetFormViewAction } from "./FixedAssetDef";
import { isAccountAssignmentCompany } from "@utils/CompanyUtils";
import { OData } from "@odata/OData";
import { getValue } from "@utils/general";
import { ISelectItem } from "@components/inputs/select/Select.types";

export const DisposalAccountPath = BindingContext.localContext("DisposalAccount");
export const DisposalDatePath = BindingContext.localContext("DisposalDate");
export const DisposalReasonPath = BindingContext.localContext("Reason");
export const DamageCompensationAmountPath = BindingContext.localContext("DamageCompensationAmount");

export type TRootAssetItems = Record<number, number[]>;

export interface IFixedAssetCustomData extends IFormStorageDefaultCustomData {
    entityBeforeAction?: IAssetEntity;
    FixedAssetFormViewAction?: FixedAssetFormViewAction;
    AssetValueLimit?: number;
    AssetItemTypeCode?: AssetItemTypeCode;
    rootItems?: TRootAssetItems;
    coefficients?: ITaxDepreciationCoefficientEntity[];
}

export interface IFixedAssetDisposalCustomData extends IFormStorageDefaultCustomData {
    assetStorage?: FormStorage<IAssetEntity, IFixedAssetCustomData>;
}

/**
 * Function returns data for TaxDepreciationPolicy selects - Type, Category and first year of increase
 * @param storage
 */
export async function getTaxDepreciationCoefficients(storage: FormStorage): Promise<ITaxDepreciationCoefficientEntity[]> {
    const { oData } = storage;

    const res = await oData.getEntitySetWrapper(EntitySetName.TaxDepreciationCoefficients).query()
            .select(TaxDepreciationCoefficientEntity.Code, TaxDepreciationCoefficientEntity.Name, TaxDepreciationCoefficientEntity.FirstYearValueIncrease,
                    TaxDepreciationCoefficientEntity.IsSpecialCalculation)
            .expand(TaxDepreciationCoefficientEntity.DepreciationType)
            .expand(TaxDepreciationCoefficientEntity.TaxDepreciationCategory, (query) =>
                    query.select(TaxDepreciationCategoryEntity.Code, TaxDepreciationCategoryEntity.Name, TaxDepreciationCategoryEntity.DateValidFrom,
                            TaxDepreciationCategoryEntity.DateValidTo, TaxDepreciationCategoryEntity.YearsOfDepreciation))
        .fetchData<ITaxDepreciationCoefficientEntity[]>();

    return res?.value || [];
}

export const getTaxDepreciationCoefficientsMemoized = memoizeOne(getTaxDepreciationCoefficients);

export interface IGetTaxDepreciationReturnValue {
    coefficients: ITaxDepreciationCoefficientEntity[];
    Types: DepreciationTypeCode[];
    Categories: ISelectItem[];
    FirstYearValues: ISelectItem[];
}

export async function getTaxDepreciationSelectItems(storage: FormStorage, type: DepreciationTypeCode, category: string, date: Date): Promise<IGetTaxDepreciationReturnValue> {
    const coefficients = await getTaxDepreciationCoefficientsMemoized(storage);

    const Types = new Set<DepreciationTypeCode>();
    const Categories = new Map<string, ISelectItem>();
    const FirstYearValues = new Map<number, ISelectItem>();

    let categoryId = category;

    coefficients.forEach(coeff => {
        const Type = coeff.DepreciationType?.Code as DepreciationTypeCode;
        Types.add(Type);

        const current = getUtcDayjs(date);
        const { DateValidFrom, DateValidTo } = coeff.TaxDepreciationCategory;
        const isValid = date && DateType.isValid(date) && current.isSameOrAfter(DateValidFrom, "date") && (!DateValidTo || current.isSameOrBefore(DateValidTo, "date"));

        if (Type === type && isValid) {
            // Create ISelectItem from TaxDepreciationCategory
            const { Code, Name, YearsOfDepreciation } = coeff.TaxDepreciationCategory;
            const cat: ISelectItem<string> = {
                id: Code,
                label: Name,
                tabularData: [
                    Name,
                    i18next.t("FixedAsset:Form.YearsCnt", { count: YearsOfDepreciation })
                ],
                additionalData: {
                    YearsOfDepreciation
                }

            };
            Categories.set(cat.id, cat);
            if (!categoryId) {
                // if category is not set, we will match firstYearValues to the first category
                categoryId = cat.id;
            }

            if (Code === categoryId) {
                // Create ISelectItem from TaxDepreciationCoefficient
                const inc = coeff.FirstYearValueIncrease;
                const fyv: ISelectItem = {
                    id: inc ?? EMPTY_VALUE,
                    label: inc ? `${inc} %` : i18next.t("Common:Select.NoRecord"),
                    groupId: inc ? "data" : "default",
                    additionalData: {
                        isNoRecord: true
                    }
                };
                FirstYearValues.set(fyv.id as number, fyv);
            }
        }
    });

    return {
        coefficients,
        Types: [...Types.values()],
        Categories: [...Categories.values()],
        FirstYearValues: [...FirstYearValues.values()]
    };
}

function firstCollectionItemValueFormatter(path: string) {
    return (val: TValue, args?: IFormatOptions): TCellValue => {
        const item = args.entity.Items?.[0];
        if (!item) {
            return null;
        }
        args.bindingContext = args.bindingContext.addKey(item);
        const value = getNestedValue(path, item);
        const infoWithoutFormatter = { ...args.info };
        delete infoWithoutFormatter.formatter;
        /**
         * todo: formatValue took Currency from entity in options, which is probably wrong
         *  -> it should consider binding context. Correct this later in formatValue (may break a lot of things)
         **/
        return formatValue(value, infoWithoutFormatter, { ...args, entity: item });
    };
}

/**
 * Adds asset definitions to document definition
 * @param definition
 * @param pathToDocFromAssetItem
 */
export function addAssetDef(definition: IDefinition, pathToDocFromAssetItem?: string): void {
    const entitySet = getValue(definition.entitySet) as EntitySetName;
    // Payment documents has to be linked with assets through their items
    const isPaymentDocument = [EntitySetName.CashReceiptsIssued, EntitySetName.CashReceiptsReceived, EntitySetName.BankTransactions].includes(entitySet);

    const filterPath = pathToDocFromAssetItem ?? AssetItemEntity.Document;
    const fixedAssetTab: IFormGroupDef = {
        id: "Asset",
        title: i18next.t("Document:FormTab.Asset"),
        table: {
            id: `asset_${definition.entitySet}`,
            entitySet: EntitySetName.Assets,
            filter: ({ storage }): IFilterQuery => {
                const { entity } = storage.data ?? {};
                if (isPaymentDocument && !entity?.Items?.some((item: IPaymentDocumentItemEntity) => !!item.LinkedDocument?.Id)) {
                    // in case there is none linked doc, we have to return empty array as in() or in(null) fails
                    return {
                        query: "Id eq null"
                    };
                }
                const ids = isPaymentDocument ? entity?.Items?.map((item: IPaymentDocumentItemEntity) => item.LinkedDocument?.Id).filter((id: TEntityKey) => !!id) : entity?.Id;
                const formattedIds = transformToODataString(ids, ValueType.Number);
                return {
                    query: `Items/any(x: x/${filterPath}/Id in (${formattedIds}))`,
                    collectionQueries: {
                        Items: { query: `${filterPath}/Id in (${formattedIds})` }
                    }
                };
            },
            initialSortBy: [{
                id: "NumberOurs",
                sort: Sort.Asc
            }],
            columns: [
                {
                    id: "Items/DocumentItem/Order",
                    label: i18next.t("FixedAsset:DocumentTable.Order"),
                    formatter: firstCollectionItemValueFormatter("DocumentItem/Order")
                }, {
                    id: "NumberOurs",
                    label: i18next.t("FixedAsset:DocumentTable.NumberOurs"),
                    formatter: (val: TValue, args: IFormatOptions): TCellValue => {
                        if (!args.entity?.Id) {
                            return args.entity?.NumberOurs;
                        }

                        return getTableIntentLink(val as string, {
                            route: `${ROUTE_FIXED_ASSET}/${args.entity.Id}`,
                            context: args.storage.context,
                            storage: args.storage
                        });
                    }
                }, {
                    id: "Name",
                    label: i18next.t("FixedAsset:DocumentTable.Name"),
                }, {
                    id: "Items/ItemType/Name",
                    label: i18next.t("FixedAsset:DocumentTable.Type"),
                    formatter: firstCollectionItemValueFormatter("ItemType/Name")
                }, {
                    id: "Items/Amount",
                    label: i18next.t("FixedAsset:DocumentTable.Amount"),
                    formatter: (val: TValue, args?: IFormatOptions): TCellValue => {
                        const items = args.entity.Items;
                        if (!items?.length) {
                            return null;
                        }
                        const infoWithoutFormatter = { ...args.info };
                        delete infoWithoutFormatter.formatter;
                        args.bindingContext = args.bindingContext.addKey(items[0].Id);
                        let value = 0;
                        for (const item of items) {
                            value += getNestedValue("Amount", item) ?? 0;
                        }
                        return formatValue(value, infoWithoutFormatter, { ...args, entity: items[0] });
                    }
                }
            ]
        }
    };
    // Add fixed asset tab to the def
    const tabsGroup: IFormGroupDef = definition.form.groups.find(group => group.id === "Tabs");
    tabsGroup.tabs.splice(2, 0, fixedAssetTab);
}

export const AssetTranslations = ["FixedAsset", "MinorAsset", "Document", "Components"];

interface IAccrualDisposalParameters {
    AssetId: number;
    Date: string;
    AccrualDisposalParameters?: IDisposeAssetRequestData;
    CbaDisposalParameters?: ICbaDisposalParameters;
}

interface IDisposeAssetRequestData {
    AssetDisposalTypeCode?: AssetDisposalTypeCode;
    DisposalAccountId?: number;
}

interface ICbaDisposalParameters {
    DamageCompensationAmount?: number;
    CbaDisposalTypeCode: AssetDisposalTypeCode;
}

export function disposeAsset(storage: FormStorage, AssetId: number): Promise<boolean | IEntity> {
    const params: IAccrualDisposalParameters = {
        AssetId,
        Date: formatDateToDateString(storage.getValueByPath(DisposalDatePath)),
    };

    if (isAccountAssignmentCompany(storage.context)) {
        params.AccrualDisposalParameters = {
            AssetDisposalTypeCode: storage.getValueByPath(DisposalReasonPath),
            DisposalAccountId: storage.getValueByPath(DisposalAccountPath)
        };
    } else {
        params.CbaDisposalParameters = {
            CbaDisposalTypeCode: storage.getValueByPath("Reason")
        };

        if (storage.getValueByPath("Reason") === CbaAssetDisposalTypeCode.OtherDamage) {
            params.CbaDisposalParameters.DamageCompensationAmount = storage.getValueByPath("DamageCompensationAmount");
        }
    }

    return fetchDataAndParseError(`${ASSET_API_URL}/${AssetItemTypeCode.Disposal}`, params);
}

interface IChangeAssetPriceData {
    AssetId: TEntityKey;
    DocumentItemIds?: number[];
    Date: string;
}

export function changeAssetPrice(type: AssetItemTypeCode, storage: FormStorage<any>, entryIds: number[], AssetId: TEntityKey): Promise<IAssetEntity> {
    const data: IChangeAssetPriceData = {
        DocumentItemIds: entryIds,
        AssetId,
        Date: formatDateToDateString(storage.getValueByPath("Date"))
    };

    return fetchDataAndParseError(`${ASSET_API_URL}/${type}`, data);
}

interface IAddToCostsData {
    DocumentItemIds: number[];
    AccountId: number;
    Date: string;
}

export function addToCosts(DocumentItemIds: number[], AccountId: number, date: Date): Promise<boolean | IEntity> {
    const data: IAddToCostsData = {
        DocumentItemIds,
        AccountId,
        Date: formatDateToDateString(date)
    };

    return fetchDataAndParseError(ASSET_TO_EXPENSE_API_URL, data);
}

const getLowPriceAssetValuesMemoized = memoizeOne(async (storage: Model) => {
    const query = storage.oData.getEntitySetWrapper(EntitySetName.AssetValues)
            .query()
            .filter(`CountryCode eq '${getCompanyCountryCode(storage.context)}'`)
            .select("Code", "Name", "minValue", "DateValidFrom", "DateValidTo")
            .orderBy("DateValidFrom");

    const result = await query.fetchData<IAssetValueEntity[]>();
    return result.value;
}, (storage: Model) => {
    return [getCompanyCountryCode(storage.context)];
});


export const getLowPriceAssetLimit = async (storage: FormStorage<IAssetEntity, IFixedAssetCustomData>, date: Date): Promise<number> => {
    const assetValues = await getLowPriceAssetValuesMemoized(storage);
    const searchDay = getUtcDayjs(date);
    const matchedValue = assetValues.find(val => {
        const from = val.DateValidFrom ?? searchDay;
        const to = val.DateValidTo ?? searchDay;
        return searchDay.isBetween(from, to, "day", "[]");
    });
    const limit = matchedValue?.MinValue ?? 0;
    storage.setCustomData({ AssetValueLimit: limit });
    return limit;
};

export const getLowPriceAssetLimitSync = (storage: FormStorage<IAssetEntity, IFixedAssetCustomData>): number => {
    return storage.getCustomData().AssetValueLimit;
};

interface IAssetCounts {
    [AssetTypeCode.Tangible]?: number;
    [AssetTypeCode.Intangible]?: number;
}

interface IGetAssetCountOptions {
    from?: Date;
    to?: Date;
}

interface IGetAssetCountResult extends IEntityBase {
    AssetTypeCode: AssetTypeCode;
}

export async function getUnorganizedAssetCounts(oData: OData, opts: IGetAssetCountOptions): Promise<IAssetCounts> {
    const filters: string[] = [];
    if (opts.from) {
        filters.push(`${UnorganizedAssetEntity.EntryDate} ge ${transformToODataString(opts.from, ValueType.Date)}`);
    }
    if (opts.to) {
        filters.push(`${UnorganizedAssetEntity.EntryDate} le ${transformToODataString(opts.to, ValueType.Date)}`);
    }

    const filter = filters.join(" and ");
    const query = oData.getEntitySetWrapper(EntitySetName.UnorganizedAssets).query()
        .filter(filter)
        .groupBy(UnorganizedAssetEntity.AssetTypeCode)
        .aggregate("$count as @odata.count");

    const result = await query.fetchData<IGetAssetCountResult[]>();

    const counts: IAssetCounts = {};

    result.value.forEach(val => {
        counts[val.AssetTypeCode] = val._metadata[""].count ?? 0;
    });

    return counts;
}