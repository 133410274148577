import { isDefined } from "@utils/general";
import React from "react";

import { AppContext } from "../../../contexts/appContext/AppContext.types";
import {
    FieldVisibility,
    FormMode,
    GroupedField,
    LabelStatus,
    NavigationSource,
    Sort,
    TextAlign
} from "../../../enums";
import { TValue } from "../../../global.types";
import { FieldAdditionalData, Model } from "../../../model/Model";
import { RefRegistrar } from "../../../model/RefRegistrar";
import BindingContext, { createBindingContext, createPath, IEntity } from "../../../odata/BindingContext";
import memoizeOne from "../../../utils/memoizeOne";
import { FormStorage } from "../../../views/formView/FormStorage";
import { ITableDef } from "../../../views/table/TableView.utils";
import {
    ButtonGroupStyled,
    GroupedKey,
    SmartFieldInHeaderStyled,
    SmartFieldStyled,
    SwitchInHeaderStyled
} from "../../formGroup";
import FormGroup from "../../formGroup/FormGroup";
import { IFormGroupAction } from "../../formGroup/FormGroupActions";
import FieldsWrapper from "../../inputs/field/FieldsWrapper";
import { SwitchType } from "../../inputs/switch/Switch";
import {
    getInfoValue,
    ICustomizationData,
    IFieldDef,
    isVisible,
    TInfoValue,
    TTemporalDialogSettings
} from "../FieldInfo";
import SmartFastEntryList, { ActionType, addEmptyLineItem, ISmartFastEntriesActionEvent } from "../smartFastEntryList";
import { ISmartFieldBlur, ISmartFieldChange } from "../smartField/SmartField";
import { getTooltip } from "../smartField/SmartFieldUtils";
import { IActionItem, SmartActionButton } from "./SmartActionButton";
import { getCollapsedGroupStatus } from "./SmartCollapsedBase";
import SmartCollapsedFormGroup from "./SmartCollapsedFormGroup";
import SmartCollapseToggle from "./SmartCollapseToggle";
import SmartFormGroupBase, { ISmartFormGroupBaseProps } from "./SmartFormGroupBase";

export interface IDependentFieldDef {
    from: IFieldDef;
    to: IFieldDef;
    parseData?: (arg: IEntity) => any;
    navigateFrom?: NavigationSource;
    value?: TValue;
}

export interface ISmartFormGroupActionEvent {
    id: string;
    groupId: string;
}

export interface IFormLineItemsDef {
    /** Used for navigation to items without own EntityType */
    collection: string;
    /** Columns displayed in line item */
    columns: IFieldDef[];
    additionalFields?: IFieldDef[];
    // order is required to make sure, backend will always return the items in the right order,
    // and so that we can use it to manually sort the items in AuditTrail
    order: string;
    orderDirection?: Sort;
    /** Flag if user can manually change the order, e.g. MinorAsset->Items are ordered by transaction date */
    canReorder?: TInfoValue<boolean>;
    /** Indicates whether first item is marked as primary. It displays colored background with title. */
    containsPrimary?: boolean;
    /* Indicates whether the content is collapsible, so only first item is visible at first */
    isCollapsible?: boolean;
    /* Indicates whether the last item can be removed (remove button is not disabled). Disabled by default.
       Last item can be removed on "Type B" line items, which allow to add items from external source (e.g. editable window) */
    isTheOnlyItemRemovable?: boolean;
    /* Indicates if there should be rendered switch in group header, which removes all items at once / adds first item */
    isGroupToggleable?: boolean;
    /* Indicates whether whole lineItems group is readonly (not every single line) */
    isReadOnly?: TInfoValue<boolean>;
    /* Indicates whether particular line item can be removed (so remove action icon is displayed for it) */
    isItemCloneable?: TInfoValue<boolean>;
    isItemRemovable?: TInfoValue<boolean>;
    isItemVisible?: TInfoValue<boolean>;
    isItemDisabled?: TInfoValue<boolean>;
    /*Indicates whether to render checkbox before the FastEntry item. Used to select items for custom action.*/
    isItemSelectable?: TInfoValue<boolean>;
    isDisabled?: TInfoValue<boolean>;
    /* Indicates whether FastEntry list should show add button and add items */
    canAdd?: TInfoValue<boolean>;
    /* Whether the add button should be disabled */
    isAddDisabled?: TInfoValue<boolean>;
    isAddButtonTransparent?: boolean;
    /** For single line only one label line is used. True by default. **/
    useLabelWrapping?: boolean;
    /** Never virtualize items */
    disableVirtualization?: boolean;

    /** Display line number in the beginning of each row*/
    showLineNumbers?: boolean;

    /** Adds icon which will open extended group actions. So far, only used for custom line items actions.*/
    actions?: TSmartFormGroupAction[];
    /** Adds custom buttons next to the "Add" button. Fires "onLineItemsAction" event, like other actions/customAction */
    customActionButtons?: IActionItem[];
    disableFieldsOnActiveAction?: boolean;
    /** Renders custom items summary next to the "Add" button. */
    itemsSummaryRenderer?: TInfoValue<React.ReactElement>;
    /** Visibility is controlled via variant management/config dialog */
    shouldShowItemsSummary?: boolean;

    /** Dialog settings for whole temporal line item */
    temporalDialog?: TTemporalDialogSettings;

    /** Custom renderer for collections */
    render?: (args: IGroupRenderFn) => React.ReactElement;
}


export type TFormTable =
        Omit<ITableDef, "columnDefinition" | "columns">
        & {
    columns: IFieldDef[];
    isSimplified?: boolean;
    preventAutoReset?: boolean;
};
export type TSmartFormGroupAction = (Omit<IFormGroupAction, "isDisabled"> & {
    confirmLabel: string,
    isDisabled?: TInfoValue<boolean>
});

export interface IFormGroupDef {
    id: string;

    title?: TInfoValue<string>;
    tooltip?: ((storage?: FormStorage) => React.ReactElement) | string;
    showGroupDivider?: boolean;

    customizationData?: ICustomizationData;
    /** Removable group renders remove icon. */
    isRemovable?: TInfoValue<boolean>;
    isVisible?: TInfoValue<boolean>;

    tabs?: IFormGroupDef[];

    /** Defines group of fields that are together in one row */
    rows?: (IFieldDef[])[];
    /** If defined, multiple groups are rendered, one for each item in given collection.
     * Other parameters (title, rows...) are reused in the groups. */
    collection?: string;
    /** If collection is used, collectionOrder is required to make sure, backend will always return the items in the right order,
     * and so that we can use it to manually sort the items in AuditTrail
     * */
    collectionOrder?: string;
    /** Use if you need different header for every item in collection  */
    collectionItemTitle?: string | ((index: number, storage: FormStorage) => string);
    /** Defines collection of items rendered as line items */
    lineItems?: IFormLineItemsDef;
    /** todo: remove this exception
     * Sometimes, we use group without label to place collapsedRows on other than last place in a group.
     * In that case, we want the group to have same bottom padding as fields would otherwise have between themselves,
     * instead of the bigger gap that we have between groups. */
    isSmallBottomMargin?: boolean;
    /**
     * If group is toggleable, this should be id of property,
     * which will be set to true / false (switch is displayed in header when configured),
     * Note: property should be added to additionalProperties, so it's definition is correctly load - FormStorage
     *       doesn't work directly with this property */
    togglePropPath?: string;

    // only main table supports customizing (columnDefinition)
    // tabs in tables should have all their settings directly in columns
    table?: TFormTable;

    /* List of action items displayed at the end of the group. Click on the item emits event on storage */
    actions?: IActionItem[];
    /** Alignment of the actions button group. Right by default. */
    actionsAlignment?: TextAlign;
}

interface IProps extends ISmartFormGroupBaseProps {
    isInEdit?: boolean;

    onExpand: (isExpanded: boolean, id: string) => void;
    onRemove?: (bindingContext: BindingContext) => void;
    onLineItemsChange: (args: ISmartFieldChange) => void;
    onLineItemsAction: (args: ISmartFastEntriesActionEvent) => void;
    onGroupAction?: (args: ISmartFormGroupActionEvent) => void;
}

export interface IGroupRenderFn {
    storage: Model;
    props: ISmartFormGroupRenderProps;
    groupId: string;
}

export interface ISmartFormGroupRenderProps extends Pick<IFormLineItemsDef, "useLabelWrapping" | "showLineNumbers" | "isTheOnlyItemRemovable" | "isCollapsible" | "containsPrimary" | "customActionButtons"
        | "isItemVisible" | "isItemRemovable" | "isItemCloneable" | "isItemDisabled" | "isItemSelectable" | "order" | "orderDirection" | "canReorder" | "canAdd" | "columns">, Pick<IProps, "storage"> {
    groupId: string;
    isReadOnly: boolean;
    customAction: TSmartFormGroupAction,
    onCustomActionFinish: () => void;
    customFooterContent: React.ReactNode;
    isLight: boolean;
    bindingContext: BindingContext;
    onBlur: (args: ISmartFieldBlur) => Promise<void>;
    onChange: (args: ISmartFieldChange) => void;
    onAction: (args: ISmartFastEntriesActionEvent) => void;
}

export default class SmartFormGroup extends SmartFormGroupBase<IProps> {
    static contextType = AppContext;

    handleExpand = (isExpanded: boolean, id: string): void => {
        this.props.onExpand?.(isExpanded, id);
        this.forceUpdate();
    };

    renderField = (rows: IFieldDef[], isInOpenGroup: boolean, isCreating?: boolean, collapseToggle?: React.ReactElement): React.ReactElement[] => {
        let multiArray: React.ReactElement[] = [];
        const array: React.ReactElement[] = [];
        let currentArray = array;

        const _isGroupedFieldStart = () => {
            return [GroupedField.MultiStart, GroupedField.NoWrapStart, GroupedField.WrappedMultiStart].includes(groupedField);
        };

        const _isGroupFieldEnd = () => {
            return [GroupedField.MultiEnd, GroupedField.NoWrapEnd, GroupedField.WrappedMultiEnd].includes(groupedField);
        };

        const _addField = (field: IFieldDef, bc: BindingContext, isLast?: boolean) => {
            const isMultiMiddle = !groupedField && isMultiField;
            const addGroupedToggle = isLast && collapseToggle;

            if (_isGroupedFieldStart() || (addGroupedToggle && !isMultiField)) {
                multiArray = [];
                currentArray = multiArray;
                if ([GroupedField.MultiStart, GroupedField.WrappedMultiStart].includes(groupedField)) {
                    isMultiField = true;
                }
            }

            currentArray.push(
                    <SmartFieldStyled
                            _isLast={isLast}
                            key={bc.toString()}
                            bindingContext={bc}
                            style={field.customFieldStyle}
                            storage={this.props.storage}
                            fieldProps={{
                                isSharpRight: [GroupedField.MultiStart, GroupedField.WrappedMultiStart].includes(groupedField) || isMultiMiddle,
                                isSharpLeft: [GroupedField.MultiEnd, GroupedField.WrappedMultiEnd].includes(groupedField) || isMultiMiddle,
                                isLight: !isInOpenGroup && !isCreating
                            }}

                            onConfirm={this.handleConfirm}
                            onCancel={this.handleCancel}
                            onFieldStateChange={this.handleFieldStateChange}
                            onTemporalChange={this.handleTemporalChange}
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                    />
            );

            if (addGroupedToggle) {
                currentArray.push(collapseToggle);
            }

            if ((_isGroupFieldEnd() || addGroupedToggle) && multiArray?.length > 0) {
                const shouldAddGroup = addGroupedToggle || groupedField !== GroupedField.WrappedMultiEnd;
                if (shouldAddGroup) {
                    array.push(<GroupedKey key={"grouped_" + field.id}>{multiArray}</GroupedKey>);
                } else {
                    // no grouping when using WrappedMultiEnd, just use sharp edges on single fields
                    array.push(...multiArray);
                }
                currentArray = array;
                isMultiField = false;
                multiArray = [];
            }
        };
        let groupedField: GroupedField;
        let isMultiField = false;
        for (let i = 0; i < rows.length; i++) {
            const field = this.getFieldDefinition(rows[i]);
            const bc = this.props.bindingContext.navigate(field.id);

            groupedField = getInfoValue(field, "groupedField", {
                bindingContext: bc,
                storage: this.props.storage
            });

            if (field.fieldVisibility && this.props.storage.formMode !== FormMode.AuditTrail) {
                if ((this.props.isInEdit && field.fieldVisibility === FieldVisibility.CreateOnly) ||
                        (!this.props.isInEdit && field.fieldVisibility === FieldVisibility.EditOnly)) {
                    continue;
                }
            }

            // use props bindingContext, not storage bindingContext
            // group can be navigated inside the collection
            if (bc.getParent()?.isCollection()) {
                const path = bc.getPath();
                const values = this.props.storage.getValue(bc.getParent()) || [];
                let newItemsCount = 0;
                for (const expandedField of values) {
                    const key = bc.getKeyPropertyName();
                    const isNew = !expandedField[key];
                    const fieldBc = bc.getParent().addKey(expandedField[key] || newItemsCount++, isNew).navigate(path);

                    _addField(field, fieldBc);
                }

                continue;
            }

            _addField(field, bc, i === rows.length - 1);
        }

        return array;
    };

    // rowsTop -- groups.rows has nested array
    renderFields = (rowsTop: (IFieldDef[])[], inTopLevelGroup: boolean, isCreating = false): React.ReactElement[] => {
        return (rowsTop || []).map((rows, index) => {
            if (!rows || !rows.length) {
                return null;
            }
            const lastField = this.getFieldDefinition(rows[rows.length - 1]);

            const {
                hasVisibleCollapsedFields,
                hasEnabledCollapsedFields
            } = this.getCollapsedFieldsVisibility(lastField);

            const collapseToggle = hasVisibleCollapsedFields && (lastField.collapsedRows || []).length > 0 && inTopLevelGroup && (
                    <SmartCollapseToggle storage={this.props.storage}
                                         key={`collapseGroupToggle-${lastField.id}`}
                                         def={lastField}
                                         isCollapsedGroupDisabled={!hasEnabledCollapsedFields}
                                         onExpand={this.handleExpand}/>
            );

            return (<React.Fragment key={index}>
                <FieldsWrapper style={{ width: "100%", maxWidth: "100%" }}>
                    {this.renderField(rows, inTopLevelGroup, isCreating, collapseToggle)}
                </FieldsWrapper>
                {this.renderCollapsedGroup(lastField, hasVisibleCollapsedFields, !hasEnabledCollapsedFields)}
            </React.Fragment>);
        });
    };

    getCollectionBindingContext = memoizeOne((collection: IFormLineItemsDef) => {
        return this.props.bindingContext.navigate(collection.collection);
    }, () => [this.props.bindingContext.toString()]);

    // toggle switch of toggleable line items
    handleGroupToggle = (checked: boolean) => {
        const { def, storage } = this.props;
        const { id, lineItems } = def;
        const bindingContext = storage.data.bindingContext.navigate(lineItems.collection);
        const affectedItems = storage.getValue(bindingContext) || [];

        // store temporarily removed items to storage, so it's bound to the entity
        const localContextPath = BindingContext.localContext(createPath(id, lineItems.collection, "removedItems"));

        if (checked) {
            // user turns line items group on. It should return back previous items (if set) or one empty item
            const removedItems = storage.getValueByPath(localContextPath) || [];
            if (!removedItems.length) {
                // add new empty item
                const newItem = addEmptyLineItem({
                    storage,
                    bindingContext,
                    newItemsCount: 1,
                    index: 1,
                    order: lineItems.order === "Id" ? null : lineItems.order,
                    columns: lineItems.columns,
                    context: this.context
                });
                removedItems.push(newItem);
            }
            this.props.onLineItemsAction?.({
                bindingContext,
                actionType: ActionType.Add,
                groupId: id,
                items: removedItems,
                affectedItems: removedItems
            });
        } else {
            // closes the group, removes all items, saves them for later restoration if needed
            this.props.onLineItemsAction?.({
                bindingContext,
                actionType: ActionType.Remove,
                groupId: id,
                items: [],
                affectedItems
            });
            // save removed items for later restoration
            storage.setValueByPath(localContextPath, affectedItems);
        }
    }

    renderLineItems = (collection: IFormLineItemsDef): React.ReactElement => {
        const { storage } = this.props;
        const bindingContext = this.getCollectionBindingContext(collection);
        const { selectedOption } = storage.getGroupStatus(this.props.def.id);

        const customAction = selectedOption ? this.props.def.lineItems.actions.find(action => action.id === selectedOption) : null;

        // todo: do we want to support table in audit trail?? If so, remove the last condition above and prepare audit
        //  trail to render changes in table.
        const isAuditTrail = storage.formMode === FormMode.AuditTrail;

        const isReadOnly = !isAuditTrail && (storage.isReadOnly || getInfoValue(collection, "isReadOnly", {
            storage,
            data: storage.data.entity
        }));

        const props = {
            groupId: this.props.def.id,
            useLabelWrapping: collection.useLabelWrapping !== false,
            showLineNumbers: !selectedOption && collection.showLineNumbers,
            isCollapsible: collection.isCollapsible,
            isReadOnly: isReadOnly,
            //TODO: for safety we won't turn this on now due to the lack of time for proper testing.. turn on after release
            //renderAsFastEntryForReadOnly: !(collection.isReadOnly === true || storage.isReadOnly),
            containsPrimary: collection.containsPrimary,
            disableVirtualization: collection.disableVirtualization,
            customAction: customAction,
            onCustomActionFinish: this.handleCustomActionFinish,
            customActionButtons: collection.customActionButtons,
            disableFieldsOnActiveAction: collection.disableFieldsOnActiveAction,
            customFooterContent: collection.shouldShowItemsSummary && getInfoValue(collection, "itemsSummaryRenderer", {
                bindingContext: this.props.bindingContext.navigate(this.props.def.lineItems.collection),
                storage: this.props.storage,
                context: this.context
            }),
            isItemVisible: collection.isItemVisible,
            isItemRemovable: collection.isItemRemovable,
            isItemCloneable: collection.isItemCloneable,
            isItemDisabled: collection.isItemDisabled,
            isItemSelectable: collection.isItemSelectable,
            isDisabled: collection.isDisabled,
            isLight: !!selectedOption,
            storage: this.props.storage,
            bindingContext: bindingContext,
            // when ordered by Id, only use it to fetch from backend, but ignore it here.
            // it would cause problems in SmartFastEntry list, because Id would collide with new items #id
            order: collection.order === "Id" ? null : collection.order,
            orderDirection: collection.orderDirection,
            canReorder: !isAuditTrail && !selectedOption && (collection.canReorder ?? true),
            canAdd: collection.canAdd ?? true,
            isAddDisabled: collection.isAddDisabled,
            isAddButtonTransparent: collection.isAddButtonTransparent,
            isTheOnlyItemRemovable: collection.isTheOnlyItemRemovable,
            isGroupToggleable: collection.isGroupToggleable,
            columns: collection.columns,
            onBlur: this.handleBlur,
            onChange: this.props.onLineItemsChange,
            onAction: this.props.onLineItemsAction
        };

        if (collection.render) {
            return <RefRegistrar
                    bindingContext={bindingContext}
                    storage={this.props.storage}>
                {() => {
                    return collection.render({
                                storage: this.props.storage,
                                props,
                                groupId: this.props.def.id
                            }
                    );
                }}
            </RefRegistrar>;
        }

        return (
                <SmartFastEntryList {...props} />
        );
    };

    getVisibleActions(): IActionItem[] {
        const actions = this.props.def.actions || [];
        const { storage } = this.props;
        return actions.filter(action => {
            const { isDisabled, ...info } = action;
            return isVisible({ info, storage, context: this.props.storage.context });
        });
    }

    handleCustomButtonAction = (id: string): void => {
        this.props.onGroupAction?.({ id, groupId: this.props.def.id });
    };

    isFormGroupOptionDisabled = (action: TSmartFormGroupAction): boolean => {
        if ((typeof action.isDisabled === "boolean" && action.isDisabled) || this.props.bindingContext.isNew()) {
            return true;
        }

        return getInfoValue(action, "isDisabled", {
            bindingContext: this.props.bindingContext.navigate(this.props.def.lineItems.collection),
            storage: this.props.storage,
            context: this.context
        });
    };

    getFormGroupOptions = () => {
        if (this.props.storage.isReadOnly || this.props.storage.formMode === FormMode.AuditTrail) {
            return null;
        }

        return this.props.def.lineItems?.actions?.map((action) => {
            return {
                ...action,
                isDisabled: this.isFormGroupOptionDisabled(action)
            };
        });
    };

    getTitle = (): string => {
        if (isDefined(this.props.title)) {
            return this.props.title;
        }
        return getInfoValue(this.props.def, "title", {
            storage: this.props.storage
        });
    };

    renderActions = (visibleActions: IActionItem[]): React.ReactElement => {
        if (!visibleActions.length || this.props.storage.formMode === FormMode.AuditTrail || this.props.isSimple) {
            return null;
        }
        return (
                <ButtonGroupStyled align={this.props.def.actionsAlignment ?? TextAlign.Right}>
                    {visibleActions.map(action => (
                            <SmartActionButton key={action.id} storage={this.props.storage} action={action}
                                               onClick={this.handleCustomButtonAction}/>))}
                </ButtonGroupStyled>
        );
    };

    getFixedHeaderFieldProps = memoizeOne(() => ({
        labelStatus: LabelStatus.Removed,
        hasPadding: false
    }));

    renderAdditionalHeaderContent = (): React.ReactElement => {
        const { togglePropPath, lineItems } = this.props.def;

        if (togglePropPath) {
            const { storage } = this.props;
            const bc = storage.data.bindingContext.navigate(togglePropPath);
            return (
                    <SmartFieldInHeaderStyled
                            key={bc.toString()}
                            fieldProps={this.getFixedHeaderFieldProps()}
                            bindingContext={bc}
                            storage={storage}
                            onConfirm={this.handleConfirm}
                            onCancel={this.handleCancel}
                            onTemporalChange={this.handleTemporalChange}
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                    />
            );
        }
        if (lineItems?.isGroupToggleable) {
            const { storage } = this.props;
            const items = storage.getValueByPath(lineItems.collection);
            const hasLineItems = !!items?.length;
            return (
                    <SwitchInHeaderStyled checked={hasLineItems}
                                          onChange={this.handleGroupToggle}
                                          type={SwitchType.YesNo}/>
            );
        }
        return null;
    };

    handleGroupRemove = (fullPath: string): void => {
        this.props.onRemove?.(createBindingContext(fullPath, this.props.storage.oData.getMetadata()));
    };

    handleOptionClick = (optionId: string): void => {
        this.props.storage.setGroupStatus({ selectedOption: optionId }, this.props.def.id);
        this.forceUpdate();
    };

    handleCustomActionFinish = (): void => {
        this.props.storage.setGroupStatus({ isMenuOpened: false, selectedOption: null }, this.props.def.id);
        this.forceUpdate();
    };

    handleOptionsButtonToggle = (): void => {
        const { storage } = this.props;
        const groupId = this.props.def.id;
        const { isMenuOpened } = storage.getGroupStatus(groupId);
        storage.setGroupStatus({ isMenuOpened: !isMenuOpened }, groupId);
        this.forceUpdate();
    };

    renderCollapsedGroup(def: IFieldDef, showCollapsedGroup = true, isCollapsedGroupDisabled = false): React.ReactElement {
        if (!def?.collapsedRows?.length) {
            return null;
        }

        const status = getCollapsedGroupStatus(this.props.storage, def);
        const isCreating = status?.isCreating;

        return showCollapsedGroup && (
                <SmartCollapsedFormGroup storage={this.props.storage}
                                         def={def}
                                         hasEditableFields={!isCollapsedGroupDisabled}
                                         onExpand={this.handleExpand}>
                    {this.renderFields(def.collapsedRows, false, isCreating)}
                </SmartCollapsedFormGroup>
        );
    }

    render() {
        const { storage } = this.props;
        let auditTrailData;
        const isAuditTrail = storage.formMode === FormMode.AuditTrail;
        if (isAuditTrail) {
            auditTrailData = storage.getAdditionalFieldData(this.props.bindingContext, FieldAdditionalData.AuditTrailData);
        }

        const hasVisibleFields = this.getFieldsVisibility();
        const visibleActions = this.getVisibleActions();

        // todo: how we can handle this better for audit trail? Do we want to show all fields or only fields, which are
        //  visible at least in one form??
        if (!hasVisibleFields && !isAuditTrail && !visibleActions.length) {
            return null;
        }
        const { isMenuOpened, selectedOption } = storage.getGroupStatus(this.props.def.id);

        return (
                <FormGroup id={this.props.def.id}
                           removeId={this.props.bindingContext.toString()}
                           title={this.getTitle()}
                           auditTrailData={auditTrailData}
                           isRemovable={getInfoValue(this.props.def, "isRemovable", {
                               storage
                           })}
                           isSimple={this.props.isSimple}
                           tooltip={getTooltip(this.props.def.tooltip, storage)}
                           showLine={this.props.def.showGroupDivider ?? true}
                           headerContent={this.renderAdditionalHeaderContent()}
                           options={this.getFormGroupOptions()}
                           optionsDisabled={this.props.storage.data.disabled}
                           selectedOption={selectedOption}
                           isOptionsOpened={isMenuOpened}
                           onOptionsButtonToggle={this.handleOptionsButtonToggle}
                           onOptionClick={this.handleOptionClick}
                           onRemove={this.handleGroupRemove}
                           isSmallBottomMargin={this.props.def.isSmallBottomMargin}>
                    {this.props.def.rows &&
                            <FieldsWrapper style={{ width: "100%" }} isColumn>
                                {this.renderFields(this.props.def.rows, true)}
                            </FieldsWrapper>
                    }
                    {this.props.def.lineItems && this.renderLineItems(this.props.def.lineItems)}
                    {this.renderActions(visibleActions)}
                </FormGroup>
        );
    }
}