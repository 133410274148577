import React from "react";
import { formatCurrency } from "../../types/Currency";
import { ISmartFieldBlur, ISmartFieldChange } from "../smart/smartField/SmartField";
import { FormStorage } from "../../views/formView/FormStorage";
import SmartFastEntryList, {
    ActionButtonPosition,
    ActionType,
    ISmartFastEntriesActionEvent
} from "../smart/smartFastEntryList";
import BindingContext from "../../odata/BindingContext";
import { AccountingBalanceStyled, PairingRow } from "@pages/banks/bankTransactions/BankTransactions.styles";
import i18next from "i18next";
import { ColoredText } from "../../global.style";
import { getTranBalance, IBankCustomData } from "@pages/banks/bankTransactions/BankTransactions.utils";
import { PaymentDocumentItemTypeCode } from "@odata/GeneratedEnums";
import { IPaymentDocumentEntity, IPaymentDocumentItemEntity, PaymentDocumentEntity } from "@odata/GeneratedEntityTypes";
import { getInfoValue, TInfoValue } from "../smart/FieldInfo";
import TestIds from "../../testIds";
import { arrayInsert, roundToDecimalPlaces } from "@utils/general";
import { CBA_CATEGORY_PATH } from "@pages/cashBasisAccounting/CashBasisAccounting.utils";
import { getNewItemsMaxId, setDirtyFlag } from "@odata/Data.utils";
import { EXCHANGE_GAIN_GROUP, isPaymentOrOther, PAYMENT_GROUP } from "@pages/banks/Pair.utils";
import { invalidateItems } from "../smart/smartSelect/SmartSelectAPI";
import { isCashBasisAccountingCompany } from "@utils/CompanyUtils";
import { setNewItemDefaultCategoryData } from "@pages/banks/Payments.utils";
import { FormMode } from "../../enums";
import { correctBpForLinkedDocument } from "@utils/PairTableUtils";

interface IProps {
    bindingContext: BindingContext;
    storage: FormStorage<unknown, IBankCustomData>;
    onChange: (args: ISmartFieldChange) => void;
    onBlur: (args: ISmartFieldBlur) => Promise<void>;
    isDisabled: TInfoValue<boolean>;
    isReadOnly: boolean;
    groupId: string;
    hasAccountAssignment: boolean;
    renderReadOnlyAsTable?: boolean;
}

export class PairingFastEntryList extends React.Component<IProps> {
    handleLineItemsChangeGain = (e: ISmartFieldChange): void => {
        const path = e.bindingContext.getPath();
        if (e.additionalData?.Number && (path === "CreditAccount" || path === "DebitAccount")) {
            e.currentValue = e.additionalData.Number;
        }

        this.handleLineItemsChange(e);
    };

    handleLineItemsChange = (event: ISmartFieldChange): void => {
        if (event.bindingContext.getPath().includes("AccountAssignment")) {
            this.props.storage.setCustomData({
                oldAssSelId: this.props.storage.getValue(event.bindingContext, {
                    useDirectValue: false
                })
            });

            this.props.onChange(event);
        } else {

            this.props.onChange(event);
            (this.props.storage as FormStorage).handleLineItemsChange(event);
        }

        setDirtyFlag(this.props.storage, event.bindingContext);
        this.props.storage.refreshFields();
    };

    handleLineItemsAction = async (e: ISmartFastEntriesActionEvent<IPaymentDocumentItemEntity>) => {
        const storage = this.props.storage;
        const entity = storage.data.entity as IPaymentDocumentEntity;
        const items = storage.getValue(e.bindingContext) as IPaymentDocumentItemEntity[];

        const createdItems: IPaymentDocumentItemEntity[] = [];

        const _appendGains = (linkedDocId?: number) => {

            if (items) {
                const gains = items.filter((item: IPaymentDocumentItemEntity) => {
                    return (item.LinkedDocument?.Id !== linkedDocId || !linkedDocId) && (item.PaymentDocumentItemTypeCode === PaymentDocumentItemTypeCode.ExchangeGain || item.PaymentDocumentItemTypeCode === PaymentDocumentItemTypeCode.ExchangeLoss);
                });

                if (gains.length > 0) {
                    createdItems.push(...gains);
                }
            }
        };

        if (e.actionType === ActionType.Custom && e.customActionType === "pair") {
            this.openDialog();
            return;
        }

        if (e.actionType === ActionType.Remove) {
            const removedBc: BindingContext[] = [];
            e.affectedItems.forEach(item => {
                removedBc.push(e.bindingContext.addKey(item).navigate("AccountAssignmentSelection/AccountAssignment"));
                _appendGains(item.LinkedDocument?.Id);
            });
            invalidateItems(removedBc, storage);

            if (entity.IsUnrelatedToBusiness) {
                storage.setValueByPath(PaymentDocumentEntity.IsUnrelatedToBusiness, false);
            }
        }

        if (e.actionType === ActionType.Add) {
            const [newItem] = e.affectedItems;
            if (isCashBasisAccountingCompany(storage.context)) {
                setNewItemDefaultCategoryData(newItem);
            }

            newItem.PaymentDocumentItemTypeCode = PaymentDocumentItemTypeCode.Other;
            newItem[BindingContext.NEW_ENTITY_ID_PROP] = getNewItemsMaxId(items) + 1;

            const balance = getTranBalance(this.props.storage) || 0;

            if (balance > 0) {
                const tranExRate = entity.ExchangeRatePerUnit ?? 1;

                newItem.TransactionAmount = roundToDecimalPlaces(2, balance);
                newItem.Amount = roundToDecimalPlaces(2, balance * tranExRate);
                setDirtyFlag(this.props.storage, e.bindingContext);
            }

            _appendGains();
        }

        if (e.actionType === ActionType.Reorder || e.actionType === ActionType.Remove) {
            setDirtyFlag(this.props.storage, e.bindingContext);
        }


        (this.props.storage as FormStorage).handleLineItemsAction({
            ...e,
            items: [...e.items, ...createdItems],
            affectedItems: [...e.affectedItems, ...createdItems]
        });

        if (e.actionType === ActionType.Remove) {
            await correctBpForLinkedDocument(this.props.storage, entity, entity.Items);
        }

        this.props.storage.refresh();
    };

    openDialog = (): void => {
        this.props.storage.setCustomData({
            pairedDialogOpened: true
        });
        this.props.storage.refresh();
    };

    handleBlur = async (blurArgs: ISmartFieldBlur): Promise<void> => {
        if (blurArgs.bindingContext.getPath() === "Amount" && blurArgs.wasChanged) {
            (this.props.storage as FormStorage).refreshGroup(blurArgs.bindingContext.getParent());
        }
        this.props.onBlur(blurArgs);
    };

    render() {
        const isDisabled = this.props.storage?.isDisabled || getInfoValue?.(this.props, "isDisabled", {
            storage: this.props.storage,
            bindingContext: this.props.bindingContext
        }) || !!this.props.storage.getBackendDisabledFieldMetadata(this.props.bindingContext);

        let columns = [{
            id: "TransactionAmount"
        }, {
            id: "LinkedDocument/NumberOurs"
        }];

        if (this.props.hasAccountAssignment) {
            columns = arrayInsert(columns, { id: "Amount" }, 1);
            columns = arrayInsert(columns, { id: "AccountAssignmentSelection/AccountAssignment" }, 2);
            columns = arrayInsert(columns, { id: "DateAccountingTransaction" }, 2);
        } else {
            columns = arrayInsert(columns, { id: CBA_CATEGORY_PATH }, 1);
        }

        const customActionButtons = this.props.isReadOnly ? [] : [{
            id: "pair",
            position: ActionButtonPosition.Before,
            isTransparent: false,
            title: i18next.t("Banks:Transactions.PairingTitle"),
            isDisabled
        }];

        return (
            <div>
                <PairingRow data-testid={TestIds.PairingRow}>
                    <PairingListHeader
                        isDisabled={this.props.isDisabled}
                        isReadOnly={this.props.isReadOnly}
                        storage={this.props.storage}
                        bindingContext={this.props.bindingContext}
                    />
                </PairingRow>
                <SmartFastEntryList
                    isDisabled={isDisabled}
                    isReadOnly={this.props.isReadOnly}
                    useLabelWrapping={true}
                    groupId={PAYMENT_GROUP}
                    isCollapsible={false}
                    storage={this.props.storage as FormStorage}
                    bindingContext={this.props.bindingContext}
                    canAdd={true}
                    isAddButtonTransparent
                    isTheOnlyItemRemovable
                    isItemCloneable={false}
                    customActionButtons={customActionButtons}
                    renderAsFastEntryForReadOnly={!(this.props.storage as FormStorage).isReadOnly}
                    columns={columns}
                    filterItems={(items: IPaymentDocumentItemEntity[]) => {

                        return items.filter(item => {
                            return isPaymentOrOther(item.PaymentDocumentItemTypeCode)
                                // in audit trail, empty items can be added into storage, to show missing item on one side
                                // => render all items PaymentDocumentItemTypeCode here, so that they are not missing in audit trail
                                || ((this.props.storage as FormStorage).formMode === FormMode.AuditTrail && !item.PaymentDocumentItemTypeCode);
                        });
                    }}
                    order={null}
                    onBlur={this.handleBlur}
                    onChange={this.handleLineItemsChange}
                    onAction={this.handleLineItemsAction}
                />

                {this.props.hasAccountAssignment && <SmartFastEntryList
                    isDisabled={isDisabled}
                    groupId={EXCHANGE_GAIN_GROUP}
                    isReadOnly={this.props.isReadOnly}
                    useLabelWrapping={true}
                    isCollapsible={false}
                    storage={this.props.storage as FormStorage}
                    bindingContext={this.props.bindingContext}
                    isItemCloneable={false}
                    showChanges={true}
                    isItemRemovable={false}
                    renderAsFastEntryForReadOnly={!(this.props.storage as FormStorage).isReadOnly}
                    order={null}
                    style={{ marginTop: "30px" }}
                    deletedItemsTranslationKey={"Banks:Transactions.DeletedExchangeGain"}
                    columns={[
                        { id: BindingContext.localContext("ExchangeGainTitle") },
                        { id: BindingContext.localContext("GainAmount") },
                        { id: "DateAccountingTransaction" },
                        { id: "AccountAssignmentSelection/AccountAssignment/DebitAccount" },
                        { id: "AccountAssignmentSelection/AccountAssignment/CreditAccount" },
                        { id: "LinkedDocument/NumberOurs" }
                    ]}
                    filterItems={(items: IPaymentDocumentItemEntity[]) => {
                        return items.filter(item => {
                            return (item.PaymentDocumentItemTypeCode === PaymentDocumentItemTypeCode.ExchangeGain || item.PaymentDocumentItemTypeCode === PaymentDocumentItemTypeCode.ExchangeLoss);
                        });
                    }}
                    onBlur={this.handleBlur}
                    onChange={this.handleLineItemsChangeGain}
                    onAction={this.handleLineItemsAction}
                />}
            </div>
        );
    }
}

interface IHeaderProps {
    storage: FormStorage;
    bindingContext: BindingContext;
    isReadOnly: boolean;
    isDisabled: TInfoValue<boolean>;
}

class PairingListHeader extends React.Component<IHeaderProps> {
    constructor(props: IHeaderProps) {
        super(props);

        this.props.storage.addRef(this, this.getThisBc());
    }

    getThisBc = () => {
        return this.props.bindingContext.getParent().navigate(BindingContext.localContext("ItemsHeader"));
    };

    componentWillUnmount() {
        this.props.storage.removeRef(this, this.getThisBc());
    }

    render() {
        const balance = getTranBalance(this.props.storage) || 0;
        const tranCurrency = this.props.storage.data.entity?.TransactionCurrency?.Code ?? this.props.storage.data.entity?.TransactionCurrencyCode;
        const formattedValue = formatCurrency(balance, tranCurrency);

        const color = balance === 0 ? "C_SEM_text_good" : balance < 0 ? "C_SEM_text_bad" : "C_SEM_text_warning";

        return (
            <>
                <label>{`${i18next.t("Banks:Transactions.AccountingBalance")}:`}</label>
                <AccountingBalanceStyled data-testid={TestIds.AccountingBalance}><ColoredText
                    color={color}> {formattedValue}</ColoredText></AccountingBalanceStyled>
            </>
        );
    }
}