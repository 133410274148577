import { getNestedValue } from "@odata/Data.utils";
import { prepareQuery } from "@odata/OData.utils";
import { parseQueryResult } from "@odata/ODataParser";
import { WithOData, withOData } from "@odata/withOData";
import { IDefinition } from "@pages/PageUtils";
import { isDefined, isNotDefined, isObjectEmpty, sortCompareFn } from "@utils/general";
import { logger } from "@utils/log";
import { TFunction } from "i18next";
import { cloneDeep, isEqual } from "lodash";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { AUDIT_API_URL } from "../../constants";
import { AppContext, IAppContext } from "../../contexts/appContext/AppContext.types";
import { FormMode, Sort } from "../../enums";
import { TRecordAny } from "../../global.types";
import { AuditTrailLineComparison, FieldAdditionalData, IAuditTrailData, ModelEvent } from "../../model/Model";
import BindingContext, { IEntity } from "../../odata/BindingContext";
import TestIds from "../../testIds";
import DateType, { DisplayFormat, getUtcDate } from "../../types/Date";
import customFetch, { getDefaultPostParams } from "../../utils/customFetch";
import { FormStorage, IGroupStatus } from "../../views/formView/FormStorage";
import { VIEW_PADDING_VALUE } from "../../views/View.styles";
import { IDialogContext } from "../dialog";
import { AuditTrailFieldType, getInfoValue, IFieldDef } from "../smart/FieldInfo";
import { getCollapsedGroupId } from "../smart/Smart.utils";
import { IFormGroupDef } from "../smart/smartFormGroup/SmartFormGroup";
import {
    LeftPane,
    RightPane,
    ScrollBar,
    ScrollLabelContent,
    StyledAuditTrail,
    StyledFocusBorder,
    StyledRightVersionSelectInAudit,
    StyledVersionSelectInAudit
} from "./AuditTrail.styles";
import { AuditTrailChangeType, IAuditEntity, IAuditEntityAtTime, prepareExpandPaths } from "./AuditTrail.utils";
import { ISelectionChangeArgs, ISelectItem } from "@components/inputs/select/Select.types";


export interface IBasicProps {
    entityType?: string;
    id?: string;
    defaultVersionId: string;
    versions: IAuditEntity[];

    bindingContext?: BindingContext;
    definition?: IDefinition;
    t: TFunction;
}

export interface IProps extends IBasicProps, RouteComponentProps, WithOData {
    dialogContext: IDialogContext;
}

interface IState {
    leftVersions: ISelectItem[];
    rightVersions: ISelectItem[];

    leftVersionId?: string;
    rightVersionId?: string;
}

const FormStyle = {
    overflowY: "hidden",
    marginTop: "12px",
    height: "calc(100% - 100px)",
    padding: `0 ${VIEW_PADDING_VALUE}px`
};

enum MISSING_ROW {
    Right = "right",
    Left = "left"
}

class AuditTrail extends React.Component<IProps, IState> {
    static contextType = AppContext;
    //sadly, breaks typescript type checking
    //context: React.ContextType<typeof AppContext>;

    private _versions: ISelectItem[] = [];

    private leftStorage: FormStorage;
    private rightStorage: FormStorage;

    private _leftFormHtml = React.createRef<any>();
    private _rightFormHtml = React.createRef<any>();

    private _leftForm = React.createRef<any>();
    private _rightForm = React.createRef<any>();

    private _refScroll = React.createRef<HTMLDivElement>();
    private _refFocus = React.createRef<HTMLDivElement>();

    private _hasLineItems = false;

    constructor(props: IProps, context: IAppContext) {
        super(props);

        this.state = this.prepareVersions();

        const initData = {
            id: "companies",
            oData: this.props.oData,
            t: this.props.t,
            context: context
        };

        this.leftStorage = new FormStorage({
            ...initData,
            refresh: () => {
                this._leftForm.current?.forceUpdate(this.refreshScrollbar);
            }
        });

        this.rightStorage = new FormStorage({
            ...initData,
            refresh: () => {
                this._rightForm.current?.forceUpdate(this.refreshScrollbar);
            }
        });

        this.leftStorage.formMode = FormMode.AuditTrail;
        this.rightStorage.formMode = FormMode.AuditTrail;

        // Keep groups in sync
        this.leftStorage.emitter.on(ModelEvent.GroupStatusChanged, this.syncGroupStatus.bind(this, this.rightStorage));
        this.rightStorage.emitter.on(ModelEvent.GroupStatusChanged, this.syncGroupStatus.bind(this, this.leftStorage));
    }

    getContext = () => {
        return this.context as IAppContext;
    };

    getCurrentCompanyId = () => {
        return this.getContext().getCompany().Id;
    };

    syncGroupStatus = (storageToSync: FormStorage, args: { groupStatus: IGroupStatus; id: string; }) => {
        const status = storageToSync.getGroupStatus(args.id);
        if (status?.isExpanded !== args.groupStatus?.isExpanded || status?.activeTab !== args.groupStatus?.activeTab) {
            storageToSync.setGroupStatus(args.groupStatus, args.id);
            storageToSync.refresh();
        }
    };

    async componentDidMount() {
        const _set = async (storage: FormStorage, entity: IEntity, additionalResults: TRecordAny): Promise<void> => {
            storage.data.additionalResults = Array.isArray(additionalResults) ? additionalResults : [additionalResults];
            storage.setEntity(entity);
            storage.loaded = true;
            // we need to await onAfterLoad so that all the data transformations are complete
            await storage.onAfterLoad?.();
        };

        this.props.dialogContext.setBusy(true);

        // we need to call this first, some stuff (f.e. in form's additionalLoadPromise should require some data (f.e. binding context)
        const initPromises = [
            this.leftStorage.initWithoutLoad(this.props.definition.form, this.props.bindingContext),
            this.rightStorage.initWithoutLoad(this.props.definition.form, this.props.bindingContext)
        ];

        await Promise.all(initPromises);

        const promises = [
            this.loadData(this.props.defaultVersionId),
            Promise.all(this.leftStorage.getAdditionalLoadPromise({
                bindingContext: this.props.bindingContext
            }) || []),
            this.loadData(this._versions[0].id as string),
            Promise.all(this.leftStorage.getAdditionalLoadPromise({
                bindingContext: this.props.bindingContext
            }) || [])
        ];

        const [leftEntity, additionalResults, rightEntity, additionalRightResults] = await Promise.all(promises);

        await Promise.all([
            _set(this.leftStorage, leftEntity, additionalResults),
            _set(this.rightStorage, rightEntity, additionalRightResults)
        ]);

        this.compare();
        this.refresh();
        // scrollbar
        this.forceUpdate();
    }

    unifyLineItemsHeight = () => {
        const leftItems = this._leftFormHtml.current?.querySelectorAll(`[data-testid=${TestIds.FastEntryItem}]`);
        const rightItems = this._rightFormHtml.current?.querySelectorAll(`[data-testid=${TestIds.FastEntryItem}]`);

        for (let i = 0; i < (leftItems || []).length; i++) {
            const leftItem = leftItems?.[i];
            const rightItem = rightItems?.[i];
            if (leftItem && rightItem) {
                const diff = leftItem.offsetHeight - rightItem.offsetHeight;

                if (diff !== 0) {
                    const elem = diff < 0 ? leftItem : rightItem;
                    elem && (elem.style.marginBottom = `${Math.abs(diff)}px`);
                }
            }
        }
    };

    refresh = () => {
        this.leftStorage.refresh();
        this.rightStorage.refresh();

        this._hasLineItems && this.unifyLineItemsHeight();
    };

    refreshScrollbar = () => {
        // form does some crazy $#@ so far we need dirty timeout so calculation does not trigger to early
        // hopefully we fix it in the future
        // the higher number means we have to wait till collapse of groups ends
        setTimeout(() => {
            let leftHeight = 0;
            let rightHeight = 0;

            if (this._leftFormHtml?.current) {
                const styles = getComputedStyle(this._leftFormHtml.current);

                leftHeight += this._leftFormHtml.current.scrollHeight;
                leftHeight += parseInt(styles.marginTop) + parseInt(styles.marginBottom);
            }

            if (this._rightFormHtml?.current) {
                const styles = getComputedStyle(this._rightFormHtml.current);

                rightHeight += this._rightFormHtml.current.scrollHeight;
                rightHeight += parseInt(styles.marginTop) + parseInt(styles.marginBottom);
            }

            const height = Math.max(leftHeight, rightHeight);

            if (height && this._refScroll.current) {
                this._refScroll.current.style.height = `${height}px`;
                this.props.dialogContext.setBusy(false);
            }
        }, 400);
    };

    prepareVersions = () => {
        this._versions = (this.props.versions).map((item: IAuditEntity): ISelectItem => {
            const date = getUtcDate(item.ChangedOn);
            const formattedDay = DateType.localFormat(date);
            const formattedTime = DateType.localFormat(date, DisplayFormat.TimeShort);

            return {
                id: item.ChangedOn,
                tabularData: [formattedDay, formattedTime, item.AuthorName, this.props.t(`Audit:Type:${item.Type}`)],
                isDisabled: item.Type === AuditTrailChangeType.Attachment
            };
        }).reverse();

        const leftVersionId = this.props.defaultVersionId as string;
        const rightVersionId = this._versions[0]?.id as string;

        return {
            leftVersions: this.disableVersion(rightVersionId),
            rightVersions: this.disableVersion(leftVersionId),
            leftVersionId,
            rightVersionId
        };
    };

    setAuditTrailDataToField = (bc: BindingContext, data: IAuditTrailData) => {
        this.leftStorage.setAdditionalFieldData(bc, FieldAdditionalData.AuditTrailData, data);
        this.rightStorage.setAdditionalFieldData(bc, FieldAdditionalData.AuditTrailData, data);
    };

    getValue = (storage: FormStorage, bc: BindingContext) => {
        let val = storage.getValue(bc, { useDirectValue: false });
        // for multiselects and checkbox groups they have navigation but still we need all values not ID
        // this is attempt to solve this issue without direct if selection --- subject to change
        if (isNotDefined(val)) {
            val = storage.getValue(bc);
        }

        return val;
    };

    compareArrayFields = (bc: BindingContext, field: IFieldDef, leftArray: TRecordAny[], rightArray: TRecordAny[]) => {
        const info = this.leftStorage.getInfo(bc);
        const idName = bc.getKeyPropertyName();
        const count = Math.max((leftArray || []).length, (rightArray || []).length);
        const missmatch: Record<string, boolean> = {};

        for (let i = 0; i < count; i++) {
            const left = leftArray[i];
            const right = rightArray[i];
            const leftId = left?.[idName];
            const rightId = right?.[idName];
            const path = info?.fieldSettings?.keyPath || idName;

            if (!left) {
                missmatch[getNestedValue(path, right)] = true;
            } else if (!right) {
                missmatch[getNestedValue(path, left)] = true;
            } else if (leftId !== rightId) {
                const leftFound = leftArray.find(item => item[idName] === rightId);
                if (!leftFound) {
                    missmatch[getNestedValue(path, right)] = true;
                }

                const rightFound = rightArray.find(item => item[idName] === leftId);
                if (!rightFound) {
                    missmatch[getNestedValue(path, left)] = true;
                }
            }
        }

        if (Object.keys(missmatch).length > 0) {
            this.setAuditTrailDataToField(bc, {
                type: AuditTrailFieldType.Difference,
                missmatchedPaths: missmatch
            });

            return false;
        } else {
            this.setAuditTrailDataToField(bc, {
                type: AuditTrailFieldType.NoDifference
            });
        }

        return true;
    };

    compareValues = (bc: BindingContext, field: IFieldDef, left: unknown, right: unknown) => {
        if (Array.isArray(left) || Array.isArray(right)) {
            return this.compareArrayFields(bc, field, left as TRecordAny[], right as TRecordAny[]);
        }

        return this.compareSingleValue(bc, left, right);
    };

    storeComparisonValue = (bc: BindingContext, isSame: boolean) => {
        this.setAuditTrailDataToField(bc, {
            type: isSame ? AuditTrailFieldType.NoDifference : AuditTrailFieldType.Difference
        });

        return isSame;
    };

    compareSingleValue = (bc: BindingContext, left: unknown, right: unknown) => {
        const isObjects = (typeof left === "object" || left === undefined)
            && (typeof right === "object" || right === undefined);
        let isSame: boolean;

        if (isObjects
            && isObjectEmpty(left as object) && isObjectEmpty(right as object)
            && !(left instanceof Date) && !(right instanceof Date)) {
            // it seems that sometimes left is null and right is empty object
            // => still the same for audit trail comparison
            isSame = true;
        } else {
            isSame = isEqual(left, right);
        }

        const info = this.leftStorage.getInfo(bc);

        // if unit is changed, whole field is supposed to be shown as changed
        // https://solitea-cz.atlassian.net/browse/DEV-27594
        if (info?.fieldSettings?.unit) {
            const leftUnit = getInfoValue(info.fieldSettings, "unit", {
                bindingContext: bc,
                storage: this.leftStorage,
                data: this.leftStorage.data.entity,
                context: this.getContext()
            });
            const rightUnit = getInfoValue(info.fieldSettings, "unit", {
                bindingContext: bc,
                storage: this.rightStorage,
                data: this.rightStorage.data.entity,
                context: this.getContext()
            });

            isSame = isSame && leftUnit === rightUnit;
        }

        return this.storeComparisonValue(bc, isSame);
    };

    compareGroup = (group: IFormGroupDef) => {
        if (group.collection) {
            this.compareCollectionGroup(group);
            return;
        }

        this.compareRows(group.rows, group);

        if (group.togglePropPath) {
            const bc = this.leftStorage.data.bindingContext.navigate(group.togglePropPath);
            this.compareField(bc, { id: group.togglePropPath });
        }

        if (group.tabs) {
            group.tabs.forEach(tab => this.compareGroup(tab));
        }

        if (group.lineItems) {
            this.compareLineItems(group);
        }
    };

    compareField = (bc: BindingContext, field: IFieldDef) => {
        const info = this.leftStorage.getInfo(bc);

        if (info?.comparisonFunction) {
            const result = info.comparisonFunction(this.leftStorage.data.entity, this.rightStorage.data.entity, bc);
            return this.storeComparisonValue(bc, result);
        }

        const leftVal = this.getValue(this.leftStorage, bc);
        const rightVal = this.getValue(this.rightStorage, bc);

        return this.compareValues(bc, field, leftVal, rightVal);
    };

    compareRows = (rows: (IFieldDef[])[], group: IFormGroupDef, groupBc = this.props.bindingContext, collapsibleParentId?: string) => {
        for (const row of rows || []) {
            for (const column of row) {
                const field = column as IFieldDef;
                const bc = groupBc.navigate(field.id);
                const result = this.compareField(bc, field);

                if (!result && collapsibleParentId) {
                    const def = this.props.definition.form?.fieldDefinition?.[collapsibleParentId] as IFieldDef;

                    if (def) {
                        const groupId = getCollapsedGroupId({
                            ...def,
                            id: collapsibleParentId
                        });

                        const groupStatus: IGroupStatus = {
                            isExpanded: true
                        };

                        this.leftStorage.setGroupStatus(groupStatus, groupId);
                        this.rightStorage.setGroupStatus(groupStatus, groupId);
                    }
                }

                const collapsedRows = this.props.definition.form?.fieldDefinition?.[column.id]?.collapsedRows;

                if (collapsedRows) {
                    this.compareRows(collapsedRows, group, groupBc, column.id);
                }
            }
        }
    };

    // todo: this is just a proposal not working great so far --- consider diff by dates and lines ?
    compareCollectionGroup = (group: IFormGroupDef) => {
        const collectionBc = this.props.bindingContext.navigate(group.collection);
        const idName = collectionBc.getKeyPropertyName();

        const leftItems: IEntity[] = (this.leftStorage.getValue(collectionBc) || []);
        const rightItems: IEntity[] = (this.rightStorage.getValue(collectionBc) || []);
        const orderProp = group.collectionOrder;

        leftItems.sort((a, b) => sortCompareFn(a[orderProp], b[orderProp], group.collectionOrder === Sort.Desc ? Sort.Desc : Sort.Asc));
        rightItems.sort((a, b) => sortCompareFn(a[orderProp], b[orderProp], group.collectionOrder === Sort.Desc ? Sort.Desc : Sort.Asc));

        const leftItemsOrigin = [...leftItems];
        const rightItemsOrigin = [...rightItems];

        const count = Math.max(leftItemsOrigin.length, rightItemsOrigin.length);

        for (let i = 0; i < count; i++) {
            const leftRow = leftItemsOrigin[i];
            const rightRow = rightItemsOrigin[i];

            // first check equality
            const leftId = leftRow?.[idName];
            const rightId = rightRow?.[idName];

            if (leftId === rightId) {
                // just compare fields and set proper order
                const groupBc = collectionBc.addKey(leftId);

                this.compareRows(group.rows, group, groupBc);
            } else if (rightId && leftId) {
                const matchLeftItem = leftItems.find((item: TRecordAny) => item[idName] === rightId);
                const matchRightItem = rightItems.find((item: TRecordAny) => item[idName] === leftId);
                if (matchLeftItem && matchRightItem) {
                    //todo: what to do, what to do ???
                    // can this even happen?
                } else if (!matchRightItem) {
                    this.addRow(collectionBc.addKey(leftId), rightItems, leftRow, this.leftStorage, this.rightStorage, i);
                } else if (!matchLeftItem) {
                    this.addRow(collectionBc.addKey(rightId), leftItems, rightRow, this.rightStorage, this.leftStorage, i);
                }
            } else if (!rightId) {
                // no ID found on the left size add empty row to the left size
                this.addRow(collectionBc.addKey(leftId), rightItems, leftRow, this.leftStorage, this.rightStorage, i);

            } else if (!leftId) {
                // no ID found on the left size add empty row to the left size
                this.addRow(collectionBc.addKey(rightId), leftItems, rightRow, this.rightStorage, this.leftStorage, i);
            }
        }
    };

    compareLineItems = (group: IFormGroupDef) => {
        const addOrderData = (storage: FormStorage, bc: BindingContext, order: number, orderChanged: boolean) => {
            const data = storage.getAdditionalFieldData(bc, FieldAdditionalData.AuditTrailData);
            storage.setAdditionalFieldData(bc, FieldAdditionalData.AuditTrailData, {
                ...data,
                lineData: {
                    ...data?.lineData,
                    order: order,
                    orderChanged
                }
            });
        };

        const compareRow = (rowBc: BindingContext) => {
            for (const col of columns) {
                const colBc = rowBc.navigate(col.id);
                this.compareField(colBc, col);
            }
        };

        const sortByOrder = (a: IEntity, b: IEntity) => {
            return a[orderName] - b[orderName];
        };

        const filterSoloItems = (sourceArray: TRecordAny[], row: TRecordAny) => {
            const id = row?.[idName];
            const item = sourceArray.find((item: TRecordAny) => item[idName] === id);
            if (!item) {
                soloItems[id] = sourceArray === rightItemsOrgin ? MISSING_ROW.Right : MISSING_ROW.Left;
            }

            return !!item;
        };

        this._hasLineItems = true;

        const name = group.lineItems.collection;
        const itemsBc = this.props.bindingContext.navigate(name);
        const idName = itemsBc.getKeyPropertyName();
        const columns = group.lineItems.columns;

        const orderName = group.lineItems.order;

        const leftItemsOrgin: IEntity[] = (this.leftStorage.getValue(itemsBc) || []).sort(sortByOrder);
        const rightItemsOrgin: IEntity[] = (this.rightStorage.getValue(itemsBc) || []).sort(sortByOrder);

        let count = Math.max(leftItemsOrgin.length, rightItemsOrgin.length);

        const soloItems: TRecordAny = {};

        // three steps:
        // A. filter out all items that has not matchind ID pair on the opposite side
        // B. put missing items to the corresponding position but ensure they are on the same row
        // C. compare items with matching position, set missing(adding) row to missing items and set correct order
        const leftItems = leftItemsOrgin.filter((row: TRecordAny) => {
            return filterSoloItems(rightItemsOrgin, row);
        });

        const rightItems = rightItemsOrgin.filter((row: TRecordAny) => {
            return filterSoloItems(leftItemsOrgin, row);
        });

        let putIndex = 0;
        // add missing rows to corresponding position
        for (let i = 0; i < count; i++) {
            const leftRow = leftItemsOrgin[i];
            const rightRow = rightItemsOrgin[i];
            const leftId = leftRow?.[idName];
            const rightId = rightRow?.[idName];
            const index = i + putIndex;


            if (soloItems[leftId]) {
                leftItems.splice(index, 0, leftRow);
                // add empty item (its missing, so it shouldn't have any value)
                // but add the id, so that the green line around is correctly rendered
                rightItems.splice(index, 0, { Id: leftRow.Id });
                putIndex++;
            }

            if (soloItems[rightId]) {
                leftItems.splice(index, 0, { Id: rightRow.Id });
                rightItems.splice(index, 0, rightRow);
                putIndex++;
            }
        }

        count = Math.max(leftItems.length, rightItems.length);
        // either compare rows and add flag with result for each field
        // or mark row missing (and oposit row complementing)
        for (let i = 0; i < count; i++) {
            const leftRow = leftItems[i];
            const rightRow = rightItems[i];
            const leftId = leftRow?.[idName];
            const rightId = rightRow?.[idName];

            const rightRowBc = itemsBc.addKey(rightId);

            if (!soloItems[rightId]) {
                compareRow(rightRowBc);
            } else {
                const isLeft = soloItems[rightId] === MISSING_ROW.Left;
                const addingStorage = isLeft ? this.rightStorage : this.leftStorage;
                const missingStorage = isLeft ? this.leftStorage : this.rightStorage;

                addingStorage.setAdditionalFieldData(rightRowBc, FieldAdditionalData.AuditTrailData, { lineData: { type: AuditTrailLineComparison.AdditionalRow } });
                missingStorage.setAdditionalFieldData(rightRowBc, FieldAdditionalData.AuditTrailData, { lineData: { type: AuditTrailLineComparison.MissingRow } });
            }

            const orderHasChanged = leftId !== rightId;

            if (orderName !== "Id") {
                // idk is this is fully correct,
                // but if order  is set to Id, it breaks if we change to another random value
                rightRow[orderName] = i;
                leftRow[orderName] = i;
            }

            addOrderData(this.rightStorage, rightRowBc, i + 1, orderHasChanged);
            addOrderData(this.leftStorage, rightRowBc, i + 1, orderHasChanged);
        }

        this.leftStorage.setValue(itemsBc, leftItems);
        this.rightStorage.setValue(itemsBc, rightItems);

    };

    getVersionSelectText = (id: string) => {
        if (this._versions) {
            const version = this._versions.find(item => item.id === id);
            if (version) {
                const data = version.tabularData;
                return `${data[0]}, ${data[1]}, ${data[2]}`;
            }
        }

        return "";
    };

    /** one liner - hence no obj as argument */
    addRow = (bc: BindingContext, items: TRecordAny[], row: TRecordAny, addingStorage: FormStorage, missingStorage: FormStorage, index?: number) => {
        const order = isDefined(index) ? index + 1 : items.length + 1;

        isDefined(index) ? items.splice(index, 0, row) : items.push(row);
        addingStorage.setAdditionalFieldData(bc, FieldAdditionalData.AuditTrailData, {
            lineData: {
                order: order,
                type: AuditTrailLineComparison.AdditionalRow
            }
        });

        missingStorage.setAdditionalFieldData(bc, FieldAdditionalData.AuditTrailData, {
            lineData: {
                order: order,
                type: AuditTrailLineComparison.MissingRow
            }
        });
    };

    clearStorage = (storage: FormStorage) => {
        for (const [key, obj] of Object.entries(storage.data.additionalFieldData)) {
            if (obj[FieldAdditionalData.AuditTrailData]) {
                delete obj[FieldAdditionalData.AuditTrailData];
            }
        }
    };

    clear = () => {
        this._hasLineItems = false;

        this.clearStorage(this.leftStorage);
        this.clearStorage(this.rightStorage);
    };

    compare = () => {
        this.clear();
        const groups = this.props.definition.form.groups;
        const additionalProperties = this.props.definition.form.additionalProperties?.filter(prop => prop.useForComparison) ?? [];
        for (const field of additionalProperties) {
            const bc = this.props.bindingContext.navigate(field.id);
            this.compareField(bc, field);
        }
        for (const group of groups) {
            this.compareGroup(group);
        }
    };

    disableVersion = (id: string) => {
        return this._versions.map(version => ({
            ...version,
            isDisabled: version.isDisabled || version.id === id
        }));
    };

    loadData = async (id: string) => {
        // get expand values and convert it to object backends expects
        const columns = this.leftStorage.convertMergedDefToColumns(this.leftStorage.data.mergedDefinition);

        const query = prepareQuery({
            oData: this.props.oData,
            bindingContext: this.props.bindingContext,
            fieldDefs: columns
        });

        const paths: string[] = prepareExpandPaths(query);

        const response = await customFetch(`${AUDIT_API_URL}/${this.props.entityType}/${this.props.id}/${id}`, {
            ...getDefaultPostParams(),
            body: JSON.stringify({
                IncludePaths: paths
            })
        });

        if (!response.ok) {
            logger.error("couldn't fetch from AUDIT API");
            return null;
        }

        const data = await response.json() as IAuditEntityAtTime;
        // parse the response, otherwise Dates are represented as strings => error
        const parsedData = parseQueryResult(data.Entity, this.props.oData.getMetadata(), this.props.bindingContext.getFullPath(true), true);

        return parsedData.value;
    };

    reloadData = async (id: string, storageChanging: FormStorage, storageStable: FormStorage) => {
        this.props.dialogContext.setBusy(true);

        const storageStableEntity = cloneDeep(storageStable.data.origEntity);

        const promises = [
            this.loadData(id),
            // we need to reset entity in not changing side
            // line comparison add some comparison stuff directly to the entity so this reset it
            storageStable.initWithoutLoad(this.props.definition.form, this.props.bindingContext),
            storageChanging.initWithoutLoad(this.props.definition.form, this.props.bindingContext)
        ];

        const [entity] = await Promise.all(promises);
        storageChanging.setEntity(entity);
        await storageChanging.onAfterLoad?.();

        storageStable.setEntity(storageStableEntity);
        await storageStable.onAfterLoad?.();

        this.compare();
        this.refresh();
    };

    handleLeftChange = async (e: ISelectionChangeArgs) => {
        const id = e.value as string;
        this.setState({
            rightVersions: this.disableVersion(id),
            leftVersionId: id
        });

        this.reloadData(id, this.leftStorage, this.rightStorage);
    };

    handleRightChange = async (e: ISelectionChangeArgs) => {
        const id = e.value as string;
        this.setState({
            leftVersions: this.disableVersion(id),
            rightVersionId: id
        });

        this.reloadData(id, this.rightStorage, this.leftStorage);
    };

    handleScroll = (e: React.UIEvent<HTMLElement>) => {
        const top = (e.target as HTMLElement).scrollTop;
        this.setNewTop(top);
    };

    setNewTop = (top: number) => {
        if (this._leftFormHtml.current) {
            this._leftFormHtml.current.scrollTop = top;
        }

        if (this._rightFormHtml.current) {
            this._rightFormHtml.current.scrollTop = top;
        }
    };

    handleWheel = (e: React.WheelEvent) => {
        const diff = e.deltaY / 2;
        if (this._refScroll.current) {
            const scrollParent = this._refScroll.current.parentNode as HTMLDivElement;
            const newTop = scrollParent.scrollTop + diff;
            this.setNewTop(newTop);
            scrollParent.scrollTop = newTop;
        }
    };

    render() {
        const Form = this.props.definition.form.formControl;
        return (
            <StyledAuditTrail onWheel={this.handleWheel} data-testid={TestIds.AuditTrail}>
                <LeftPane>
                    {this.leftStorage.loaded &&
                        <StyledVersionSelectInAudit
                            text={this.getVersionSelectText(this.state.leftVersionId)}
                            value={this.state.leftVersionId}
                            items={this.state.leftVersions}
                            onChange={this.handleLeftChange}/>
                    }
                    <Form
                        style={FormStyle}
                        passRef={this._leftFormHtml}
                        storage={this.leftStorage}
                        ref={this._leftForm}
                    />
                </LeftPane>
                {this.leftStorage.loaded && this.rightStorage.loaded &&
                    <ScrollBar
                        tabIndex={0}
                        onScroll={this.handleScroll}>
                        <ScrollLabelContent ref={this._refScroll}/>
                    </ScrollBar>
                }
                <StyledFocusBorder ref={this._refFocus}/>

                <RightPane>
                    {this.rightStorage.loaded &&
                        <StyledRightVersionSelectInAudit
                            text={this.getVersionSelectText(this.state.rightVersionId)}
                            value={this.state.rightVersionId}
                            items={this.state.rightVersions}
                            onChange={this.handleRightChange}/>
                    }
                    <Form
                        style={FormStyle}
                        passRef={this._rightFormHtml}
                        storage={this.rightStorage}
                        ref={this._rightForm}
                    />
                </RightPane>
            </StyledAuditTrail>
        );
    }
}

export default withOData(withRouter(AuditTrail));