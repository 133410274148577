import { WithBusyIndicator, withBusyIndicator } from "@components/busyIndicator/withBusyIndicator";
import { ConditionType, createFilterRow } from "@components/conditionalFilterDialog/ConditionalFilterDialog.utils";
import { getIntentLink } from "@components/drillDown/DrillDown.utils";
import SelectionTableViewBase, { THeaderDataGetter } from "@components/smart/SelectionTableViewBase";
import { getRow } from "@components/smart/smartTable/SmartTable.utils";
import { IRow, TId } from "@components/table";
import {
    EntityTypeName,
    IDocumentEntity,
    IDocumentLinkEntity,
    IInvoiceIssuedEntity,
    IInvoiceReceivedEntity
} from "@odata/GeneratedEntityTypes";
import { ClearedStatusCode, DocumentLinkTypeCode, DocumentTypeCode, PostedStatusCode } from "@odata/GeneratedEnums";
import { transformToODataString } from "@odata/OData.utils";
import { PropertyTranslationCache } from "@odata/PropertyTranslationCache";
import { DocumentStatusLocalPath, getStatusFilterId } from "@pages/reports/CommonDefs";
import { isAccountAssignmentCompany, isCashBasisAccountingCompany } from "@utils/CompanyUtils";

import { DASH_CHARACTER } from "../../../../constants";
import { RowAction, Status, ValueType } from "../../../../enums";
import BindingContext from "../../../../odata/BindingContext";
import { ROUTE_BUSINESS_PARTNER } from "../../../../routes";
import { formatCurrency } from "../../../../types/Currency";
import DateType from "../../../../types/Date";
import { ITableViewBaseProps } from "../../../../views/table/TableView";
import { DOCUMENT_CLEARING_FORM_TAB_ID, isReceived, setNotPostedStatusFilter } from "../../Document.utils";
import { IProformaEntity } from "../../proformaInvoices/ProformaInvoice.utils";
import {
    createProformaDocumentLink,
    fetchAndApplyProformaInvoice,
    findProformaDocumentLink,
    loadRelatedProformas,
    PROFORMA_FORM_TAB,
    RELATED_PROFORMA_ADDITIONAL_RESULT_IDX
} from "./Proforma.utils";
import { NOT_PAIRED_PROFORMAS_LOCAL_FILTER_PATH } from "./ProformaDef";


export interface IProps extends ITableViewBaseProps, WithBusyIndicator {
    onConfirm?: () => void;
}

type IInvoiceEntity = IInvoiceReceivedEntity | IInvoiceIssuedEntity;

class PairingProformaTableView extends SelectionTableViewBase<IProps> {
    _originalLinks: IDocumentLinkEntity[];

    get title(): string {
        return this.props.rootStorage.t(this.props.rootStorage.data.definition.title);
    }

    get secondaryTitle(): string {
        return this.props.storage.data.definition.title;
    }

    get selectionConfirmText(): string {
        return this.props.storage.t("Proforma:Pairing.PairActionText");
    }

    get headerData(): THeaderDataGetter {
        const bc = this.props.rootStorage.data.bindingContext;
        const entity = this.props.rootStorage.data.entity as IInvoiceEntity;
        const numberOursBc = bc.navigate("NumberOurs");
        const datePath = (this.isExpense ? "DateReceived" : "DateIssued") as keyof IInvoiceEntity;
        const emptyValue = DASH_CHARACTER;
        const transactionCurrency = entity.TransactionCurrencyCode ?? entity.TransactionCurrency?.Code;

        return [
            {
                // NumberOurs is used as summary item with custom label => label in fieldInfo isn't the one we want
                label: PropertyTranslationCache.getCachedTranslation(numberOursBc.getProperty()),
                value: entity.NumberOurs ?? emptyValue
            },
            {
                label: this.props.rootStorage.getInfo(bc.navigate("BusinessPartner/BusinessPartner")).label,
                value: entity.BusinessPartner?.BusinessPartner?.Id ? getIntentLink(entity.BusinessPartner.Name, {
                    route: `${ROUTE_BUSINESS_PARTNER}/${entity.BusinessPartner.BusinessPartner?.Id}`,
                    context: this.props.rootStorage.context,
                    storage: this.props.rootStorage
                }) : emptyValue
            },
            {
                label: this.props.rootStorage.getInfo(bc.navigate(datePath)).label,
                value: entity[datePath] ? DateType.format(entity[datePath] as Date) : emptyValue
            },
            {
                label: this.props.storage.t("Proforma:Pairing.TransactionAmount"),
                value: formatCurrency(entity.TransactionAmount ? entity.TransactionAmount : emptyValue, transactionCurrency)
            },
            {
                label: this.props.rootStorage.getInfo(bc.navigate("TransactionAmountNet")).label,
                value: formatCurrency(entity.TransactionAmountNet ? entity.TransactionAmountNet : emptyValue, transactionCurrency)
            },
            {
                label: this.props.rootStorage.getInfo(bc.navigate("TransactionAmountDue")).label,
                value: formatCurrency(entity.TransactionAmountDue ? entity.TransactionAmountDue : emptyValue, transactionCurrency)
            }
        ];
    }

    get isExpense(): boolean {
        return isReceived(this.props.rootStorage.data.entity.DocumentTypeCode as DocumentTypeCode);
    }

    // When new invoice is created from Proforma, we allow select only one and prefill the entire
    // entity from the proforma data
    get isNewInvoice() {
        return this.props.rootStorage.data.bindingContext.isNew();
    }

    get shouldApplyProformaDataOnInvoice() {
        return this.isNewInvoice && !this.props.rootStorage.isDirty() && !this.props.rootStorage.data.entity.DocumentDraft?.Id;
    }

    isDraftView = (): boolean => {
        return false;
    };

    presetDefaultFilters() {
        const { rootStorage, storage } = this.props;

        const pairedProformas = rootStorage.data.additionalResults[RELATED_PROFORMA_ADDITIONAL_RESULT_IDX];
        const pairedProformaIds = pairedProformas?.map((item: IProformaEntity) => item.Id) ?? [];
        // empty array is considered as "not set", so we need to use "true" by default
        const pairedProformaIdQuery = pairedProformaIds.length > 0 ? transformToODataString(pairedProformaIds, ValueType.Number) : true;

        storage.store({
            predefinedFilters: {
                [NOT_PAIRED_PROFORMAS_LOCAL_FILTER_PATH]: pairedProformaIdQuery
            }
        });
        storage.setFilterValueByPath(NOT_PAIRED_PROFORMAS_LOCAL_FILTER_PATH, pairedProformaIdQuery);

        // initiate default filters
        const { data } = rootStorage;

        if (!this.shouldApplyProformaDataOnInvoice) {
            // user already selects some data in form -> we should match them:
            // -> prefilter proformas by currency
            storage.setFilterValueByPath("TransactionCurrency", [data.entity.TransactionCurrency?.Code]);
        }

        if (isAccountAssignmentCompany(this.props.rootStorage.context)) {
            setNotPostedStatusFilter(storage);
        }

        if (isCashBasisAccountingCompany(this.props.rootStorage.context)) {
            storage.setFilterValueByPath(DocumentStatusLocalPath, [
                createFilterRow({
                    type: ConditionType.Included,
                    value: [
                        getStatusFilterId(EntityTypeName.ClearedStatus, ClearedStatusCode.Cleared)
                    ]
                })
            ]);
        }

        storage.applyFilters();
    }

    isRowWithoutAction(rowId: TId, action: RowAction, row: IRow): boolean {
        const currentDocument = row.customData.entity as IDocumentEntity;
        const isNotPosted = currentDocument.PostedStatusCode === PostedStatusCode.NotPosted;

        return isNotPosted;
    }

    handleTableLoad(): void {
        this._originalLinks = [];
        this._selectedItems = [];

        const document = this.props.rootStorage.data.entity as IDocumentEntity;
        const links = document.DocumentLinks ?? [];

        for (const row of this.props.storage.tableAPI.getRowsArray()) {
            const proformaInvoiceLink = findProformaDocumentLink(links, row.customData.entity.Id);

            if (proformaInvoiceLink) {
                this._originalLinks.push(proformaInvoiceLink);
                this._selectedItems.push(row.id as BindingContext);
            }
        }

        super.handleTableLoad();
    }

    handleToolbarConfirm = async () => {
        const { rootStorage } = this.props;
        const { tableAPI } = this.props.storage;

        const affectedRows = await tableAPI.getAffectedRows();

        // Validate currency that it's not mismatched and is same as document (if it won't be changed potentially)
        const tableState = tableAPI.getState();
        const rowsData = affectedRows.map(row => getRow(tableState.rows, row.bc));
        const mandatoryCurrency = this.shouldApplyProformaDataOnInvoice ? rowsData?.[0].customData.entity.TransactionCurrencyCode
            : rootStorage.data.entity.TransactionCurrency?.Code;

        if (mandatoryCurrency) {
            const rowWithInvalidCurrency = rowsData.find(rowData => rowData.customData.entity.TransactionCurrencyCode !== mandatoryCurrency);
            if (rowWithInvalidCurrency) {
                this.setAlert({
                    status: Status.Error,
                    subTitle: rootStorage.t("Proforma:Pairing.DifferentCurrencies")
                });
                return;
            }
        }

        this.props.setBusy(true);

        if (this.shouldApplyProformaDataOnInvoice && affectedRows?.length === 1) {
            // When user creates new invoice and pair it immediately with just one entity, we prefill
            // the form with the data from proforma
            await fetchAndApplyProformaInvoice(rootStorage, affectedRows[0].bc);
        }

        const entity = rootStorage.data.entity as IDocumentEntity;
        const newLinks: IDocumentLinkEntity[] = [];

        // Keep all links, which are not proforma link type...
        (entity.DocumentLinks ?? []).forEach(link => {
            if (link.TypeCode !== DocumentLinkTypeCode.ProformaInvoiceDeduction) {
                newLinks.push(link);
            }
        });
        // ... adds selected proforma links
        rowsData.forEach(rowData => {
            newLinks.push(createProformaDocumentLink(rowData.id as BindingContext, rowData.customData.entity));
        });

        rootStorage.setDirty(rootStorage.data.bindingContext);
        entity.DocumentLinks = newLinks;

        // load related proformas
        const affectedRowsBc = affectedRows.map(affectedRow => affectedRow.bc);
        const res = await Promise.all([
            rootStorage.updateTabsVisibility([PROFORMA_FORM_TAB, DOCUMENT_CLEARING_FORM_TAB_ID], true),
            loadRelatedProformas(rootStorage.data.bindingContext, rootStorage.oData, affectedRowsBc)
        ]);
        rootStorage.data.additionalResults[RELATED_PROFORMA_ADDITIONAL_RESULT_IDX] = res[1];
        rootStorage.refresh();
        this.props.setBusy(false);

        this.setAlert({
            status: Status.Success,
            subTitle: this.props.storage.t("Proforma:Pairing.SuccessSubtitle")
        });

        this.props.onConfirm?.();
    };
}

export default withBusyIndicator({ passBusyIndicator: true })(PairingProformaTableView);
