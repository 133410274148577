import { GrowthNegativeIcon, GrowthPositiveIcon } from "@components/icon";
import { fetchAndSetItemsByInfo } from "@components/smart/smartSelect/SmartSelectAPI";
import { TEntityKey } from "@evala/odata-metadata/src";
import { getNestedValue, getNewItemsMaxId, setNestedValue } from "@odata/Data.utils";
import { getEntitySetByDocumentType } from "@odata/EntityTypes";
import {
    AccountAssignmentEntity,
    DocumentAccountAssignmentSelectionEntity,
    DocumentEntity,
    EntityTypeName,
    IBankTransactionEntity,
    IPaymentDocumentItemEntity,
    ISplitAccountAssignmentEntity,
    PaymentDocumentEntity,
    PaymentDocumentItemAccountAssignmentEntity,
    PaymentDocumentItemAccountAssignmentSelectionEntity,
    PaymentDocumentItemEntity,
    SelectionEntity
} from "@odata/GeneratedEntityTypes";
import {
    BankTransactionTypeCode,
    DocumentTypeCode,
    PaymentDocumentItemTypeCode,
    SelectionCode
} from "@odata/GeneratedEnums";
import { getInfo, loadAccountsBasedOnDate } from "@pages/accountAssignment/AccountAssignment.utils";
import { ExchangeRateDiffLabel, GainWrapper, IconWrapper } from "@pages/banks/bankTransactions/BankTransactions.styles";
import { getTransactionTypeFromEntity } from "@pages/banks/bankTransactions/BankTransactions.utils";
import { getBankItemDefaultDateAccountingTransaction } from "@pages/banks/Pair.utils";
import { IPaymentDocumentItemEntityExpanded } from "@pages/banks/PairTableView";
import {
    businessPartnerDependentFields,
    loadPartner,
    setSavedContactItems
} from "@pages/businessPartner/BusinessPartner.utils";
import { isMatchingAccount, isReceived } from "@pages/documents/Document.utils";
import i18next from "i18next";
import React from "react";

import {
    BANK_ACCOUNT_BALANCE_SHEET_ACCOUNT_PREFIX,
    BANK_ACCOUNT_PROFORMA_ISSUED,
    BANK_ACCOUNT_PROFORMA_RECEIVED,
    CASH_BOXES_BALANCE_SHEET_ACCOUNT_PREFIX,
    CREATE_CLEARING_SPLIT_ITEMS
} from "../constants";
import { IconSize, Status } from "../enums";
import { TRecordAny } from "../global.types";
import BindingContext, { createPath, IEntity } from "../odata/BindingContext";
import TestIds from "../testIds";
import { getUtcDate } from "../types/Date";
import { FormStorage } from "../views/formView/FormStorage";
import { getCompanyCurrency, isAccountAssignmentCompany, isCashBasisAccountingCompany } from "./CompanyUtils";
import { getDefaultPostParams } from "./customFetch";
import { isDefined, roundToDecimalPlaces } from "./general";

export const hasPairedChanged = (storage: FormStorage, changedData: TRecordAny): boolean => {
    const pairedOrigData = (storage.data.entity.Items || []).filter((link: IPaymentDocumentItemEntity) => {
        return link.LinkedDocument?.Id && link.PaymentDocumentItemTypeCode === PaymentDocumentItemTypeCode.Payment;
    });

    for (const item of pairedOrigData) {
        const id = item?.LinkedDocument?.Id;
        const origItem = changedData[id];
        if (!origItem || origItem.Amount !== item.Amount) {
            return true;
        }
    }

    return Object.keys(changedData)?.length !== pairedOrigData.length;
};

interface IGetExpenseTitleOptions {
    width?: string;
    withMargin?: boolean;
}

export const getExpenseTitle = (isLoss: boolean, options: IGetExpenseTitleOptions = {}): React.ReactNode => {
    const text = i18next.t(`Banks:Pairing.${isLoss ? "ExchangeRateLoss" : "ExchangeRateGain"}`);
    const Icon = isLoss ? GrowthNegativeIcon : GrowthPositiveIcon;

    return (
        <GainWrapper data-testid={TestIds.GainRowLabel} $width={options.width} $withMargin={options.withMargin}
                     data-elementtype="FastEntryCol">
            <IconWrapper>
                <Icon status={isLoss ? Status.Error : Status.Success}
                      width={IconSize.XS}/></IconWrapper>
            <ExchangeRateDiffLabel isLoss={isLoss}>{text}</ExchangeRateDiffLabel>
        </GainWrapper>
    );
};

interface ICreateGainArgs {
    gain?: IPaymentDocumentItemEntity;
    item: IPaymentDocumentItemEntity;
    splitItem?: ISplitAccountAssignmentEntity;
    amount: number;
    storage: FormStorage;
    newMax: number;
    date?: Date;
}

export const createNewGain = async (args: ICreateGainArgs) => {
    const tValue = Math.abs(args.amount);

    const currency = args.storage.data.entity?.TransactionCurrency?.Code;
    if (args.gain) {
        args.gain.PaymentDocumentItemTypeCode = args.amount > 0 ? PaymentDocumentItemTypeCode.ExchangeGain : PaymentDocumentItemTypeCode.ExchangeLoss;
        args.gain.LinkedDocument = { ...args.item.LinkedDocument };
    } else {
        args.gain = BindingContext.createNewEntity<IPaymentDocumentItemEntity>(args.newMax ?? 1, {
            PaymentDocumentItemTypeCode: args.amount > 0 ? PaymentDocumentItemTypeCode.ExchangeGain : PaymentDocumentItemTypeCode.ExchangeLoss,
            DateAccountingTransaction: args.item.DateAccountingTransaction,
            LinkedDocument: { ...args.item.LinkedDocument }
        });
    }

    args.gain.Amount = tValue;
    args.gain.TransactionAmount = currency === getCompanyCurrency(args.storage.context) ? args.gain.Amount : 0;

    const isGain = args.gain.PaymentDocumentItemTypeCode === PaymentDocumentItemTypeCode.ExchangeGain;

    const accounts = await loadAccountsBasedOnDate(args.storage, args.date ?? getUtcDate());
    const type = getTransactionTypeFromEntity(args.storage);
    const isIncoming = type === BankTransactionTypeCode.IncomingPayment;
    const sourcePropName = isIncoming ? "CreditAccount" : "DebitAccount";

    const propName = isGain ? "DebitAccount" : "CreditAccount";
    const propName1 = isGain ? "CreditAccount" : "DebitAccount";
    const defVal = isGain ? "663" : "563";

    const defAcc = accounts.find((obj: TRecordAny) => isMatchingAccount(obj, { Number: defVal }, true));
    if (defAcc) {
        setNestedValue({
            Id: defAcc.Id,
            Number: defAcc.Number,
            Name: defAcc.Name
        }, `AccountAssignmentSelection/AccountAssignment/${propName1}`, args.gain);
    }

    const prefix = args.splitItem ? "" : "AccountAssignmentSelection/AccountAssignment/";
    const sourcePath = `${prefix}${sourcePropName}`;
    const acc = getNestedValue(sourcePath, args.splitItem ?? args.item);

    const path = `AccountAssignmentSelection/AccountAssignment/${propName}`;
    if (acc) {
        setNestedValue({
            Id: acc.Id,
            Name: acc.Name,
            Number: acc.Number
        }, path, args.gain);
    }

    setNestedValue(SelectionCode.Own, "AccountAssignmentSelection/AccountAssignment/Id", args.gain);
    setNestedValue(SelectionCode.Own, "AccountAssignmentSelection/Selection/Code", args.gain);

    const name = isGain ? i18next.t("Banks:Pairing.ExchangeGain") : i18next.t("Banks:Pairing.ExchangeLoss");
    setNestedValue(name, "AccountAssignmentSelection/AccountAssignment/Name", args.gain);

    return args.gain;
};

export const loadCreditData = async (storage: FormStorage) => {
    const caInfo = getInfo(storage, `Items/AccountAssignmentSelection/AccountAssignment/CreditAccount`),
        daInfo = getInfo(storage, `Items/AccountAssignmentSelection/AccountAssignment/DebitAccount`);

    const cas = caInfo.fieldSettings.items;
    const das = daInfo.fieldSettings.items;

    const promises = [];

    if (!cas) {
        promises.push(fetchAndSetItemsByInfo(storage, caInfo));
    }

    if (!das) {
        promises.push(fetchAndSetItemsByInfo(storage, daInfo));
    }

    const [_cas, _das] = await Promise.all(promises);

    return {
        cas: cas || _cas || [],
        das: das || _das || []
    };
};

export const getAccNumberFromPair = (pair: IPaymentDocumentItemEntityExpanded, isReceived: boolean) => {
    const accName = isReceived ? "CreditAccount" : "DebitAccount";
    return Number(getNestedValue(`AccountAssignmentSelection/AccountAssignment/${accName}/Number`, pair));
};


export const getRoundedSplitSum = (item: IPaymentDocumentItemEntity, storage: FormStorage) => {
    const sum = item.SplitAccountAssignments?.reduce((accumulator: number, object: ISplitAccountAssignmentEntity) => {
        return accumulator + (object.TransactionAmount || 0);
    }, 0) || 0;

    const maximumFractionDigits = storage.data.entity.TransactionCurrency?.MinorUnit ?? 2;
    return roundToDecimalPlaces(maximumFractionDigits, sum);
};

enum DocumentItemsType {
    None,
    Single,
    Split
}

interface ICreateSplitItemsAndAccountNumberArgs {
    documentType: DocumentTypeCode;
    key: number;
    storage: FormStorage;
    isReceived: boolean;
    transactionType: BankTransactionTypeCode;
    isTaxDocument: boolean;
    isBank: boolean;
    transactionDate: Date;
    pairedDoc: IPaymentDocumentItemEntityExpanded;
    maxId: number;
}

export const getFixAccountNumber = (storage: FormStorage, isBank: boolean) => {
    const suffix = _getAccountSuffix(storage);
    const prefix = isBank ? BANK_ACCOUNT_BALANCE_SHEET_ACCOUNT_PREFIX : CASH_BOXES_BALANCE_SHEET_ACCOUNT_PREFIX;
    return `${prefix}${suffix}`;
};


export const createOrUpdatePair = async (args: ICreateSplitItemsAndAccountNumberArgs) => {
    const _getCa = (item: IEntity) => {
        const selectionCode = item.AccountAssignmentSelection.SelectionCode;
        if (selectionCode === SelectionCode.Default) {
            return document.AccountAssignmentSelection?.AccountAssignment?.[loadAccName]?.Number;
        }

        if (selectionCode === SelectionCode.Own || selectionCode === SelectionCode.Copy) {
            return item.AccountAssignmentSelection?.AccountAssignment?.[loadAccName]?.Number;
        }
    };

    const _getDocItemsType = (document: IEntity) => {
        let type = DocumentItemsType.None;
        const items = document?.Items || [];

        for (const item of items) {
            const itemNumber = _getCa(item);
            if (assNumber !== itemNumber) {
                if (assNumber) {
                    type = DocumentItemsType.Split;
                    break;
                }

                type = DocumentItemsType.Single;
            }

            assNumber = itemNumber;
        }

        return type;
    };

    const _loadDoc = async (): Promise<IEntity> => {
        const entitySet = getEntitySetByDocumentType(args.documentType);
        const query = args.storage.oData.getEntitySetWrapper(entitySet)
            .query(args.key)
            .expand(DocumentEntity.AccountAssignmentSelection,
                q => q.expand(DocumentAccountAssignmentSelectionEntity.AccountAssignment, q => q.expand(loadAccName)))
            .expand(PaymentDocumentEntity.Items,
                q => q.expand(DocumentEntity.AccountAssignmentSelection,
                    q => q.expand(DocumentAccountAssignmentSelectionEntity.AccountAssignment, q => q.expand(loadAccName)))
            );

        const result = await query.fetchData<IEntity[]>();
        return result?.value as IEntity;
    };

    const _manageSingleAccount = async () => {
        const accounts = await loadAccountsBasedOnDate(args.storage, args.transactionDate);
        const fixNumberAcc = getFixAccountNumber(args.storage, args.isBank);
        const fixAcc = accounts?.find(a => isMatchingAccount(a, { Number: fixNumberAcc }, true));
        const account = accounts?.find(a => isMatchingAccount(a, { Number: assNumber?.toString() }, true));

        if ((args.isReceived && fixAcc) || (!args.isReceived && account)) {
            const fixPath = createPath(PaymentDocumentItemEntity.AccountAssignmentSelection, PaymentDocumentItemAccountAssignmentSelectionEntity.AccountAssignment, fixAccName);
            if (fixAcc) {
                setNestedValue({
                    Id: fixAcc.Id,
                    Number: fixAcc.Number,
                    Name: fixAcc.Name
                }, fixPath, pair);
            } else {
                // account doesn't exist in selected fiscal year
                setNestedValue(null, fixPath, pair);
            }

            const path = createPath(PaymentDocumentItemEntity.AccountAssignmentSelection, PaymentDocumentItemAccountAssignmentSelectionEntity.AccountAssignment, setAccName);
            if (account) {
                setNestedValue({
                    Id: account.Id,
                    Number: account.Number,
                    Name: account.Name
                }, path, pair);
            } else {
                // account doesn't exist in selected fiscal year
                setNestedValue(null, path, pair);
            }


            pair.AccountAssignmentSelection.AccountAssignment.Name = args.transactionType === BankTransactionTypeCode.IncomingPayment ?
                args.storage.t("Banks:Pairing.PaymentIncoming") : args.storage.t("Banks:Pairing.PaymentOutgoing");

            pair.AccountAssignmentSelection.AccountAssignment.ShortName =
                `${pair.AccountAssignmentSelection.AccountAssignment.DebitAccount?.Number} / ${pair.AccountAssignmentSelection.AccountAssignment.CreditAccount?.Number}`;

            setNestedValue(SelectionCode.Own,
                createPath(PaymentDocumentItemEntity.AccountAssignmentSelection, PaymentDocumentItemAccountAssignmentSelectionEntity.AccountAssignment, AccountAssignmentEntity.Id), pair);
            setNestedValue(SelectionCode.Own,
                createPath(PaymentDocumentItemEntity.AccountAssignmentSelection, PaymentDocumentItemAccountAssignmentSelectionEntity.Selection, SelectionEntity.Code), pair);
        }
    };

    const _manageSplitAccount = async () => {
        splitRequestData = {
            DocumentId: args.key,
            Amount: args.pairedDoc.Amount,
            TransactionAmount: args.pairedDoc.TransactionAmount,
            ClearedAmount: args.pairedDoc.ClearedAmount ?? args.pairedDoc.TransactionAmount,
            ClearingDate: args.transactionDate
        };

        setNestedValue(SelectionCode.Split, "AccountAssignmentSelection/AccountAssignment/Id", pair);
        setNestedValue(SelectionCode.Split, "AccountAssignmentSelection/Selection/Code", pair);
    };


    // !! LEGEND !!
    // debit/credit, MD/D
    // Incoming Payment => 221.../X where X can be both based on document AR, InvoiceReceived = Credit, InvoiceIssued = Debit
    // Outgoing payment => X/221.... where X is the same is in incoming payment

    const fixAccName = args.transactionType === BankTransactionTypeCode.IncomingPayment ? PaymentDocumentItemAccountAssignmentEntity.DebitAccount : PaymentDocumentItemAccountAssignmentEntity.CreditAccount;
    const setAccName = args.transactionType === BankTransactionTypeCode.IncomingPayment ? PaymentDocumentItemAccountAssignmentEntity.CreditAccount : PaymentDocumentItemAccountAssignmentEntity.DebitAccount;
    const loadAccName = args.isReceived ? PaymentDocumentItemAccountAssignmentEntity.CreditAccount : PaymentDocumentItemAccountAssignmentEntity.DebitAccount;

    const hasAccountAssignment = isAccountAssignmentCompany(args.storage.context);
    const isNew = !isDefined(args.pairedDoc?.Id) && !isDefined(args.pairedDoc?.[BindingContext.NEW_ENTITY_ID_PROP]);
    const pair = isNew ? BindingContext.createNewEntity(args.maxId, args.pairedDoc) : args.pairedDoc;
    const pairedDoc = args.pairedDoc;
    const isChanged = isNew || (pairedDoc.OrigData?.Amount !== pairedDoc.Amount || pairedDoc.OrigData?.ClearedAmount !== pairedDoc.ClearedAmount);

    let type = DocumentItemsType.None;

    let splitRequestData: IEntity;

    let assNumber = 0;
    let document: IEntity;

    // if it is not newly created pair we don't overwrite it, user may change it
    if (isChanged) {
        if (!args.isTaxDocument && (args.documentType === DocumentTypeCode.ProformaInvoiceIssued || args.documentType === DocumentTypeCode.ProformaInvoiceReceived)) {
            assNumber = args.documentType === DocumentTypeCode.ProformaInvoiceReceived ? BANK_ACCOUNT_PROFORMA_RECEIVED : BANK_ACCOUNT_PROFORMA_ISSUED;
            type = DocumentItemsType.Single;
        } else {
            document = await _loadDoc();
            type = _getDocItemsType(document);
        }

        // split account has to be calculated every time because it operates with number and they can be changed in dialog
        if (hasAccountAssignment) {
            switch (type) {
                case DocumentItemsType.Single:
                    await _manageSingleAccount();
                    break;

                case DocumentItemsType.Split:
                    await _manageSplitAccount();
                    break;
            }
        }
    }

    const scale = args.storage.data.entity.TransactionCurrency?.MinorUnit ?? 2;

    pair.PaymentDocumentItemTypeCode = PaymentDocumentItemTypeCode.Payment;
    pair.TransactionAmount = roundToDecimalPlaces(scale, pairedDoc.TransactionAmount);
    pair.Amount = roundToDecimalPlaces(scale, pairedDoc.Amount);
    pair.ClearedAmount = pairedDoc.ClearedAmount;
    pair.ExchangeRate = pairedDoc.ExchangeRate;
    pair.LinkedDocument.ExchangeRatePerUnit = pairedDoc.LinkedDocument.ExchangeRatePerUnit;
    if (hasAccountAssignment) {
        pair.DateAccountingTransaction = pairedDoc.DateAccountingTransaction ?? getUtcDate();
    }

    let gains: IPaymentDocumentItemEntity[];

    if (isChanged) {
        const exchangeGain = pairedDoc.ExchangeGain;
        if (type === DocumentItemsType.Single && exchangeGain && !isCashBasisAccountingCompany(args.storage.context)) {
            gains = [await createNewGain({
                item: pair,
                gain: pair.Gains?.[0],
                amount: exchangeGain,
                storage: args.storage,
                newMax: args.maxId + 1,
                date: pair?.DateAccountingTransaction ?? getBankItemDefaultDateAccountingTransaction(args.storage)
            })];
        }
    } else {
        gains = pairedDoc.Gains;
    }

    delete pair.ExchangeGain;
    delete pair.IsTaxDocument;
    delete pair.Gains;
    delete pair.OrigData;

    return { pair, gains, splitRequestData };
};

const _getAccountSuffix = (storage: FormStorage) => {
    return storage.data.entity.BankStatement?.BankAccount?.BalanceSheetAccountNumberSuffix ?? storage.data.entity?.CashBox?.BalanceSheetAccountNumberSuffix;
};


const getBusinessPartnerData = (items: IPaymentDocumentItemEntity[]) => {
    let bpId: number = undefined;
    for (const item of items) {
        const currentBpId = item?.LinkedDocument?.BusinessPartner?.BusinessPartner?.Id;
        if (currentBpId && currentBpId !== bpId) {
            if (bpId) {
                return {
                    Id: undefined,
                    isSet: true,
                };
            }

            bpId = currentBpId;
        }
    }

    return {
        Id: bpId,
        isSet: isDefined(bpId) ? true : false
    };
};

export const correctBpForLinkedDocument = async (storage: FormStorage, entity: IBankTransactionEntity, items: IPaymentDocumentItemEntity[]) => {
    const bpData = getBusinessPartnerData(items);
    if (isDefined(bpData.Id)) {
        const bp = await loadPartner(bpData.Id.toString(), storage.oData);
        if (bp) {
            storage.processDependentField(businessPartnerDependentFields(), bp, storage.data.bindingContext);
            setSavedContactItems(storage, bp.Contacts);
        }
    } else {
        if (bpData.isSet) {
            entity.BusinessPartner = null;
            setSavedContactItems(storage, []);
        }
    }
}

export const savePairChanges = async (storage: FormStorage, pairedDocs: Record<TEntityKey, IPaymentDocumentItemEntityExpanded>, type: BankTransactionTypeCode) => {
    const entity = storage.data.entity as IBankTransactionEntity;

    if (!entity.Items) {
        entity.Items = [];
    }

    const entityType = storage.data.bindingContext?.getEntityType()?.getName?.();
    const isBank = entityType === EntityTypeName.BankTransaction;

    let maxNew = getNewItemsMaxId(entity.Items) + 1;
    let items: IPaymentDocumentItemEntityExpanded[] = [];

    if (isBank) {
        await correctBpForLinkedDocument(storage, entity, Object.values(pairedDocs) as IPaymentDocumentItemEntity[]);
    }

    const splitData: IEntity[] = [];

    const transactionDate = getBankItemDefaultDateAccountingTransaction(storage);

    for (const [key, link] of Object.entries(pairedDocs)) {
        if (link.Amount === 0) {
            delete pairedDocs[key];
            continue;
        }

        const documentType = link?.LinkedDocument.DocumentTypeCode as DocumentTypeCode;
        const _isReceived = isReceived(documentType as DocumentTypeCode);

        const isTaxDocument = link?.IsTaxDocument;

        const { pair, gains, splitRequestData } = (await createOrUpdatePair({
            documentType, storage, isReceived: _isReceived, isTaxDocument, isBank, transactionDate,
            maxId: maxNew++,
            transactionType: type,
            key: Number(key),
            pairedDoc: link
        }));

        if (splitRequestData) {
            splitData.push(splitRequestData);
        }

        items.push(pair);

        if (gains?.length > 0) {
            items = items.concat(gains);
            maxNew += gains.length;
        }
    }

    if (splitData.length > 0) {
        const url = `${CREATE_CLEARING_SPLIT_ITEMS}?CompanyId=${storage.context.getCompany().Id}`;
        const Payment = {
            AccountNumber: getFixAccountNumber(storage, isBank),
            PaymentDate: transactionDate,
            CurrencyCode: storage.context.getDefaultCurrency(),
            TransactionCurrencyCode: entity.TransactionCurrency?.Code,
            ExchangeRatePerUnit: entity.ExchangeRatePerUnit ?? 1,
            IsCredit: type === BankTransactionTypeCode.IncomingPayment,
            IsDebit: type === BankTransactionTypeCode.OutgoingPayment
        };

        const response = await fetch(url, {
            ...getDefaultPostParams(),
            body: JSON.stringify({
                Payment,
                ClearedDocuments: splitData
            })
        });

        const results = await response.json();
        for (const returnedItem of results) {
            const item = items.find(item => item.LinkedDocument.Id === returnedItem.DocumentId);
            if (returnedItem.SplitAccountAssignments?.length > 0) {
                const splitItems = [];
                let i = 0;
                for (const split of returnedItem.SplitAccountAssignments) {
                    splitItems.push({
                        [BindingContext.NEW_ENTITY_ID_PROP]: `${BindingContext.NEW_ENTITY_ID_PROP} = ${i++}`,
                        Amount: split.Amount,
                        TransactionAmount: split.TransactionAmount,
                        DebitAccount: {
                            Name: split.DebitAccount.Name,
                            Number: split.DebitAccount.Number,
                            Id: split.DebitAccount.Id
                        },
                        CreditAccount: {
                            Name: split.CreditAccount.Name,
                            Number: split.CreditAccount.Number,
                            Id: split.CreditAccount.Id
                        },
                        Description: split.Description
                    });
                }
                item.SplitAccountAssignments = splitItems;
            }

            for (const ex of returnedItem.ExchangeRateDifferences) {
                items.push({
                    [BindingContext.NEW_ENTITY_ID_PROP]: ++maxNew,
                    Amount: ex.Amount,
                    TransactionAmount: ex.TransactionAmount,
                    DateAccountingTransaction: transactionDate,
                    LinkedDocument: { ...item.LinkedDocument },
                    PaymentDocumentItemTypeCode: ex.PaymentDocumentItemTypeCode,
                    AccountAssignmentSelection: {
                        AccountAssignment: {
                            Id: ex.AccountAssignmentSelection.AccountAssignment.Id,
                            Name: ex.AccountAssignmentSelection.AccountAssignment.Name,
                            ShortName: ex.AccountAssignmentSelection.AccountAssignment.ShortName,
                            CreditAccount: {
                                Id: ex.AccountAssignmentSelection.AccountAssignment.CreditAccount.Id,
                                Number: ex.AccountAssignmentSelection.AccountAssignment.CreditAccount.Number,
                                Name: ex.AccountAssignmentSelection.AccountAssignment.CreditAccount.Name
                            },
                            DebitAccount: {
                                Id: ex.AccountAssignmentSelection.AccountAssignment.DebitAccount.Id,
                                Number: ex.AccountAssignmentSelection.AccountAssignment.DebitAccount.Number,
                                Name: ex.AccountAssignmentSelection.AccountAssignment.DebitAccount.Name
                            }
                        },
                        Selection: {
                            Code: ex.AccountAssignmentSelection.Code
                        }
                    }
                });
            }
        }
    }

    const unpairedDocs = (storage.data.entity.Items || []).filter((link: IEntity) => {
        return !link.LinkedDocument?.Id;
    });

    const _setSplitError = (item: IPaymentDocumentItemEntity) => {
        const id = item.Id ?? `#id=${(item as TRecordAny)[BindingContext.NEW_ENTITY_ID_PROP]}`;
        const _path = `Items(${id})/AccountAssignmentSelection/AccountAssignment`;
        const bc = storage.data.bindingContext.navigate(_path);

        if (item.LinkedDocument?.Id && (
            !item.AccountAssignmentSelection?.Selection?.Code || (item.AccountAssignmentSelection?.Selection?.Code === SelectionCode.Split && !item.SplitAccountAssignments))) {
            storage.setError(bc, {
                message: i18next.t("Banks:Transactions.MissingSplit")
            });
        }
    };

    const mergedItems = items.concat(unpairedDocs).map((item) => {
        _setSplitError(item);
        return item;
    });

    storage.data.entity.Items = mergedItems;
    storage.handleFirstChange();
};